import React from 'react';
import { TextInput, SelectInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    gender: { display: 'inline-block', marginLeft: '2em' },
    contact_person: { display: 'inline-block', width: 120 }
})

const AvatarField = ({ record, size, classes }) => (
    <div style={{ display: 'flex' }}>
        <SelectInput formClassName={classes.contact_person}
            style={{ width: 120, minWidth: 120 }} label="contact_person"
            source="contact_person" choices={[
                { id: '母亲', name: '母亲' },
                { id: '父亲', name: '父亲' },
                { id: '本人', name: '本人' },
                { id: '奶奶', name: '奶奶' },
                { id: '爷爷', name: '爷爷' },
                { id: '其他', name: '其他' }
            ]} />
        <TextInput style={{ width: 200 }} label="phone" source="phone" />

    </div>
);


export default withStyles(styles)(AvatarField);