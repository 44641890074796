import React, {Component, Fragment} from 'react';
import { ArrayInput, List as RaList, Datagrid, DateField, CREATE,  TextField, Create, SingleFieldList, ChipField, 
     SimpleForm, required, FunctionField, TextInput, SelectInput, Edit, Filter, EditButton, withDataProvider, refreshView as refreshViewAction, } from 'react-admin';
import { refreshView } from 'ra-core';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon, SMSIcon, PreviewIcon, SMSFailedIcon, SMSSuccessIcon } from '../../components/Icons';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import DateInput from '../../components/Inputs/DateInput';
import TableIterator from '../../components/Iterators/TableIterator';
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux';
import FromNowField from '../../components/Fields/FromNowField';
import FormMaker from '../../components/Inputs/FormMaker';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import QRCode from 'qrcode.react';
import Typography from '@material-ui/core/Typography';
import AggregationField from '../../components/Fields/AggregationField';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';

const selector = formValueSelector('record-form')

const styles = theme => ({
    array: {
        width: '400px !important'
    },
    dense: {
        width: '130px !important',
    },
    arraySlim: {
        width: '80px !important',
    }
});

const CampaignFilters = props => {
    return (
      <Filter {...ShowFilterWhenHasOp(props)}>
          <TextInput  source="name" label="活动名称" />
          <SelectInput source="media" label="营销媒介" 
            choices={[
            { id: '社交媒体', name: '社交媒体' },
            { id: '微信', name: '微信' },
            { id: '地推', name: '地推' },
            { id: '试听课', name: '试听课' },
            { id: '付费推广', name: '付费推广' },
            { id: '公开课', name: '公开课' },
            { id: '其他线上', name: '其他线上' },
            { id: '其他线下', name: '其他线下' },
          ]} />
          <TextInput source="source" label="营销场景" />
          <SelectInput source="status" label="活动状态" 
            choices={[
            { id: 'running', name: '进行中' },
            { id: 'yet', name: '未开始' },
            { id: 'over', name: '已结束' }
          ]} />
    </Filter>
    )
}

class Campaigns extends Component {
    state = {
        checked: []
    }

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
          checked: newChecked,
        });
    };

    sendSms = () => {
        this.props.dataProvider(CREATE, 'sms-temps', { 
                                data: 
                                { campaignId: this.state.record.id,
                                  shortId: this.state.record.shortId,
                                  name: this.state.record.name,
                                  branchId: this.state.record.branchId,
                                  temps: this.state.record.temps
                                                .filter((t, i) => this.state.checked.indexOf(i) >= 0)
                                                .map(t => ({
                                                    id: t.campaigns_temps.tempId,
                                                    phone: t.phone
                                                }))
                                }}).then(() => this.setState({ record: null, checked: [] }, 
                                    () => this.props.dispatch(refreshView()) ) );
    }

    render () {
        let props = this.props;
        return (
            <Fragment>
              <RaList actions={<ListActions />}  {...props} filters={<CampaignFilters />}>
                   <Datagrid>
                       <TextField source="name" label="活动名称"/>
                       <TextField source="media" label="活动媒介"/>
                       <AggregationField reference="campaigns" targetId="campaignId" target="students" 
                            filter={{ getStudentsCount: true, registered: true }}  label="已报名学生">
                             <FunctionField render={record => record   } />
                        </AggregationField>

                        <AggregationField reference="campaigns" targetId="campaignId" target="intent-students" 
                            filter={{ getStudentsCount: true, registered: false }}  label="未报名学生">
                             <FunctionField render={record => record   } />
                        </AggregationField>

                        <FunctionField label="推广员工" render={record => 
                            record.temps && record.temps.length > 0 &&
                            <Button size="small"
                                onClick={() => this.setState({ record: record })}> 
                                <SMSIcon />
                                发送登录短信
                            </Button>
                        } />

                        <FunctionField label="表单预览" render={record => 
                            record.form && record.form.design &&
                            <Button size="small"
                                onClick={event => this.setState({ anchorEl: event.currentTarget,
                                                                  id: record.id,
                                                                  branchId: record.branchId })}>
                                <PreviewIcon />
                                表单预览
                            </Button>
                        } />

                       <DateField locales="ZH" source="startAt" label="开始日期" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'}} />
                       <DateField locales="ZH" source="endAt" label="结束日期" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'}} />
                       {/* <FunctionField label="活动状态" render={record => 
                             !record.startAt ? '进行中' :
                             record.name === '朋友圈分享' ? '进行中' :
                             '已结束'
                        } /> */}
                         <TextField sortable={false} source="status" label="活动状态"/>
                       <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                       
                       <EditButton icon={<EditIcon />}/>
                       <CloneButton />
                   </Datagrid>
               </RaList>
    
               <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.record}>
                    <DialogTitle >发送通知短信</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            每条短信都会产生额外费用， 请勿重复发送.
                        </DialogContentText>
                        <List disablePadding  dense style={{ minWidth: 320 }}>
                        　{
                            this.state.record && 
                            this.state.record.temps.map((temp, i) => (
                                <ListItem
                                    key={i}
                                    dense
                                    button disableGutters
                                    onClick={this.handleToggle(i)}
                                    >
                                    <Checkbox
                                        checked={this.state.checked.indexOf(i) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={temp.phone} secondary={temp.name} />
                                    <ListItemSecondaryAction>
                                        {
                                            temp.campaigns_temps.sentAt ? 
                                             <Typography variant="body2"> 已发送 <SMSSuccessIcon /> </Typography>:
                                            temp.campaigns_temps.error ? <Typography variant="body2"> {temp.campaigns_temps.error} <SMSFailedIcon /> </Typography>
                                                : <Typography variant="body2">未发送</Typography>
                                        }
                                       
                                    </ListItemSecondaryAction>
                                    </ListItem>
                            ))}
                        </List>    
                    </DialogContent>
                    <DialogActions>
                    <Button style={{fontSize: 16}} onClick={() => this.setState({ record: null, checked: [] })} color="primary">
                        关闭
                    </Button>
                    <Button disabled={this.state.checked.length === 0} 
                          onClick={this.sendSms} color="primary">
                        发送
                    </Button>
                    </DialogActions>
                </Dialog>
                <Popover
                    open={this.state.anchorEl}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({anchorEl: null})}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    >
                    <div style={{padding: 16}}>
                        <a target="_blank" href={`https://${window.location.host}/yywd/campaigns/${this.state.id}/index.html?campaign=${this.state.id}&branch=${this.state.branchId}`} >表单链接</a>
                        <div style={{ display: 'block', marginTop: 8 }}>
                        <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                             扫码在手机中预览表单
                        </Typography>
                            <br />
                            <QRCode size="256" value={`https://${window.location.host}/yywd/campaigns/${this.state.id}/index.html?campaign=${this.state.id}&branch=${this.state.branchId}`} />
                        </div>
                    </div>
                </Popover>
            </Fragment>)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        refreshView: refreshViewAction
    }
}

export const CampaignList = compose(withDataProvider, 
                            connect(null, mapDispatchToProps),
                            withStyles())(Campaigns);

const create = (props) => 
{
    return (
            <Create title="新建营销活动" resource="campaigns" location="/campaigns" 
                    basePath="campaigns" actions={<FormContainerActions />}  {...props} > 
                <Form {...props}/>
            </Create> 
    )
}

const edit = (props) => (
    <Edit title="编辑营销活动" resource="campaigns" location="/campaigns" 
            basePath="campaigns" actions={<FormContainerActions />}  {...props}>
         <Form {...props}/>
    </Edit>
)

const Form = props => 
 <SimpleForm {...props} submitOnEnter={true} defaultValue={{ }}  
    //validate={}
    toolbar= { <FormToolbar /> } redirect="list">
     <TextInput validate={required()} source="name" label="活动名称" />
     <SelectInput validate={required()} source="media" label="营销媒介" 
            choices={[
            { id: '社交媒体', name: '社交媒体' },
            { id: '微信', name: '微信' },
            { id: '地推', name: '地推' },
            { id: '试听课', name: '试听课' },
            { id: '付费推广', name: '付费推广' },
            { id: '公开课', name: '公开课' },
            { id: '其他线上', name: '其他线上' },
            { id: '其他线下', name: '其他线下' },
    ]} />
     <TextInput source="source" label="营销场景"
        placeholder="比如：微信文章， 地推地点， Email， 百度营销"  />
     <DateInput source="startAt" label="活动开始日期"
        showTimeSelect={true} timeIntervals={15}/>
     <DateInput source="endAt" label="活动结束日期" 
        showTimeSelect={true} timeIntervals={15}/>

     <FormMaker source="form" label="名单表单" />
     <ArrayInput classes={{root: props.classes.array}} 
        source="temps" label="推广活动临时员工">
        <TableIterator>
            <TextInput classes={{ root: props.classes.dense }} validate={required()}
                source="phone" label="临时员工手机号" placeholder="用于临时员工登录" />
            <TextInput classes={{ root: props.classes.dense }} 
                source="name" label="临时员工名(非必须）" /> 
        </TableIterator>
     </ArrayInput>
    
    {/*

    <ReferenceArrayInput source="tag_ids" reference="courses">
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput> */}

</SimpleForm>

export const CreateCampaign = compose(withStyles(styles),
                                connect(state => ({ meida: selector(state, 'meida') })))(create);
export const EditCampaign = compose(withStyles(styles),
                                connect(state => ({ meida: selector(state, 'meida') })))(edit);