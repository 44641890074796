import React, { Component } from 'react'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = BigCalendar.momentLocalizer(moment)

class Calendar extends Component {
    render() {
        const minTime = new Date()
        minTime.setHours(8, 0, 0)
        const maxTime = new Date()
        maxTime.setHours(22, 30, 0)
        return (
            <div className="Calendar">
                <BigCalendar
                    localizer={localizer}
                    min={minTime}
                    max={maxTime}
                    defaultView="week"
                    height="1000px"
                    style={{ width: "100%", height: "1000px" }}
                    startAccessor={event => new Date(event.beginAt)}
                    endAccessor={event => new Date(event.endAt)}
                    titleAccessor={event => event.class.name}
                    tooltipAccessor={event => event.class.name}
                    messages={{
                        month: '按月查看',
                        week: '按周查看',
                        day: '按天查看',
                        agenda: '日程',
                        today: '今天',
                        previous: '<向前翻页',
                        next: '向后翻页>',
                    }}
                    {...this.props}
                />
            </div>
        )
    }
}


export default Calendar