import React, { Component, Fragment } from 'react';
import {
    List, Responsive, SimpleList, Datagrid, DateField, EditButton, FunctionField, TextField, Create, ImageInput, ImageField, Button, SingleFieldList,
    ReferenceInput, TabbedForm, FormTab, TextInput, SelectInput, Edit, Filter, LongTextInput, ReferenceManyField,　AutocompleteInput, translate, ReferenceField
} from 'react-admin';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import {
    EditIcon, CalendarIcon, OrderListIcon, RegisterIcon, BuyMaterialIcon, CommunicationIcon, HomeworkIcon,
    StudentBasicInfoIcon, ClassAttendanceLogIcon, AssignClassIcon, BirthDayIcon, IncidentalIcon
} from '../../components/Icons';
import { CommunicationList } from './communications';
import { HomeworkList } from './homeworks';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import formCardStyle from '../../assets/style/formCard';
import ContactField from '../../components/Fields/ContactField';
import { CourseList, ApplyCourse } from './courses';
import { connect } from 'react-redux';
import DateInput from '../../components/Inputs/DateInput';
import DateFnsUtils from '@date-io/date-fns';
import NameAvatarField from '../../components/Fields/NameAvatarField';
import GenderAvatar from '../../components/Fields/GenderAvatar';
import IconButton from '@material-ui/core/IconButton';
import { Button as MuiButton } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { TransactionItemsShow, PrintContract, CreateTransaction } from './transactions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import {Rollcalls} from './rollcall';
import Materials from './materials';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import PhoneField from '../../components/Fields/PhoneField';
import FromNowField from '../../components/Fields/FromNowField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';

const moment = require('moment');

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

const styles = theme => ({
    leftInput: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    dateInputRoot: {
        width: 152,
        minWidth: 152
    },
    tertiary: {
        textAlign: 'right',
        marginRight: 16
    },
    actionIcon: {
        fill: theme.palette.primary.main
    },
    ...formCardStyle(theme)
})

const validateStudentCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['学员名字不能为空'];
    }
    return errors
};

const fields = [
    { source: "extra_attributes.prop1", label: "属性1" },
    { source: "extra_attributes.prop2", label: "属性2" },
    { source: "extra_attributes.prop3", label: "属性3" },
]

const StudentFilter = props => {
    return (
        <Filter {...ShowFilterWhenHasOp(props)}>
          <ReferenceInput label="姓名(汉字或拼音)" alwaysOn source="name" reference={props.resource} 
            filterToQuery={searchText => ({ 'hanzi_pinyin[$iLike]': `%${searchText}%` })} >
            <AutocompleteInput  optionValue="name" optionText={student => student.name || '' } />
        </ReferenceInput>

        <ReferenceInput label="手机号" alwaysOn source="phone" reference={props.resource} 
            filterToQuery={searchText => ({ 'phone[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="phone" optionText={student => student.phone || '' } />
        </ReferenceInput>

        <DateInput source="birthday" label="生日" />
        <TextInput source="wechat" label="微信号" />
        {/* <ReferenceInput label="微信号" source="wechat" reference={props.resource} 
            filterToQuery={searchText => ({ 'wechat[$iLike]': `%${searchText}%` })} >
           <AutocompleteInput optionValue="wechat" optionText={student => student.wechat || '' } />
        </ReferenceInput> */}
        {/* {
            fields.map(f =>
                <TextInput label={f.label} source={f.source} />
            )
        } */}
    </Filter>
    );
}

class BaseStudents extends Component {
    state = { open: false }
    handleClick = (record, event) => {
        this.setState({record: record, anchorEl: event.currentTarget, open: true });
        event.stopPropagation();
        event.preventDefault();
    };

    handleClose = () => {
        this.setState({ anchorEl: null, open: false });
    };

    render() {
        const { anchorEl } = this.state;
        return (
            <Fragment>
                <List title="学生列表" bulkActionButtons={false}
                    actions={<ListActions user={this.props.user} customActions={this.props.customActions} />}
                    filters={<StudentFilter />} {...this.props}>
                    <Responsive
                        small={
                            <SimpleList classes={this.props.classes}
                                leftAvatar={record => <GenderAvatar person={record} />}
                                primaryText={record => record.name}
                                secondaryText={record => <span><BirthDayIcon style={{ width: 13, height: 13 }} /> 
                                {record.birthday ? `${moment(record.birthday).format('YYYY-MM-DD')}` : '-'} </span>}
                                
                                // tertiaryText={record => record.phone ? <span>{`${record.phone}`} {`(${record.contact_person || ''})`}</span> : '未录入电话'}

                                rightIcon={record => (
                                    <Fragment>
                                        <IconButton
                                            aria-label="More"
                                            aria-owns={anchorEl ? 'long-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleClick.bind(this, record)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Fragment>　
                                )
                                }
                            />
                        }
                        medium={
                            <Datagrid>
                                <NameAvatarField label="student.name"/>
                              
                                <DateField label="student.birthday" locales="ZH" options={{ year: 'numeric', month: 'long', day: 'numeric' }} source="birthday" />
                                <TextField label="student.gender" source="gender" />
                                <TextField label="contact_person" source="contact_person" />
                                {
                                    this.props.user && this.props.user.name === 'admin' ?
                                    <PhoneField label="phone" source="phone" /> : null
                                }
                               
                                {
                                    this.props.additionalColumns ?
                                    this.props.additionalColumns : null
                                }
                                
                                <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                                {/* <ReferenceField perPage={10000} allowEmpty label="创建人" source="updatedBy" reference="employees">
                                    <TextField source="name" />
                                </ReferenceField> */}
                                <ButtonContainer>
                                    {this.props.additionalRowButtons && this.props.additionalRowButtons.map(button =>
                                        React.cloneElement(button, {
                                            ...this.props
                                        }))}
                                </ButtonContainer>

                                <EditButton icon={<EditIcon />} />
                            </Datagrid>}
                    />
                </List>
                <ApplyCourse />
                <CreateTransaction resource={this.props.resource}/>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }} >
                    {
                        this.props.additionalRowButtons && this.props.additionalRowButtons.map(button =>
                        (
                            <div>　{
                                React.cloneElement(button, {
                                    ...this.props,
                                    record: this.state.record
                                })
                            }
                            </div>
                        ))
                     }
                    </Popover>
            </Fragment>
        )
    }
};

const ButtonContainer = ({ record, ...props }) => {
    return (
        <div style={{ textAlign: 'right', padding: 0 }}>
            {
                props.children.map(button => React.cloneElement(button, { record }))
            }
        </div>
    )
}

const BasicInfo = ({ classes, ...props }) => (
    <Fragment>
        <TextInput formClassName={classes.name} source="name" label="student.name" />
        <div>
            <ImageInput source="pictures" label="avatar" accept="image/*">
                <ImageField source="src" classes={classes} title="title"
                    style={{ width: 100, height: 100, borderRadius: 50, overflow: 'hidden', marginLeft: 10 }} />
            </ImageInput>
        </div>
        <ContactField />
        <div style={{ display: 'flex' }}>
            <DateInput classes={{ root: classes.dateInputRoot }} style={{ width: 152, minWidth: 152 }} 
                customFormat={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }}
                label="student.birthday" source="birthday" />

            <SelectInput formClassName={classes.rightInput} style={{ width: 152, minWidth: 152 }} label="student.gender"
                source="gender" choices={[
                    { id: '男', name: '男' },
                    { id: '女', name: '女' },
                    { id: '未知', name: '未知' },
                ]} />
        </div>
        <div style={{ display: 'flex' }}>
            <ReferenceInput label="student.channel" source="channel.id" reference="acquisition-channels">
                <SelectInput optionText="name" formClassName={classes.leftInput} style={{ width: 152, minWidth: 152 }} />
            </ReferenceInput>

            <TextInput formClassName={classes.rightInput} style={{ width: 152, minWidth: 152, marginLeft: 16 }} source="wechat" label="student.wechat" />
        </div>
        <LongTextInput label="student.address" source="address" resettable />
        <LongTextInput label="comment" source="comment" resettable />
    </Fragment>
)

const create = (props) => {
    const { classes } = props
    return (
        <Create redirect="edit" title="student.create" actions={<FormContainerActions />} {...props}>
            <TabbedForm submitOnEnter={true} variant="scrollable"
                 redirect="edit" validate={validateStudentCreation}
                 defaultValue={{gender: '未知'}}
                toolbar={<FormToolbar />}>

                <FormTab icon={<StudentBasicInfoIcon />} label="基本信息">
                    <BasicInfo {...props} />
                </FormTab>

                {/* <FormTab disabled={true} icon={<CommunicationIcon />} label="沟通互动">
                        <CommunicationList/>
                    </FormTab> */}
                <FormTab style={{opacity: .4}} icon={<CalendarIcon />} label="在读课程">
                    {/* <CourseList /> */}
                </FormTab>
                <FormTab style={{opacity: .4}} icon={<OrderListIcon />} label="交易记录">
                    {/* <TransactionList /> */}
                </FormTab>

                <FormTab style={{opacity: .4}} icon={<ClassAttendanceLogIcon />} label="上课记录"></FormTab>

            </TabbedForm>
        </Create>
    )
}

const StudentTitle = ({ record }) =>
    record ? <NameAvatarField record={record} /> : null;

class StudentEdit extends Component {
    state ={
        hideSaveButton: false
    }
    render () {
        const { classes, user } = this.props;
        return (
            <Fragment>
                <Edit title={<StudentTitle />} actions={<FormContainerActions />} {...this.props}>
                    <TabbedForm variant="scrollable" submitOnEnter={true} validate={validateStudentCreation}
                        toolbar={<FormToolbar hideDeleteButton={!user || user.name !== 'admin'} hideSaveButton={this.state.hideSaveButton} />}>
                        <FormTab onClick={() => this.setState({ hideSaveButton: false })} 
                                icon={<StudentBasicInfoIcon />} label="基本信息">
                            <BasicInfo {...this.props} />
                        </FormTab>

                        <FormTab onClick={() => this.setState({ hideSaveButton: true })} icon={<HomeworkIcon />} label="课后作业">
                        <ReferenceManyField
                                addLabel={false}
                                reference="homeworks"
                                style={{ width: '100%'  }}
                                target="studentId">
                                <HomeworkList/>
                            </ReferenceManyField>
                        </FormTab>
        
                        <FormTab icon={<CommunicationIcon />} label="沟通互动">
                                <CommunicationList/>
                        </FormTab> 
                        <FormTab path="courses" onClick={() => this.setState({ hideSaveButton: true })} icon={<CalendarIcon />} label="在读课程">
                           
                            <ReferenceManyField
                                addLabel={false}
                                reference="students-learnings"
                                style={{ width: '100%'  }}
                                filter={{ includeClassHistory: 1, groupClassesByCourse: 1 }}
                                target="studentId"
                            >
                                <CourseList />
                            </ReferenceManyField>
                        </FormTab>
                        <FormTab path="materials" onClick={() => this.setState({ hideSaveButton: true })} 
                                icon={<IncidentalIcon />} label="教材杂费">
                                 <Materials />
                        </FormTab>
                        <FormTab path="transactions" onClick={() => this.setState({ hideSaveButton: true })} icon={<OrderListIcon />} label="交易记录">
                            <ReferenceManyField
                                addLabel={false}
                                reference="transactions"
                                style={{ width: '100%' }}
                                target="studentId"
                                filter={{ $limit: 200 }}
                            >
                                <Datagrid　style={{width: '100%'}} expand={<TransactionItemsShow />}>
                                    <TextField label="订单类型" source="type" />
                                    <FunctionField label="应收/退"
                                        render={record => <NumberFormat displayType="text" thousandSeparator={true} prefix={'¥'} value={record.subtotal} />} />
        
                                    <FunctionField label="实收/退"
                                        render={record => <NumberFormat value={record.payments ? record.payments.reduce((sum, item) => sum + Number(item.received), 0) : 0}
                                            displayType="text" thousandSeparator={true} prefix={'¥'} />} />
        
                                    <FunctionField label="交易渠道"
                                        render={record => record.payments && record.payments.map(p => p.payment_method.name).join(', ')} />
                                    <FromNowField sortBy="transaction_date" source="transaction_date" label="交易时间"/>
                                    
                                </Datagrid>
                            </ReferenceManyField>
                        </FormTab>
        
                        <FormTab path="records" onClick={() => this.setState({ hideSaveButton: true })} icon={<ClassAttendanceLogIcon />} label="上课记录">
                            <ReferenceManyField
                                addLabel={false}
                                perPage={1000} 
                                reference="teaching-logs"
                                target="studentId"
                            >
                                <Rollcalls />
                            </ReferenceManyField>
                        </FormTab>
                    </TabbedForm>
                    
                </Edit>
                <CreateTransaction resource={this.props.resource}/>
                <ApplyCourse />
                <PrintContract />
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const mapStateToProps = state => ({
    showSelectCourse: state.student.showSelectCourse
});

export const EditStudent = compose(withStyles(styles), connect(state => ({
    user: state.global.user,
}), null))(StudentEdit);
export const CreateStudent = compose(withStyles(styles), connect( state => ({
    state: state
}), null),)(create);
export const BaseStudentList = compose(
    connect(null, mapDispatchToProps))(BaseStudents);

const MaterialButton = ({ ...props }) => (<Button label="购买教材杂费" onClick={(porps) => this.props.dispatch({
    type: 'START_TRANSACTION',
    payload: {
        ids: props.record ? [props.record.id] : props.selectedIds,
        showApplyCourse: true,
        applyType: '教材杂费'
    }
})} >
    <BuyMaterialIcon />
</Button>)

class PurchaseButton extends Component {
    state = {
        anchorEl: null,
        open: false,
    };

    handleClick = event => {
        const { currentTarget } = event;
        this.setState(state => ({
          anchorEl: currentTarget,
          open: !state.open,
        }));
    };

    buyMaterial = () => {
        this.props.dispatch({
            type: 'START_TRANSACTION',
            payload: {
                ids: this.props.record ? [this.props.record.id] : this.props.selectedIds,
                showApplyCourse: true,
                applyType: '教材杂费'
            }
        })
    }

    register = () => {
        this.props.dispatch({
            type: 'START_TRANSACTION',
            payload: {
                ids: this.props.record ? [this.props.record.id] : this.props.selectedIds,
                showApplyCourse: true,
                applyType: '报名',
                defaultValue: {
                  payments: [{ received: 0 }]
                }
              }
        })
    }

    render () {
        const { anchorEl, open } = this.state;
        return (
            <Fragment>
                <MuiButton onClick={this.handleClick}  >办理 <MoreVertIcon /></MuiButton>
                <Popover onClose={() => this.setState({open: false})} 
                    open={open} anchorEl={anchorEl} >
                <MenuList>
                    <MenuItem onClick={this.register}>
                        <ListItemIcon >
                            <RegisterIcon />
                        </ListItemIcon>
                        <ListItemText inset primary={this.props.translate('报名新课程')} />
                    </MenuItem>
                </MenuList>

                <MenuList>
                    <MenuItem onClick={this.buyMaterial}>
                        
                        <ListItemIcon >
                            <BuyMaterialIcon />
                        </ListItemIcon>
                        <ListItemText inset primary={this.props.translate('教材杂费')} />
                    </MenuItem>
                </MenuList>
                </Popover>
            </Fragment>
        )
    }
}

class Students extends Component {

    render() {
        return (
            <Fragment>
                {
                    React.createElement(BaseStudentList, {
                        ...this.props,
                        additionalRowButtons: [
                             <PurchaseButton />
                        ],
                        additionalColumns: [
                            <FunctionField label="student.learning" render={student =>
                                <ReferenceManyField basePath="students" record={student} 
                                    linkType={false}
                                    reference="students-learnings" target="studentId">
                                    <SingleFieldList style={{ display: 'inline' }}>
                                        <FunctionField render={record => 
                                        <Link to={`/${this.props.resource}/${student.id}/courses`}
                                                onClick={e => e.stopPropagation()}>
                                                <Chip style={{"max-width":"350px","height":"initial","margin":"3px 0px"}} deleteIcon={<AssignClassIcon />} 
                                                    color="primary"
                                                    label={<span style={{  textDecoration: 'underline', cursor: 'pointer', "white-space":"normal", "padding": "5px 0px" }}>
                                                
                    {`${record.course ? record.course.name : ''} (${record.studentsLearningsClasses && record.studentsLearningsClasses.length>0 ? record.studentsLearningsClasses.map(d=>d.class.name).join(", ") : '未安排班级'})`}</span>}
                                                
                                                />
                                                </Link>
                                                } />
                                    </SingleFieldList>
                                </ReferenceManyField>
                            } />,
                            <ReferenceField allowEmpty={true}  label="课程顾问" 
                                reference="employees" source="employeeId">
                                    <TextField source="name" />
                                    </ReferenceField>,
                            <ReferenceField linkType={false} emptyText="" label="验证码" source="phone" reference="sms-code">
                               <CodeField />
                            </ReferenceField>
                        ]
                    })
                }
            </Fragment>
        )
    }
};

const CodeField = ({record}) => {
  return record ? <span>{record.code}</span> : <span></span>
}

export const StudentList = compose(
    translate,
    connect(state => ({
        user: state.global.user,
    }), mapDispatchToProps))
    (Students);
