import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import { UserMenu, MenuItemLink} from 'react-admin';
import Headroom from 'react-headroom';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import BelongBranches from '../Widgets/BelongBranches';
import { translate } from 'react-admin'; 
import Logo from '../../assets/images/logo.png';

const defaultStyle = {
    position: 'fixed',
    zIndex: 1300,
};

const HeadroomCustom = ({ children }) => (
    <Headroom style={defaultStyle}>{children}</Headroom>
);

const MyUserMenu = props => (
    <UserMenu {...props}>
        <MenuItemLink
            to="/configuration"
            primaryText="个人设置"
        />
    </UserMenu>
);

const styles = theme => ({
    toolbar: {
        //paddingRight: 24,
    },
    menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: '1em'
    },
    appBar: {
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        fontWeight: 700,
        boxShadow: '0 2px 12px rgba(0,0,0,.06)'
    }
});

const AppBar = ({
    children,
    classes,
    className,
    logo,
    logout,
    open,
    title,
    toggleSidebar,
    userMenu,
    width,
    translate,
    ...rest
}) => 
{
   
   return (
        <Headroom>
            <MuiAppBar
                className={classes.appBar}
                position="static"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar}
                        className={classNames(classes.menuButton)}
                    >
                        <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed,
                            }}
                        />
                    </IconButton>
                   <a href="/"> <img style={{height: 45, marginLeft: -16}} src={Logo} /> </a>
                    {Children.count(children) === 0 ? 
                        width !== 'xs' ?
                        (
                            <Typography
                                variant="title"
                                color="inherit"
                                className={classes.title} 
                            > 
                            {/* {translate('company')} */}
                            </Typography>
                        ) : null : (
                            children
                        )}　
                    
                    <BelongBranches /> 
                    <LoadingIndicator />
                    {cloneElement(userMenu, { logout })}
                </Toolbar>
            </MuiAppBar>
        </Headroom>
    )
}


AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <MyUserMenu />,
};

const enhance = compose(
    connect(
        state => ({
            locale: state.i18n.locale, // force redraw on locale change
        }),
        {
            toggleSidebar: toggleSidebarAction
        }
    ),
    withStyles(styles),
    withWidth(),
    translate
);

export default enhance(AppBar);
