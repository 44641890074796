import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import Img from './Img';
const styles = {
    media: {
        height: '25em'
    },
};

const Welcome = ({ classes, translate }) =>
{
    let user = JSON.parse(localStorage.getItem('user'));
    return (
        <Card>
            <CardMedia image={Img} className={classes.media} />
            <CardContent>
                <Typography variant="headline" component="h2">
                    {/* {translate('pos.dashboard.welcome.title')} */}
                    欢迎访问伊叶学员管理系统
                </Typography>
                {/* <Typography component="p">
                    {translate('pos.dashboard.welcome.subtitle')}
                </Typography> */}
            </CardContent>
            {/* <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button href="https://marmelab.com/react-admin">
                    <HomeIcon style={{ paddingRight: '0.5em' }} />
                    {translate('pos.dashboard.welcome.aor_button')}
                </Button>
                <Button href="https://github.com/marmelab/react-admin/tree/master/examples/demo">
                    <CodeIcon style={{ paddingRight: '0.5em' }} />
                    {translate('pos.dashboard.welcome.demo_button')}
                </Button>
            </CardActions> */}
        </Card>
    );
}


const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(Welcome);
