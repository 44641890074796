import React, { Component, Fragment, useState, useEffect } from 'react';
import {
    AutocompleteArrayInput, Link, Responsive, ArrayField,
    showNotification, FormDataConsumer, SimpleFormIterator, AutocompleteInput, FunctionField, Button, Labeled,
    LinearProgress, Loading, GET_MANY, required, RadioButtonGroupInput, NumberInput, withDataProvider, GET_LIST,
    ArrayInput, TabbedForm, FormTab, ReferenceField, DateField, List, Datagrid, EditButton, TextField,
    Create, ReferenceInput, SimpleForm, TextInput, SelectInput, Edit, Filter, CREATE
} from 'react-admin';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import DateInput from "../../components/Inputs/DateInput";
import { SelectedIcon, EditIcon, SendIcon, PreviewIcon } from '../../components/Icons';
import ExtractIndexFromId from '../../util/ExtractIndexFromId';
import { Field, formValueSelector, startSubmit, change, submit, reset } from 'redux-form';
import Drawer from '@material-ui/core/Drawer';
import { ClassIcon } from '../../components/Icons';
import { connect } from 'react-redux';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';
import { CopyIcon } from '../../components/Icons';
import { isDate, uniqBy, get } from 'lodash';
import TinymceInput from '../../components/Inputs/TinymceInput';
import * as qiniu from 'qiniu-js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button as MuiButton } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TreeView from 'react-treeview';
import 'react-treeview/react-treeview.css'
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import { List as MuiList } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import moment from 'moment';

const selector = formValueSelector('record-form')
const teachingLogSelector = formValueSelector('teachingLog-form')

const styles = theme => ({
    courseRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
    },
    listSection: {
        backgroundColor: 'inherit',
        listStyle: 'none'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,

    },
    cardContent: theme.typography.body1,
    subheading: {
        minWidth: 80,
        fontWeight: 500
    },
    label: {
        opacity: .7
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
    teaching_log_type: {
        "& div[role='radiogroup']": { "flex-direction": 'row', "flex-wrap": "wrap" }
    },
    inline_block: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
        // width: 500
    },
    card: {
        boxShadow: 'none'
    },
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
})

const validateUserCreation = (values) => {
    // const errors = {};
    // if (!values.name) {
    //     errors.name = ['不能为空'];
    // } 
    // if (!values.mobile) {
    //     errors.mobile = ['不能为空'];
    // } 
    // if (!values.gender) {
    //     errors.gender = ['不能为空'];
    // } 
    // if (!values.status) {
    //     errors.status = ['不能为空'];
    // } 
    // if (!values.branchid) {
    //     errors.branchid = ['不能为空'];
    // } 
    // return errors
};

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,                 //月份 
        "d+": this.getDate(),                    //日 
        "h+": this.getHours(),                   //小时 
        "m+": this.getMinutes(),                 //分 
        "s+": this.getSeconds(),                 //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds()             //毫秒 
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

const getRemendInfo = obj => {
    if (typeof (obj) == "undefined" || obj == null) {
        return "";
    }
    else {
        let info = (
            obj.student.name + " - " +
            obj.classSchedule.class.name + "(" +
            new Date(obj.classSchedule.beginAt).format("yyyy-MM-dd hh:mm") + " - " +
            new Date(obj.classSchedule.endAt).format("hh:mm") + ")")
        return info
    }
}

const ClassScheduleFilter = (props) => {
    var user = JSON.parse(localStorage.getItem("user"))
    var teacherFilterQueryObj = { isTeacher: true, status: '正式员工' }

    if (user && user.selectedBranch) {
        teacherFilterQueryObj.branchId = user.selectedBranch
    }
    return (
        <Filter {...ShowFilterWhenHasOp(props)}>
            <TextInput label="班级名称" source="class.name.$substring" alwaysOn />
            <ReferenceInput label="教师"
                perPage={10000}
                source="teacherId"
                reference="employees"
                filter={teacherFilterQueryObj}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput label="班级状态" optionText="name"
                optionValue="id" choices={[{ id: "开放", name: "开放" }, { id: "结班", name: "结班" }, { id: "停用", name: "停用" }]} source="class[type]" alwaysOn />
            <DateInput source="beginAt.$gte" label="上课日期从" />
            <DateInput source="endAt.$lte" label="上课日期至" />
        </Filter>
    );
}

const exportMetadata = {
    name: '班级排课',
    sheets: [{
        name: '班级排课',
        columns: {
            '班级': record => record.class ? record.class.name : '',
            '课程': record => record.class && record.class.course ? record.class.course.name : '',
            '教师': record => record.teacher ? record.teacher.name : '',
            '上课开始时间': record => isDate(new Date(record.beginAt)) ? (new Date(record.beginAt)).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Asia/Shanghai' }) : '',
            '上课结束时间': record => isDate(new Date(record.endAt)) ? (new Date(record.endAt)).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Asia/Shanghai' }) : '',
            '实到人数': record => getShiDaoCount(record),
            '应到人数': record => getYingDaoCount(record),
            '班级人数': record => getBanJiCount(record),
            '上课人数': record => getShangKeCount(record),
            '请假人数': record => getQingJiaCount(record),
            '补课人数': record => getBuKeCount(record),
            '旷课人数': record => getKuangKeCount(record),
            '班级状态': record => record.class ? record.class.type : '',
            '教师课时': record => record.class ? record.class.decutionTeacher : '',
            '教师课时(实际)': record => record.logTeacherPeriod
        }
    }]
}
const getShangKeCount = record => record.teachingLogs != null ? record.teachingLogs.filter(item => item.type == "上课").length : 0
const getBuKeCount = record => record.teachingLogs != null ? record.teachingLogs.filter(item => item.type == "补课").length : 0
const getKuangKeCount = record => record.teachingLogs != null ? record.teachingLogs.filter(item => item.type == "旷课").length : 0
const getQingJiaCount = record => record.teachingLogs != null ? record.teachingLogs.filter(item => item.type == "请假").length : 0

const getShiDaoCount = record => record.teachingLogs != null ? record.teachingLogs.filter(item => item.type == "上课" || item.type == "补课").length : 0
const getYingDaoCount = record => record.teachingLogs != null ? record.teachingLogs.length : 0
const getBanJiCount = record => record.class != null && record.class.studentsLearningsClasses && record.class.studentsLearningsClasses.length > 0 ? record.class.studentsLearningsClasses.length : 0

const CustomActionButton = ({ basePath }) => {
    //var basePath = this.props.basePath;
    var today = new Date();
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    var todayStr = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    var tomorrowStr = tomorrow.getFullYear() + "-" + (tomorrow.getMonth() + 1) + "-" + tomorrow.getDate();
    return <Fragment>
        <EditButton label="今日上课列表" icon={<ClassIcon />} to={basePath + encodeURI(`?filter={"endAt":{"$lte":"` + tomorrowStr + `"},"beginAt":{"$gte":"` + todayStr + `"},"class":{"type":["开放"]}}&order=DESC&page=1&perPage=10&sort=id`)} />
        <EditButton label="所有排课列表" icon={<ClassIcon />} to={basePath + "?filter={" + encodeURI(`"class":{"type":["开放"]}}`)} />
    </Fragment>
}

const CustomEditButton = ({ basePath, record, label = "记上课", location = {} }) => 
{
    let dayDiff = moment(record.beginAt).diff(moment(Date.now()) , 'days') ;
    return <EditButton disabled={!record.id} onClick={(e) => { 
        if (dayDiff < -3) {
            alert("上课结束超过三天不能再次点名")
            e.preventDefault();
        }
     }} icon={<EditIcon />} to={`${basePath}/${record.id}/teachinglog`} label={label} />;

}

class ScheduleList extends Component {
    render() {
        const ShiDaoCountField = ({ record }) =>
            <span>{getShiDaoCount(record)}</span>
        const YingDaoCountField = ({ record }) =>
            <span>{getYingDaoCount(record)}</span>
        const BanJiCountField = ({ record }) =>
            <span>{getBanJiCount(record)}</span>
        const ShangKeCountField = ({ record }) =>
            <span>{getShangKeCount(record)}</span>
        const QingJiaCountField = ({ record }) =>
            <span>{getQingJiaCount(record)}</span>
        const BuKeCountField = ({ record }) =>
            <span>{getBuKeCount(record)}</span>
        const KuangKeCountField = ({ record }) =>
            <span>{getKuangKeCount(record)}</span>

        return (
            <Fragment>
                <List actions={<ListActions exportMetadata={exportMetadata} customActions={<CustomActionButton {...this.props} />} />}
                    filters={<ClassScheduleFilter />} filterDefaultValues={{ "class": { "type": ["开放"] } }} {...this.props} bulkActionButtons={false}>
                    <Datagrid>
                        {/* <TextField source="classScheduleGroupId" label="classScheduleGroupId" /> */}
                        <ReferenceField label="课程" source="class.courseId" reference="courses">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="班级" source="classId" reference="classes">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="老师" source="teacherId" reference="employees">
                            <TextField source="name" />
                        </ReferenceField>
                        <ShiDaoCountField label='实到人数' />
                        <YingDaoCountField label='应到人数' />
                        <BanJiCountField label='班级人数' />
                        <ShangKeCountField label='上课人数' />
                        <QingJiaCountField label='请假人数' />
                        <BuKeCountField label='补课人数' />
                        <KuangKeCountField label='旷课人数' />
                        <TextField source="class.type" label='班级状态' />
                        <TextField source="class.decutionTeacher" label='教师课时' />
                        <TextField source="logTeacherPeriod" label='教师课时(实际)' />
                        <DateField source="beginAt" label="上课开始时间" showTime locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
                        <DateField source="endAt" label="上课结束时间" showTime locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
                        {/* <FromNowField sortBy="createdAt" source="createdAt" label="创建时间" /> */}
                        <FunctionField render={record => (record.class && record.class.type != "结班" && record.class.type != "停用") ?
                            <Fragment>
                                <EditButton record={record} basePath={this.props.basePath} icon={<EditIcon />} />
                                <CustomEditButton record={record} basePath={this.props.basePath} />
                                <CloneButton record={record} basePath={this.props.basePath} omitProps={['class']} />
                            </Fragment>
                            : <CustomEditButton label="上课记录" record={record} basePath={this.props.basePath} />} />
                    </Datagrid>
                </List>
            </Fragment >)
    }
}


export const ClassScheduleList = compose()(ScheduleList);

class CreateForm extends Component {
    state = {
        isTeacherFetched: true
    }
    changeClass = (e, value) => {
        if (value != this.props.classId) {
            this.setState({ selectedBranchId: value })
        }

    }
    componentWillMount() {
        if (localStorage) {
            var user = JSON.parse(localStorage.getItem("user"))
            if (user && user.selectedBranch) {
                this.setState({ selectedBranchId: user.selectedBranch })
            }
        }
    }
    componentWillReceiveProps(props) {
        if (props.classId !== this.props.classId) {
            this.props.dataProvider(GET_LIST, 'classes',
                {
                    pagination: { page: 1, perPage: 100000 },
                    filter: { id: props.classId }
                }).then(result => {
                    if (result.data.length > 0 && result.data[0].teachers && result.data[0].teachers.length > 0) {
                        var isTeacherLoaded = false;
                        var isAssistantLoaded = false;
                        result.data[0].teachers.forEach((item, index) => {
                            if (item.teacherType == "教师" && !isTeacherLoaded) {
                                isTeacherLoaded = true;
                                this.props.dispatch(change('record-form', 'teacherId', item.teacherId))
                            }
                            else if (item.teacherType == "助教" && !isAssistantLoaded) {
                                isAssistantLoaded = true;
                                this.props.dispatch(change('record-form', 'assistantId', item.teacherId))
                            }
                        })
                    }
                    else {
                        this.props.dispatch(change('record-form', 'teacherId', null))
                        this.props.dispatch(change('record-form', 'assistantId', null))
                    }
                    if (result.data.length > 0 && result.data[0].decutionTeacher) {
                        this.props.dispatch(change('record-form', 'logTeacherPeriod', result.data[0].decutionTeacher))
                    }
                    this.setState({ isTeacherFetched: true })
                }
                )
        }
    }
    render() {
        return <Create actions={<FormContainerActions customActions={<CustomActionButton {...this.props} />} />} title={<span>新建排课日程</span>} {...this.props}>
            <SimpleForm submitOnEnter={true} redirect="list" toolbar={<FormToolbar />}
                validate={validateUserCreation}>
                <ReferenceInput perPage={100} source="classId" label="班级"
                    filter={{ idAndName: true }}
                    filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })}
                    reference="classes" onChange={this.changeClass} validate={required()}>
                    <AutocompleteInput optionText="name" options={{ placeholder: "输入班级名称以筛选" }} />
                </ReferenceInput>
                <DateInput source="beginAt" timeIntervals={5} label="上课开始时间" showTimeSelect validate={required()} />
                <DateInput source="endAt" timeIntervals={5} label="上课结束时间" showTimeSelect validate={required()} />
                {this.state.isTeacherFetched ? <Fragment>
                    <ReferenceInput perPage={10000} source="teacherId" label="教师" reference="employees" filter={{ isTeacher: true, branchId: this.state.selectedBranchId }} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={10000} source="assistantId" label="助教" reference="employees" filter={{ isTeacher: true, branchId: this.state.selectedBranchId }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Fragment> :
                    <Loading />

                }

                <ReferenceInput perPage={10000} source="classroomId" label="教室" reference="classrooms" validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput perPage={10000} source="subjectId" label="科目" reference="subjects">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="topic" label="上课主题" />
            </SimpleForm>
        </Create>
    }
}

const TeacherClassScheduleFilter =
    (props => {
        var user = JSON.parse(localStorage.getItem("user"))
        var teacherFilterQueryObj = { isTeacher: true }

        if (user && user.selectedBranch) {
            teacherFilterQueryObj.branchId = user.selectedBranch
        }
        return (
            <Filter {...ShowFilterWhenHasOp(props)}>
                <TextInput label="班级名称" source="class.name.$substring" alwaysOn />
                {/* <SelectArrayInput label="班级状态" optionText="name"
                    optionValue="id" choices={[{ id: "开放", name: "开放" }, { id: "结班", name: "结班" }, { id: "停用", name: "停用" }]} source="class[type]" alwaysOn /> */}
                <DateInput source="beginAt.$gte" label="上课日期从" />
                <DateInput source="endAt.$lte" label="上课日期至" />
            </Filter>
        );
    })

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

export const TeacherClassScheduleEdit = compose(withDataProvider)
    (props => {
        let params = getUrlVars()
        return <Edit title={<span>布置作业</span>} {...props}>
            <SimpleForm submitOnEnter={true}
                redirect="list" toolbar={<FormToolbar />} >
                <TextInput source="taskTitle" record={{ taskTitle: params.taskTitle }} label="作业简介" validate={required()} />
                <TinymceInput content={params.task} source="task" label="作业" init={{
                    language: 'zh_CN',
                    mobile: {
                        theme: 'silver', menubar: 'file insert'
                    },
                    width: '350px',
                    height: '500px',
                    fontsize_formats: "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 16pt 24pt 28pt 36pt 48pt",
                    menubar: 'file insert',
                    plugins: 'image media preview',
                    toolbar: 'preview | image media | formatselect | bold italic forecolor backcolor permanentpen formatpainter | link pageembed | alignleft aligncenter alignright  | numlist bullist outdent indent',
                    paste_data_images: true,
                    file_picker_types: 'image media',
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_callback: (cb, value, meta) => {
                        console.log(props)
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*, video/*');

                        input.onchange = async function (files) {
                            var file = this.files[0];
                            if (meta.filetype == 'image') {
                                var reader = new FileReader();
                                reader.onload = function () {
                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache = window.tinyMCE.activeEditor.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);
                                    cb(blobInfo.blobUri(), { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            } else if (meta.filetype == 'media') {
                                let data = await props.dataProvider('GET_LIST', 'media-materials', {
                                    filter: { getToken: "1" }
                                }).then(res => JSON.parse(res.data[0].id));
                                if (data && data.token) {
                                    let putExtra = {
                                        fname: '', params: {}, mimeType: null,
                                    };
                                    let config = {
                                        useCdnDomain: true, disableStatisticsReport: false,
                                        retryCount: 6, region: qiniu.region.z0,
                                    };
                                    let preventClose = () => { alert('正在上传作业，请稍后关闭网页') }
                                    window.addEventListener('close', preventClose, true);
                                    let subObject = {
                                        next: res => { },
                                        error: err => { window.removeEventListener('close', preventClose, true); },
                                        complete: res => {
                                            window.removeEventListener('close', preventClose, true);
                                            cb(`${process.env.REACT_APP_ASSETS_DOMAIN}/homeworks/${props.id}/${file.name}`, { title: file.name })
                                            document.body.removeChild(document.querySelector('#loading'));
                                        }
                                    };

                                    let observable = qiniu.upload(file,
                                        `homeworks/${props.id}/${file.name}`, data.token, putExtra, config);
                                    observable.subscribe(subObject);
                                    let div = document.createElement('div');
                                    div.style = "text-align:center; padding-top: 30vh;position:fixed;width: 100%; z-index:10000; height: 100%; background-color: #fff; top: 0; left: 0"
                                    div.id = 'loading';
                                    div.innerHTML = `<div class="lds-ring"><div></div><div></div><div></div><div></div></div> <p>正在上传文件，请稍候</p>`
                                    document.body.appendChild(div)

                                }
                            }

                        };
                        input.click();
                    }
                }} />
                <DateInput source="taskDueDate" label="最晚提交日期" />
            </SimpleForm>
        </Edit>
    })

export const TeacherClassScheduleModelList =
    compose(withStyles(styles))
        (({ ids, data, basePath, classes, assignHomeowrk, createHomeowrk, showPreview }) => {
            return <div style={{ margin: '1em' }}>
                {ids.map(id => (
                    <Card key={id} style={{ margin: '1.5rem 0px' }}>
                        <CardContent className={classes.cardContent}>
                            <span className={classes.cardContentRow}>
                                <Typography className={classes.subheading}
                                    component="span" variant="subheading">
                                    课程 </Typography>
                                <ReferenceField linkType={false} label="课程"
                                    record={data[id]} basePath={basePath}
                                    source="class.courseId" reference="courses">
                                    <TextField source="name" />
                                </ReferenceField>
                            </span>
                            <Divider />
                            <span className={classes.cardContentRow}>
                                <Typography className={classes.subheading}
                                    component="span" variant="subheading">
                                    实到人数</Typography>
                                <FunctionField record={data[id]} label="实到人数" basePath={basePath}
                                    render={record => getShiDaoCount(record)} />

                            </span>
                            <Divider />
                            <span className={classes.cardContentRow}>
                                <Typography className={classes.subheading}
                                    component="span" variant="subheading">
                                    班级人数</Typography>
                                <FunctionField record={data[id]} label="实到人数" basePath={basePath}
                                    render={record => getBanJiCount(record)} />
                            </span>
                            <Divider />

                            <span className={classes.cardContentRow}>
                                <Typography className={classes.subheading}
                                    component="span" variant="subheading">
                                    班级</Typography>
                                <ReferenceField linkType={false}
                                    record={data[id]} basePath={basePath}
                                    source="classId" label="班级"
                                    reference="classes" >
                                    <TextField className={classes.label} source="name" />
                                </ReferenceField>
                            </span>
                            <Divider />
                            <span className={classes.cardContentRow}>
                                <Typography className={classes.subheading}
                                    component="span" variant="subheading">
                                    班级状态</Typography>
                                <TextField source="class.type" record={data[id]} basePath={basePath}
                                    className={classes.label} label='班级状态' />
                            </span>
                            <Divider />
                            <span className={classes.cardContentRow}>
                                <Typography className={classes.subheading}
                                    component="span" variant="subheading">
                                    上课时间</Typography>
                                <DateField source="beginAt" label="上课开始时间" showTime record={data[id]} basePath={basePath}
                                    locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />

                            </span>
                        </CardContent>
                        <CardActions>
                            <MuiButton size="small" color="primary"
                                onClick={() => createHomeowrk(data[id])} >
                                <EditIcon />
                            布置作业
                        </MuiButton>
                            <MuiButton size="small" color="primary"
                                disabled={!data[id].taskTitle}
                                onClick={() => assignHomeowrk(data[id])} >
                                <SendIcon />
                            发送作业通知
                        </MuiButton>
                            <MuiButton size="small" color="primary"
                                disabled={!data[id].taskTitle}
                                onClick={() => showPreview(data[id])} >
                                <PreviewIcon />
                            预览作业
                        </MuiButton>

                        </CardActions>
                    </Card>
                ))}
            </div>
        })

export const TeacherClassScheduleList = compose(withDataProvider,
    withMobileDialog(),
    withStyles(styles),
    connect(state => ({ user: state.global.user, state: state }),
        dispatch => { return { dispatch } }))(props => {
            if (!props.user) {
                return null;
            }
            const { classes } = props;
            const [open, setOpen] = useState(false);
            const [selectCourse, setSelectCourse] = useState(null);
            const [copy, setCopy] = useState();
            const [dialogType, setDialogType] = useState('');
            const [html, setHtml] = useState('');
            const [id, setId] = useState(null);
            const [data, setData] = useState(null);
            const [collapsedItems, setCollapsedItems] = useState([]);
            const [homeworkType, setHomeworkType] = useState('new');
            const ShiDaoCountField = ({ record }) =>
                <span>{getShiDaoCount(record)}</span>
            const YingDaoCountField = ({ record }) =>
                <span>{getYingDaoCount(record)}</span>
            const BanJiCountField = ({ record }) =>
                <span>{getBanJiCount(record)}</span>
            const ShangKeCountField = ({ record }) =>
                <span>{getShangKeCount(record)}</span>
            const QingJiaCountField = ({ record }) =>
                <span>{getQingJiaCount(record)}</span>
            const BuKeCountField = ({ record }) =>
                <span>{getBuKeCount(record)}</span>
            const KuangKeCountField = ({ record }) =>
                <span>{getKuangKeCount(record)}</span>
            const assignHomeowrk = record => {
                if (!record.taskTitle) {
                    props.dispatch(showNotification("请先布置作业"))
                }
                props.dataProvider(CREATE, 'wechat', {
                    data: {
                        type: 'assignHomework',
                        classSchedule: { id: record.id }
                    }
                })
            }

            const showPreview = record => {
                setOpen(true)
                setDialogType('showPreview')
                setHtml(record.task)
            }

            const createHomeowrk = record => {
                setOpen(true)
                setId(record.id)
                setDialogType('createHomeowrk')
            }

            const handleCollapseClick = i => {
                collapsedItems[i] = !collapsedItems[i];
                setCollapsedItems(collapsedItems);
            }

            const onSelectCourse = course => {
                setSelectCourse(course);
            }

            useEffect(() => {
                let id = props.user.id
                if (open && !data) {
                    props.dataProvider(GET_LIST, 'class-schedules',
                        {
                            pagination: { page: 1, perPage: 100000 },
                            filter: { teacherId: id, isLogged: true, task: { "$ne": null } }
                        })
                        .then(res => {

                            props.dataProvider(GET_MANY, 'courses', { ids: uniqBy(res.data.map(d => d.class.courseId)) })
                                .then(c => {
                                    setCollapsedItems(Array(c.data.length).fill(true));
                                    setData({
                                        courses: c.data,
                                        logs: res.data
                                    })
                                })
                        })
                }
            }, [open]);
            return <Fragment>
                <List
                    filters={<TeacherClassScheduleFilter />}
                    filterDefaultValues={{ teacherId: props.user.id, "class": { "type": ["开放"] } }} {...props}  bulkActionButtons={false} >
                    <Responsive
                        small={
                            (<TeacherClassScheduleModelList assignHomeowrk={assignHomeowrk}
                                createHomeowrk={createHomeowrk} showPreview={showPreview} />)
                        }
                        medium={
                            <Datagrid
                                isRowSelectable={r => { return false }}>
                                {/* <TextField source="classScheduleGroupId" label="classScheduleGroupId" /> */}
                                <ReferenceField linkType={false} label="课程" source="class.courseId" reference="courses">
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField linkType={false} label="班级" source="classId" reference="classes">
                                    <TextField source="name" />
                                </ReferenceField>
                                <ShiDaoCountField label='实到人数' />
                                <YingDaoCountField label='应到人数' />
                                <BanJiCountField label='班级人数' />
                                <ShangKeCountField label='上课人数' />
                                <QingJiaCountField label='请假人数' />
                                <BuKeCountField label='补课人数' />
                                <KuangKeCountField label='旷课人数' />
                                <TextField source="class.type" label='班级状态' />
                                <FunctionField render={record => record.homeworks.length > 0 ?
                                    <a href={`/#/homeworks?${record.id}`}> {record.homeworks.length}</a> : 0} label="已上传作业" />
                                <TextField source="logTeacherPeriod" label='教师课时' />
                                <DateField source="beginAt" label="上课开始时间" showTime locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
                                <FunctionField render={record => <Button label="布置作业" onClick={() => createHomeowrk(record)} ><EditIcon /></Button>} />
                                <FunctionField render={record => <Button disabled={!record.taskTitle} label="发送作业通知" onClick={() => assignHomeowrk(record)} ><SendIcon /></Button>} />
                                <FunctionField render={record => <Button label="预览作业" disabled={!record.taskTitle}
                                    onClick={() => showPreview(record)} ><PreviewIcon /></Button>} />
                            </Datagrid>
                        } />
                </List>

                <Dialog open={open} fullScreen={props.fullScreen}
                    onClose={() => { setOpen(false); setHtml(null); setCopy(null) }} >
                    <DialogTitle >{dialogType === 'createHomeowrk' ? "布置作业" : "预览作业"}</DialogTitle>
                    {
                        dialogType === 'createHomeowrk' &&
                        <DialogContent >
                            <FormControl component="fieldset" >
                                <FormLabel component="legend">如何布置作业?</FormLabel>
                                <RadioGroup name="quiz" value={homeworkType}
                                    onChange={e => setHomeworkType(e.target.value)}>
                                    <FormControlLabel value="new" control={<Radio />} label="上传新的作业" />
                                    <FormControlLabel value="copy" control={<Radio />} label="Copy现有的作业" />

                                </RadioGroup>
                            </FormControl>

                            {homeworkType === 'copy' &&
                                <div>
                                    <Typography> 请从以下课班级作业选择一个Copy</Typography>
                                    <div style={{ margin: '1rem 0px' }}>
                                        <FormControl  >
                                            <InputLabel htmlFor="age-required">筛选课程</InputLabel>
                                            <Select value={selectCourse} onChange={e => onSelectCourse(e.target.value)}>
                                                {data && data.courses.map(d =>
                                                    <MenuItem value={d}>
                                                        {
                                                            d.name
                                                        }
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <MuiList className={classes.courseRoot} subheader={<li />}>
                                        {
                                            data ? data.courses.filter(c => !selectCourse ||
                                                selectCourse.id === c.id)
                                                .map((c, i) =>
                                                    <li key={`section-${c.id}`} className={classes.listSection}>
                                                        <ul className={classes.ul}>
                                                            <ListSubheader>{c.name}</ListSubheader>
                                                            {data.logs.filter(l => l.class.courseId == c.id)
                                                                .map(l =>
                                                                    <ListItem key={l.id} >
                                                                        <ListItemSecondaryAction>
                                                                            <Link to={`${props.basePath}/${id}${`?taskTitle=${l.taskTitle}&task=${l.task}`}`}>
                                                                                <MuiButton size="small" color="primary" >
                                                                                    <CopyIcon />
                                                                            Copy此作业
                                                                        </MuiButton></Link>
                                                                        </ListItemSecondaryAction>
                                                                        <ListItemText primary={l.taskTitle}
                                                                            secondary={
                                                                                <div>
                                                                                    {l.class.name}
                                                                                    <br />
                                                                                    {new Date(l.beginAt).toLocaleDateString('zh-CN',
                                                                                        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' })}
                                                                                </div>} />
                                                                    </ListItem>)
                                                            }
                                                        </ul>
                                                    </li>) :
                                                <Loading />

                                        }
                                    </MuiList>
                                </div>
                            }
                        </DialogContent>
                    }

                    {
                        html &&
                        <DialogContent style={{ textAlign: 'center' }}>
                            <div dangerouslySetInnerHTML={{ __html: html ? decodeURIComponent(html) : '' }} />
                        </DialogContent>
                    }

                    <DialogActions>
                        {dialogType === 'createHomeowrk' &&
                            <Link to={`${props.basePath}/${id}${copy ? `?taskTitle=${copy.taskTitle}&task=${copy.task}` : ''}`}>
                                <MuiButton color="primary" variant="raised"
                                    disabled={homeworkType === 'copy'} >
                                    确定
                    </MuiButton>
                            </Link>}
                        <MuiButton onClick={() => { setOpen(false); setHtml(null); setCopy(null) }}
                            color="primary" style={{ fontSize: 16 }} autoFocus>
                            关闭
                </MuiButton>
                    </DialogActions>
                </Dialog>

            </Fragment>
        })

const absParser = v => { return Math.abs(v) }
const absFormatter = v => { return Math.abs(v) }

class EditForm extends Component {
    state = {
        teachingLogs: {},
        showPanel: false,
        showPanelRemend: false,
        isShowRemendTextProgressBar: false,
        isTeachingLogsFetched: false,
        isLogTeacherPeriodFetched: false,
        askForLeaveTeachingLogs: new Array()
    }
    handleClick = () => {
        this.setState({ showPanel: true, isTeachingLogsFetched: false });
    }
    handleCloseClick = ({ teachingLogs }) => {
        this.setState({ showPanel: false, isTeachingLogsFetched: true });

    };

    handleCloseClickRemend = ({ isFromSubmit }) => {
        if (!isFromSubmit) {
            var obj = this.props.teachingLogs.find(item => item.studentId === this.state.selectedRemendStudentId)
            obj.remendLogId = null
            obj.remendLog = null
            obj.type = "上课"
            this.props.dispatch(change('record-form', 'teachingLogs', this.props.teachingLogs))
        }
        this.setState({ showPanelRemend: false, isShowRemendTextProgressBar: false });
    };

    handleSave = (data) => {
        console.log('data', data);
    }
    componentWillReceiveProps(props) {
    }
    findUnRemenedStudent = (item, studentId) => {
        return item.studentId === studentId && (item.beRemendedLog == null || (item.beRemendedLog.classScheduleId == this.props.id))
    }
    async componentWillMount() {
        if (window.location.href.toLowerCase().indexOf("teachinglog") > 0) {
            //首先获取前端请假记录
            var resultsLeaveApplications = await this.props.dataProvider(GET_LIST, 'student-leave-applications',
                {
                    pagination: { page: 1, perPage: 100000 },
                    filter: { classScheduleId: this.props.record.id }
                })

            //begin 加载当前班级下面默认记上课的学生
            var initialTeachingLogs
            if (this.props.record.isLogged == true) {
                //如果已经有记上课记录，获取teaching_logs表
                var resultSelectedTeachingLogs = await this.props.dataProvider(GET_LIST, 'teaching-logs',
                    {
                        pagination: { page: 1, perPage: 100000 },
                        filter: { classScheduleId: this.props.record.id }
                    })
                //读取数据库中teachinglogs记录
                initialTeachingLogs = resultSelectedTeachingLogs.data
                //添加显示字段
                initialTeachingLogs.forEach(item => {
                    let askForLeaveObj = resultsLeaveApplications.data.find(x => x.studentId == item.student.id);
                    item.studentName = item.student.name
                    item.courseName = item.course.name
                    item.className = item.classSchedule.class.name
                    item.remendInfo = getRemendInfo(item.remendLog)
                    item.askForLeaveObj = askForLeaveObj
                })
            }
            else {
                //第一次保存时，才发送记录课时提醒
                this.props.dispatch(change('record-form', 'isNeedToSendWechat', true))

                //如果没有记上课记录，获取students_learnings表中该班级的学员集合
                var results = await this.props.dataProvider(GET_LIST, 'students-learnings',
                    {
                        pagination: { page: 1, perPage: 100000 },
                        filter: { "status": "在读", students_learnings_classes: { classId: this.props.record.classId } }
                    })
                results.data = results.data.filter(item =>
                    item.course != null &&
                    item.student !== null);
                initialTeachingLogs = results.data.map((item) => {
                    let askForLeaveObj = resultsLeaveApplications.data.find(x => x.studentId == item.studentId);
                    return {
                        id: null,
                        studentsLearningsId: item.id,
                        studentId: item.studentId,
                        studentName: item.student.name,
                        studentOpenId: item.student.wechat,
                        courseName: item.course.name,
                        courseId: item.course.id,
                        type: askForLeaveObj ? "请假" : "上课",
                        // className: item.class.name,
                        classesName: item.studentsLearningsClasses.length > 0 ? item.studentsLearningsClasses.filter(x => typeof (x.class) != "undefined").map(x => x.class.name).join(",") : "未加入班级",
                        period: askForLeaveObj ? 0 : this.props.record.class.decutionStudent,
                        askForLeaveObj: askForLeaveObj
                    }
                })
            }
            //end 加载当前班级下面默认记上课的学生


            //获取所有该课程下的学生剩余课时/总课时记录
            var resultStudentsLearningsForKeShiData = await this.props.dataProvider(GET_LIST, 'students-learnings',
                {
                    pagination: { page: 1, perPage: 100000 },
                    filter: { status: '在读', courseId: this.props.record.class.courseId, includeClassHistory: 1, useCourseType: 1 }
                })


            //将剩余课时/总课时记录添加至初始已选择学生集合
            initialTeachingLogs.forEach((item, index) => {
                var obj = resultStudentsLearningsForKeShiData.data.find((item2) => { return item2.courseId === item.courseId && item2.studentId === item.studentId })
                item.leftLessons = obj ? obj.leftLessons : 0;
                if (obj) {
                    item.periodAndLessons = obj.leftLessons + "/" + obj.lessons
                }
                else {
                    item.periodAndLessons = "不可用"
                }
            })

            //将初始已选择学生集合加入record-form
            this.props.dispatch(change('record-form', 'teachingLogs', initialTeachingLogs))
            this.setState({ askForLeaveTeachingLogs: [], isTeachingLogsFetched: true })

            //班级的教师课时
            this.props.dispatch(change('record-form', 'logTeacherPeriod', this.props.record.class.decutionTeacher))
            this.setState({ isLogTeacherPeriodFetched: true })

        }
    }

    changeTeachingLogType = async (event, value, studentId) => {
        if (value === "补课") {
            this.setState({ isShowRemendTextProgressBar: studentId })
            let tempTeachingLogs = JSON.parse(JSON.stringify(this.props.teachingLogs))
            var resultAskForLeaveTeachingLogs = await this.props.dataProvider(GET_LIST, 'teaching-logs',
                {
                    pagination: { page: 1, perPage: 100000 },
                    filter: { classScheduleId: this.props.record.id, courseId: this.props.record.class.courseId, getAskForLeaveRecordsByCourse: 1, useCourseType: 1, studentId: studentId }
                })
            this.setState({ askForLeaveTeachingLogs: resultAskForLeaveTeachingLogs.data })
            var remendStudent = this.state.askForLeaveTeachingLogs.filter(item => this.findUnRemenedStudent(item, studentId))
            if (remendStudent.length == 0) {
                this.props.dispatch(showNotification("该学生没有未补课的请假记录，无法选择补课！"));
                this.setState({ isShowRemendTextProgressBar: false })
                this.props.dispatch(change('record-form', 'teachingLogs', tempTeachingLogs))
                return
            }
            else {
                this.setState({ selectedRemendStudentId: studentId, showPanelRemend: true })
            }
        }
        else {
            var obj = this.props.teachingLogs.find(item => item.studentId === studentId)
            if (obj.remendLogId && obj.remendLogId != null) {
                obj.remendLogId = null
                obj.remendLog = null
                this.props.dispatch(change('record-form', 'teachingLogs', this.props.teachingLogs))
            }
        }
        if (value == "请假" || value == "旷课") {
            var obj = this.props.teachingLogs.find(item => item.studentId === studentId)
            obj.period = 0  
            this.props.dispatch(change('record-form', 'teachingLogs', this.props.teachingLogs))
        }
        else {
            var obj = this.props.teachingLogs.find(item => item.studentId === studentId)
            obj.period = this.props.record.class.decutionStudent
            this.props.dispatch(change('record-form', 'teachingLogs', this.props.teachingLogs))
        }
    }
    render() {
        const { classes } = this.props;
        if (window.location.href.toLowerCase().indexOf("teachinglog") > 0) {
            this.props.dispatch(change('record-form', 'isLogged', true))
        }
        console.log("this.props.teachingLogs", this.props.teachingLogs)
        return (
            <TabbedForm disabled={
                this.props.record && this.props.record.class &&
                (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')
            } submitOnEnter={false} save={this.handleSave} redirect="list" toolbar={<FormToolbar />}
                validate={validateUserCreation} {...this.props}>
                <FormTab label="基本信息" disabled >
                    <ReferenceInput perPage={100} source="classId" label="班级"
                        filter={{ idAndName: true }}
                        filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })}
                        reference="classes" validate={required()}>
                        <AutocompleteInput optionText="name" options={{ placeholder: "输入班级名称以筛选" }} />
                    </ReferenceInput>
                    <DateInput source="beginAt" timeIntervals={15} label="上课开始时间" showTimeSelect validate={required()} />
                    <DateInput source="endAt" timeIntervals={15} label="上课结束时间" showTimeSelect validate={required()} />
                    <ReferenceInput perPage={10000} source="teacherId" label="教师" reference="employees" filter={{ isTeacher: true, branchId: this.state.selectedBranchId }} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={10000} source="assistantId" label="助教" reference="employees" filter={{ isTeacher: true, branchId: this.state.selectedBranchId }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={10000} source="classroomId" label="教室" reference="classrooms" validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput perPage={10000} source="subjectId" label="科目" reference="subjects">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="topic" label="上课主题" />
                </FormTab>
                <FormTab label="记上课" path="teachinglog" disabled>
                    {/* <TextField source="isLogged" label="isLogged" style={{ display: "block" }} /> */}
                    <ReferenceInput perPage={10000} source="logTeacherId" label="教师"
                        disabled={
                            this.props.record && this.props.record.class &&
                            (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')}
                        reference="employees" filter={{ isTeacher: true }} formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20 }} >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput disabled={
                        this.props.record && this.props.record.class &&
                        (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')} perPage={10000} source="logAssistantId" label="助教" reference="employees" filter={{ isTeacher: true }} formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20 }} >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput disabled={
                        this.props.record && this.props.record.class &&
                        (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')} perPage={10000} source="subjectId" label="科目" reference="subjects" formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20 }} >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    {
                        this.state.isLogTeacherPeriodFetched ?
                            <NumberInput source="logTeacherPeriod" format={absFormatter} parse={absParser} label="教师课时" step={1} formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20 }} />
                            : <LinearProgress formClassName={classes.inline_block} />
                    }

                    {
                        this.state.isTeachingLogsFetched ?
                            <Fragment>
                                <Button disabled={
                                    this.props.record && this.props.record.class &&
                                    (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')} onClick={this.handleClick} label="修改学员"><EditIcon /></Button>
                                

                                <ArrayInput
                                    label="学生" record={{ teachingLogs: this.props.teachingLogs }} source="teachingLogs">
                                    <SimpleFormIterator disableAdd disableRemove={(record2) => {
                                        return record2.beRemendedLog || this.props.record && this.props.record.class &&
                                            (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')
                                    }} >
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                 
                                                const isAskForLeaveRemended = scopedFormData && scopedFormData.beRemendedLog ? true : false;

                                                return scopedFormData.leftLessons <= 0 ? <Fragment></Fragment> : (
                                                    <Fragment>
                                                        <TextInput source={getSource("periodAndLessons")} label="课程情况" formClassName={classes.inline_block} style={{ width: 70 }} disabled />
                                                        <TextInput source={getSource("studentName")} label="姓名" formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20 }} disabled />
                                                        <TextInput source={getSource("courseName")} label="扣课时课程" formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20 }} disabled />
                                                        <TextInput source={getSource("courseId")} label="courseId" formClassName={classes.inline_block} style={{ width: 70, "margin-left": 20, display: "none" }} disabled />
                                                        <RadioButtonGroupInput 
                                                            label="记上课类型" source={getSource("type")}
                                                            onChange={(event, value) => { this.changeTeachingLogType(event, value, formData.teachingLogs[ExtractIndexFromId(rest.id)].studentId) }} choices={[
                                                                { id: '上课', name: '上课' },
                                                                { id: '请假', name: '请假' },
                                                                { id: '旷课', name: '旷课' },
                                                                { id: '补课', name: '补课' },
                                                            ]} className={classes.teaching_log_type} style={{ width: 320, "flex-direction": "row", "margin-left": 20 }} disabled={get(formData, getSource("periodAndLessons"))  === "不可用" ||
                                                                 isAskForLeaveRemended ||
                                                                this.props.record && this.props.record.class &&
                                                                (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')} validate={required()} />
                                                        <NumberInput source={getSource("period")} disabled={
                                                            this.props.record && this.props.record.class &&
                                                            (this.props.record.class.type === '结班' || this.props.record.class.type === '停课')}
                                                            format={absFormatter} parse={absParser} label="课时" step={1} formClassName={classes.inline_block} style={{ width: 40, "margin-left": 20 }} />
                                                        {
                                                            scopedFormData && scopedFormData.askForLeaveObj ? <Fragment>
                                                                <TextInput source={getSource("askForLeaveObj.comments")} label="家长请假原因" formClassName={classes.inline_block} style={{ width: 100, "margin-left": 20 }} disabled />
                                                            </Fragment>
                                                                : null
                                                        }
                                                        {
                                                            scopedFormData && scopedFormData.type == "补课" ? (this.state.isShowRemendTextProgressBar != formData.teachingLogs[ExtractIndexFromId(rest.id)].studentId ?
                                                                <Fragment>
                                                                    <TextInput source={getSource("remendInfo")} label="补课情况" formClassName={classes.inline_block} style={{ "margin-left": 20 }} disabled />
                                                                </Fragment> : <LinearProgress className={classes.inline_block} />)
                                                                : null
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                            }
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Fragment>
                            :
                            <Loading />
                    }

                    <Drawer
                        anchor="right"
                        classes={{ paperAnchorRight: classes.drawerRoot }}
                        open={this.state.showPanel}
                        onClose={this.handleCloseClick}>
                        <div style={{ marginTop: 16 }}>
                            {
                                <CreateClassScheduleStudent recordClassSchedule={this.props.record} teachingLogsFromPage={this.props.teachingLogs}
                                    handleClose={this.handleCloseClick} />
                            }
                        </div>
                    </Drawer>

                    <Drawer
                        anchor="right"
                        classes={{ paperAnchorRight: classes.drawerRoot }}
                        open={this.state.showPanelRemend}
                        onClose={this.handleCloseClickRemend}>
                        <div style={{ marginTop: 16 }}>
                            {
                                <CreateClassScheduleRemendStudent teachingLogs={this.props.teachingLogs} selectedRemendStudentId={this.state.selectedRemendStudentId} logSet={this.state.askForLeaveTeachingLogs.filter(item => this.findUnRemenedStudent(item, this.state.selectedRemendStudentId))} handleClose={this.handleCloseClickRemend} />
                            }
                        </div>
                    </Drawer>
                </FormTab>
            </TabbedForm>
        )
    }
}
class AddRemendStudentLog extends Component {
    handleSave = () => {
        this.props.dispatch(submit('remendLog-form'));
    }
    handleSubmit = values => {
        var obj = this.props.teachingLogs.find(item => item.studentId === this.props.selectedRemendStudentId)
        var logObj = this.props.logSet.find(item => item.studentId === this.props.selectedRemendStudentId)
        obj.remendLogId = values.id
        obj.remendLog = logObj
        obj.remendInfo = getRemendInfo(logObj)
        this.props.dispatch(change('record-form', 'teachingLogs', this.props.teachingLogs))
        this.props.handleClose({ isFromSubmit: true });
    }
    componentWillUnmount() {
        this.props.dispatch(reset('remendLog-form'));
    }
    render() {
        const renderOptionText = choice => {
            var beginAt = new Date(choice.classSchedule.beginAt)
            var endAt = new Date(choice.classSchedule.endAt)
            return choice.student.name + " - " + choice.classSchedule.class.name + "(" + beginAt.toLocaleString() + "-" + endAt.toLocaleString() + ")"
        }
        return (
            <div>
                <SimpleForm onSubmit={this.handleSubmit} save={this.handleSave} form="remendLog-form" >
                    {
                        <SelectInput source="id" label="请选择补课" optionText={renderOptionText}
                            optionValue="id" choices={this.props.logSet} validate={required()} />
                    }
                </SimpleForm>
            </div >
        )
    }
}
class AddStudentInClassSchedule extends Component {
    state = {
        fetched: false
    }

    handleSave = () => {
        this.props.dispatch(submit('teachingLog-form'));
    }

    handleSubmit = async values => {
        var that = this
        var currentIds = values.currentStudentsLearningsIds
        //从teachingLogsSetFromSL中选择出所选学生集合
        var currentTeachingLogs = new Array();
        var currentSelectedStudentsLearnings = this.state.teachingLogsSetFromSL.filter((obj) => currentIds.indexOf(obj.studentsLearningsId) > -1)
        currentSelectedStudentsLearnings.forEach((item, index) => {
            var teachingLogsObj = new Object();
            //将当前页面已有的teachinglogs记录的数据赋值给已选择的记录
            var obj = that.props.teachingLogsFromPage.find((item2) => { return item2.studentsLearningsId === item.studentsLearningsId })
            if (obj) {
                teachingLogsObj = obj
            }
            else {
                teachingLogsObj = item;
            }
            currentTeachingLogs.push(teachingLogsObj)
        })
        this.props.dispatch(change('record-form', 'teachingLogs', currentTeachingLogs))

        this.props.handleClose({ teachingLogs: currentTeachingLogs });

    }
    async componentWillMount() {

        //获取所有该课程（updated：课程大类）下学生集合，用于添加插班和补课学生
        var resultStudentsLearnings = await this.props.dataProvider(GET_LIST, 'students-learnings',
            {
                pagination: { page: 1, perPage: 100000 },
                filter: { courseId: this.props.recordClassSchedule.class.courseId, useCourseType: 1 }
            })
        var resultStudentsLearningsData = resultStudentsLearnings.data.filter(item =>
            item.course != null &&
            item.student !== null)
        var teachingLogsSetFromSL = resultStudentsLearningsData.map((item) => {
            return {
                id: null,
                studentsLearningsId: item.id,
                studentId: item.studentId,
                studentName: item.student.name,
                courseName: item.course.name,
                courseId: item.course.id,
                // className: item.class.name,
                classesName: item.studentsLearningsClasses.length > 0 ? item.studentsLearningsClasses.filter(x => typeof (x.class) != "undefined").map(x => x.class.name).join(",") : "未加入班级",
                period: this.props.recordClassSchedule.class.decutionStudent
            }
        })

        //获取所有该课程下的学生剩余课时/总课时记录
        var resultStudentsLearningsForKeShiData = await this.props.dataProvider(GET_LIST, 'students-learnings',
            {
                pagination: { page: 1, perPage: 100000 },
                filter: { status: '在读', courseId: this.props.recordClassSchedule.class.courseId, includeClassHistory: 1, useCourseType: 1 }
            })

        //将剩余课时/总课时记录添加至该课程下总学生集合
        teachingLogsSetFromSL.forEach((item, index) => {
            var obj = resultStudentsLearningsForKeShiData.data.find((item2) => { return item2.courseId === item.courseId && item2.studentId === item.studentId })
            if (obj) {
                item.periodAndLessons = obj.leftLessons + "/" + obj.lessons
            }
            else {
                item.periodAndLessons = "不可用"
            }
        })
        this.setState({ teachingLogsSetFromSL: teachingLogsSetFromSL, fetched: true })
    }
    componentDidMount() {
        this.props.dispatch(change('teachingLog-form', 'currentStudentsLearningsIds',
            this.props.teachingLogsFromPage.map(d => d.studentsLearningsId)))
    }

    componentWillUnmount() {
        this.props.dispatch(reset('teachingLog-form'));
    }
    render() {
        const optionRenderer = choice => choice == null ? `` : `${choice.studentName}(${choice.courseName} - ${choice.classesName})`;
        const onChange = data => {
            //console.log("data", data)
            //console.log(this.props.currentStudentsLearningsIds)
            let dataArray = Object.values(data).filter(x => typeof (x) != "function")
            //console.log(dataArray)
            if (dataArray.length < this.props.currentStudentsLearningsIds.length) {
                //删除判断
                console.log(dataArray)
                let deletedId = this.props.currentStudentsLearningsIds.filter(x => !dataArray.includes(x))

                //console.log(deletedId)
                let obj = this.props.teachingLogsFromPage.find(x => x.studentsLearningsId == deletedId)
                //console.log(obj)
                if (obj && obj.beRemendedLog) {
                    this.props.dispatch(showNotification(obj.studentName + "的该上课记录已经被补课，无法删除" + obj.remendInfo));
                    data.preventDefault();
                }
            }
            const arr = Object.values(data);
            if (arr.length > 2) {
                arr.pop();
            }
            const value = arr.pop();
            if (typeof (value) != "function") {
                if (value && (arr.includes(value) || this.state.teachingLogsSetFromSL.length == 0 || !this.state.teachingLogsSetFromSL.find(x => x.studentsLearningsId == value))) {
                    //添加重复判断
                    data.preventDefault();
                }
            }
        };
        return (
            <div>
                <SimpleForm onSubmit={this.handleSubmit} save={this.handleSave} form="teachingLog-form" >
                    {
                        this.state.fetched ?
                            <AutocompleteArrayInput
                                onChange={onChange} source="currentStudentsLearningsIds" options={{ suggestionsContainerProps: { style: { zIndex: 1800 } } }} choices={this.state.teachingLogsSetFromSL}
                                optionText={optionRenderer} optionValue="studentsLearningsId" /> :
                            <Labeled label="学生"><LinearProgress /></Labeled>
                    }
                </SimpleForm>
            </div >
        )
    }
}



const mapStateToProps = state => {
    return {
        state: state
    }
};
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export const CreateClassSchedule = compose(withDataProvider,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(formCardStyle), connect(state => ({ classId: selector(state, 'classId') })))(CreateForm)

const CreateClassScheduleStudent = compose(withDataProvider, withStyles(styles), connect(state => ({ currentStudentsLearningsIds: teachingLogSelector(state, 'currentStudentsLearningsIds') })))(AddStudentInClassSchedule);
const CreateClassScheduleRemendStudent = compose(withDataProvider, withStyles(styles))(AddRemendStudentLog);

const ScheduleForm = compose(withDataProvider,
    connect(null, mapDispatchToProps),
    withStyles(styles), connect(state => ({ teachingLogs: selector(state, 'teachingLogs') })))(EditForm)

export const EditClassSchedule = (withStyles)(formCardStyle)(props => (
    <Edit actions={<FormContainerActions customActions={<CustomActionButton {...props} />} />}
        title={<span>编辑排课日程</span>} {...props}>
        <ScheduleForm {...props} />
    </Edit>
))