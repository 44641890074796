
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';

class PositiveNumberFormat extends Component {
  render () {
    const { inputRef, onChange, showNotification, ...other } = this.props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        allowNegative={false}
        thousandSeparator
        isAllowed={
          values => {
            if (!values.value || 
                this.lastValue === values.value) {
                  showNotification('您输入的不是有效的数字', 'warning')
            }
            this.lastValue = values.value;
            onChange(this.lastValue)
            return true;
          }
        }
      />
    );
  }
}

export default compose(connect(null, {
  showNotification
}))(PositiveNumberFormat)