import React, { Fragment, useState } from 'react';
import {
    List, Datagrid,refreshView,ReferenceField, TextField, Filter, Button, TextInput, GET_LIST, Loading, UPDATE,
    FunctionField, ReferenceInput, AutocompleteInput, withDataProvider, GET_MANY, showNotification
} from 'react-admin';
import AvatarField from '../../components/Fields/AvatarField';
import Dialog from '@material-ui/core/Dialog';
import { Button as MuiButton } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListSubheader from '@material-ui/core/ListSubheader';
import { List as MuiList } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { useEffect } from 'react';
import { uniqBy } from 'lodash';

const styles = theme => ({
    courseRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
    },
    listSection: {
        backgroundColor: 'inherit',
        listStyle: 'none'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,

    },
    cardContent: theme.typography.body1,
    subheading: {
        minWidth: 80,
        fontWeight: 500
    },
    label: {
        opacity: .7
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
    teaching_log_type: {
        "& div[role='radiogroup']": { "flex-direction": 'row', "flex-wrap": "wrap" }
    },
    inline_block: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
        // width: 500
    },
    card: {
        boxShadow: 'none'
    },
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
})

const UserFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="微信昵称" source="id"
            reference="wechat-users"
            filterToQuery={searchText => ({ 'nickname[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="id" optionText={s => s.nickname || ''} />
        </ReferenceInput>
        <ReferenceInput perPage={10000} label="员工" source="employeeId"
            reference="employees"
            filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="id" optionText={s => s.name || ''} />
        </ReferenceInput>
        <TextInput source="province" label="省份" />
        <TextInput source="city" label="城市" />
    </Filter>
);
export const WechatUserList = compose(withDataProvider,
    withMobileDialog(),
    withStyles(styles),
    connect(state => ({ user: state.global.user, state: state }),
        dispatch => { return { dispatch } }))(props => {
            const { classes, dataProvider, dispatch } = props;
            const [data, setData] = React.useState();
            const [branches, setBranches] = React.useState();
            const [open, setOpen] = useState(false)
            const [record, setRecord] = useState()

            useEffect(() => {
                if (!data && open) {
                    dataProvider(GET_LIST, 'employees',
                        {
                            pagination: { page: 1, perPage: 100000 },
                            filter: { isTeacher: true }
                        }).then(res => {
                            setData(res.data)
                            dataProvider(GET_MANY, 'branches', 
                            { ids: uniqBy(res.data.map(d => d.branchId)) })
                            .then(r => setBranches(r.data))
                        })
                }
            }, [open]);
            
            const bindEmployee = employee => {
                setOpen(false);
                dataProvider(UPDATE, 'employees',
                { id: employee.id, 
                　　data: {
                        wechatUserId: record.id
                    } 
            　　　}).then(() => {
                    dataProvider(UPDATE, 'wechat-users',
                    { id: record.id, 
                    　　data: {
                            employeeId: employee.id
                        } 
                　　　})
                }).then(() => dispatch(refreshView()) )
                .then(() => dispatch(showNotification('ra.notification.updated', 'info', { messageArgs:  { smart_count: 1 }} )))
            }

            const unBind = user => {
                dataProvider(UPDATE, 'employees',
                { id: user.employeeId, 
                　　data: {
                        wechatUserId: null
                    } 
            　　　}).then(() => {
                    dataProvider(UPDATE, 'wechat-users',
                    { id: user.id, 
                    　　data: {
                            employeeId: null
                        } 
                　　　})
                }).then(() => dispatch(refreshView()) )
                .then(() => dispatch(showNotification('ra.notification.updated', 'info', { messageArgs:  { smart_count: 1 }} )))
            }

            return (<Fragment>
                <List filters={<UserFilter />}  {...props}>
                    <Datagrid >
                        <AvatarField avatarSource="headimgurl" label="微信昵称" nameSource="nickname" />
                        {/* <TextField  label="微信昵称"/> */}
                        <TextField source="province" label="省份" />
                        <TextField source="city" label="城市" />
                        <FunctionField render={record => 
                            record.employeeId &&
                             <ReferenceField {...props} record={record} 
                                source="employeeId" reference="employees" label="员工" >
                                <TextField source="name" />
                            </ReferenceField>
                        } />
                        
                        <FunctionField render={record => 
                            !record.employeeId ?
                            <MuiButton onClick={() => { setOpen(true); setRecord(record) }} >关联系统员工</MuiButton>:
                            <MuiButton onClick={() => { unBind(record) }} >取消关联员工</MuiButton>
                        } />
                    </Datagrid>
                </List>
                <Dialog fullScreen={props.fullScreen}
                    open={open}
                    onClose={() => setOpen(false)} >
                    <DialogTitle >
                        系统员工
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            选择需要绑定的员工，绑定了微信用户后， 员工可以收到微信通知
                        </DialogContentText>
                        <MuiList className={classes.courseRoot} subheader={<li />}>
                            {
                                branches ? branches.map((c, i) =>
                                        <li key={`section-${c.id}`} className={classes.listSection}>
                                            <ul className={classes.ul}>
                                                <ListSubheader>{c.name}</ListSubheader>
                                                {data.filter(l => l.branchId === c.id)
                                                    .map(l =>
                                                        <ListItem style={{ cursor: 'pointer' }}
                                                           key={l.id} onClick={()=>bindEmployee(l)}>
                                                            <ListItemSecondaryAction>
                                                            </ListItemSecondaryAction>
                                                            <ListItemText primary={l.name} 
                                                                secondary={l.mobile}
                                                            />                                                                   
                                                        </ListItem>)
                                                }
                                            </ul>
                                        </li>) :
                                    <Loading />

                            }
                        </MuiList>
                    </DialogContent>

                    <DialogActions>
                        <MuiButton color="primary">
                            关闭
                        </MuiButton>
                    </DialogActions>
                </Dialog>
            </Fragment>
            );
        })