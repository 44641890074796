import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {
    BooleanInput,
    NumberInput,
    EditController,
    LongTextInput,
    SimpleForm,
    DateField,
    Edit,
    TextInput,
    SelectInput
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import FormContainerActions from "../../../components/Widgets/FormContainerActions";

const editStyle = theme => ({
    root: {
        paddingTop: 60,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    }
});

const BranchEdit = ({ classes, onCancel, ...props }) => (
    <EditController {...props}>
        {controllerProps =>
            controllerProps.record ? (
                <div className={classes.root}>
                    <div className={classes.title}>
                        <IconButton onClick={onCancel}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Edit title={<span>编辑分校</span>} actions={<FormContainerActions />} {...props}>
                        <SimpleForm submitOnEnter={true} redirect="list">
                            <Card>
                                <CardContent>
                                    <TextInput label="分校名称" source="name" />
                                    <TextInput label="branch.code" source="branchCode" />
                                    <BooleanInput label="是否为组织"  source="isOrganization" />
                                    <NumberInput label="branch.seed" source="orderNumberSeed" />
                                    <TextInput label="联系电话1" source="tel" />
                                    <TextInput label="联系电话2" source="tel2" />
                                    <TextInput label="地址" source="address" options={{ multiLine: true }} />
                                    <SelectInput label="类型" source="type" choices={[
                                        { id: '直营', name: '直营' },
                                        { id: '加盟', name: '加盟' },
                                        { id: '其他', name: '其他' },
                                    ]} />
                                </CardContent>
                            </Card>
                        </SimpleForm>
                    </Edit>
                </div>
            ) : null
        }
    </EditController>
);

export default withStyles(editStyle)(BranchEdit);
