import React, { Component, Fragment } from 'react';
import {
    FunctionField, required, Loading, GET_LIST, withDataProvider, CheckboxGroupInput,
    NumberInput, ReferenceField, DateField, List, AutocompleteInput, Datagrid, EditButton,
    TextField, Create, ReferenceInput, SimpleForm, TextInput, SelectInput, Edit, Filter
} from 'react-admin';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DrawerInput from '../../components/Inputs/DrawerInput'
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon } from '../../components/Icons';
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import PositiveNumberFormat from '../../util/PositiveNumberFormat';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { formValueSelector, change } from 'redux-form'
// import { DateInput, TimeInput } from "react-admin-date-inputs"
import zhLocale from "date-fns/locale/zh-CN";
import DateFnsUtils from '@date-io/date-fns';
import DateInput from '../../components/Inputs/DateInput';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';
import FromNowField from '../../components/Fields/FromNowField';

const selector = formValueSelector('record-form')
DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth


const validateUserCreation = (values) => {
    // const errors = {};
    // if (!values.name) {
    //     errors.name = ['不能为空'];
    // } 
    // if (!values.mobile) {
    //     errors.mobile = ['不能为空'];
    // } 
    // if (!values.gender) {
    //     errors.gender = ['不能为空'];
    // } 
    // if (!values.status) {
    //     errors.status = ['不能为空'];
    // } 
    // if (!values.branchid) {
    //     errors.branchid = ['不能为空'];
    // } 
    // return errors
};
const StudentLeaveApplicationFilter = (props) => {
    // var user = JSON.parse(localStorage.getItem("user"))
    // var teacherFilterQueryObj = { isTeacher: true }

    // if (user && user.selectedBranch) {
    //     teacherFilterQueryObj.branchId = user.selectedBranch
    // }

    return (
        <Filter {...ShowFilterWhenHasOp(props)}>
            <ReferenceInput label="学员姓名(汉字或拼音)" alwaysOn source="studentId" reference="students"
                filterToQuery={searchText => ({ 'hanzi_pinyin[$iLike]': `%${searchText}%` })} >
                <AutocompleteInput optionValue="id" optionText={student => student.name || ''} />
            </ReferenceInput>
            <ReferenceInput perPage={10} label="班级"
                source="classScheduleId"
                reference="class-schedules"
                filterToQuery={searchText => ({ 'class[name][$substring]': `${searchText}` })} 
                alwaysOn
            >
                <AutocompleteInput optionValue="id" optionText={schedule => schedule.class.name + schedule.beginAt || ''} />
                {/* <SelectInput optionText="class[name]" /> */}
            </ReferenceInput>
            {/* <SelectArrayInput label="班级状态" optionText="name"
                optionValue="id" choices={[{ id: "开放", name: "开放" }, { id: "结班", name: "结班" }, { id: "停用", name: "停用" }]} source="class[type]" alwaysOn />
            <DateInput source="beginAt.$gte" label="上课日期从" />
            <DateInput source="endAt.$lte" label="上课日期至" /> */}
        </Filter>
    );
}
export const StudentLeaveApplicationList = props => {
    return <List actions={<ListActions />} filters={<StudentLeaveApplicationFilter />} {...props} bulkActionButtons={<Fragment></Fragment>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <ReferenceField label="学员" source="studentId" reference="students">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="请假班级" source="classScheduleId" reference="class-schedules">
                <TextField source="class.name" />
            </ReferenceField>
            <ReferenceField label="请假排课日期" source="classScheduleId" reference="class-schedules">
                <DateField source="beginAt" locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }} />
            </ReferenceField>
            <DateField source="createdAt" label="申请时间" showTime locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
            <TextField source="comments" label="申请理由" />
            {/* <TextField source="id" label="id" />
            <ReferenceField label="班级" source="classId" reference="classes">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="class.type" label='班级状态' />
            <ReferenceField label="老师" source="teacherId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="repeatBeginAt" label="周期开始日期" locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }} />
            <DateField source="repeatEndAt" label="周期结束日期" locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }} />
            <DateField source="beginAt" label="上课开始时间" showTime locales="ZH" options={{ hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
            <DateField source="endAt" label="上课结束时间" showTime locales="ZH" options={{ hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
            <FromNowField sortBy="createdAt" source="createdAt" label="创建时间" />
            {/* <EditButton icon={<EditIcon />} />
            <CloneButton omitProps={['classSchedules']} /> 

            <FunctionField render={record => (record.class && record.class.type != "结班" && record.class.type != "停用") ?
                <Fragment>
                    <EditButton record={record} basePath={props.basePath} icon={<EditIcon />} />
                    <CloneButton record={record} basePath={props.basePath} omitProps={['classSchedules', 'class']} />
                </Fragment>
                : null} /> */}
        </Datagrid>
    </List>
};
