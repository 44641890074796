
import React from 'react'
import {Editor} from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { addField } from 'react-admin';
import { Labeled } from 'react-admin';

const TinyMCEInput = ({
  id,
  content,
  disabled,
  label,
  inline,
  input: { value, onChange },
  init
}) => 
{
  return (
    <Labeled label={label}>
      <Editor
            apiKey='mikf6vz3vkm1o6w6rfezfyjzlagw83yk01k6pwndakjfdzvd' 
            init={{
              ...init
            }}
            initialValue={content ? decodeURIComponent(content) : decodeURIComponent(value)}
            onEditorChange={content => { value = onChange(encodeURIComponent(content));  }}
        />
      </Labeled>
  )
}

TinyMCEInput.propTypes = {
  addField: PropTypes.bool.isRequired,
  addLabel: PropTypes.bool.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  config: PropTypes.object
}

TinyMCEInput.defaultProps = {
  addField: true,
  addLabel: true,
  options: {},
  record: {}
}
export default addField(TinyMCEInput)

