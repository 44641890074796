import React from 'react';
import { Route } from 'react-router-dom';
import DataImport from './containers/system/import';
import SystemConfiguration from './containers/system/systemConfigs'
import CourseRelation from './containers/operation/courseRelation';
import Configuration from './containers/system/configuration'

export default [
    <Route exact path="/data-import" component={DataImport} />,
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/system-configuration" component={SystemConfiguration} />,
    <Route exact path="/courses-relation" component={CourseRelation} />,
    <Route exact path="/configuration" component={Configuration} />,
];