import React from 'react';
import { Notification as RaNotification } from 'react-admin';
import Portal from "@material-ui/core/Portal";

export default props => 
        <Portal>
          {React.createElement(RaNotification,
             {...props,
               anchorOrigin: { vertical: window.innerWidth < 960 ? 'bottom' : 'top', horizontal: 'right' }
              })}
        </Portal>