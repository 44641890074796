import React, { Component, Fragment } from 'react';
import {
    Datagrid, withDataProvider, refreshView as refreshViewAction, Filter, DatagridLoading,
    TextField,  FunctionField, ReferenceField, translate, Button as RaButton,
    Responsive, TextInput, showNotification, UPDATE, CREATE } from 'react-admin';
import {
     RegisterIcon, RefundIcon, AssignClassIcon, ResumeLearningIcon, PauseLearningIcon,
    RenewIcon, TransferClassIcon, NoCourseIcon, ClassIcon
} from '../../components/Icons';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { GET_LIST } from 'ra-core/lib/dataFetchActions';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import {SimpeCourseShow} from '../operation/course';
import ListSubheader from '@material-ui/core/ListSubheader';
import { arrayPush, arrayRemoveAll, change } from 'redux-form';
import { connect } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CardActions from '@material-ui/core/CardActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import { refreshView } from 'ra-core';
import { isEmpty } from 'lodash';
import {TextField as MuiTextField} from '@material-ui/core';

const moment = require('moment');

const styles = theme => ({
    leftInput: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    // drawerRoot: {
    //     //width: 500
    //     '@media (max-width:500px)': {

    //     },
    // },
    listRoot: {
        '@media (max-width:500px)': {
            width: '100%'
        }
    },
   
    card: {
        boxShadow: 'none'
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    table: {
        tableLayout: 'auto',
    },
    dialogRoot: {
        width: 700,
        maxWidth: 700
    },
})

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['分校名不能为空'];
    }
    return errors
};

const fields = [ 
    // { source: "extra_attributes.prop1", label: "属性1" },
    // { source: "extra_attributes.prop2", label: "属性2" },
    // { source: "extra_attributes.prop3", label: "属性3" },
]

const StudentFilter = (props) => (
    // <div style={{ }}> 
    <Filter {...ShowFilterWhenHasOp(props)}>
        {/* <TextInput label="属性1" source="extra_attributes.prop1" />
        <TextInput label="属性2" source="extra_attributes.prop2" /> */}
        <TextInput label="姓名" source="name" />
        {
            fields.map(f =>
                <TextInput label={f.label} source={f.source} />
            )
        }
    </Filter>
    // </div>
);

const EmptyRecord = translate(props => 
    <div style={{ textAlign: 'center', margin: 16 }}>
        <NoCourseIcon style={{ height: 70, width: 70 }} />
        <p>{props.translate('该学员还没有在读的课程')}</p>
        <Button color="secondary" onClick={props.showCreate}
            variant="contained">{props.translate('点此报名')}</Button>
    </div>
)

class Courses extends Component {
    state = {
        showPanel: false,
        updating: false,
        showExpire: false
    }

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };
    
    showRefund = record => {
        this.props.dispatch({
            type: 'START_TRANSACTION',
            payload: {
                ids: [record.studentId],
                showApplyCourse: true,
                applyType: '退费',
                defaultValue: {
                    endCourse: 'all',
                    learningId: record.id,
                    courseId: record.courseId,
                    pricePerLesson: 'regular',
                    period: Number(record.period)
                }
            }
        })
    }

    showRenew = record =>  
        this.props.dispatch({
        type: 'START_TRANSACTION',
        payload: {
            ids: [record.studentId],
            showApplyCourse: true,
            applyType: '续费',
            defaultValue: {
                tuitions: [
                    {
                        type: '课程学费',
                        detail: {
                            hasDiscount: [],
                            quantity: 1,
                            rule: this.props.state.admin.resources.courses.data[record.courseId].rules[0],
                            type: record.classId ? 'class' : 'course',
                            referenceId: record.classId || record.courseId,
                            chargeMode: this.props.state.admin.resources.courses.data[record.courseId].chargeMode
                        },
                        course: this.props.state.admin.resources.courses.data[record.courseId]
                    }
                ]
            }
        }
    })

    showRegister = record => {
        this.props.dispatch({type: 'START_TRANSACTION', payload: {ids: [this.props.record.id], showApplyCourse: true, applyType: '报名'} });
    };

    showJoinClass = record =>
        this.props.dispatch({
            type: 'SHOW_CLASS_TRANSFER',
            payload: {
                ids: [record.studentId],
                learningId: record.id,
                title: '加入班级',
                selectedCourse: record.courseId,
                selectedClass: record.learningClasses,
                show: true
            }
        })

    showTransferClass = (record, learningClass) => 
        this.props.dispatch({
            type: 'SHOW_CLASS_TRANSFER',
            payload: {
                ids: [record.studentId],
                show: true,
                learningClassId: learningClass.learningClassId,
                title: '转班',
                selectedCourse: record.courseId,
                selectedClass: record.learningClasses,
            }
    })

    showExpireDialog = record => {
        this.setState({
            showExpire: true,
            selectedRecord: record
        })
    }

    setExpireDate = () => {
        this.props.dataProvider(UPDATE, 'students-learnings', {
            id: this.state.selectedRecord.id,
            data: { expire_date: this.state.ex_date }
        }).then(d => this.props.dispatch(showNotification('ra.notification.updated')))
        this.setState({
            showExpire:false
        })
        
    }

    pauseOrResumeLearning = (record, status) => {
        this.setState({updating: true})
        this.props.dataProvider(UPDATE, 'students-learnings', {
            id: record.id,
            data: { status: status }
        }).then(() => {
            this.props.dispatch(refreshView())
           
        }).then(() => {
            this.props.dispatch(showNotification('ra.notification.updated'));
            this.setState({updating: true})
        })
    }

    handlePopupClick = (record, disabled, event) => {
        this.setState({record: record, anchorEl: event.currentTarget, diabled: disabled });
        event.stopPropagation();
        event.preventDefault();
     };

     handlePopupClose = () => {
        this.setState({ anchorEl: null, record: null });
    };

    render() {
        const { classes, translate } = this.props; 
        return (
            <div>
               　<Responsive medium={
                        this.state.updating ?
                        <DatagridLoading nbChildren={3} classes={classes}/>
                        :
                      <Datagrid {...this.props} style={{ width: '100%' }}
                        expand={<ClassList showTransferClass={this.showTransferClass} />} >
                        <ReferenceField label="课程" basePath="/courses" source="courseId" reference="courses">
                           <TextField source="name" />
                       </ReferenceField>
                       <TextField label="状态" source="status" />
                       <FunctionField label="剩余/总共课时" 
                        render={record => 
                            {
                               const leftLessons = record.leftLessons
                               return record.duration ? 
                                record.duration :
                                record.status === '结业' ?
                                Number(record.lessons)
                                :
                                <div>
                                    <LinearProgress color="secondary" variant="determinate" 
                                        value={leftLessons/Number(record.lessons) * 100} /> 
                                    <span style={{marginTop: 5, display: 'inline-block'}}>
                                        {leftLessons} / {record.lessons}
                                    </span> 
                                </div>  
                            }
                        } />

                    <FunctionField 
                        render={record => 
                            {
                                const leftLessons = Number(record.leftLessons)
                               　　return <span >
                                     <RaButton 
                                       onClick={this.showRenew.bind(this, record)}
                                       label="续费"><RenewIcon/></RaButton>　
                                     <RaButton label="加入班级" disabled={!record.duration && !leftLessons}
                                        onClick={this.showJoinClass.bind(this, record)}  ><AssignClassIcon/></RaButton>  
                                    {
                                        record.status === '在读'? 
                                        <RaButton label="停课" 
                                            onClick={this.pauseOrResumeLearning.bind(this, record, '停课')}  ><PauseLearningIcon/></RaButton>: 
                                        record.status === '停课' || record.status === '结业'? 
                                        <RaButton label="复课"
                                            onClick={this.pauseOrResumeLearning.bind(this, record, '在读')}  ><ResumeLearningIcon/></RaButton> : null
                                    }
                                   
                                   <RaButton onClick={this.showRefund.bind(this, record)} disabled={!record.duration && !leftLessons} label="退费"><RefundIcon /></RaButton>

                                   <RaButton 
                                       onClick={this.pauseOrResumeLearning.bind(this, record, '结业')}
                                       label="结业"><RenewIcon/></RaButton>　
                                    <RaButton label="设置有效期"
                                            onClick={this.showExpireDialog.bind(this, record)}  ><ResumeLearningIcon/></RaButton>
                                </span>
                            }
                        } />

                     </Datagrid>
                    }
                 />
                 <Dialog
                    open={this.state.showExpire}>
                    <DialogTitle >设置有效期</DialogTitle>
                    <DialogContent>
                        <MuiTextField id="date"
                            label="课程有效期"
                            type="date"
                            defaultValue={this.state.selectedRecord ? this.state.selectedRecord.expire_date : ""}
                            onChange={e => this.setState({ ex_date: e.target.value }) }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ showExpire: false })}>
                        关闭
                        </Button>
                        <Button disabled={!this.state.ex_date} onClick={this.setExpireDate}>
                        确定
                        </Button>
                    </DialogActions>
                 </Dialog>
                <ClassTransferDialog onComplete={this.fetchData} />
                {
                   this.state.list && this.state.list.length > 0 &&
                   <Button style={{marginTop: 16}} color="primary" onClick={this.showRegister} ><RegisterIcon/> {translate('报名新课程')} </Button>
                }
            </div>)
    }
};

export const ClassList = props => 
        // <List style={{maxWidth:360}}
        // subheader={<ListSubheader>班级</ListSubheader>}>
        //   {props.record.classid.map(id => (
        //     <ListItem dense key={id} >
        //       <ListItemText primary={
        //           <ReferenceField  basePath="/classes" record={{id: id}} 
        //                 source="id" reference="classes">
        //                  <TextField source="name" />
        //            </ReferenceField>
        //       } />
        //       <ListItemSecondaryAction>
        //          <RaButton onClick={props.showTransferClass.bind(this, props.record)} label="转班"
        //                 disabled={!props.record.duration && !props.record.leftLessons} >
        //                     <TransferClassIcon/>
        //             </RaButton>
        //       </ListItemSecondaryAction>
        //     </ListItem>
        //   ))}
        // </List>
    <Table style={{maxWidth: 360}}>
        <TableHead>
        <TableRow>
            <TableCell>班级</TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
          {props.record.learningClasses.map(cls => {
            return (
              <TableRow>
                <TableCell>
                    <ReferenceField  basePath="/classes" record={cls} 
                        source="classId" reference="classes">
                         <TextField source="name" />
                   </ReferenceField>
                </TableCell>
                <TableCell>
                    <RaButton onClick={props.showTransferClass.bind(this, props.record, cls)} label="转班"
                        disabled={!props.record.duration && !props.record.leftLessons} >
                            <TransferClassIcon/>
                    </RaButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
    </Table>

class CreateStudentCourse extends Component {

    state = {
        open: false,
        checked: null,
        showDetail: false,
        expands: [],
        registers: {}
    }

    handleClose = () => {
        this.setState({
            open: false,
            checked: null,
            showDetail: false,
            expands: [],
            registers: {}
        })
        this.props.dispatch({ type: 'SHOW_SELECT_COURSE', payload: { showSelectCourse: false } });
    }

    componentWillMount = () => {
        this.props.dataProvider(GET_LIST, 'courses',
            { pagination: { page: 1, perPage: 100000 } }).then(data => {
                this.setState({
                    allCourses: data.data
                })
            })
    }

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
    };

    expandClass = id => {
        if (this.state.expands.indexOf(id) >= 0) {
            this.state.expands.splice(this.state.expands.indexOf(id), 1);
        } else {
            this.state.expands.push(id);
        }
        this.setState(this.state.expands);
    }

    onClassChange = (courseId, classId) => {
        if (this.state.registers[courseId] && 
                this.state.registers[courseId] === classId) {
            this.state.registers[courseId] = null;
        } else {
            this.state.registers[courseId] = classId
        }
        this.setState(this.state.registers);
    }

    onCourseChange = courseId => {
        if (this.state.registers[courseId] && 
            this.state.registers[courseId] === courseId) {
            this.state.registers[courseId] = null;
        } else {
            this.state.registers[courseId] = courseId
        }
        this.setState(this.state.registers);
    }

    itemChecked = id => Object.values(this.state.registers).indexOf(id) >= 0

    selectCourses = () => {
        this.props.dispatch(arrayRemoveAll('transaction-form', 'tuitions'));
        this.props.dispatch(arrayRemoveAll('transaction-form', 'materials'));
        this.props.dispatch(arrayRemoveAll('transaction-form', 'incidentals'));
        let materials = [], incidentals = [];
        Object.keys(this.state.registers).filter(key => this.state.registers[key])
            .forEach((courseId, i) => {
                let course = this.state.allCourses.find(course => course.id == courseId);
                this.props.dispatch(arrayPush('transaction-form', 'tuitions',
                {
                    type: '课程学费',
                    detail: {
                        hasDiscount: [],
                        quantity: 1,
                        chargeMode: course.chargeMode,
                        rule: course.rules.length > 0 ? course.rules[0] : null,
                        type: courseId === this.state.registers[courseId] ? 'course' : 'class',
                        referenceId: courseId === this.state.registers[courseId] ? courseId: this.state.registers[courseId]
                    },
                    course: {
                        id: course.id,
                        name: course.name,
                        studyMode: course.studyMode,
                        chargeMode: course.chargeMode,
                        rules: course.rules
                    }
                }));
                course.incidentals.forEach(m => 
                    this.props.dispatch(arrayPush('transaction-form', 'incidentals',
                        {
                            type: '杂费',
                            detail: {
                                quantity: 1,
                                price: m.sellingPrice,
                                referenceId: m.id
                            },
                            incidentals: { id: m.id, inTuition: true }
                        })));
                course.materials.forEach(m => 
                    this.props.dispatch(arrayPush('transaction-form', 'materials',
                        {
                            type: '教材',
                            detail: {
                                quantity: 1,
                                price: m.sellingPrice,
                                referenceId: m.id
                            },
                            materials: { id: m.id, inTuition: true }
                        }))); 
                
            });
            
        this.handleClose();
    }

    render() {
        const { classes } = this.props
        return (
            <div> 
                <Drawer
                    anchor="right"
                    classes={{ paperAnchorRight: classes.drawerRoot }}
                    open={this.props.showSelectCourse}
                    onClose={this.handleClose}>

                     <Typography component="body1" 
                        style={{lineHeight: 'normal', marginTop: 16, marginLeft: 16, backgroundColor: '#fff'}}>
                        同一课程下的班级为单选， 不同课程可以多选
                          <br/>可以只选择课程， 而暂时不选择班级 
                    　</Typography>

                    <div style={{ marginTop: 16, padding: 16, overflowY: 'auto' }}>
                        <List dense={true} classes={{root: classes.listRoot}} >
                                {this.state.allCourses && this.state.allCourses.map(course => (
                                    <Fragment>
                                        <ListItem key={course.id}  >
                                            <Checkbox checked={this.itemChecked(course.id)} onChange={this.onCourseChange.bind(this, course.id)}  />
                                            <ListItemText primary={ <Typography onClick={e => this.setState({ showDetail: true, anchorEl: e.target, showData: course })}
                                                                                variant="h3" gutterBottom>{course.name}</Typography> }
                                                secondary={`${course.studyMode} | ${course.chargeMode}`}
                                            />
                                            <ListItemSecondaryAction>
                                                {
                                                    course.classes && course.classes.length > 0 &&
                                                        <IconButton onClick={this.expandClass.bind(this, course.id)}>
                                                        {
                                                            this.state.expands.indexOf(course.id) >= 0 ?
                                                            <ExpandLess />: 
                                                            <ExpandMore />
                                                        }
                                                    </IconButton>
                                                }
                                                
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {
                                            course.classes && course.classes.length > 0 &&
                                            <Collapse in={this.state.expands.indexOf(course.id) >= 0} timeout="auto" unmountOnExit>
                                                <List  dense={true}  disablePadding>
                                                    {
                                                        course.classes.map(c => (
                                                            <ListItem dense  className={classes.nested}>
                                                                    <Checkbox checked={this.itemChecked(c.id)} onChange={this.onClassChange.bind(this, course.id, c.id)} />
                                                                    <ListItemText primary={ <Typography onClick={e => this.setState({ showDetail: true, anchorEl: e.target, showData: c })}
                                                                                variant="h3" gutterBottom>{c.name}</Typography> }
                                                                            secondary={`${moment(c.openAt).format('YYYY-M-D')} -> ${moment(c.closeAt).format('YYYY-M-D')}`}
                                                                        />
                                                                </ListItem>)
                                                            )
                                                    }
                                                </List>
                                            </Collapse>
                                        }
                                    </Fragment>
                                ))}
 
                            </List> 
                    </div>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                                <Button onClick={this.handleClose}>关闭</Button>
                                 <Button onClick={this.selectCourses}>确定</Button>
                            </CardActions>
                </Drawer> 

                <Popover useLayerForClickAway={false}
                    id="mouse-over-popover"
                    open={this.state.showDetail}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => this.setState({ showDetail: false })}
                    disableRestoreFocus
                    >
                       <SimpeCourseShow record={this.state.showData}  />
                    </Popover>
            </div>
        )
    }
}

class ClassTransfer extends Component {

    state = {}

    handleCloseClick = () => {
        this.props.dispatch({ type: 'SHOW_CLASS_TRANSFER', payload: { show: false } });
    }

    handleSave = () => {
        let p = !isEmpty(this.props.learningId) ? 
        this.props.dataProvider(CREATE, 'students-learnings-classes', {
            data: { 
                classId: this.state.value,
                studentsLearningsId: this.props.learningId
            }
        })
        : this.props.dataProvider(UPDATE, 'students-learnings-classes', {
            id: this.props.learningClassId,
            data: { classId: this.state.value }
        })
        
        p.then(data => {
            this.props.dispatch(showNotification('ra.notification.updated', 'info', { messageArgs:  { smart_count: 1 } }));
            this.props.dispatch(refreshView());
            this.handleCloseClick();
            if (this.props.onComplete) {
                this.props.onComplete();
            }
        })
    }

    closed = () => {
       // this.props.dispatch(destroy('transaction-form'));
    }

    componentWillReceiveProps = props => {
        if (props.showDialog && props.showDialog !== this.props.showDialog) {
            props.dataProvider(GET_LIST, 'courses', {
                pagination: { page: 1, perPage: 10000 },
                filter: { onlyClass: true }
            }).then(result => {
                if (result.data) {
                   this.setState({courses: result.data})
                }
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.showDialog}
                fullWidth={true}
                onClose={this.handleCloseClick}
                classes={{ paper: classes.dialogRoot }}
                onExited={this.closed} >
                <DialogTitle id="responsive-dialog-title">
                    {
                        this.props.title || '转班'
                    }
                    </DialogTitle>
                <DialogContent dividers>
                    <FormLabel component="legend">{
                        this.state.data && 
                        this.state.data.length === 0 ?
                            <span>该课程下没有可用的班级,
                                <Button href="/classes" color="primary" >
                                    <ClassIcon /> 请先添加班级
                                </Button>
                            </span>
                        : '请选择班级'
                    }</FormLabel>
                      <List  >
                          {
                            this.state.courses && this.state.courses.map(c =>
                                <Fragment>
                                  <ListItem onClick={() => this.setState({ selectedCourse: c })}>
                                    <ListItemText primary={c.name} />
                                    <ListItemSecondaryAction>
                                        <RaButton onClick={() => this.setState({ selectedCourse: c === this.state.selectedCourse ? null : c })}>{ c.classes.length > 0 ?  this.state.open ? <ExpandLess /> : <ExpandMore /> : null}</RaButton>
                                        
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse in={c === this.state.selectedCourse} timeout="auto" unmountOnExit>
                                    <RadioGroup name="selectClass"
                                            style={{ marginLeft: 48 }}
                                            onChange={event => this.setState({ value: event.target.value })} 
                                            value={this.state.value}>
                                        {
                                            this.state.selectedCourse &&  this.state.selectedCourse.classes.map(cls => 
                                                <FormControlLabel value={cls.id} control={<Radio />} label={cls.name}
                                                    disabled={this.props.learningClasses && this.props.learningClasses.some(c => c.classId === cls.id)} />
                                                )
                                        }
                                        </RadioGroup>
                                </Collapse>
                               </Fragment>
                              )
                          }
                       
                        </List>
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseClick} color="primary">
                        关闭
                        </Button>
                    <Button disabled={!this.state.value} onClick={this.handleSave} color="primary">
                        保存
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
      dispatch,
      refreshView: refreshViewAction
    }
}

const mapStateToProps = state => ({
    showSelectCourse: state.student.showSelectCourse,
    state: state
});

export const ApplyCourse = compose(withStyles(styles), 
                                   connect(mapStateToProps, mapDispatchToProps),
                                   withDataProvider)(CreateStudentCourse);

export const CourseList = compose(withStyles(styles), 
                            withMobileDialog(),
                            withDataProvider,
                            translate,
                            connect(mapStateToProps, mapDispatchToProps),)(Courses);

export const ClassTransferDialog = compose(withStyles(styles), 
                                    withMobileDialog(),
                                    withDataProvider,
                                    connect(state => ({
                                        showDialog: state.student.showClassTransfer,
                                        coruseId: state.student.selectedCourse,
                                        learningId: state.student.learningId,
                                        learningClasses: state.student.selectedClass,
                                        learningClassId: state.student.learningClassId,
                                        title: state.student.title,
                                        ids: state.student.ids,
                                        state: state
                                    }), mapDispatchToProps))(ClassTransfer);