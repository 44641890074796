import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';

import { translate } from 'react-admin';

const styles = theme => ({
    listItem: {
        paddingLeft: '1rem',
    },
    listItemText: {
        paddingLeft: 2,
        fontSize: '1rem',
        whiteSpace: 'nowrap',
       
    },
    primaryColor: {
        color: theme.palette.text.secondary
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
});

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    classes,
    children,
    translate,
}) => (
        <Fragment>
            <ListItem
                dense
                button
                onClick={handleToggle}
                className={classes.listItem}
            >
                <ListItemIcon classes={{root: classes.primaryColor}}>{icon}</ListItemIcon>
                <ListItemText
                    inset
                    primary={isOpen ? translate(name) : ''}
                    secondary={isOpen ? '' : translate(name)}
                    className={classes.listItemText}
                    classes={{primary: classes.primaryColor, secondary: classes.primaryColor}}
                />
                <ListItemIcon>{isOpen ? <ExpandMore /> : <ExpandLess />}</ListItemIcon>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    );

const enhance = compose(
    translate,
    withStyles(styles)
);

export default enhance(SubMenu);