import isValid from 'date-fns/isValid';

let courseError = {}, classError = {};

export const validateStudentData = (data, courses, classes, employees, paymentMethods) => {
    let errors = {
        '学员姓名': [],
        '日期格式': [],
        '课程': [],
        '班级': [],
        '销售员': [],
        '报名类型': [],
        '购买数量': [],
        '单位': [],
        '支付账户': [],
        '剩余课时': []
    };
    let dataPos = -1, validData = 0;
    courseError = {};
    classError = {};
    data.forEach((row, i) => {
        if (row.length === 0) {
            return;
        }
        if (i === 0) {
            setColumnsIndex(row, indexes);
            if (row[0].trim().startsWith('学员姓名'))　{
                return;
            }
        } else if (i === 1) {
            if (row[0].trim().startsWith('导入须知'))　{
                return;
            }
        } else if (i === 2) {
            if (row[0].trim().startsWith('张三'))　{
                return;
            }
        }
        else if (i === 3) {
            if (row[0].trim().startsWith('李四'))　{
                return;
            }
        }
        else if (i === 4) {
            if (row[0].trim().startsWith('李四') || row[0].trim().startsWith('王五'))　{
                return;
            }
        }
        if (dataPos === -1) {
            dataPos = i;
        }
        if (row.every(col => !col || col.trim() === '') ||
            !row[indexes.name.pos]
         ) {
            return;
        }

        if (row[indexes['transaction.type'].pos] !== '续费') {
            validData++;
        }
        
        errors['日期格式'].push(...dateIsValid(row, i));
        let err = courseIsValid(row, i, courses, classes);
        if (err.course) {
            errors['课程'].push(err.course);
        }
        if (err.class) {
            errors['班级'].push(err.class);
        }
        let saleError = saleEmployeeIsValid(row, i, employees);
        
        if (saleError) {
            errors['销售员'].push(saleError);
        }

        let paymentError = paymentIsValid(row, i ,paymentMethods);
        if (paymentError) {
            errors['支付账户'].push(paymentError);
        }

        if (data[i][indexes['transaction.type'].pos]) {
            let quantityError = quantityIsValid(row, i);
            let unitError = unitIsValid(row, i);
            let rlessonsError = remainingLessonsIsValid(row, i);
            if (quantityError) {
                errors['购买数量'].push(quantityError);
            }
            if (unitError) {
                errors['单位'].push(unitError);
            }
            if (rlessonsError) {
                errors['剩余课时'].push(rlessonsError);
            }
        }
        
    });
    let c = 0;
    Object.keys(errors).map(key => {
        c += errors[key].length
    });
    errors.count = c;
    return {
        dataPos,
        indexes,
        errors,
        validData
    };
}
let indexes = {
    'name': { column: '学员姓名',  pos: undefined },
    'birthday': { column: '生日',  pos: undefined },
    'transaction.contract': { column: '合同编号',  pos: undefined },
    'phone': { column: '联系电话',  pos: undefined },
    'contact_person': { column: '所属关系(主要)',  pos: undefined },
    'gender': { column: '性别',  pos: undefined },
    'transaction.subtotal': { column: '实缴学费',  pos: undefined },
    'course': { column: '报读课程',  pos: undefined },
    'class': { column: '报读班级',  pos: undefined },
    'transaction.type': { column: '报名类型',  pos: undefined },
    'lessons': { column: '购买数量',  pos: undefined },
    'chargeMode': { column: '单位',  pos: undefined },
    'freeLessons': { column: '赠送课时',  pos: undefined },
    'price': { column: '原价',  pos: undefined },
    'remainingLessons': { column: '剩余课时',  pos: undefined },
    'transaction.payment': { column: '收款账户',  pos: undefined },
    'transaction.date': { column: '报名日期',  pos: undefined },
    'secondary_phone': { column: '次要电话',  pos: undefined },
    'secondary_contact_person': { column: '所属关系(次要)',  pos: undefined },
    'wechat': { column: '微信号',  pos: undefined },
    'address': { column: '居住区域',  pos: undefined },
    'comment': { column: '备注',  pos: undefined },
    'transaction.saleEmployee': { column: '销售员',  pos: undefined },
};

const dateCols = [
    'birthday',
    'transaction.date'
]

const setColumnsIndex = student => {
    Object.keys(indexes).forEach(key => {
        for(let i = 0; i < student.length; i++) {
            let col = student[i];
            col = col ? col.trim() : '';
           if (col.startsWith(indexes[key].column)) {
            indexes[key].pos = i;
            break;
           }
        }
    })
}

const nameIsValid = (student, i) => {
    let name = student[indexes.name.pos];
    if (!name || name.trim().length === 0) {
        return {
            type: 'too long',
            position: [i, indexes.name.pos]
        };
    }
}

const dateIsValid = (student, i) => {
    let errors = [];
    dateCols.forEach(col => { 
        if (student[indexes[col].pos] && !isValid(new Date(student[indexes[col].pos]))) {
            errors.push(
                {
                    type: 'invalid',
                    position: [i, indexes[col].pos]
                });
        }
    });
    if (errors.length === 0) {
        return [];
    } else {
        return errors;
    }
}

const courseIsValid = (student, i, courses, classes) => {
    let errors = {};
    if (student[indexes.course.pos] && !courseError[student[indexes.course.pos]]) {
        if (!courses.some(c => c.name === student[indexes.course.pos])) 
        {
            errors.course = {
                    name: student[indexes.course.pos],
                    type: 'not exist',
                    position: [i, indexes.course.pos]
                };
            courseError[student[indexes.course.pos]] = true;
        }
        
    }

    if (student[indexes.class.pos] && !classError[student[indexes.class.pos]]) {
        if (!classes.some(c => c.name === student[indexes.class.pos])) 
        {
            errors.class = {
                    name: student[indexes.class.pos],
                    type: 'not exist',
                    position: [i, indexes.class.pos]
                };
                classError[student[indexes.class.pos]] = true;
        }
    }
    return errors;
}

const saleEmployeeIsValid = (student, i, employees) => {
    if (student[indexes['transaction.saleEmployee'].pos] && 
                !employees.some(em => em.name === student[indexes['transaction.saleEmployee'].pos].trim() ) ) {
                    return {
                        type: 'not exist',
                        position: [i, indexes['transaction.saleEmployee'].pos]
                    }
        }
}

const paymentIsValid = (student, i, payments) => {
    if (student[indexes['transaction.payment'].pos] && payments &&
                !payments.some(em => em.name === student[indexes['transaction.payment'].pos].trim() ) ) {
                    return {
                        type: 'not exist',
                        position: [i, indexes['transaction.payment'].pos]
                    }
        }
}

const quantityIsValid = (student, i) => {
    if (isNaN(Number(student[indexes.lessons.pos]))) {
        return {
            type: 'not number',
            position: [i, indexes.lessons.pos]
        }
    }
}

const remainingLessonsIsValid = (student, i) => {
    if (isNaN(Number(student[indexes.remainingLessons.pos]))) {
        return {
            type: 'not number',
            position: [i, indexes.remainingLessons.pos]
        }
    }
}

const unitIsValid = (student, i) => {
    if (!student[indexes.chargeMode.pos]) {
        return {
            type: 'empty',
            position: [i, indexes.lessons.pos]
        }
    }
}