import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import "video-react/dist/video-react.css"; 

class MediaPreview extends React.Component {

      handleClose = () => {
        this.props.dispatch({ type: 'TOGGLE_MEDIA_PREVIEW', payload: { url: '' } })
      };
      
      render() {
        const { fullScreen } = this.props; 
        return (
            <Dialog
                fullScreen={fullScreen}
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{"预览"}</DialogTitle>
            <DialogContent style={{textAlign: 'center'}}>
                <Player fluid={false}
                    width={400}
                    playsInline
                    src={this.props.previewUrl}　/>
            </DialogContent>
            <DialogActions>
                <Button style={{fontSize: 16}} onClick={this.handleClose} color="primary" autoFocus>
                关闭
                </Button>
            </DialogActions>
            </Dialog>
        );
    }
}


const enhance = compose(
    withMobileDialog(),
    connect(
       state => ({ 
           open: state.global.ui.showMediaPreview,
           previewUrl: state.global.ui.mediaPreivewUrl
       }),
       dispatch => {
            return {
                dispatch
            }
        }
    )
);

export default enhance(MediaPreview);
