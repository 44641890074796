import feathers from '@feathersjs/client';
import axios from 'axios';
import io from 'socket.io-client';

const host = process.env.REACT_APP_API_ADDRESS;

axios.interceptors.request.use(function (config) {
    let user = localStorage.getItem('user');
    if (user && typeof user === 'string') {
        user = JSON.parse(user);
        config.headers.user = user.id;
        config.headers.selectedBranch = user.selectedBranch;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
});

// const socket = io(process.env.REACT_APP_API_ADDRESS);

// export const socketClient = feathers()

//.configure(feathers.authentication({ jwtStrategy: 'jwt', storage: window.localStorage }))

export const feathersClient = feathers()
    .configure(feathers.rest(host).axios(axios))
    .configure(feathers.authentication({ jwtStrategy: 'jwt', storage: window.localStorage }));
