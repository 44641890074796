import React, { Component, Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import TreeView from 'react-treeview';
import 'react-treeview/react-treeview.css'
import {SelectedIcon, SelectIcon} from '../Icons';
import Popover from '@material-ui/core/Popover';
import { UPDATE} from 'ra-core';
import {withDataProvider} from 'react-admin';

class BelongBranches extends Component {

    state = { open: false, user: null }

    componentWillMount = () => {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user) {
            this.props.dispatch( { type: 'SET_USER', payload: { user: this.user } } );
            this.props.dispatch( { type: 'SET_WORKING_BRANCH', payload: { branchId: this.user.selectedBranch } } );
        }
    }

    renderChildren = children => {
       return children.map(child => 
            child.children && child.children.length > 0 ?
            <TreeView style={{marginTop: 4, marginBottom: 4, cursor: 'pointer'}} 
                nodeLabel={<span onClick={this.changeBranch.bind(this,child)}> {child.name} 
                 {
                      child.id === this.user.selectedBranch &&
                       <SelectedIcon style={{ width: 16, height: 16 }}/>
                  }
               </span>} defaultCollapsed={false}>
                {
                    this.renderChildren(child.children)
                }
             </TreeView> :
             <div onClick={this.changeBranch.bind(this,child)}
                 style={{paddingLeft: 12, cursor: 'pointer', marginTop: 4, marginBottom: 4}}>{child.name}
                    {
                                                child.id === this.user.selectedBranch &&
                                                <SelectedIcon style={{ width: 16, height: 16 }}/>
                     }
                 </div>
        )
    }

    changeBranch = branch => {
        this.props.dataProvider(UPDATE, 'users',
            { id: this.user.id, 
            　　data: {
                    selectedBranch: branch.id
                } 
        　　　}).then(() => window.location.reload() )
    }

    handleClick = event => {
        const { currentTarget } = event;
        this.setState(state => ({
          anchorEl: currentTarget,
          open: !state.open,
        }));
     };
    
     selectedBranchName = () => {
         if (!this.user) {
             return;
         }
         let id = this.user.selectedBranch;
         let loop = children => {
             let name = null;
             for(let i = 0; i < children.length; i++) {
                 if (children[i].id === id) {
                     name = children[i].name;
                 } else if (children[i].children) {
                     name = loop(children[i].children);
                 }
                 if (name) {
                     break;
                 }
             }
             return name;
         }
         if (this.user.branch.id === id) {
             return this.user.branch.name
         } else {
             return loop(this.user.branch.children);
         }
     }

    render () {
        /* TODO: 用户所属的分校和选择的分校*/
        let branch = this.user ? this.user.branch : null;
        const { anchorEl, open } = this.state;

        return ( 
            <Fragment>
                <TextField  onClick={this.handleClick}
                    style={{ width: 180, cursor: 'pointer', marginLeft: '-20px' }}
                    value={this.selectedBranchName()}
                    InputProps={{
                        startAdornment: 
                        <InputAdornment style={{ whiteSpace: 'nowrap' }} 
                        position="start">当前校区</InputAdornment>,
                        endAdornment:
                        <InputAdornment style={{ whiteSpace: 'nowrap' }} 
                                 position="end"><SelectIcon/></InputAdornment>
                    }}
                >
                
                </TextField>
                    <Popover open={open} anchorEl={anchorEl} 
                        onClose={()=>this.setState({open: false})}>
                        <div style={{padding: 16, width: 260 }}>
                            {
                                branch && this.user ?
                                branch.isOrganization ?
                                <TreeView style={{marginTop: 4, marginBottom: 4}} key={branch.id} onClick={e => e.stopPropagation()} 
                                        nodeLabel= {<span　style={{cursor: 'pointer'}} 
                                            onClick={this.changeBranch.bind(this,branch)}> {branch.name} 
                                            {
                                                branch.id === this.user.selectedBranch &&
                                                <SelectedIcon style={{ width: 16, height: 16 }}/>
                                            }
                                            </span>} 
                                            defaultCollapsed={false}>
                                        {
                                            this.renderChildren(branch.children)
                                        }
                                </TreeView> 
                            : <div style={{marginTop: 4, marginBottom: 4}} key={branch.id} style={{cursor: 'pointer'}} onClick={this.changeBranch.bind(this,branch)}>{branch.name}</div> : null
                        }
                        </div>
                </Popover>
        </Fragment>
        )
    }
}

const enhance = compose(
    withDataProvider,
    connect(
       null,
       dispatch => {
            return {
                dispatch
            }
        }
    )
);

export default enhance(BelongBranches);

