import React from 'react';
import { CardActions, Button, ExportButton, usePermissions } from 'react-admin';
import { DownloadIcon, AddIcon, FilterIcon } from '../Icons';
import {
    withStyles,
} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import XLSX from 'xlsx';
import { isFunction } from 'lodash';
import { connect } from 'react-redux';

const exporter = (meta, resource, branch, user, data) => {
    
    var wb = XLSX.utils.book_new();
    console.log(meta)
    if (meta) {
        meta.sheets.forEach(sheet=> {
            let cols = Object.keys(sheet.columns);
            let sheetContent = [cols];
            data.forEach(d => {
                let row = [];
                cols.forEach(col => {
                    if (typeof sheet.columns[col] === 'string') {
                        row.push(d[sheet.columns[col]]);
                    } else if (isFunction(sheet.columns[col])) {
                        row.push(sheet.columns[col](d));
                    }
                });
                sheetContent.push(row);
            });
            let ws = XLSX.utils.aoa_to_sheet(sheetContent);
            
            XLSX.utils.book_append_sheet(wb, ws, sheet.name); 
        })
    } else {
        console.log(data)
        let sheetContent = [
            data.length > 0 ? 
            resource === 'students' && user.name != 'admin' ? Object.keys(data[0]).filter(k => k != 'phone' && k != 'secondary_phone') : Object.keys(data[0]) : []
        ]
        sheetContent.push(
            ...data.map(d => {
                if ( resource === 'students' && user.name != 'admin') {
                    delete d.phone;
                    delete d.secondary_phone;
                }
                return Object.values(d);
            })
        )
        let ws = XLSX.utils.aoa_to_sheet(sheetContent);
        XLSX.utils.book_append_sheet(wb, ws, resource);
    }
    
    XLSX.writeFile(wb, `${meta ? meta.name : resource}(${branch.name}).xlsx`);
}

const styles = theme => ({
    root: {
        zIndex: '1'
    },
    title: {
        flex: '1 1 auto',
        alignItems: 'flex-start'
    },
    actions: {
        //alignItems: 'flex-end'
    }
})
const ListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    //exporter,
    selectedIds,
    showFilter,
    total,
    exportMetadata = null,
    workingBranch,
    customActions,
    addtionalFlags,
    user,
    permissions
}) => {
    console.log(user)
    console.log(bulkActions, permissions)
    return ( 
        <CardActions style={{ zIndex: 0 }}>
                {customActions }
                {bulkActions && React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems,
                })}
                {filters && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    icon: <FilterIcon />,
                    context: 'button',
                })}
                { (addtionalFlags && addtionalFlags.isHideCreate)? null
                     :
                     <Button component={Link}
                            to={`${basePath}/create`}
                            label='新建'>
                           <AddIcon />
                      </Button>　
                }
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filter={filterValues}
                    maxResults={99999}
                    exporter={exporter.bind(this, exportMetadata, resource, workingBranch,user)}
                    icon={<DownloadIcon />}
                />
                
           </CardActions>
    );
}

export default compose(
    withStyles(styles),
    connect(state =>  ({ workingBranch: state.global.workingBranch }))
)(ListActions);