import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function SaveIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.5,24C0.673,24,0,23.327,0,22.5V3.914c0-0.401,0.156-0.777,0.439-1.061l2.414-2.414C3.137,0.156,3.513,0,3.914,0H22.5
		C23.327,0,24,0.673,24,1.5v21c0,0.827-0.673,1.5-1.5,1.5H1.5z M22.5,23c0.276,0,0.5-0.224,0.5-0.5v-21C23,1.224,22.776,1,22.5,1H20
		v5.5C20,7.327,19.327,8,18.5,8h-13C4.673,8,4,7.327,4,6.5V1H3.914C3.781,1,3.655,1.052,3.561,1.146L1.146,3.561
		C1.052,3.655,1,3.781,1,3.914V22.5C1,22.776,1.224,23,1.5,23H4v-2.5C4,19.673,4.673,19,5.5,19h13c0.827,0,1.5,0.673,1.5,1.5V23
		H22.5z M19,23v-2.5c0-0.276-0.224-0.5-0.5-0.5h-13C5.224,20,5,20.224,5,20.5V23H19z M5,6.5C5,6.776,5.224,7,5.5,7h13
		C18.776,7,19,6.776,19,6.5V1H5V6.5z"/>
      <path
        d="M12,17c-1.93,0-3.5-1.57-3.5-3.5S10.07,10,12,10s3.5,1.57,3.5,3.5S13.93,17,12,17z M12,11c-1.379,0-2.5,1.121-2.5,2.5
		c0,1.378,1.121,2.5,2.5,2.5c1.378,0,2.5-1.122,2.5-2.5C14.5,12.121,13.378,11,12,11z"/>
    </SvgIcon>
  );
}

export function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.5,24C0.673,24,0,23.327,0,22.5v-19C0,2.673,0.673,2,1.5,2h13C14.775,2,15,2.224,15,2.5S14.775,3,14.5,3h-13
		C1.224,3,1,3.224,1,3.5v19C1,22.776,1.224,23,1.5,23h17c0.276,0,0.5-0.224,0.5-0.5v-11c0-0.276,0.224-0.5,0.5-0.5
		s0.5,0.224,0.5,0.5v11c0,0.827-0.673,1.5-1.5,1.5H1.5z"/>
      <path
        d="M7.875,16.627c-0.134,0-0.259-0.052-0.354-0.146c-0.145-0.145-0.187-0.361-0.106-0.55l2.119-4.945
		c0.021-0.05,0.05-0.094,0.085-0.135c0.007-0.01,0.015-0.018,0.023-0.026l8.829-8.828c0.008-0.009,0.016-0.018,0.025-0.025
		c0.002-0.002,1.824-1.823,1.824-1.823C20.415,0.052,20.54,0,20.674,0s0.259,0.052,0.354,0.146l2.828,2.828
		c0.195,0.195,0.195,0.512,0,0.707L13.177,14.361c-0.045,0.045-0.098,0.081-0.157,0.106l-4.948,2.12
		C8.009,16.613,7.943,16.627,7.875,16.627z M8.826,15.175l3.122-1.337l-1.784-1.785L8.826,15.175z M12.824,13.3l8.135-8.136
		l-2.121-2.121l-8.135,8.135L12.824,13.3z M21.666,4.457l1.128-1.129l-2.121-2.121l-1.129,1.128L21.666,4.457z"/>
    </SvgIcon>
  );
}

export function NotificationIcon(props) {
	return (
	  <SvgIcon {...props}>
		<path d="M11.498,24c-0.134,0-0.259-0.052-0.354-0.147c-0.128-0.128-0.177-0.316-0.127-0.491l1.2-4.199
			c0.004-0.014,0.013-0.038,0.02-0.051c-0.001,0.001-0.001,0.002-0.001,0.002c0,0,0.004-0.01,0.009-0.021
			c0.028-0.06,0.06-0.108,0.1-0.147l7.179-7.179C20.018,11.272,20.677,11,21.377,11s1.359,0.273,1.854,0.768
			c1.022,1.022,1.022,2.685,0,3.707l-7.179,7.179c-0.04,0.04-0.087,0.072-0.145,0.099c-0.008,0.003-0.034,0.014-0.042,0.017
			c-0.013,0.006-0.014,0.007-0.028,0.011l-4.201,1.2C11.59,23.994,11.544,24,11.498,24z M12.226,22.772l2.517-0.719l-1.798-1.798
			L12.226,22.772z M15.698,21.593l5.572-5.572l-2.293-2.293L13.405,19.3L15.698,21.593z M21.977,15.314l0.546-0.546
			c0.632-0.632,0.632-1.661,0-2.293C22.218,12.169,21.811,12,21.377,12s-0.841,0.168-1.146,0.474l-0.546,0.546L21.977,15.314z"/>
		<path d="M4.498,19c-0.066,0-0.131-0.013-0.192-0.038c-0.187-0.077-0.308-0.259-0.308-0.462V15h-2.5c-0.827,0-1.5-0.673-1.5-1.5v-12
			c0-0.827,0.673-1.5,1.5-1.5h19c0.827,0,1.5,0.673,1.5,1.5v8c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-8
			c0-0.276-0.224-0.5-0.5-0.5h-19c-0.276,0-0.5,0.224-0.5,0.5v12c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5,0.224,0.5,0.5v2.793
			l3.146-3.146C8.239,14.052,8.365,14,8.498,14h5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H8.705l-3.854,3.854
			C4.757,18.948,4.632,19,4.498,19z"/>
	  </SvgIcon>
	);
  }
export function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.5,24C3.673,24,3,23.327,3,22.5V4H0.5C0.224,4,0,3.776,0,3.5S0.224,3,0.5,3H7V1.5C7,0.673,7.673,0,8.5,0h7
          C16.327,0,17,0.673,17,1.5V3h6.5C23.776,3,24,3.224,24,3.5S23.776,4,23.5,4H21v18.5c0,0.827-0.673,1.5-1.5,1.5H4.5z M4,22.5
          C4,22.776,4.224,23,4.5,23h15c0.276,0,0.5-0.224,0.5-0.5V4H4V22.5z M16,3V1.5C16,1.224,15.776,1,15.5,1h-7C8.224,1,8,1.224,8,1.5V3
          H16z"/>
      <path
        d="M7.5,19.5C7.224,19.5,7,19.276,7,19V7c0-0.276,0.224-0.5,0.5-0.5S8,6.724,8,7v12C8,19.276,7.776,19.5,7.5,19.5z"/>
      <path
        d="M12,19.5c-0.276,0-0.5-0.224-0.5-0.5V7c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v12
          C12.5,19.276,12.276,19.5,12,19.5z"/>
      <path
        d="M16.5,19.5c-0.276,0-0.5-0.224-0.5-0.5V7c0-0.276,0.224-0.5,0.5-0.5S17,6.724,17,7v12C17,19.276,16.776,19.5,16.5,19.5z"/>
    </SvgIcon>
  );
}

export function DashboardIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.5,24C0.673,24,0,23.327,0,22.5v-5C0,16.673,0.673,16,1.5,16h8c0.827,0,1.5,0.673,1.5,1.5v5c0,0.827-0.673,1.5-1.5,1.5
		H1.5z M1.5,17C1.224,17,1,17.224,1,17.5v5C1,22.776,1.224,23,1.5,23h8c0.276,0,0.5-0.224,0.5-0.5v-5c0-0.276-0.224-0.5-0.5-0.5H1.5
		z"/>
      <path
        d="M14.5,24c-0.827,0-1.5-0.673-1.5-1.5v-11c0-0.827,0.673-1.5,1.5-1.5h8c0.827,0,1.5,0.673,1.5,1.5v11
		c0,0.827-0.673,1.5-1.5,1.5H14.5z M14.5,11c-0.276,0-0.5,0.224-0.5,0.5v11c0,0.276,0.224,0.5,0.5,0.5h8c0.276,0,0.5-0.224,0.5-0.5
		v-11c0-0.276-0.224-0.5-0.5-0.5H14.5z"/>
      <path
        d="M14.5,8C13.673,8,13,7.327,13,6.5v-5C13,0.673,13.673,0,14.5,0h8C23.327,0,24,0.673,24,1.5v5C24,7.327,23.327,8,22.5,8
		H14.5z M14.5,1C14.224,1,14,1.224,14,1.5v5C14,6.776,14.224,7,14.5,7h8C22.776,7,23,6.776,23,6.5v-5C23,1.224,22.776,1,22.5,1H14.5
		z"/>
      <path
        d="M1.5,14C0.673,14,0,13.327,0,12.5v-11C0,0.673,0.673,0,1.5,0h8C10.327,0,11,0.673,11,1.5v11c0,0.827-0.673,1.5-1.5,1.5H1.5
		z M1.5,1C1.224,1,1,1.224,1,1.5v11C1,12.776,1.224,13,1.5,13h8c0.276,0,0.5-0.224,0.5-0.5v-11C10,1.224,9.776,1,9.5,1H1.5z"/>
    </SvgIcon>
  );
}

export function SchoolAdminIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1,24c-0.551,0-1-0.448-1-1v-9c0-0.552,0.449-1,1-1h6v-2c0-2.396,1.67-4.422,4-4.894V0.5C11,0.224,11.224,0,11.5,0h4
		c0.552,0,1,0.449,1,1v2c0,0.551-0.448,1-1,1H12v2c2.757,0,5,2.243,5,5v2h6c0.552,0,1,0.448,1,1v9c0,0.552-0.448,1-1,1H1z M23,23v-9
		h-6v9H23z M16,23V11c0-2.206-1.794-4-4-4s-4,1.794-4,4v12h2v-4c0-1.103,0.897-2,2-2s2,0.897,2,2v4H16z M13,23v-4
		c0-0.552-0.448-1-1-1c-0.551,0-1,0.448-1,1v4H13z M1,23h6v-9l-6,0L1,23z M15.5,3V1H12v2H15.5z"/>
      <path
        d="M19,18c-0.552,0-1-0.448-1-1v-1c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v1c0,0.552-0.448,1-1,1H19z M19,17h2v-1h-2
		L19,17z"/>
      <path
        d="M18.5,22c-0.276,0-0.5-0.224-0.5-0.5V20c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v1.5c0,0.276-0.224,0.5-0.5,0.5H18.5z
		 M21,21v-1h-2l0,1H21z"/>
      <path
        d="M3,18c-0.551,0-1-0.448-1-1v-1c0-0.552,0.449-1,1-1h2c0.551,0,1,0.448,1,1v1c0,0.552-0.449,1-1,1H3z M3,17h2v-1H3L3,17z"/>
      <path
        d="M3,22c-0.551,0-1-0.448-1-1v-1c0-0.552,0.449-1,1-1h2c0.551,0,1,0.448,1,1v1c0,0.552-0.449,1-1,1H3z M3,21h2v-1H3L3,21z"/>
      <path
        d="M12,13c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S13.103,13,12,13z M12,10c-0.551,0-1,0.449-1,1s0.449,1,1,1
		c0.552,0,1-0.449,1-1S12.552,10,12,10z"/>
    </SvgIcon>
  );
}

export function StudentIcon(props) {
  return (
    <SvgIcon {...props}>

      <path
        d="M12,13.997c-0.648,0-1.287-0.212-1.8-0.597c-0.107-0.08-0.176-0.197-0.195-0.329C9.986,12.939,10.02,12.807,10.1,12.7
		c0.094-0.125,0.243-0.2,0.4-0.2c0.109,0,0.212,0.034,0.3,0.1c0.342,0.256,0.768,0.397,1.2,0.397c0.432,0,0.859-0.141,1.2-0.397
		c0.087-0.065,0.191-0.1,0.3-0.1c0.157,0,0.306,0.075,0.4,0.2c0.08,0.106,0.114,0.238,0.095,0.37
		c-0.019,0.132-0.088,0.25-0.195,0.33C13.287,13.785,12.648,13.997,12,13.997z"/>
      <path
        d="M23,24c-0.276,0-0.5-0.224-0.5-0.5v-1.106c0.003-0.461-0.212-0.903-0.575-1.183c-0.834-0.636-2.305-1.511-4.604-2.095
		l-4.631,4.415c-0.188,0.178-0.433,0.277-0.69,0.277c-0.258,0-0.503-0.098-0.69-0.277l-4.63-4.414
		c-2.295,0.584-3.77,1.459-4.607,2.096c-0.361,0.278-0.576,0.719-0.573,1.178V23.5C1.5,23.776,1.276,24,1,24s-0.5-0.224-0.5-0.5
		v-1.106c-0.005-0.766,0.355-1.505,0.965-1.975C2.899,19.328,6.174,17.5,12,17.5c5.839,0,9.105,1.828,10.533,2.917
		c0.611,0.471,0.972,1.21,0.967,1.98V23.5C23.5,23.776,23.276,24,23,24z M12,22.807l4.142-3.949C14.853,18.621,13.463,18.5,12,18.5
		c-1.462,0-2.852,0.121-4.141,0.359L12,22.807z"/>
      <circle cx="14" cy="10.5" r="0.75"/>
      <circle cx="10" cy="10.5" r="0.75"/>
      <path
        d="M12,17c-3.584,0-6.5-2.916-6.5-6.5V4.534l-3-0.682V8.37c0.722,1.266,1.067,2.701,1,4.154C3.487,12.791,3.268,13,3,13H1
		c-0.268,0-0.487-0.209-0.5-0.477c-0.068-1.453,0.278-2.887,1-4.154V3.395C1.396,3.228,1.362,3.03,1.405,2.837
		c0.063-0.283,0.287-0.506,0.569-0.569l9.692-2.206c0.109-0.025,0.22-0.037,0.333-0.037c0.112,0,0.224,0.012,0.332,0.037
		l9.706,2.206c0.194,0.043,0.36,0.16,0.468,0.329c0.107,0.169,0.142,0.37,0.099,0.565c-0.062,0.282-0.286,0.506-0.568,0.569
		L18.5,4.536V10.5C18.5,14.084,15.584,17,12,17z M6.5,10.5c0,3.033,2.467,5.5,5.5,5.5s5.5-2.467,5.5-5.5V8.273
		C16.612,8.592,14.907,9,12,9C9.093,9,7.388,8.592,6.5,8.273V10.5z M2.506,12C2.485,11.181,2.313,10.368,2,9.613
		C1.687,10.369,1.515,11.181,1.494,12H2.506z M6.5,7.195C7.068,7.448,8.691,8,12,8c3.308,0,4.931-0.553,5.5-0.806V4.764
		l-5.162,1.174c-0.109,0.025-0.221,0.037-0.333,0.037c-0.112,0-0.223-0.012-0.332-0.037L6.5,4.762V7.195z M11.894,4.963
		c0.036,0.008,0.073,0.012,0.111,0.012c0.038,0,0.075-0.004,0.112-0.012L20.744,3l-8.633-1.963C12.075,1.03,12.038,1.026,12,1.026
		c-0.038,0-0.076,0.004-0.112,0.012L3.265,3L11.894,4.963z"/>
    </SvgIcon>
  );
}

export function CourseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.5,24C4.121,24,3,22.879,3,21.5V2.598C2.993,2.433,3.004,2.255,3.034,2.08C3.277,0.872,4.34,0,5.562,0L18.5,0
		C19.327,0,20,0.673,20,1.5v2.586c0.59,0.209,1,0.774,1,1.414v17c0,0.827-0.673,1.5-1.5,1.5H5.5z M4,21.5C4,22.328,4.673,23,5.5,23
		h14c0.276,0,0.5-0.224,0.5-0.5v-17C20,5.225,19.775,5,19.5,5h-14C5.359,5,5.217,4.988,5.079,4.965C4.684,4.898,4.315,4.738,4,4.502
		V21.5z M19,4V1.5C19,1.225,18.775,1,18.5,1H5.607L5.551,1c-0.753,0-1.384,0.52-1.534,1.264C4.007,2.323,4,2.391,3.998,2.459
		C3.999,2.472,4,2.486,4,2.5v0.096c0.015,0.267,0.11,0.538,0.275,0.771c0.232,0.327,0.577,0.544,0.972,0.611
		C5.33,3.993,5.415,4,5.5,4H17V3.5C17,3.225,16.775,3,16.5,3h-11C5.224,3,5,2.776,5,2.5S5.224,2,5.5,2h11C17.327,2,18,2.673,18,3.5
		V4H19z"/>
      <path
        d="M7.5,20C7.224,20,7,19.776,7,19.5v-5C7,14.225,7.224,14,7.5,14h1c0.827,0,1.5,0.673,1.5,1.5V16
		c0,0.375-0.138,0.727-0.387,1C9.861,17.274,10,17.625,10,18v0.5c0,0.827-0.673,1.5-1.5,1.5H7.5z M8.5,19C8.775,19,9,18.776,9,18.5
		V18c0-0.276-0.224-0.5-0.5-0.5H8V19H8.5z M8.5,16.5C8.775,16.5,9,16.276,9,16v-0.5C9,15.225,8.775,15,8.5,15H8v1.5H8.5z"/>
      <path
        d="M16.5,20c-1.378,0-2.5-1.122-2.5-2.5v-1c0-1.378,1.122-2.5,2.5-2.5c0.276,0,0.5,0.224,0.5,0.5S16.775,15,16.5,15
		c-0.827,0-1.5,0.673-1.5,1.5v1c0,0.827,0.673,1.5,1.5,1.5c0.276,0,0.5,0.224,0.5,0.5S16.775,20,16.5,20z"/>
      <path
        d="M13,13c-0.276,0-0.5-0.224-0.5-0.5V11h-1v1.5c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-4C10.5,7.673,11.173,7,12,7
		c0.827,0,1.5,0.673,1.5,1.5v4C13.5,12.776,13.275,13,13,13z M12.5,10V8.5C12.5,8.225,12.275,8,12,8s-0.5,0.224-0.5,0.5V10H12.5z"/>
    </SvgIcon>
  );
}

export function ClassIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.5,11.984c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H10.5z"/>
      <path
        d="M10.5,9.984c-0.276,0-0.5-0.224-0.5-0.5V8.761c-0.905-0.62-1.476-1.668-1.5-2.766c0.054-1.92,1.585-3.457,3.486-3.511
		c1.922,0.054,3.46,1.585,3.514,3.485c0,0.004,0,0.021,0,0.026c-0.025,1.116-0.583,2.14-1.5,2.766v0.723c0,0.276-0.224,0.5-0.5,0.5
		H10.5z M13,8.984v-0.5c0-0.18,0.098-0.347,0.255-0.436c0.747-0.42,1.224-1.213,1.245-2.07c-0.042-1.352-1.143-2.452-2.507-2.495
		C10.633,3.527,9.539,4.63,9.5,5.998c0.019,0.839,0.496,1.629,1.245,2.05C10.902,8.137,11,8.304,11,8.484v0.5H13z"/>
      <path
        d="M4,20.484c-1.241,0-2.25-1.009-2.25-2.25s1.009-2.25,2.25-2.25s2.25,1.009,2.25,2.25S5.241,20.484,4,20.484z M4,16.984
		c-0.689,0-1.25,0.561-1.25,1.25s0.561,1.25,1.25,1.25s1.25-0.561,1.25-1.25S4.689,16.984,4,16.984z"/>
      <path
        d="M0.5,23.984c-0.156,0-0.3-0.071-0.395-0.193c-0.094-0.121-0.128-0.282-0.089-0.43c0.372-1.459,1.509-2.596,2.968-2.968
		c0.336-0.086,0.68-0.129,1.023-0.129c0.732,0,1.455,0.2,2.092,0.578c0.945,0.561,1.614,1.456,1.885,2.52
		c0.038,0.148,0.004,0.309-0.09,0.43C7.8,23.914,7.656,23.984,7.5,23.984H0.5z M6.785,22.984c-0.264-0.533-0.672-0.973-1.196-1.283
		c-0.482-0.286-1.029-0.437-1.583-0.437c-0.259,0-0.52,0.033-0.775,0.097c-0.882,0.225-1.613,0.82-2.014,1.623H6.785z"/>
      <path
        d="M12,20.484c-1.241,0-2.25-1.009-2.25-2.25s1.009-2.25,2.25-2.25c1.241,0,2.25,1.009,2.25,2.25S13.241,20.484,12,20.484z
		 M12,16.984c-0.689,0-1.25,0.561-1.25,1.25s0.561,1.25,1.25,1.25s1.25-0.561,1.25-1.25S12.689,16.984,12,16.984z"/>
      <path
        d="M8.5,23.984c-0.156,0-0.3-0.071-0.395-0.193c-0.094-0.121-0.128-0.282-0.089-0.43c0.372-1.459,1.509-2.596,2.968-2.968
		c0.336-0.086,0.68-0.129,1.023-0.129c0.732,0,1.456,0.2,2.092,0.578c0.944,0.561,1.614,1.456,1.885,2.52
		c0.038,0.149,0.004,0.31-0.09,0.43c-0.094,0.121-0.241,0.193-0.395,0.193H8.5z M14.785,22.984
		c-0.264-0.533-0.673-0.973-1.197-1.283c-0.482-0.286-1.029-0.438-1.583-0.438c-0.259,0-0.52,0.033-0.775,0.098
		c-0.882,0.225-1.613,0.82-2.014,1.623H14.785z"/>
      <path
        d="M20,20.484c-1.241,0-2.25-1.009-2.25-2.25s1.009-2.25,2.25-2.25s2.25,1.009,2.25,2.25S21.241,20.484,20,20.484z M20,16.984
		c-0.689,0-1.25,0.561-1.25,1.25s0.561,1.25,1.25,1.25s1.25-0.561,1.25-1.25S20.689,16.984,20,16.984z"/>
      <path
        d="M16.5,23.984c-0.153,0-0.301-0.072-0.395-0.193c-0.094-0.12-0.127-0.281-0.089-0.43c0.372-1.459,1.509-2.596,2.968-2.969
		c0.333-0.085,0.675-0.128,1.015-0.128c1.882,0,3.52,1.273,3.985,3.096c0.038,0.149,0.004,0.31-0.09,0.43
		c-0.094,0.121-0.241,0.193-0.395,0.193H16.5z M22.785,22.984c-0.264-0.533-0.673-0.973-1.196-1.283
		c-0.482-0.286-1.029-0.438-1.583-0.438c-0.26,0-0.52,0.033-0.775,0.098c-0.882,0.225-1.614,0.821-2.014,1.623H22.785z"/>
      <path
        d="M12,1.984c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v1
		C12.5,1.76,12.276,1.984,12,1.984z"/>
      <path
        d="M15.182,3.302c-0.134,0-0.259-0.052-0.354-0.146s-0.146-0.22-0.146-0.354c0-0.134,0.052-0.259,0.146-0.354l0.707-0.707
		c0.094-0.094,0.22-0.146,0.354-0.146c0.134,0,0.259,0.052,0.354,0.146s0.146,0.22,0.146,0.354c0,0.134-0.052,0.259-0.146,0.354
		l-0.707,0.707C15.441,3.25,15.315,3.302,15.182,3.302z"/>
      <path
        d="M16.5,5.984c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H16.5z"/>
      <path
        d="M8.818,3.302c-0.134,0-0.259-0.052-0.354-0.146L7.757,2.449c-0.094-0.094-0.146-0.22-0.146-0.354
		c0-0.134,0.052-0.259,0.146-0.354s0.22-0.146,0.354-0.146c0.134,0,0.259,0.052,0.354,0.146l0.707,0.707
		c0.094,0.094,0.146,0.22,0.146,0.354c0,0.134-0.052,0.259-0.146,0.354S8.952,3.302,8.818,3.302z"/>
      <path
        d="M6.5,5.984c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6.5z"/>
      <path
        d="M11,13.984c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H11z"/>
    </SvgIcon>
  );
}

export function DownloadIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.5,24C1.121,24,0,22.878,0,21.5v-4C0,16.122,1.121,15,2.5,15h19c1.378,0,2.5,1.122,2.5,2.5v4c0,1.378-1.122,2.5-2.5,2.5
		H2.5z M2.5,16C1.673,16,1,16.673,1,17.5v4C1,22.327,1.673,23,2.5,23h19c0.827,0,1.5-0.673,1.5-1.5v-4c0-0.827-0.673-1.5-1.5-1.5
		H2.5z"/>
      <path
        d="M3.5,21C3.224,21,3,20.776,3,20.5S3.224,20,3.5,20h17c0.276,0,0.5,0.224,0.5,0.5S20.776,21,20.5,21H3.5z"/>
      <circle cx="16.812" cy="18.5" r="0.75"/>
      <path
        d="M19.771,19.25c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75c0.204,0.001,0.392,0.079,0.533,0.222
		c0.141,0.142,0.218,0.33,0.217,0.53C20.521,18.914,20.185,19.25,19.771,19.25z"/>
      <path
        d="M11.5,13c-0.066,0-0.13-0.013-0.192-0.039c-0.061-0.025-0.116-0.062-0.163-0.109L5.646,7.354C5.552,7.259,5.5,7.134,5.5,7
		s0.052-0.259,0.146-0.354S5.866,6.5,6,6.5s0.259,0.052,0.354,0.146L11,11.293V0.5C11,0.224,11.224,0,11.5,0S12,0.224,12,0.5v10.793
		l4.646-4.646C16.741,6.552,16.866,6.5,17,6.5s0.259,0.052,0.354,0.146S17.5,6.866,17.5,7s-0.052,0.259-0.146,0.354l-5.5,5.5
		c-0.046,0.046-0.101,0.083-0.163,0.109C11.63,12.987,11.566,13,11.5,13z"/>

    </SvgIcon>
  );
}

export function AddIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,23.5c-0.276,0-0.5-0.224-0.5-0.5V12.5H1c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h10.5V1
		c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v10.5H23c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5H12.5V23
		C12.5,23.276,12.276,23.5,12,23.5z"/>
    </SvgIcon>
  );
}

export function FilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.988,24c-0.827,0-1.5-0.673-1.5-1.5v-9.743L1.779,2.399C1.586,2.142,1.48,1.823,1.48,1.5c0-0.827,0.673-1.5,1.5-1.5
  h18.019c0.323,0,0.642,0.106,0.9,0.3c0.661,0.496,0.796,1.438,0.301,2.099l-7.712,10.356V21c0,0.469-0.224,0.918-0.6,1.2l-2,1.5
  C11.63,23.894,11.31,24,10.988,24z M2.98,1c-0.276,0-0.5,0.224-0.5,0.5c0,0.108,0.036,0.214,0.1,0.3l7.809,10.493
  c0.064,0.085,0.099,0.191,0.099,0.298V22.5c0,0.276,0.224,0.5,0.5,0.5c0.108,0,0.214-0.035,0.3-0.1l2-1.5
  c0.125-0.094,0.2-0.244,0.2-0.4v-8.41c0-0.107,0.035-0.213,0.099-0.299L21.399,1.8c0.165-0.22,0.12-0.535-0.1-0.7
  c-0.086-0.064-0.192-0.1-0.3-0.1H2.98z"/>
    </SvgIcon>
  );
}

export function StructureIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13,20.5c-0.552,0-1-0.448-1-1v-7H9.5C9.224,12.5,9,12.276,9,12c0-0.276,0.224-0.5,0.5-0.5H12v-7c0-0.551,0.448-1,1-1h2.5
		C15.776,3.5,16,3.724,16,4s-0.224,0.5-0.5,0.5H13v15h2.5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H13z"/>
      <path
        d="M20,5c-1.241,0-2.25-1.009-2.25-2.25S18.759,0.5,20,0.5s2.25,1.009,2.25,2.25S21.241,5,20,5z M20,1.5
		c-0.689,0-1.25,0.561-1.25,1.25S19.311,4,20,4s1.25-0.561,1.25-1.25S20.689,1.5,20,1.5z"/>
      <path
        d="M16.5,8.5c-0.153,0-0.301-0.072-0.395-0.193c-0.095-0.123-0.128-0.28-0.09-0.43c0.372-1.459,1.509-2.597,2.968-2.969
		c0.333-0.085,0.675-0.128,1.017-0.128c1.881,0,3.519,1.273,3.984,3.097c0.038,0.151,0.006,0.308-0.089,0.43
		C23.801,8.428,23.653,8.5,23.5,8.5H16.5z M22.784,7.5c-0.521-1.042-1.599-1.72-2.785-1.72c-0.258,0-0.516,0.032-0.768,0.097
		c-0.882,0.225-1.613,0.82-2.014,1.623H22.784z"/>
      <path
        d="M20,20c-1.241,0-2.25-1.009-2.25-2.25S18.759,15.5,20,15.5s2.25,1.009,2.25,2.25S21.241,20,20,20z M20,16.5
		c-0.689,0-1.25,0.561-1.25,1.25S19.311,19,20,19s1.25-0.561,1.25-1.25S20.689,16.5,20,16.5z"/>
      <path
        d="M16.5,23.5c-0.153,0-0.301-0.072-0.395-0.193c-0.094-0.12-0.127-0.281-0.089-0.43c0.372-1.459,1.509-2.597,2.968-2.968
		c0.333-0.085,0.675-0.128,1.016-0.128c1.882,0,3.52,1.273,3.985,3.096c0.038,0.149,0.004,0.31-0.09,0.43
		C23.801,23.428,23.653,23.5,23.5,23.5H16.5z M22.784,22.5c-0.521-1.042-1.598-1.72-2.784-1.72c-0.258,0-0.517,0.032-0.769,0.097
		c-0.882,0.225-1.613,0.82-2.014,1.623H22.784z"/>
      <path
        d="M0.5,17.5C0.224,17.5,0,17.276,0,17c0-2.757,2.243-5,5-5s5,2.243,5,5c0,0.276-0.224,0.5-0.5,0.5H0.5z M8.969,16.5
		C8.722,14.517,7.027,13,5,13s-3.721,1.517-3.969,3.5H8.969z"/>
      <path
        d="M5,12c-1.792,0-3.25-1.458-3.25-3.25S3.208,5.5,5,5.5s3.25,1.458,3.25,3.25S6.792,12,5,12z M5,6.5
		c-1.241,0-2.25,1.009-2.25,2.25S3.76,11,5,11s2.25-1.009,2.25-2.25S6.241,6.5,5,6.5z"/>

    </SvgIcon>
  );
}
export function EmployeeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,12.5c-2.619,0-4.75-2.131-4.75-4.75S9.381,3,12,3s4.75,2.131,4.75,4.75S14.619,12.5,12,12.5z M12,4
        C9.932,4,8.25,5.682,8.25,7.75S9.932,11.5,12,11.5c2.068,0,3.75-1.682,3.75-3.75S14.068,4,12,4z"/>
      <path d="M5,21c-0.276,0-0.5-0.224-0.5-0.5c0-4.135,3.365-7.5,7.5-7.5s7.5,3.365,7.5,7.5c0,0.276-0.224,0.5-0.5,0.5H5z M18.481,20
        c-0.255-3.385-3.054-6-6.481-6s-6.226,2.615-6.481,6H18.481z"/>
    </SvgIcon>
  );
}
export function MaterialIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M23.459,18.488a39.143,39.143,0,0,0,0-12.977l.318-1.741A1.5,1.5,0,0,0,22.3,2H11.681A1.5,1.5,0,0,0,10.2,3.746L10.5,5.511c-.164,1.322-.288,2.453-.371,3.489H2.5a2.493,2.493,0,0,0-1.485,4.5,2.478,2.478,0,0,0,0,4A2.493,2.493,0,0,0,2.5,22H22.3a1.5,1.5,0,0,0,1.476-1.77ZM11.443,18a41,41,0,0,1,0-12H22.516a37.478,37.478,0,0,1,0,12ZM11.3,3.177A.5.5,0,0,1,11.681,3H22.3a.5.5,0,0,1,.492.59L22.536,5H11.423l-.236-1.418A.5.5,0,0,1,11.3,3.177ZM1,11.5A1.5,1.5,0,0,1,2.5,10h7.557C10,11.029,9.986,12,10.018,13H2.5A1.5,1.5,0,0,1,1,11.5Zm0,4A1.5,1.5,0,0,1,2.5,14h7.562c.053.912.14,1.891.262,3H2.5A1.5,1.5,0,0,1,1,15.5Zm0,4A1.5,1.5,0,0,1,2.5,18h7.938c.019.162.037.32.057.487L10.2,20.254a1.487,1.487,0,0,0,.073.746H2.5A1.5,1.5,0,0,1,1,19.5Zm21.686,1.319A.5.5,0,0,1,22.3,21H11.681a.5.5,0,0,1-.494-.582L11.423,19H22.536l.257,1.41A.494.494,0,0,1,22.686,20.819Z"/>
    </SvgIcon>
  );
}
export function IncidentalIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M8.504,13c-0.276,0-0.5-0.224-0.5-0.5V12H7c-0.276,0-0.5-0.224-0.5-0.5S6.724,11,7,11h2.035
        c0.345,0,0.651-0.207,0.78-0.528c0.173-0.431-0.036-0.922-0.467-1.095L7.285,8.551C6.584,8.269,6.13,7.599,6.13,6.843
        c0-0.492,0.191-0.955,0.539-1.303C7.016,5.192,7.479,5,7.971,5h0.033V4.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V5H10
        c0.276,0,0.5,0.224,0.5,0.5S10.276,6,10,6H7.971C7.746,6,7.535,6.088,7.376,6.247C7.217,6.406,7.13,6.618,7.13,6.843
        c0,0.345,0.207,0.652,0.528,0.781l2.063,0.826c0.943,0.379,1.401,1.454,1.022,2.397C10.461,11.546,9.791,11.999,9.035,12H9.004v0.5
        C9.004,12.776,8.78,13,8.504,13z"/>
      <path d="M4.5,24C3.673,24,3,23.327,3,22.5v-21C3,0.673,3.673,0,4.5,0h9.59c0.208,0,0.411,0.044,0.608,0.131
        c0.177,0.079,0.33,0.182,0.456,0.309l5.414,5.414c0.283,0.283,0.439,0.66,0.439,1.06L21,22.5c0,0.827-0.673,1.5-1.5,1.5H4.5z
        M4.5,1C4.224,1,4,1.224,4,1.5v21C4,22.776,4.224,23,4.5,23h15c0.276,0,0.5-0.224,0.5-0.5L20.007,7H15.5C14.673,7,14,6.327,14,5.5
        V1H4.5z M15,5.5C15,5.776,15.224,6,15.5,6h3.8L15,1.7V5.5z"/>
      <path d="M6.587,17c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10.917c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6.587z"/>
      <path d="M12.52,14c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4.984c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H12.52z"/>
      <path d="M14.504,11c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H14.504z"/>
      <path d="M6.587,20c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10.917c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6.587z"/>
    </SvgIcon>
  );
}
export function InventoryIcon(props) {
  return (
    <SvgIcon {...props}>
	<path d="M7.5,14.5C7.224,14.5,7,14.276,7,14s0.224-0.5,0.5-0.5h9c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7.5z"/>
	<path d="M7.5,16.5C7.224,16.5,7,16.276,7,16s0.224-0.5,0.5-0.5h9c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7.5z"/>
	<path d="M7.5,18.5C7.224,18.5,7,18.276,7,18s0.224-0.5,0.5-0.5h9c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7.5z"/>
	<path d="M2,21.5c-0.827,0-1.5-0.673-1.5-1.5V9c0-0.065,0.131-6.5,11.5-6.5c4.708,0,8.07,1.064,9.994,3.162
		C23.483,7.287,23.5,8.931,23.5,9v11c0,0.827-0.673,1.5-1.5,1.5H2z M22,20.5c0.276,0,0.5-0.224,0.5-0.5V9.5h-21V20
		c0,0.276,0.224,0.5,0.5,0.5h2.5V12c0-0.276,0.224-0.5,0.5-0.5h14c0.276,0,0.5,0.224,0.5,0.5v8.5H22z M18.5,20.5v-8h-13v8H18.5z
		 M22.436,8.5c-0.303-1.475-1.924-5-10.436-5c-8.511,0-10.133,3.525-10.436,5H22.436z"/>
	<path d="M10.5,6.5C10.224,6.5,10,6.276,10,6s0.224-0.5,0.5-0.5h3C13.776,5.5,14,5.724,14,6s-0.224,0.5-0.5,0.5H10.5z"/>
    </SvgIcon>
  );
}
export function InternalSettingIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4,23.401c-0.551,0-1-0.448-1-1v-9.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v9.5h16v-9c0-0.276,0.224-0.5,0.5-0.5
  s0.5,0.224,0.5,0.5v9c0,0.552-0.448,1-1,1H4z"/>
      <path
        d="M23.5,12.901c-0.134,0-0.259-0.052-0.354-0.146L12.004,1.608L0.854,12.754c-0.094,0.094-0.22,0.146-0.353,0.146
  s-0.259-0.052-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l11.146-11.15c0.188-0.189,0.44-0.293,0.707-0.293
  c0.264,0,0.513,0.102,0.701,0.286l11.153,11.157c0.195,0.195,0.195,0.512,0,0.707C23.759,12.849,23.634,12.901,23.5,12.901z"/>
      <path
        d="M12,16.021c-0.965,0-1.75-0.785-1.75-1.75s0.785-1.75,1.75-1.75c0.965,0,1.75,0.785,1.75,1.75S12.965,16.021,12,16.021z
   M12,13.521c-0.414,0-0.75,0.336-0.75,0.75s0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75S12.414,13.521,12,13.521z"/>
      <path
        d="M12.005,20.398c-0.157,0-0.313-0.023-0.465-0.069c-0.519-0.156-0.922-0.559-1.078-1.078l-0.37-1.204
  c-0.042-0.139-0.172-0.236-0.317-0.236c-0.024,0-0.049,0.003-0.073,0.008l-1.225,0.284c-0.122,0.028-0.246,0.042-0.369,0.042
  c0,0,0,0,0,0c-0.299,0-0.593-0.084-0.849-0.243c-0.366-0.227-0.623-0.584-0.721-1.004c-0.124-0.529,0.025-1.081,0.398-1.477
  L7.791,14.5c0.119-0.128,0.119-0.328,0-0.455l-0.858-0.927c-0.293-0.315-0.447-0.727-0.431-1.158
  c0.016-0.432,0.198-0.831,0.514-1.125c0.3-0.279,0.691-0.433,1.1-0.433c0.119,0,0.239,0.013,0.356,0.04l1.23,0.286
  c0.023,0.005,0.047,0.008,0.071,0.008c0.144,0,0.274-0.096,0.316-0.234l0.368-1.211c0.121-0.409,0.396-0.748,0.773-0.954
  c0.236-0.128,0.501-0.196,0.767-0.196c0.154,0,0.308,0.022,0.458,0.067c0.524,0.155,0.93,0.561,1.086,1.086l0.367,1.207
  c0.042,0.138,0.172,0.234,0.316,0.234c0.024,0,0.048-0.003,0.072-0.008l1.225-0.285c0.119-0.027,0.241-0.041,0.361-0.041
  c0.747,0,1.41,0.528,1.576,1.256c0.12,0.523-0.027,1.07-0.393,1.462l-0.857,0.925c-0.119,0.128-0.119,0.327,0,0.455l0.858,0.926
  c0.293,0.314,0.447,0.725,0.432,1.156c-0.015,0.431-0.197,0.831-0.512,1.125c-0.301,0.28-0.692,0.434-1.102,0.434
  c-0.12,0-0.24-0.014-0.358-0.04l-1.229-0.284c-0.023-0.005-0.047-0.008-0.071-0.008c-0.144,0-0.274,0.096-0.315,0.233l-0.368,1.211
  c-0.123,0.41-0.4,0.749-0.779,0.952C12.531,20.331,12.268,20.398,12.005,20.398z M9.775,16.812c0.582,0,1.106,0.388,1.274,0.944
  l0.37,1.204c0.06,0.199,0.212,0.352,0.409,0.411c0.058,0.018,0.117,0.026,0.177,0.026c0.099,0,0.198-0.025,0.286-0.073
  c0.144-0.077,0.248-0.205,0.295-0.361l0.369-1.212c0.168-0.555,0.692-0.943,1.273-0.943c0.099,0,0.198,0.011,0.294,0.033
  l1.229,0.284c0.043,0.01,0.089,0.015,0.134,0.015c0.156,0,0.305-0.059,0.42-0.166c0.12-0.112,0.189-0.264,0.195-0.428
  c0.006-0.164-0.053-0.32-0.165-0.44l-0.859-0.927c-0.473-0.509-0.472-1.306,0-1.815l0.857-0.926
  c0.14-0.151,0.197-0.359,0.151-0.559c-0.037-0.16-0.134-0.297-0.273-0.384c-0.099-0.062-0.211-0.095-0.326-0.095
  c-0.046,0-0.093,0.005-0.139,0.016l-1.225,0.285c-0.098,0.022-0.197,0.033-0.295,0.033c-0.582,0-1.106-0.388-1.275-0.944
  c0,0-0.37-1.218-0.371-1.222c-0.071-0.204-0.219-0.346-0.409-0.402c-0.057-0.017-0.116-0.026-0.174-0.026
  c-0.1,0-0.2,0.026-0.289,0.074c-0.143,0.078-0.247,0.206-0.293,0.362l-0.369,1.214c-0.168,0.555-0.692,0.943-1.274,0.943
  c-0.098,0-0.197-0.011-0.293-0.033l-1.229-0.286c-0.042-0.009-0.087-0.014-0.132-0.014c-0.156,0-0.305,0.059-0.419,0.165
  c-0.121,0.112-0.19,0.265-0.196,0.429c-0.006,0.165,0.052,0.322,0.165,0.442l0.859,0.927c0.472,0.509,0.472,1.306,0,1.815
  l-0.858,0.926c-0.145,0.153-0.202,0.364-0.155,0.566c0.037,0.16,0.135,0.295,0.275,0.382c0.098,0.06,0.209,0.092,0.323,0.092
  c0.047,0,0.095-0.005,0.142-0.016l1.228-0.284C9.578,16.823,9.677,16.812,9.775,16.812z"/>
    </SvgIcon>
  );
}

export function BackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.501,17.999c-0.276,0-0.5-0.224-0.5-0.5v-6.5H1.708l4.146,4.146c0.195,0.195,0.195,0.512,0,0.707
		c-0.094,0.094-0.22,0.146-0.354,0.146s-0.259-0.052-0.354-0.146l-5-5c-0.046-0.046-0.083-0.101-0.108-0.162
		c-0.05-0.122-0.05-0.261,0-0.383c0.026-0.062,0.062-0.116,0.108-0.163l5-5c0.094-0.094,0.22-0.146,0.354-0.146
		S5.76,5.051,5.854,5.146c0.195,0.195,0.195,0.512,0,0.707L1.708,9.999h21.793c0.276,0,0.5,0.224,0.5,0.5v7
		C24.001,17.775,23.777,17.999,23.501,17.999z"/>
    </SvgIcon>
  );
}

export function ListIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.5,6.998c-1.379,0-2.5-1.121-2.5-2.5s1.121-2.5,2.5-2.5S5,3.12,5,4.498S3.879,6.998,2.5,6.998z M2.5,2.998
		c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5S4,5.325,4,4.498S3.327,2.998,2.5,2.998z"/>
      <path
        d="M8.5,4.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h15c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H8.5z"/>
      <path
        d="M2.5,14.998c-1.379,0-2.5-1.122-2.5-2.5c0-1.379,1.121-2.5,2.5-2.5S5,11.12,5,12.498C5,13.876,3.879,14.998,2.5,14.998z
		 M2.5,10.998c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S3.327,10.998,2.5,10.998z"/>
      <path
        d="M8.5,12.998c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h15c0.276,0,0.5,0.224,0.5,0.5
		c0,0.276-0.224,0.5-0.5,0.5H8.5z"/>
      <path
        d="M2.5,22.998c-1.379,0-2.5-1.122-2.5-2.5s1.121-2.5,2.5-2.5S5,19.12,5,20.498S3.879,22.998,2.5,22.998z M2.5,18.998
		c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S3.327,18.998,2.5,18.998z"/>
      <path
        d="M8.5,20.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h15c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H8.5z"/>
    </SvgIcon>
  );
}

export function RefreshIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M3.474,15.006c-0.156-0.008-0.301-0.091-0.388-0.22l-2.787-4.15c-0.074-0.111-0.101-0.244-0.076-0.375
		c0.026-0.131,0.101-0.244,0.212-0.319c0.083-0.056,0.179-0.085,0.278-0.085c0.167,0,0.322,0.083,0.415,0.221l1.616,2.406
		c-0.068-4.292,2.845-8.16,7.088-9.218l0.093-0.023C10.609,3.082,11.309,3,12.008,3c2.572,0,5.027,1.108,6.734,3.041
		c0.183,0.207,0.163,0.523-0.044,0.706c-0.092,0.081-0.209,0.125-0.331,0.125c-0.143,0-0.28-0.062-0.375-0.169
		C16.475,4.985,14.294,4,12.008,4c-0.621,0-1.243,0.073-1.85,0.217l-0.085,0.021c-4.117,1.026-6.784,4.971-6.268,9.126l2.517-3.014
		c0.095-0.114,0.235-0.179,0.384-0.179c0.117,0,0.231,0.041,0.32,0.116c0.212,0.177,0.24,0.493,0.063,0.704l-3.205,3.838
		C3.789,14.941,3.478,15.006,3.474,15.006z"/>
	<path d="M11.971,21.006c-2.366,0-4.603-0.924-6.3-2.602c-0.095-0.094-0.148-0.219-0.148-0.353c-0.001-0.134,0.051-0.26,0.145-0.354
		c0.094-0.096,0.22-0.149,0.355-0.149c0.133,0,0.258,0.052,0.352,0.145c1.508,1.491,3.497,2.312,5.599,2.312c0,0,0,0,0,0
		c0.624,0,1.251-0.074,1.864-0.219c3.674-0.879,6.258-4.245,6.227-7.979l-2.388,2.859c-0.095,0.114-0.235,0.179-0.384,0.179
		c-0.117,0-0.23-0.041-0.32-0.116c-0.211-0.177-0.24-0.492-0.063-0.704l3.146-3.766c0.014-0.02,0.03-0.038,0.047-0.056l0.014-0.018
		c0.095-0.114,0.235-0.179,0.383-0.179c0.005,0,0.024,0,0.029,0.001c0.156,0.008,0.301,0.091,0.388,0.221l2.785,4.152
		c0.074,0.111,0.101,0.244,0.075,0.375c-0.026,0.131-0.101,0.244-0.212,0.319c-0.082,0.055-0.178,0.085-0.278,0.085
		c-0.167,0-0.323-0.083-0.416-0.222l-1.81-2.698c-0.156,4.053-2.985,7.562-6.99,8.52C13.38,20.923,12.674,21.006,11.971,21.006
		L11.971,21.006z"/>
    </SvgIcon>
  );
}


export function CloseIcon (props) {
	return (
    <SvgIcon {...props}>
			<path d="M23.5,23.999c-0.134,0-0.259-0.052-0.354-0.146L12,12.706L0.854,23.853c-0.094,0.094-0.22,0.146-0.354,0.146
				s-0.259-0.052-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l11.146-11.146L0.147,0.853c-0.195-0.195-0.195-0.512,0-0.707
				C0.241,0.051,0.367-0.001,0.5-0.001s0.259,0.052,0.354,0.146L12,11.292L23.147,0.146c0.094-0.094,0.22-0.146,0.354-0.146
				s0.259,0.052,0.354,0.146c0.195,0.195,0.195,0.512,0,0.707L12.707,11.999l11.146,11.146c0.195,0.195,0.195,0.512,0,0.707
				C23.759,23.947,23.634,23.999,23.5,23.999z"/>
		 </SvgIcon>
  );
}

export function CloseRightIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.5,15.999c-0.134,0-0.259-0.052-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l3.146-3.146H0.5
		c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h15.793l-3.146-3.146c-0.195-0.195-0.195-0.512,0-0.707
		c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146l4,4c0.046,0.046,0.083,0.101,0.109,0.163
		c0.05,0.121,0.05,0.261,0,0.382c-0.025,0.061-0.062,0.116-0.109,0.163l-3.999,3.999C13.759,15.947,13.634,15.999,13.5,15.999z"/>
      <path
        d="M23.5,23.999c-0.276,0-0.5-0.224-0.5-0.5v-23c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v23
		C24,23.775,23.776,23.999,23.5,23.999z"/>
    </SvgIcon>
  );
}

export function AddToListIcon(props) {
  return (
    <SvgIcon {...props}>
<path d="M4.5,2.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h14c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4.5z"/>
	<path d="M4.5,7.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h14c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4.5z"/>
	<path d="M4.5,12.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4.5z"/>
	<path d="M4.5,17.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4.5z"/>
	<path d="M0.5,2.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H0.5z"/>
	<path d="M0.5,7.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H0.5z"/>
	<path d="M0.5,12.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H0.5z"/>
	<path d="M0.5,17.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H0.5z"/>
	<path d="M18.5,23.998c-3.033,0-5.5-2.467-5.5-5.5s2.467-5.5,5.5-5.5s5.5,2.467,5.5,5.5S21.533,23.998,18.5,23.998z M18.5,13.998
		c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5S20.981,13.998,18.5,13.998z"/>
	<path d="M18.5,21.498c-0.276,0-0.5-0.224-0.5-0.5v-2h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2v-2
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2h2c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-2v2
		C19,21.274,18.776,21.498,18.5,21.498z"/>
    </SvgIcon>
    );
  }

  export function AddCircleIcon(props) {
    return (
      <SvgIcon {...props}>
  <path d="M12,23.5C5.659,23.5,0.5,18.341,0.5,12S5.659,0.5,12,0.5S23.5,5.659,23.5,12S18.341,23.5,12,23.5z M12,1.5
		C6.21,1.5,1.5,6.21,1.5,12c0,5.79,4.71,10.5,10.5,10.5c5.79,0,10.5-4.71,10.5-10.5C22.5,6.21,17.79,1.5,12,1.5z"/>
	<path d="M12,18.5c-0.276,0-0.5-0.224-0.5-0.5v-5.5H6c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h5.5V6
		c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v5.5H18c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5h-5.5V18
		C12.5,18.276,12.276,18.5,12,18.5z"/>
    </SvgIcon>
    );
  }

  export function RemoveCircleIcon(props) {
    return (
      <SvgIcon {...props}>
  <path d="M17,17.499c-0.134,0-0.259-0.052-0.354-0.146L12,12.706l-4.646,4.646C7.259,17.447,7.134,17.499,7,17.499
		s-0.259-0.052-0.354-0.146S6.5,17.133,6.5,16.999s0.052-0.259,0.146-0.354l4.646-4.646L6.646,7.353
		C6.552,7.258,6.5,7.133,6.5,6.999S6.552,6.74,6.646,6.646S6.866,6.499,7,6.499s0.259,0.052,0.354,0.146L12,11.292l4.646-4.646
		c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146S17.5,6.865,17.5,6.999s-0.052,0.259-0.146,0.354l-4.646,4.646
		l4.646,4.646c0.094,0.094,0.146,0.22,0.146,0.354s-0.052,0.259-0.146,0.354S17.134,17.499,17,17.499z"/>
	<path d="M12,23.999c-6.617,0-12-5.383-12-12c0-6.617,5.383-12,12-12c6.617,0,12,5.383,12,12C24,18.616,18.617,23.999,12,23.999z
		 M12,0.999c-6.065,0-11,4.935-11,11s4.935,11,11,11s11-4.935,11-11S18.065,0.999,12,0.999z"/>
     </SvgIcon>
     );
   }
 

   export function IncomingMoneyIcon(props) {
    return (
      <SvgIcon {...props}>
   <path d="M8.002,24.007c-0.19,0-0.384-0.007-0.574-0.021c-2.131-0.152-4.076-1.124-5.476-2.739c-1.4-1.615-2.087-3.678-1.935-5.809
		c0.297-4.166,3.801-7.429,7.978-7.429c0.003,0,0.006,0,0.009,0c0-0.003,0-0.006,0-0.009c0-4.411,3.589-8,8-8s8,3.589,8,8
		s-3.589,8-8,8c-0.004,0-0.007,0-0.011,0c0.002,0.207-0.004,0.397-0.016,0.575C15.68,20.742,12.177,24.007,8.002,24.007
		L8.002,24.007z M7.994,9.009c-3.654,0-6.719,2.855-6.979,6.5c-0.133,1.865,0.468,3.67,1.693,5.083
		c1.225,1.413,2.927,2.264,4.792,2.397c0.167,0.012,0.336,0.018,0.503,0.018c3.652,0,6.717-2.856,6.977-6.503
		c0.012-0.175,0.017-0.363,0.013-0.569c-3.605-0.457-6.469-3.321-6.925-6.926C8.043,9.009,8.018,9.009,7.994,9.009z M15.469,14.974
		c0.041,0,0.074,0.007,0.095,0.011C15.694,14.993,15.848,15,16.004,15c3.86,0,7-3.14,7-7s-3.14-7-7-7c-3.86,0-7,3.14-7,7
		c0,0.159,0.007,0.316,0.018,0.472c0.003,0.003,0.01,0.042,0.007,0.089c0,0,0,0,0,0.001c0.271,3.393,3.021,6.142,6.414,6.413
		C15.45,14.975,15.463,14.974,15.469,14.974z"/>
	<path d="M7.504,21c-0.276,0-0.5-0.224-0.5-0.5V20H6c-0.276,0-0.5-0.224-0.5-0.5S5.724,19,6,19h2.035
		c0.345,0,0.651-0.208,0.78-0.528c0.173-0.431-0.036-0.922-0.467-1.096l-2.063-0.825c-0.701-0.282-1.155-0.953-1.155-1.708
		c0-0.492,0.191-0.955,0.539-1.303C6.016,13.192,6.478,13,6.97,13h0.033v-0.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V13H9
		c0.276,0,0.5,0.224,0.5,0.5S9.275,14,9,14H6.971c-0.224,0-0.436,0.088-0.595,0.247s-0.246,0.371-0.246,0.595
		c0,0.345,0.208,0.652,0.528,0.781l2.063,0.825c0.943,0.38,1.401,1.455,1.022,2.397C9.461,19.546,8.79,20,8.035,20H8.004v0.5
		C8.004,20.776,7.779,21,7.504,21z"/>
	<path d="M16.004,12.5c-0.276,0-0.5-0.224-0.5-0.5v-0.5H14.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.035
		c0.345,0,0.651-0.207,0.78-0.528c0.084-0.209,0.082-0.438-0.007-0.644c-0.088-0.207-0.251-0.367-0.46-0.451l-2.063-0.826
		c-0.701-0.282-1.155-0.952-1.155-1.708c0-1.016,0.825-1.843,1.841-1.844h0.033V4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v0.5
		H17.5C17.775,4.5,18,4.724,18,5s-0.224,0.5-0.5,0.5h-2.029c-0.464,0-0.842,0.378-0.841,0.843c0,0.345,0.208,0.652,0.528,0.781
		l2.063,0.826c0.457,0.184,0.815,0.534,1.008,0.987c0.193,0.453,0.198,0.953,0.014,1.41c-0.282,0.701-0.952,1.154-1.708,1.155
		h-0.031V12C16.504,12.276,16.279,12.5,16.004,12.5z"/>
    </SvgIcon>
    );
  }


  export function OutMoneyIcon(props) {
    return (
      <SvgIcon {...props}>
	<path d="M20.25,24c-0.161,0-0.313-0.078-0.407-0.209l-2.5-3.5C17.282,20.206,17.25,20.105,17.25,20v-4.5
		c0.031-1.303-1.47-2.633-3-3.923V13.5c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V4.207L10.043,1H7.457L4.25,4.207v10.586
		L7.457,18h3.293c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-6.5c-0.551,0-1-0.448-1-1V1c0-0.551,0.449-1,1-1h9
		c0.552,0,1,0.449,1,1v9.268c0.133,0.113,0.267,0.226,0.401,0.339c1.873,1.576,3.642,3.064,3.599,4.905v4.328l2.407,3.37
		c0.078,0.108,0.108,0.241,0.086,0.373s-0.094,0.247-0.203,0.325C20.456,23.968,20.355,24,20.25,24z M4.25,18h1.793L4.25,16.207V18z
		 M13.25,2.793V1h-1.793L13.25,2.793z M4.25,2.793L6.043,1H4.25L4.25,2.793z"/>
	<path d="M15.311,24c-0.132,0-0.257-0.051-0.351-0.144c-0.285-0.281-1.71-1.742-1.71-2.856v-1.296l-3.047-3.123
		C9.911,16.288,9.75,15.896,9.75,15.48c0.001-0.417,0.163-0.808,0.458-1.102c0.022-0.022,0.047-0.042,0.073-0.061
		c0.383-0.259,0.829-0.396,1.289-0.396c0.459,0,0.905,0.137,1.289,0.397c0.029,0.019,0.055,0.042,0.079,0.067l2.689,2.767
		c0.192,0.198,0.188,0.515-0.01,0.707C15.524,17.95,15.401,18,15.269,18c-0.134,0-0.265-0.055-0.359-0.151l-2.651-2.729
		c-0.208-0.13-0.446-0.199-0.689-0.199c-0.242,0-0.479,0.068-0.686,0.197c-0.187,0.218-0.176,0.553,0.029,0.759l3.195,3.274
		c0.092,0.094,0.142,0.218,0.142,0.349V21c0,0.518,0.826,1.566,1.412,2.144c0.095,0.094,0.148,0.219,0.149,0.353
		c0.001,0.133-0.05,0.259-0.144,0.354C15.573,23.947,15.446,24,15.311,24z"/>
	<path d="M10.75,12c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v2c0,0.827-0.673,1.5-1.5,1.5
		s-1.5-0.673-1.5-1.5V10h-0.5c-0.276,0-0.5-0.224-0.5-0.5S5.474,9,5.75,9h0.5V7.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3
		c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-2c0-0.827,0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5V9h0.5c0.276,0,0.5,0.224,0.5,0.5
		s-0.224,0.5-0.5,0.5h-0.5v1.5C11.25,11.776,11.026,12,10.75,12z"/>
    </SvgIcon>
    );
  }


  export function StudentCenterIcon(props) {
    return (
      <SvgIcon {...props}>
  <path d="M10,24c-0.551,0-1-0.448-1-1c0-0.155,0.037-0.31,0.106-0.448L11,19.363V13c0-0.552,0.449-1,1-1h9c0.552,0,1,0.448,1,1
		v6.363l1.877,3.158c0.003,0.005,0.015,0.026,0.018,0.032c0.246,0.493,0.046,1.095-0.446,1.342C23.309,23.963,23.154,24,23,24H10z
		 M22.998,23l-1.783-3h-9.431l-1.779,2.993L22.998,23z M21,19v-6h-9l0,6H21z"/>
	<path d="M16,22c-0.276,0-0.5-0.224-0.5-0.5S15.724,21,16,21h1c0.276,0,0.5,0.224,0.5,0.5S17.275,22,17,22H16z"/>
	<path d="M0.5,18c-0.132,0-0.261-0.053-0.354-0.146C0.052,17.759,0,17.634,0,17.5c0-0.723,0.105-1.44,0.311-2.132
		c0.571-1.922,1.856-3.506,3.618-4.461c1.099-0.595,2.332-0.91,3.566-0.91c0.723,0,1.445,0.106,2.147,0.314
		c0.128,0.038,0.234,0.124,0.297,0.241c0.064,0.117,0.078,0.253,0.04,0.38C9.917,11.143,9.72,11.29,9.5,11.29
		c-0.048,0-0.096-0.007-0.143-0.021c-0.609-0.181-1.235-0.273-1.862-0.273c-1.069,0-2.137,0.273-3.09,0.789
		c-1.528,0.828-2.642,2.201-3.136,3.867c-0.13,0.439-0.214,0.891-0.25,1.347H8.5C8.775,17,9,17.224,9,17.5S8.775,18,8.5,18H0.5z"/>
	<path d="M7.5,9.5c-2.619,0-4.75-2.131-4.75-4.75c0-0.822,0.218-1.636,0.631-2.356c0.018-0.04,0.04-0.073,0.058-0.097
		C4.311,0.858,5.828,0,7.5,0c2.358,0,4.379,1.763,4.701,4.101c0.003,0.011,0.007,0.032,0.009,0.059
		c0.028,0.221,0.041,0.408,0.041,0.59C12.25,7.369,10.119,9.5,7.5,9.5z M3.995,3.426C3.832,3.851,3.75,4.295,3.75,4.75
		c0,2.068,1.682,3.75,3.75,3.75c2.023,0,3.677-1.611,3.748-3.617C10.497,5.127,9.717,5.25,8.923,5.25
		C7.129,5.25,5.355,4.589,3.995,3.426z M8.936,4.25c0.746,0,1.49-0.131,2.204-0.389C10.734,2.191,9.235,1,7.5,1
		C6.306,1,5.195,1.566,4.486,2.524c1.2,1.098,2.799,1.725,4.422,1.726c0.003,0,0.006,0,0.009,0C8.923,4.25,8.931,4.25,8.936,4.25z"
		/>
    </SvgIcon>
    );
  }

  export function TransferStudentIcon(props) {
    return (
      <SvgIcon {...props}>
  <path d="M8.5,19c-0.134,0-0.259-0.052-0.354-0.146l-3-3c-0.047-0.047-0.083-0.101-0.109-0.163c-0.05-0.121-0.05-0.26,0-0.382
		c0.025-0.061,0.062-0.116,0.108-0.163l3-3C8.241,12.052,8.366,12,8.5,12s0.259,0.052,0.354,0.146S9,12.366,9,12.5
		s-0.052,0.259-0.146,0.354L6.707,15H13.5c0.276,0,0.5,0.224,0.5,0.5S13.776,16,13.5,16H6.707l2.146,2.146
		C8.948,18.241,9,18.366,9,18.5s-0.052,0.259-0.146,0.354S8.634,19,8.5,19z"/>
	<path d="M15.5,12c-0.134,0-0.259-0.052-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707L17.293,9H10.5C10.224,9,10,8.776,10,8.5
		S10.224,8,10.5,8h6.793l-2.146-2.146c-0.195-0.195-0.195-0.512,0-0.707C15.241,5.052,15.366,5,15.5,5s0.259,0.052,0.354,0.146l3,3
		c0.046,0.046,0.083,0.101,0.109,0.163c0.05,0.121,0.05,0.261,0,0.382c-0.025,0.061-0.062,0.116-0.109,0.163l-2.999,2.999
		C15.759,11.948,15.634,12,15.5,12z"/>
	<path d="M12,24C5.383,24,0,18.617,0,12C0,5.383,5.383,0,12,0c6.617,0,12,5.383,12,12C24,18.617,18.617,24,12,24z M12,1
		C5.935,1,1,5.935,1,12s4.935,11,11,11s11-4.935,11-11S18.065,1,12,1z"/>
    </SvgIcon>
    );
  }


  export function PatchLessonIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M6.5,16C4.57,16,3,14.43,3,12.5c0-0.604,0.16-1.202,0.462-1.73c0.011-0.022,0.022-0.038,0.029-0.048
		C4.118,9.661,5.272,9,6.5,9c1.743,0,3.233,1.304,3.465,3.033c0.001,0.006,0.005,0.021,0.006,0.043C9.991,12.233,10,12.368,10,12.5
		C10,14.43,8.43,16,6.5,16z M4.099,11.808C4.033,12.033,4,12.264,4,12.5C4,13.878,5.121,15,6.5,15c1.281,0,2.339-0.968,2.483-2.21
		c-0.484,0.137-0.977,0.206-1.468,0.206C6.269,12.996,5.069,12.576,4.099,11.808z M4.575,10.909
		c0.813,0.701,1.848,1.084,2.938,1.084c0.472,0,0.935-0.073,1.38-0.218C8.576,10.735,7.603,10,6.5,10
		C5.754,10,5.049,10.336,4.575,10.909z"/>
	<path d="M13.858,16.144c-0.092,0-0.182-0.025-0.26-0.073c-0.114-0.069-0.194-0.179-0.225-0.309c-0.031-0.13-0.01-0.264,0.059-0.378
		c0.349-0.573,0.522-1.232,0.5-1.904l0-0.908c0-1.968,1.601-3.569,3.569-3.569s3.569,1.601,3.569,3.569v0.892
		c-0.023,0.686,0.15,1.347,0.5,1.921c0.07,0.114,0.091,0.248,0.059,0.378c-0.031,0.13-0.112,0.24-0.226,0.309
		c-0.078,0.048-0.168,0.073-0.26,0.073c-0.174,0-0.337-0.092-0.427-0.24c-0.254-0.416-0.436-0.872-0.541-1.346
		c-0.523,0.793-1.38,1.327-2.354,1.424c-0.108,0.011-0.216,0.016-0.322,0.016c-1.082,0-2.086-0.564-2.671-1.442
		c-0.105,0.475-0.287,0.931-0.542,1.349C14.193,16.054,14.033,16.144,13.858,16.144z M15.399,13.468c0.29,0.9,1.136,1.53,2.1,1.53
		c0.073,0,0.147-0.004,0.221-0.011c0.871-0.087,1.612-0.698,1.88-1.519c-0.79-0.171-1.508-0.54-2.101-1.081
		C16.907,12.927,16.19,13.297,15.399,13.468z M17.5,10.002c-1.405,0-2.551,1.134-2.569,2.535c0.854-0.121,1.624-0.538,2.19-1.193
		c0.093-0.107,0.231-0.169,0.378-0.169s0.285,0.062,0.378,0.169c0.566,0.655,1.336,1.072,2.191,1.193
		C20.051,11.136,18.905,10.002,17.5,10.002z"/>
	<path d="M1.5,22C1.224,22,1,21.776,1,21.5c0-0.369,0.037-0.739,0.111-1.101c0.294-1.439,1.131-2.678,2.356-3.488
		c0.904-0.598,1.949-0.914,3.021-0.914c0.371,0,0.745,0.038,1.112,0.113c0.131,0.027,0.243,0.103,0.317,0.214
		S8.017,16.569,7.99,16.7c-0.047,0.232-0.253,0.4-0.49,0.4c-0.033,0-0.066-0.003-0.099-0.01c-0.301-0.062-0.607-0.093-0.91-0.093
		c-0.878,0-1.732,0.259-2.472,0.747c-1.003,0.663-1.687,1.676-1.928,2.854C2.064,20.731,2.043,20.865,2.028,21H7.5
		C7.776,21,8,21.224,8,21.5S7.776,22,7.5,22H1.5z"/>
	<path d="M16.5,22c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5.472c-0.015-0.135-0.036-0.269-0.063-0.401
		c-0.24-1.178-0.925-2.191-1.928-2.854c-0.74-0.489-1.594-0.747-2.472-0.747c-0.303,0-0.609,0.031-0.909,0.092
		c-0.033,0.007-0.067,0.01-0.101,0.01c-0.236,0-0.442-0.168-0.489-0.4c-0.027-0.131-0.001-0.264,0.073-0.376
		c0.074-0.111,0.186-0.188,0.317-0.214c0.367-0.075,0.741-0.113,1.111-0.113c1.073,0,2.117,0.316,3.021,0.913
		c1.226,0.81,2.062,2.049,2.356,3.488C22.963,20.759,23,21.13,23,21.5c0,0.276-0.224,0.5-0.5,0.5H16.5z"/>
	<path d="M12,20.5c-1.516,0-2.75-1.234-2.75-2.75S10.484,15,12,15s2.75,1.234,2.75,2.75S13.516,20.5,12,20.5z M12,16
		c-0.965,0-1.75,0.785-1.75,1.75S11.035,19.5,12,19.5c0.965,0,1.75-0.785,1.75-1.75S12.965,16,12,16z"/>
	<path d="M9,24c-0.149,0-0.289-0.065-0.384-0.18c-0.095-0.115-0.135-0.264-0.108-0.41c0.308-1.686,1.776-2.91,3.49-2.91
		c1.704,0.015,3.172,1.237,3.493,2.906c0.028,0.148-0.011,0.298-0.106,0.413C15.291,23.934,15.15,24,15,24H9z M14.317,23
		c-0.415-0.889-1.325-1.491-2.32-1.5l0,0c-1.003,0-1.914,0.602-2.321,1.5H14.317z"/>
	<path d="M12,9C9.519,9,7.5,6.981,7.5,4.5S9.519,0,12,0c2.481,0,4.5,2.019,4.5,4.5S14.481,9,12,9z M12,1c-1.93,0-3.5,1.57-3.5,3.5
		S10.07,8,12,8s3.5-1.57,3.5-3.5S13.93,1,12,1z"/>
	<path d="M12,7c-0.276,0-0.5-0.224-0.5-0.5V5H10C9.724,5,9.5,4.776,9.5,4.5S9.724,4,10,4h1.5V2.5C11.5,2.224,11.724,2,12,2
		c0.276,0,0.5,0.224,0.5,0.5V4H14c0.276,0,0.5,0.224,0.5,0.5S14.276,5,14,5h-1.5v1.5C12.5,6.776,12.276,7,12,7z"/>
    </SvgIcon>
    );
  }

  export function IntentStudentIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M11.5,16.5c-0.066,0-0.131-0.013-0.192-0.038C11.121,16.385,11,16.203,11,16v-3H9.5C8.673,13,8,12.327,8,11.5v-10
		C8,0.673,8.673,0,9.5,0h13C23.327,0,24,0.673,24,1.5v10c0,0.827-0.673,1.5-1.5,1.5h-7.293l-3.354,3.354
		C11.759,16.448,11.634,16.5,11.5,16.5z M9.5,1C9.224,1,9,1.224,9,1.5v10C9,11.776,9.224,12,9.5,12h2c0.276,0,0.5,0.224,0.5,0.5
		v2.293l2.646-2.646C14.741,12.052,14.866,12,15,12h7.5c0.276,0,0.5-0.224,0.5-0.5v-10C23,1.224,22.776,1,22.5,1H9.5z"/>
	<path d="M3.5,13c-1.516,0-2.75-1.234-2.75-2.75S1.984,7.5,3.5,7.5s2.75,1.234,2.75,2.75S5.016,13,3.5,13z M3.5,8.5
		c-0.965,0-1.75,0.785-1.75,1.75S2.535,12,3.5,12s1.75-0.785,1.75-1.75S4.465,8.5,3.5,8.5z"/>
	<path d="M2.5,24c-0.258,0-0.472-0.194-0.498-0.45L1.548,19H0.5C0.224,19,0,18.776,0,18.5v-2C0,14.57,1.57,13,3.5,13S7,14.57,7,16.5
		v2C7,18.776,6.776,19,6.5,19H5.452l-0.455,4.55C4.972,23.806,4.758,24,4.5,24H2.5z M4.048,23l0.455-4.55
		C4.528,18.194,4.742,18,5,18h1v-1.5C6,15.122,4.879,14,3.5,14S1,15.122,1,16.5V18h1c0.258,0,0.472,0.194,0.498,0.45L2.952,23H4.048
		z"/>
	<path d="M16,10.5c-0.135,0-0.267-0.056-0.361-0.154L12.23,6.79c-0.762-0.761-0.95-1.928-0.464-2.899
		c0.301-0.601,0.818-1.049,1.456-1.262c0.26-0.086,0.528-0.13,0.798-0.13c0.391,0,0.769,0.09,1.124,0.267
		c0.242,0.121,0.462,0.28,0.654,0.471L16,3.441l0.203-0.203c0.475-0.475,1.107-0.736,1.78-0.736c0.673,0,1.305,0.261,1.78,0.736
		c0.192,0.192,0.35,0.411,0.471,0.653c0.485,0.971,0.295,2.139-0.472,2.906l-3.402,3.548C16.267,10.444,16.135,10.5,16,10.5z
		 M14.019,3.5c-0.163,0-0.324,0.026-0.481,0.079c-0.385,0.128-0.696,0.398-0.877,0.76c-0.292,0.585-0.178,1.289,0.284,1.752
		L16,9.278l3.048-3.18c0.469-0.469,0.584-1.174,0.292-1.76c-0.073-0.145-0.168-0.278-0.284-0.394
		c-0.286-0.286-0.667-0.444-1.073-0.444s-0.787,0.157-1.073,0.443l-0.557,0.557C16.262,4.593,16.134,4.645,16,4.645
		s-0.262-0.052-0.354-0.144L15.09,3.944c-0.115-0.115-0.248-0.211-0.394-0.284C14.482,3.554,14.254,3.5,14.019,3.5z"/>
    </SvgIcon>
    );
  }


  export function RegisterIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M18,6c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S19.654,6,18,6z M18,1c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2
		S19.103,1,18,1z"/>
	<path d="M5,6C3.346,6,2,4.654,2,3s1.346-3,3-3s3,1.346,3,3S6.654,6,5,6z M5,1C3.897,1,3,1.897,3,3s0.897,2,2,2s2-0.897,2-2
		S6.103,1,5,1z"/>
	<path d="M20,24c-0.575,0-1.123-0.253-1.5-0.679C18.123,23.747,17.575,24,17,24c-1.103,0-2-0.897-2-2v-8.787
		C14.33,14.546,13.146,16,10.5,16c-1.103,0-2-0.897-2-2H1v1h5c0.276,0,0.5,0.224,0.5,0.5S6.276,16,6,16H3v7.5
		C3,23.776,2.776,24,2.5,24S2,23.776,2,23.5V16H1c-0.551,0-1-0.448-1-1v-1.5C0,13.224,0.224,13,0.5,13h8.271
		c0.356-0.612,1.018-1,1.729-1l0.06,0.001c0.487,0,0.903-0.325,1.011-0.791l0.012-0.082C12.012,8.734,14.082,7,16.507,7H20.5
		c1.93,0,3.5,1.57,3.5,3.5v5c0,0.827-0.673,1.5-1.5,1.5H22v5C22,23.103,21.103,24,20,24z M18.5,16.5c0.276,0,0.5,0.224,0.5,0.5v5
		c0,0.552,0.448,1,1,1s1-0.448,1-1V11c0-0.276,0.224-0.5,0.5-0.5S22,10.724,22,11v5h0.5c0.276,0,0.5-0.224,0.5-0.5v-5
		C23,9.121,21.878,8,20.5,8h-3.992c-1.941,0-3.597,1.386-3.939,3.295l-0.018,0.109c-0.22,0.948-1.03,1.598-1.973,1.598
		c-0.034,0-0.069-0.001-0.103-0.002C9.943,13,9.5,13.449,9.5,14c0,0.552,0.449,1,1,1c2.748,0,3.48-1.83,4.069-3.3
		c0.176-0.439,0.326-0.815,0.52-1.095c0.093-0.135,0.247-0.215,0.411-0.215c0.051,0,0.101,0.008,0.149,0.023
		c0.209,0.065,0.35,0.257,0.35,0.477V22c0,0.552,0.448,1,1,1s1-0.448,1-1v-5C18,16.724,18.224,16.5,18.5,16.5z"/>
	<path d="M0.5,12.03c-0.276,0-0.5-0.224-0.5-0.5V10.5C0,8.57,1.57,7,3.5,7h3C8.43,7,10,8.57,10,10.5c0,0.276-0.224,0.5-0.5,0.5
		S9,10.776,9,10.5C9,9.121,7.879,8,6.5,8h-3C2.121,8,1,9.121,1,10.5v1.03C1,11.806,0.776,12.03,0.5,12.03z"/>
	<path d="M7.5,24C7.224,24,7,23.776,7,23.5V23H5.5C5.224,23,5,22.776,5,22.5S5.224,22,5.5,22h3C8.776,22,9,21.776,9,21.5
		S8.776,21,8.5,21h-2C5.673,21,5,20.327,5,19.5S5.673,18,6.5,18H7v-0.5C7,17.224,7.224,17,7.5,17S8,17.224,8,17.5V18h1.5
		c0.276,0,0.5,0.224,0.5,0.5S9.776,19,9.5,19h-3C6.224,19,6,19.224,6,19.5S6.224,20,6.5,20h2c0.827,0,1.5,0.673,1.5,1.5
		S9.327,23,8.5,23H8v0.5C8,23.776,7.776,24,7.5,24z"/>
    </SvgIcon>
    );
  }

  export function TransactionsIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M12,23.792c-0.268,0-0.519-0.104-0.708-0.293L10,22.206l-1.289,1.289c-0.005,0.005-0.014,0.014-0.019,0.018
		C8.518,23.688,8.267,23.792,8,23.792c-0.268,0-0.519-0.104-0.708-0.293L6,22.206l-0.792,0.792
		c-0.186,0.187-0.444,0.293-0.707,0.293c-0.552,0-1-0.448-1-1V1.706c0-0.268,0.104-0.519,0.294-0.708
		C3.983,0.81,4.233,0.707,4.5,0.707c0.268,0,0.519,0.104,0.708,0.294L6,1.792L7.292,0.5C7.482,0.311,7.733,0.207,8,0.207
		c0.268,0,0.519,0.104,0.708,0.293L10,1.792L11.292,0.5C11.482,0.311,11.733,0.207,12,0.207c0.268,0,0.519,0.104,0.708,0.293
		L14,1.792L15.292,0.5C15.487,0.308,15.736,0.207,16,0.207c0.268,0,0.519,0.104,0.708,0.294L18,1.792L18.792,1
		c0.186-0.186,0.444-0.293,0.708-0.293c0.267,0,0.518,0.104,0.707,0.293c0.189,0.189,0.293,0.44,0.293,0.707v20.586
		c0,0.264-0.107,0.522-0.294,0.708c-0.188,0.188-0.439,0.292-0.706,0.292c0,0,0,0,0,0c-0.268,0-0.519-0.104-0.708-0.293L18,22.206
		l-1.292,1.292c-0.189,0.189-0.44,0.293-0.708,0.293c-0.268,0-0.519-0.104-0.708-0.293L14,22.206l-1.292,1.292
		C12.518,23.688,12.267,23.792,12,23.792z M14,20.999c0.134,0,0.259,0.052,0.354,0.146L16,22.792l1.647-1.646
		c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146l1.146,1.146l0-20.585l-1.146,1.146
		C18.259,2.947,18.134,2.999,18,2.999s-0.259-0.052-0.354-0.146L16,1.207c-0.004,0.004-0.019,0.018-0.023,0.021l-1.624,1.625
		C14.259,2.947,14.134,2.999,14,2.999s-0.259-0.052-0.354-0.146L12,1.207l-1.647,1.646C10.259,2.947,10.134,2.999,10,2.999
		S9.741,2.947,9.646,2.853L8,1.207L6.354,2.853C6.259,2.947,6.134,2.999,6,2.999S5.741,2.947,5.646,2.853L4.5,1.707l0,20.585
		l1.146-1.146c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146L8,22.792c0.002-0.002,0.019-0.017,0.021-0.019
		l1.626-1.627c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146L12,22.792l1.647-1.646
		C13.741,21.051,13.866,20.999,14,20.999z"/>
	<path d="M7,6.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7z"/>
	<path d="M7,9.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7z"/>
	<path d="M7,12.999c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h4c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5H7
		z"/>
	<path d="M7,15.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7z"/>
	<path d="M15,18.999c-0.276,0-0.5-0.224-0.5-0.5v-0.771c-0.612-0.355-1-1.018-1-1.729c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5
		c0,0.552,0.448,1,1,1s1-0.448,1-1s-0.448-1-1-1c-1.103,0-2-0.897-2-2s0.897-2,2-2v-0.5c0-0.276,0.224-0.5,0.5-0.5
		s0.5,0.224,0.5,0.5v0.771c0.612,0.356,1,1.018,1,1.729c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5c0-0.552-0.448-1-1-1
		s-1,0.448-1,1s0.448,1,1,1c1.103,0,2,0.897,2,2s-0.897,2-2,2v0.5C15.5,18.775,15.276,18.999,15,18.999z"/>
    </SvgIcon>
    );
  }

  export function CommunicationIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M5.5,13c-0.072,0-0.141-0.015-0.206-0.044C5.116,12.876,5,12.697,5,12.5V9H1.5C0.673,9,0,8.327,0,7.5v-6
		C0,0.673,0.673,0,1.5,0h21C23.327,0,24,0.673,24,1.5v6C24,8.327,23.327,9,22.5,9H10.19l-4.358,3.874C5.74,12.955,5.622,13,5.5,13z
		 M1.5,1C1.224,1,1,1.224,1,1.5v6C1,7.776,1.224,8,1.5,8h4C5.776,8,6,8.224,6,8.5v2.886l3.668-3.26C9.76,8.045,9.877,8,10,8h12.5
		C22.776,8,23,7.776,23,7.5v-6C23,1.224,22.776,1,22.5,1H1.5z"/>
	<path d="M18.5,24c-0.123,0-0.24-0.045-0.332-0.126L13.81,20H1.5C0.673,20,0,19.327,0,18.5v-6C0,11.673,0.673,11,1.5,11H3
		c0.276,0,0.5,0.224,0.5,0.5S3.276,12,3,12H1.5C1.224,12,1,12.224,1,12.5v6C1,18.776,1.224,19,1.5,19H14
		c0.123,0,0.241,0.045,0.333,0.127L18,22.387V19.5c0-0.276,0.224-0.5,0.5-0.5h4c0.276,0,0.5-0.224,0.5-0.5v-6
		c0-0.276-0.224-0.5-0.5-0.5h-12c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h12c0.827,0,1.5,0.673,1.5,1.5v6
		c0,0.827-0.673,1.5-1.5,1.5H19v3.5c0,0.197-0.116,0.376-0.295,0.456C18.641,23.985,18.572,24,18.5,24z"/>
    </SvgIcon>
    );
  }

  export function CalendarIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M1.5,24.001c-0.827,0-1.5-0.673-1.5-1.5v-18c0-0.827,0.673-1.5,1.5-1.5H3v-1.5c0-0.276,0.224-0.5,0.5-0.5S4,1.225,4,1.501
		v1.5h3v-1.5c0-0.276,0.224-0.5,0.5-0.5S8,1.225,8,1.501v1.5h3v-1.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1.5h3v-1.5
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1.5h3v-1.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1.5h1.5
		c0.827,0,1.5,0.673,1.5,1.5v18c0,0.827-0.673,1.5-1.5,1.5H1.5z M1.5,4.001c-0.276,0-0.5,0.224-0.5,0.5v18
		c0,0.276,0.224,0.5,0.5,0.5h20c0.276,0,0.5-0.224,0.5-0.5v-18c0-0.276-0.224-0.5-0.5-0.5H20v0.5c0,0.276-0.224,0.5-0.5,0.5
		S19,4.777,19,4.501v-0.5h-3v0.5c0,0.276-0.224,0.5-0.5,0.5S15,4.777,15,4.501v-0.5h-3v0.5c0,0.276-0.224,0.5-0.5,0.5
		S11,4.777,11,4.501v-0.5H8v0.5c0,0.276-0.224,0.5-0.5,0.5S7,4.777,7,4.501v-0.5H4v0.5c0,0.276-0.224,0.5-0.5,0.5S3,4.777,3,4.501
		v-0.5H1.5z"/>
	<path d="M7.5,21.001c-0.007,0-0.014,0-0.021-0.001H4.5C3.673,21,3,20.327,3,19.5v-6C3,12.673,3.673,12,4.5,12h3
		c0.007,0,0.014,0,0.021,0.001H11V9.5C11,8.673,11.673,8,12.5,8h6C19.327,8,20,8.673,20,9.5v10c0,0.827-0.673,1.5-1.5,1.5h-2.979
		c-0.007,0.001-0.014,0.001-0.021,0.001H7.5z M15,20.001v-3h-3v3H15z M11,20.001v-3H8v3H11z M18.5,20c0.276,0,0.5-0.224,0.5-0.5
		v-2.499h-3V20H18.5z M4,19.5C4,19.776,4.224,20,4.5,20H7v-3H4V19.5z M15,16.001v-3h-3v3H15z M11,16.001v-3H8v3H11z M19,16v-2.999
		h-3V16H19z M7,16v-3H4.5C4.224,13,4,13.224,4,13.5V16H7z M19,12V9.5C19,9.224,18.776,9,18.5,9H16v3H19z M15,12V9h-2.5
		C12.224,9,12,9.224,12,9.5V12H15z"/>
    </SvgIcon>
    );
  }

  export function OrderListIcon(props) {
    return (
      <SvgIcon {...props}> 
  <path d="M1.504,16.5c-0.827,0-1.5-0.673-1.5-1.5V5c0-0.827,0.673-1.5,1.5-1.5h17c0.827,0,1.5,0.673,1.5,1.5v10
		c0,0.827-0.673,1.5-1.5,1.5H1.504z M1.504,4.5c-0.276,0-0.5,0.224-0.5,0.5v10c0,0.276,0.224,0.5,0.5,0.5h17
		c0.276,0,0.5-0.224,0.5-0.5V5c0-0.276-0.224-0.5-0.5-0.5H1.504z"/>
	<path d="M2.5,18.5C2.224,18.5,2,18.276,2,18s0.224-0.5,0.5-0.5h18c0.276,0,0.5-0.224,0.5-0.5V6c0-0.276,0.224-0.5,0.5-0.5
		S22,5.724,22,6v11c0,0.827-0.673,1.5-1.5,1.5H2.5z"/>
	<path d="M4.5,20.5C4.224,20.5,4,20.276,4,20s0.224-0.5,0.5-0.5h18c0.276,0,0.5-0.224,0.5-0.5V8c0-0.276,0.224-0.5,0.5-0.5
		S24,7.724,24,8v11c0,0.827-0.673,1.5-1.5,1.5H4.5z"/>
	<path d="M10.004,13.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S11.934,13.5,10.004,13.5z M10.004,7.5
		c-1.379,0-2.5,1.121-2.5,2.5s1.121,2.5,2.5,2.5s2.5-1.121,2.5-2.5S11.382,7.5,10.004,7.5z"/>
	<path d="M2.504,14.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H2.504z"/>
	<path d="M15.504,6.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H15.504z"/>
  </SvgIcon>
  );
}


export function StudentBasicInfoIcon(props) {
  return (
    <SvgIcon {...props}> 
    <path d="M3.5,24C2.673,24,2,23.327,2,22.5v-21C2,0.673,2.673,0,3.5,0h13.293c0.401,0,0.777,0.156,1.061,0.439l3.707,3.707
    C21.844,4.43,22,4.807,22,5.207V22.5c0,0.827-0.673,1.5-1.5,1.5H3.5z M3.5,1C3.224,1,3,1.224,3,1.5v21C3,22.776,3.224,23,3.5,23h17
    c0.276,0,0.5-0.224,0.5-0.5V5.207c0-0.133-0.052-0.259-0.146-0.354l-3.707-3.707C17.053,1.053,16.925,1,16.793,1H3.5z"/>
    <path d="M6,7.5C5.724,7.5,5.5,7.276,5.5,7S5.724,6.5,6,6.5h7c0.276,0,0.5,0.224,0.5,0.5S13.276,7.5,13,7.5H6z"/>
    <path d="M6,10.5c-0.276,0-0.5-0.224-0.5-0.5S5.724,9.5,6,9.5h12c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6z"/>
    <path d="M6,13.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h12c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6z"/>
    <path d="M6,16.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h12c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6z"/>
    <path d="M6,19.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h12c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H6z"/>
  </SvgIcon>
  );
}

export function ClassAttendanceLogIcon(props) {
  return (
    <SvgIcon {...props}> 
<path d="M2.5,23.999c-1.379,0-2.5-1.122-2.5-2.5v-19c0-1.379,1.121-2.5,2.5-2.5h19c1.378,0,2.5,1.121,2.5,2.5v19
		c0,1.378-1.122,2.5-2.5,2.5H2.5z M2.5,0.999c-0.827,0-1.5,0.673-1.5,1.5v19c0,0.827,0.673,1.5,1.5,1.5h19
		c0.827,0,1.5-0.673,1.5-1.5v-19c0-0.827-0.673-1.5-1.5-1.5H2.5z"/>
	<path d="M6.5,9.499c-0.134,0-0.259-0.052-0.354-0.146l-1.5-1.5C4.552,7.758,4.5,7.633,4.5,7.499S4.552,7.24,4.646,7.146
		S4.866,6.999,5,6.999s0.259,0.052,0.354,0.146L6.5,8.292l3.646-3.646c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146
		S11,4.865,11,4.999s-0.052,0.259-0.146,0.354l-4,4C6.759,9.447,6.634,9.499,6.5,9.499z"/>
	<path d="M6.5,19.499c-0.134,0-0.259-0.052-0.354-0.146l-1.5-1.5C4.552,17.758,4.5,17.633,4.5,17.499s0.052-0.259,0.146-0.354
		S4.866,16.999,5,16.999s0.259,0.052,0.354,0.146L6.5,18.292l3.646-3.646c0.094-0.094,0.22-0.146,0.354-0.146
		s0.259,0.052,0.354,0.146S11,14.865,11,14.999s-0.052,0.259-0.146,0.354l-4,4C6.759,19.447,6.634,19.499,6.5,19.499z"/>
	<path d="M13.5,8.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H13.5z"/>
	<path d="M13.5,17.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H13.5z"/>
  </SvgIcon>
  );
}


export function NoCourseIcon(props) {
  return (
    <SvgIcon {...props}> 
<path d="M9.517,5.25c-0.199,0-0.386-0.077-0.527-0.217C8.847,4.892,8.768,4.704,8.768,4.504c0,0,0-0.002,0-0.003
		C8.767,4.302,8.844,4.114,8.985,3.972C9.126,3.83,9.314,3.751,9.513,3.75c0,0,0.004,0,0.004,0c0.414,0,0.75,0.336,0.75,0.75
		c0,0.408-0.329,0.742-0.735,0.75c-0.005,0-0.009,0-0.014,0H9.517z"/>
	<path d="M14.517,5.25c-0.198,0-0.386-0.077-0.527-0.217c-0.142-0.142-0.221-0.329-0.222-0.529c0-0.001,0-0.003,0-0.003
		c-0.001-0.412,0.333-0.749,0.746-0.751c0,0,0.004,0,0.004,0c0.414,0,0.75,0.336,0.75,0.75c0,0.408-0.329,0.742-0.735,0.75
		c-0.005,0-0.009,0-0.014,0H14.517z"/>
	<path d="M12.018,10c-1.103,0-2-0.897-2-2c0-0.033,0.003-0.066,0.01-0.099C10.124,7.417,10.676,5,12.018,5
		c1.343,0,1.894,2.417,1.99,2.902c0.007,0.033,0.01,0.065,0.01,0.098C14.018,9.103,13.121,10,12.018,10z M11.019,8.046
		C11.043,8.574,11.486,9,12.018,9c0.532,0,0.975-0.426,0.999-0.954C12.798,7.009,12.306,6,12.018,6S11.238,7.009,11.019,8.046z"/>
	<path d="M12.012,11.993c-0.924,0-1.817-0.21-2.656-0.625C8.059,10.726,7.048,9.651,6.49,8.326c-0.413,0.073-0.811,0.11-1.186,0.11
		c-1.815,0-2.777-0.864-3.189-1.379c-0.871-1.09-1.03-2.709-0.396-4.029C2.339,1.739,3.541,1,5.018,1
		c0.968,0.03,1.901,0.304,2.718,0.797C8.857,0.652,10.404,0,12.012,0c1.62,0,3.167,0.652,4.288,1.797
		C17.116,1.304,18.046,1.03,19.002,1c0,0,0,0,0,0c1.466,0,2.657,0.737,3.274,2.021c0.639,1.329,0.482,2.955-0.39,4.047
		c-0.408,0.51-1.363,1.367-3.164,1.367l0,0c-0.373,0-0.769-0.037-1.179-0.109c-0.664,1.562-1.998,2.805-3.605,3.349
		C13.312,11.886,12.664,11.993,12.012,11.993z M6.807,7.251c0.215,0,0.405,0.137,0.474,0.34c0.428,1.265,1.322,2.288,2.519,2.88
		c0.699,0.346,1.443,0.521,2.213,0.521c0.543,0,1.083-0.089,1.606-0.266c1.468-0.496,2.64-1.668,3.136-3.136
		c0.068-0.203,0.259-0.34,0.474-0.34c0.038,0,0.075,0.004,0.112,0.013c0.486,0.112,0.949,0.169,1.376,0.169
		c1.045,0,1.871-0.342,2.389-0.989c0.638-0.797,0.747-1.998,0.271-2.989C20.931,2.53,20.072,2,19.018,2
		c-0.898,0.028-1.768,0.324-2.509,0.855c-0.086,0.061-0.186,0.094-0.291,0.094c-0.148,0-0.287-0.065-0.382-0.177
		C14.886,1.646,13.497,1,12.024,1c-0.002,0-0.007,0-0.007,0C10.539,1,9.149,1.646,8.2,2.771C8.105,2.884,7.965,2.949,7.818,2.949
		c-0.105,0-0.206-0.032-0.291-0.094C6.786,2.324,5.913,2.028,5.002,2C3.938,2,3.067,2.533,2.62,3.462
		C2.148,4.443,2.259,5.636,2.896,6.432C3.419,7.087,4.254,7.433,5.31,7.433c0.43,0,0.896-0.057,1.384-0.17
		C6.732,7.255,6.769,7.251,6.807,7.251z"/>
	<path d="M8.018,24c-1.851-0.006-3.626-0.795-4.87-2.164c-1.527-1.866-1.75-4.509-0.564-6.584c0.573-0.862,1.877-2.742,3.054-4.437
		C5.732,10.68,5.885,10.6,6.049,10.6c0.102,0,0.201,0.031,0.285,0.089c0.11,0.076,0.183,0.191,0.207,0.322
		c0.024,0.131-0.005,0.264-0.081,0.374c-1.232,1.773-2.48,3.573-3.026,4.392c-0.967,1.696-0.781,3.874,0.471,5.406
		C4.947,22.329,6.45,22.995,8.019,23h4.667c0.4,0,0.777-0.156,1.061-0.439l2.417-2.414c0.365-0.365,0.458-0.706,0.473-0.927
		c0.016-0.244-0.056-0.465-0.198-0.607c-0.095-0.095-0.21-0.144-0.341-0.144c-0.015,0-0.03,0.001-0.045,0.002
		c-0.212,0.017-0.448,0.149-0.682,0.383l-1.5,1.5c-0.094,0.094-0.22,0.146-0.354,0.146s-0.259-0.052-0.354-0.146L11.811,19H9.518
		c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.5c0.134,0,0.259,0.052,0.354,0.146l1.146,1.146l1.146-1.146
		c0.561-0.56,1.087-0.678,1.43-0.678c0.396,0,0.77,0.155,1.052,0.437c0.345,0.345,0.524,0.848,0.489,1.378
		c-0.026,0.393-0.179,0.985-0.764,1.57l-2.417,2.414C13.982,23.74,13.354,24,12.687,24H8.018z"/>
	<path d="M12.867,17.3c-0.077,0-0.155-0.019-0.224-0.053c-0.119-0.06-0.208-0.162-0.25-0.289c-0.042-0.127-0.032-0.262,0.028-0.382
		l1.1-2.194c0.425-0.851,1.28-1.38,2.232-1.382h2.265c0.724,0,1.259-0.198,1.59-0.589c0.365-0.431,0.437-1.029,0.441-1.411h-2.031
		c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.5c0.246,0,0.453,0.176,0.493,0.418c0.011,0.064,0.253,1.585-0.64,2.64
		C19.841,13.683,19.049,14,18.018,14h-2.264c-0.571,0.001-1.084,0.319-1.339,0.829l-1.101,2.195
		C13.229,17.194,13.058,17.3,12.867,17.3z"/>
    </SvgIcon>
  );
}


export function RenewIcon(props) {
  return (
    <SvgIcon {...props}> 
   <path d="M17.5,22c-0.276,0-0.5-0.224-0.5-0.5V21h-1c-0.276,0-0.5-0.224-0.5-0.5S15.724,20,16,20h2.031
		c0.345,0,0.651-0.208,0.78-0.528c0.084-0.208,0.081-0.437-0.007-0.644c-0.088-0.207-0.251-0.367-0.46-0.451l-2.063-0.826
		c-0.702-0.283-1.155-0.953-1.155-1.708c0-0.492,0.191-0.954,0.539-1.302c0.348-0.348,0.81-0.54,1.302-0.541H17v-0.5
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V14h1c0.276,0,0.5,0.224,0.5,0.5S19.276,15,19,15h-1.479
		c-0.007,0.001-0.014,0.001-0.021,0.001s-0.014,0-0.021-0.001h-0.511c-0.224,0-0.436,0.088-0.594,0.248
		c-0.159,0.159-0.247,0.371-0.247,0.595c0,0.345,0.207,0.652,0.528,0.781l2.063,0.826c0.457,0.184,0.815,0.535,1.008,0.987
		c0.193,0.453,0.198,0.953,0.014,1.41C19.457,20.546,18.787,21,18.031,21H18v0.5C18,21.776,17.776,22,17.5,22z"/>
	<path d="M17.5,24c-3.584,0-6.5-2.916-6.5-6.5s2.916-6.5,6.5-6.5s6.5,2.916,6.5,6.5S21.084,24,17.5,24z M17.5,12
		c-3.033,0-5.5,2.467-5.5,5.5s2.467,5.5,5.5,5.5s5.5-2.467,5.5-5.5S20.533,12,17.5,12z"/>
	<path d="M0.5,18C0.224,18,0,17.776,0,17.5c0-2,0.781-3.883,2.199-5.301C3.617,10.781,5.5,10,7.5,10c1.44,0,2.842,0.411,4.053,1.19
		c0.232,0.149,0.299,0.459,0.151,0.691c-0.093,0.144-0.25,0.23-0.421,0.23l0,0c-0.096,0-0.189-0.027-0.27-0.079
		C9.963,11.356,8.748,11,7.5,11c-1.737,0-3.369,0.676-4.597,1.904C1.8,14.008,1.136,15.454,1.019,17H8.5C8.776,17,9,17.224,9,17.5
		S8.776,18,8.5,18H0.5z"/>
	<path d="M7.5,9.5c-2.619,0-4.75-2.131-4.75-4.75c0-0.822,0.218-1.636,0.631-2.355c0.018-0.041,0.041-0.074,0.06-0.099
		C4.313,0.858,5.829,0,7.5,0c2.357,0,4.377,1.762,4.7,4.099c0.003,0.011,0.007,0.034,0.009,0.062
		c0.028,0.221,0.041,0.409,0.041,0.589C12.25,7.369,10.119,9.5,7.5,9.5z M3.995,3.426C3.832,3.852,3.75,4.296,3.75,4.75
		c0,2.068,1.682,3.75,3.75,3.75c2.023,0,3.677-1.611,3.748-3.617C10.497,5.127,9.717,5.25,8.923,5.25
		C7.128,5.25,5.355,4.589,3.995,3.426z M4.487,2.524c1.201,1.1,2.802,1.726,4.427,1.726c0.766,0,1.512-0.131,2.227-0.389
		C10.734,2.191,9.235,1,7.5,1C6.307,1,5.195,1.566,4.487,2.524z"/>
  </SvgIcon>
  );
}

export function BuyMaterialIcon(props) {
  return (
    <SvgIcon {...props}> 
<path d="M12.504,21.077c-0.276,0-0.5-0.224-0.5-0.5v-0.5H11c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.034
		c0.345,0,0.651-0.208,0.78-0.528c0.173-0.431-0.036-0.922-0.467-1.095l-2.062-0.825c-0.707-0.281-1.162-0.955-1.159-1.716
		c0.002-0.492,0.194-0.954,0.543-1.3c0.347-0.345,0.808-0.535,1.297-0.535h0.037v-0.5c0-0.276,0.224-0.5,0.5-0.5
		c0.276,0,0.5,0.224,0.5,0.5v0.5H14c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-2.029c-0.161,0.007-0.406,0.055-0.597,0.244
		c-0.159,0.158-0.248,0.369-0.248,0.594c-0.001,0.348,0.207,0.656,0.53,0.784l2.064,0.826c0.943,0.38,1.401,1.455,1.022,2.397
		c-0.282,0.701-0.952,1.154-1.708,1.155h-0.031v0.5C13.004,20.853,12.78,21.077,12.504,21.077z"/>
	<path d="M2,24c-0.827,0-1.5-0.673-1.5-1.5v-16c0.005-0.165,0.016-0.331,0.034-0.499c0,0,0-0.001,0-0.001
		c0-0.018,0.002-0.038,0.006-0.057c0.061-0.538,0.182-1.05,0.36-1.542l1.264-3.159c0.336-0.73,1.027-1.201,1.809-1.241L20,0
		c0.803,0.041,1.497,0.507,1.825,1.219l1.268,3.168c0.003,0.007,0.008,0.021,0.01,0.029c0.177,0.486,0.299,1.007,0.358,1.537
		C23.464,5.968,23.466,5.985,23.466,6c0,0,0,0,0,0.001c0.018,0.17,0.029,0.328,0.033,0.484l0,16.016c0,0.827-0.673,1.5-1.5,1.5H2z
		 M1.5,22.5C1.5,22.776,1.724,23,2,23h20c0.276,0,0.5-0.224,0.5-0.5v-16h-21C1.5,6.505,1.5,22.5,1.5,22.5z M22.373,5.5
		c-0.055-0.264-0.127-0.516-0.213-0.754l-1.253-3.133C20.744,1.262,20.387,1.023,19.98,1L12.5,1v4.5H22.373z M11.5,5.5V1L4,1
		C3.611,1.021,3.255,1.265,3.083,1.637l-1.248,3.12C1.752,4.988,1.682,5.237,1.627,5.5H11.5z"/>
    </SvgIcon>
    );
  }
	
	export function BoyIcon (props) {
		return (
				<SvgIcon {...props}> 
					<path d="M8.75,12.998c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75s0.75,0.337,0.75,0.75S9.164,12.998,8.75,12.998z"/>
					<path d="M15.25,12.998c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75S16,11.835,16,12.248S15.664,12.998,15.25,12.998z"/>
					<path d="M12.006,19c-0.354,0-0.709-0.048-1.055-0.144c-1.017-0.28-1.864-0.94-2.385-1.857c-0.136-0.24-0.052-0.546,0.188-0.682
						c0.075-0.042,0.16-0.065,0.247-0.065c0.18,0,0.346,0.097,0.435,0.253c0.523,0.921,1.508,1.493,2.571,1.493
						c0.507,0,1.009-0.133,1.451-0.384c0.463-0.263,0.846-0.646,1.109-1.109c0.088-0.156,0.255-0.253,0.434-0.253
						c0.086,0,0.172,0.023,0.247,0.066c0.116,0.066,0.199,0.173,0.235,0.302c0.035,0.129,0.019,0.264-0.048,0.38
						c-0.352,0.62-0.865,1.133-1.484,1.484C13.355,18.822,12.683,19,12.006,19z"/>
					<path d="M11.996,23.997c-4.802,0-9.059-3.356-10.196-8.008C0.791,15.887,0,15.033,0,13.998c0-0.964,0.676-1.775,1.602-1.961
						c0.502-3.569,2.852-6.677,6.17-8.146c0.034-0.019,0.065-0.03,0.085-0.037C8.833,3.43,9.88,3.157,10.951,3.049
						c0.352-0.035,0.709-0.053,1.061-0.053c1.189,0,2.354,0.199,3.469,0.592c-0.097-1.1-0.694-2.079-1.643-2.664
						c-0.234-0.145-0.307-0.453-0.163-0.688c0.092-0.149,0.251-0.238,0.426-0.238c0.093,0,0.183,0.026,0.263,0.075
						c1.363,0.841,2.17,2.336,2.134,3.934c0.769,0.366,1.491,0.823,2.149,1.361c2.059,1.683,3.386,4.046,3.752,6.67
						C23.324,12.224,24,13.034,24,13.998c0,1.035-0.791,1.89-1.8,1.99c-0.961,3.938-4.125,6.988-8.11,7.797
						C13.4,23.925,12.696,23.997,11.996,23.997L11.996,23.997z M2.2,14.985c0.237,0,0.443,0.168,0.49,0.4
						c0.896,4.41,4.809,7.611,9.306,7.611c0,0,0,0,0.001,0c0.632,0,1.269-0.064,1.894-0.191c3.747-0.76,6.659-3.673,7.419-7.419
						c0.047-0.232,0.254-0.4,0.492-0.4c0.019,0,0.039,0.001,0.058,0.003c0.05,0.006,0.096,0.009,0.142,0.01c0.55,0,0.998-0.448,0.998-1
						s-0.448-1-1-1h-0.05c-0.258,0-0.472-0.193-0.498-0.45c-0.253-2.525-1.475-4.8-3.439-6.406c-0.517-0.422-1.075-0.79-1.665-1.095
						c-0.112,0.433-0.286,0.841-0.518,1.217c-0.825,1.338-2.26,2.136-3.837,2.136c-0.832,0-1.646-0.231-2.355-0.669
						C8.659,7.129,7.949,6.166,7.653,5.056c-2.841,1.472-4.787,4.315-5.105,7.492c-0.026,0.257-0.24,0.45-0.498,0.45H2
						c-0.551,0-1,0.448-1,1c0,0.265,0.106,0.516,0.297,0.706c0.191,0.189,0.442,0.293,0.707,0.293c0.043,0,0.087-0.003,0.131-0.009
						C2.16,14.987,2.18,14.985,2.2,14.985z M8.585,4.637c0.201,0.924,0.78,1.753,1.578,2.245c0.552,0.34,1.185,0.52,1.832,0.52
						c1.227,0,2.343-0.621,2.985-1.662c0.213-0.345,0.362-0.718,0.445-1.11c-1.086-0.421-2.23-0.634-3.404-0.634
						c-0.321,0-0.647,0.016-0.968,0.049C10.208,4.129,9.38,4.328,8.585,4.637z"/>
				</SvgIcon>
    );
	}
	

	export function GirlIcon (props) {
		return (
				<SvgIcon {...props}> 
	<path d="M16.235,6.5c-0.607,0-1.178-0.236-1.606-0.664l-0.51-0.511C13.665,6.048,12.867,6.5,12.001,6.5
		c-0.867,0-1.665-0.452-2.118-1.175L9.372,5.836C8.944,6.264,8.375,6.5,7.769,6.5C6.517,6.499,5.5,5.482,5.501,4.232V2.268
		C5.501,1.017,6.518,0,7.768,0c0.607,0,1.177,0.236,1.605,0.664l1.251,1.251C11.032,1.643,11.504,1.5,12.001,1.5
		s0.968,0.143,1.377,0.416l1.251-1.251C15.057,0.236,15.627,0,16.232,0c0.606,0,1.175,0.235,1.603,0.663
		c0.428,0.428,0.665,0.997,0.665,1.603v1.966C18.501,5.482,17.485,6.499,16.235,6.5C16.235,6.5,16.235,6.5,16.235,6.5z
		 M15.336,5.129c0.24,0.24,0.558,0.371,0.896,0.371c0.339,0,0.657-0.132,0.897-0.372c0.24-0.24,0.372-0.558,0.371-0.896V2.268
		c0-0.339-0.132-0.658-0.372-0.897C16.89,1.132,16.572,1,16.234,1h-0.001c-0.339,0-0.657,0.132-0.897,0.371l-1.251,1.251
		C14.357,3.032,14.501,3.504,14.501,4c0,0.088-0.006,0.179-0.017,0.276L15.336,5.129z M12.001,2.5c-0.827,0-1.5,0.673-1.5,1.5
		s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S12.828,2.5,12.001,2.5z M7.768,1C7.069,1,6.501,1.568,6.501,2.266v1.966
		C6.5,4.931,7.068,5.5,7.767,5.5c0.34,0,0.659-0.132,0.898-0.371l0.853-0.853C9.506,4.179,9.501,4.088,9.501,4
		c0-0.496,0.143-0.968,0.416-1.377L8.665,1.371C8.426,1.132,8.107,1,7.768,1L7.768,1z"/>
	<path d="M8.001,14.625c-1.103,0-2-0.729-2-1.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,0.295,0.428,0.625,1,0.625
		s1-0.33,1-0.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5C10.001,13.896,9.103,14.625,8.001,14.625z"/>
	<path d="M16.001,14.625c-1.103,0-2-0.729-2-1.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,0.295,0.428,0.625,1,0.625
		c0.542,0,1-0.286,1-0.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5C18.001,13.911,17.122,14.625,16.001,14.625z"/>
	<path d="M12.226,20.009c-0.075,0-0.15-0.003-0.226-0.008c-0.075,0.005-0.149,0.008-0.224,0.008c-0.978,0-1.893-0.435-2.511-1.193
		c-0.174-0.214-0.142-0.53,0.072-0.704C9.427,18.04,9.539,18,9.653,18c0.151,0,0.292,0.067,0.388,0.184
		c0.427,0.524,1.06,0.825,1.737,0.825c0.061,0,0.121-0.002,0.182-0.008c0.007,0,0.034-0.001,0.041-0.001
		c0.013,0,0.025,0,0.038,0.001c0.065,0.005,0.127,0.008,0.188,0.008c0.674,0,1.306-0.301,1.733-0.825
		C14.055,18.067,14.197,18,14.348,18c0.115,0,0.227,0.04,0.316,0.113c0.213,0.174,0.246,0.489,0.072,0.703
		C14.117,19.574,13.203,20.009,12.226,20.009z"/>
	<path d="M12.001,24c-4.403-0.069-8.253-2.902-9.626-7.066c-1.116-0.238-2.003-1.088-2.283-2.204
		c-0.373-1.487,0.439-3.011,1.859-3.54c0.34-1.854,1.156-3.595,2.366-5.043c0.095-0.114,0.235-0.179,0.384-0.179
		c0.117,0,0.231,0.041,0.32,0.116c0.212,0.177,0.24,0.493,0.063,0.704C3.922,8.178,3.162,9.86,2.887,11.651
		c-0.031,0.199-0.177,0.36-0.373,0.409c-1.069,0.268-1.721,1.357-1.453,2.427c0.205,0.818,0.907,1.422,1.746,1.504
		c0.203,0.02,0.373,0.159,0.431,0.355c1.162,3.916,4.686,6.59,8.77,6.654c4.071-0.064,7.592-2.738,8.754-6.654
		c0.059-0.197,0.228-0.336,0.431-0.355c0.531-0.052,1.011-0.308,1.35-0.72c0.339-0.413,0.498-0.933,0.446-1.464
		c-0.082-0.839-0.686-1.541-1.504-1.746c-0.195-0.049-0.342-0.209-0.373-0.409c-0.275-1.791-1.035-3.472-2.197-4.864
		C18.74,6.576,18.769,6.26,18.98,6.083c0.089-0.075,0.203-0.116,0.32-0.116c0.148,0,0.289,0.065,0.384,0.179
		c1.209,1.449,2.025,3.19,2.365,5.043c1.071,0.4,1.824,1.373,1.935,2.519c0.078,0.797-0.16,1.578-0.668,2.196
		c-0.433,0.527-1.029,0.888-1.69,1.029c-1.373,4.164-5.219,6.997-9.617,7.066c-0.001,0-0.003,0-0.004,0S12.002,24,12.001,24z"/>
		</SvgIcon>
    );
	}

	export function BirthDayIcon (props) {
		return (
				<SvgIcon {...props}> 
	<path d="M0.5,24C0.224,24,0,23.775,0,23.5S0.224,23,0.5,23h23c0.276,0,0.5,0.224,0.5,0.5S23.776,24,23.5,24H0.5z"/>
	<path d="M3.5,22c-1.375-0.003-2.496-1.125-2.5-2.499V11.5C1,10.672,1.673,10,2.5,10H4V7c0-0.551,0.449-1,1-1h1c0.551,0,1,0.449,1,1
		v3h3.5V7c0-0.551,0.449-1,1-1h1c0.552,0,1,0.449,1,1v3H17V7c0-0.551,0.448-1,1-1h1c0.552,0,1,0.449,1,1v3h1.5
		c0.827,0,1.5,0.673,1.5,1.5v8c-0.003,1.375-1.124,2.497-2.499,2.5C20.501,22,3.5,22,3.5,22z M2,19.5
		c0.002,0.825,0.676,1.498,1.501,1.5H20.5c0.825-0.002,1.498-0.676,1.5-1.501v-4.21c-0.573,0.532-1.329,0.836-2.125,0.836
		c-1.069,0-2.054-0.549-2.625-1.431c-0.571,0.882-1.556,1.431-2.625,1.431S12.571,15.576,12,14.694
		c-0.57,0.882-1.556,1.431-2.625,1.431S7.32,15.576,6.75,14.694c-0.57,0.882-1.556,1.431-2.625,1.431
		c-0.796,0-1.552-0.304-2.125-0.836V19.5z M17.25,12.5c0.276,0,0.5,0.224,0.5,0.5c0,1.172,0.953,2.125,2.125,2.125S22,14.171,22,13
		v-1.5c0-0.276-0.224-0.5-0.5-0.5h-19C2.224,11,2,11.224,2,11.5V13c0,1.172,0.953,2.125,2.125,2.125S6.25,14.171,6.25,13
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,1.172,0.953,2.125,2.125,2.125S11.5,14.171,11.5,13c0-0.276,0.224-0.5,0.5-0.5
		c0.276,0,0.5,0.224,0.5,0.5c0,1.172,0.953,2.125,2.125,2.125S16.75,14.171,16.75,13C16.75,12.724,16.974,12.5,17.25,12.5z M19,10V7
		h-1l0,3H19z M12.5,10V7h-1l0,3H12.5z M6,10V7H5l0,3H6z"/>
	<path d="M5.5,5c-1.103,0-2-0.897-2-2c0-0.035,0.004-0.071,0.011-0.106c0.2-0.918,0.618-1.79,1.209-2.52
		C4.909,0.136,5.194-0.001,5.5-0.001c0.225,0,0.447,0.077,0.623,0.218c0.059,0.047,0.112,0.1,0.158,0.158
		c0.59,0.729,1.007,1.6,1.207,2.518C7.496,2.928,7.5,2.964,7.5,3C7.5,4.102,6.603,5,5.5,5z M4.501,3.049C4.527,3.576,4.969,4,5.5,4
		s0.973-0.424,0.999-0.95C6.327,2.304,5.983,1.597,5.502,1.002L5.5,0.999C5.018,1.596,4.673,2.304,4.501,3.049z"/>
	<path d="M18.5,5c-1.103,0-2-0.897-2-2c0-0.036,0.004-0.072,0.012-0.108c0.2-0.917,0.618-1.788,1.208-2.519
		c0.165-0.207,0.403-0.338,0.668-0.368c0.038-0.004,0.076-0.007,0.115-0.007c0.224,0,0.444,0.078,0.621,0.219
		c0.057,0.045,0.111,0.098,0.158,0.158c0.59,0.729,1.007,1.6,1.207,2.518C20.496,2.93,20.5,2.965,20.5,3C20.5,4.102,19.603,5,18.5,5
		z M17.501,3.049C17.527,3.576,17.969,4,18.5,4s0.973-0.424,0.999-0.95c-0.172-0.745-0.516-1.453-0.996-2.047L18.501,1
		C18.018,1.596,17.673,2.304,17.501,3.049z"/>
	<path d="M12,5c-1.103,0-2-0.897-2-2c0-0.035,0.004-0.071,0.011-0.106c0.2-0.918,0.618-1.79,1.209-2.52
		c0.165-0.207,0.402-0.338,0.668-0.368c0.038-0.004,0.077-0.007,0.115-0.007c0.223,0,0.444,0.078,0.62,0.219
		c0.057,0.045,0.111,0.098,0.158,0.158c0.59,0.729,1.007,1.6,1.207,2.518C13.996,2.93,14,2.965,14,3C14,4.102,13.103,5,12,5z
		 M11.001,3.049C11.027,3.576,11.469,4,12,4s0.973-0.424,0.999-0.95c-0.172-0.745-0.516-1.453-0.997-2.047l-0.005-0.003
		C11.518,1.596,11.173,2.304,11.001,3.049z"/>
		</SvgIcon>
    );
	}

	export function PhoneIcon (props) {
		return (
				<SvgIcon {...props}> 
					<path d="M9.086,7.4a2.315,2.315,0,0,0,0-3.271L6.632,1.678a2.313,2.313,0,0,0-3.271,0L2.016,3.024a3.463,3.463,0,0,0-.439,4.365A53.778,53.778,0,0,0,16.611,22.423a3.469,3.469,0,0,0,4.366-.437l1.345-1.346a2.312,2.312,0,0,0,0-3.271l-2.453-2.453a2.312,2.312,0,0,0-3.271,0l-.818.818A54.879,54.879,0,0,1,8.268,8.22Z"/>

		</SvgIcon>
    );
	}


	export function TransferClassIcon (props) {
		return (
			<SvgIcon {...props}> 
<path d="M21.25,24c-1.516,0-2.75-1.234-2.75-2.75c0-0.18,0.018-0.36,0.055-0.54l-2.482-0.993C15.57,20.514,14.699,21,13.75,21
		C12.234,21,11,19.766,11,18.25s1.234-2.75,2.75-2.75c0.797,0,1.535,0.337,2.058,0.932l2.868-1.721
		C18.559,14.402,18.5,14.08,18.5,13.75c0-1.516,1.234-2.75,2.75-2.75S24,12.234,24,13.75s-1.234,2.75-2.75,2.75
		c-0.797,0-1.535-0.337-2.058-0.932l-2.868,1.72c0.117,0.309,0.176,0.632,0.176,0.961c0,0.18-0.018,0.36-0.055,0.54l2.482,0.993
		c0.503-0.796,1.374-1.282,2.323-1.282c1.516,0,2.75,1.234,2.75,2.75S22.766,24,21.25,24z M21.25,19.5
		c-0.965,0-1.75,0.785-1.75,1.75S20.285,23,21.25,23S23,22.215,23,21.25S22.215,19.5,21.25,19.5z M13.75,16.5
		c-0.965,0-1.75,0.785-1.75,1.75S12.785,20,13.75,20s1.75-0.785,1.75-1.75c0-0.304-0.081-0.605-0.235-0.871
		c-0.008-0.01-0.015-0.02-0.02-0.028c-0.006-0.01-0.011-0.021-0.016-0.032C14.917,16.813,14.361,16.5,13.75,16.5z M19.77,14.681
		c0.313,0.506,0.869,0.819,1.48,0.819c0.965,0,1.75-0.785,1.75-1.75S22.215,12,21.25,12s-1.75,0.785-1.75,1.75
		c0,0.304,0.081,0.605,0.235,0.872c0.005,0.007,0.01,0.013,0.014,0.02C19.757,14.653,19.764,14.667,19.77,14.681z"/>
	<path d="M0.5,18C0.224,18,0,17.776,0,17.5c0-2,0.781-3.883,2.199-5.301S5.499,10,7.499,9.999c2.453,0,4.755,1.202,6.156,3.215
		c0.076,0.109,0.105,0.242,0.082,0.373c-0.023,0.132-0.097,0.246-0.207,0.323C13.447,13.969,13.348,14,13.245,14
		c-0.164,0-0.317-0.08-0.41-0.214c-1.215-1.745-3.209-2.786-5.335-2.786c-1.737,0-3.369,0.676-4.597,1.904
		C1.799,14.008,1.136,15.454,1.019,17H8.5C8.776,17,9,17.224,9,17.5S8.776,18,8.5,18H0.5z"/>
	<path d="M7.5,9.5c-2.619,0-4.75-2.131-4.75-4.75c0-0.822,0.218-1.636,0.631-2.356c0.018-0.04,0.04-0.073,0.058-0.097
		C4.311,0.858,5.828,0,7.5,0c2.357,0,4.377,1.762,4.7,4.099c0.003,0.011,0.007,0.034,0.009,0.062
		c0.028,0.221,0.041,0.409,0.041,0.589C12.25,7.369,10.119,9.5,7.5,9.5z M3.996,3.426C3.833,3.851,3.75,4.295,3.75,4.75
		c0,2.068,1.682,3.75,3.75,3.75c2.023,0,3.677-1.611,3.748-3.617C10.497,5.127,9.717,5.25,8.923,5.25
		C7.129,5.25,5.355,4.589,3.996,3.426z M8.936,4.25c0.746,0,1.49-0.131,2.204-0.389C10.734,2.191,9.235,1,7.5,1
		C6.307,1,5.195,1.566,4.487,2.524c1.2,1.098,2.799,1.725,4.422,1.726c0.003,0,0.006,0,0.009,0C8.923,4.25,8.931,4.25,8.936,4.25z"
		/>
	</SvgIcon>
	);
}


export function JoinClassIcon (props) {
	return (
		<SvgIcon {...props}> 
<path d="M13.5,24c-0.827,0-1.5-0.673-1.5-1.5v-5.114l-1.177,0.996C10.732,18.458,10.617,18.5,10.5,18.5
		c-0.148,0-0.287-0.065-0.382-0.177c-0.178-0.209-0.152-0.526,0.059-0.704l5.854-4.953c0.271-0.23,0.615-0.356,0.969-0.356
		s0.698,0.127,0.969,0.357l5.854,4.952c0.211,0.178,0.237,0.495,0.059,0.705c-0.095,0.112-0.234,0.177-0.382,0.177
		c-0.118,0-0.232-0.042-0.322-0.118L22,17.386V22.5c0,0.827-0.673,1.5-1.5,1.5H13.5z M20.5,23c0.276,0,0.5-0.224,0.5-0.5v-5.96
		l-3.677-3.111C17.232,13.352,17.117,13.31,17,13.31c-0.118,0-0.232,0.042-0.323,0.119L13,16.54v5.96c0,0.276,0.224,0.5,0.5,0.5H15
		v-2.5c0-1.103,0.897-2,2-2s2,0.897,2,2V23H20.5z M18,23v-2.5c0-0.552-0.448-1-1-1s-1,0.448-1,1V23H18z"/>
	<path d="M0.5,18C0.224,18,0,17.776,0,17.5c0-2,0.781-3.883,2.199-5.301S5.499,10,7.5,10c2.222,0,4.317,0.976,5.745,2.679
		c0.086,0.102,0.127,0.232,0.115,0.365s-0.074,0.254-0.177,0.34c-0.09,0.075-0.204,0.117-0.321,0.117
		c-0.148,0-0.288-0.065-0.383-0.179C11.241,11.846,9.426,11,7.5,11c-1.736,0-3.368,0.676-4.596,1.904
		C1.799,14.008,1.136,15.454,1.018,17H8.5C8.775,17,9,17.224,9,17.5S8.775,18,8.5,18H0.5z"/>
	<path d="M7.5,9.5c-2.619,0-4.75-2.131-4.75-4.75c0-0.822,0.218-1.636,0.631-2.355c0.018-0.041,0.041-0.074,0.06-0.099
		C4.312,0.858,5.829,0,7.5,0c2.357,0,4.377,1.762,4.7,4.099c0.003,0.011,0.007,0.034,0.009,0.062
		c0.028,0.221,0.041,0.409,0.041,0.589C12.25,7.369,10.119,9.5,7.5,9.5z M3.995,3.426C3.832,3.852,3.75,4.296,3.75,4.75
		c0,2.068,1.682,3.75,3.75,3.75c2.023,0,3.677-1.611,3.748-3.617C10.497,5.127,9.717,5.25,8.923,5.25
		C7.128,5.25,5.354,4.589,3.995,3.426z M4.486,2.524c1.201,1.1,2.802,1.726,4.427,1.726c0.766,0,1.512-0.131,2.227-0.389
		C10.734,2.191,9.235,1,7.5,1C6.306,1,5.195,1.566,4.486,2.524z"/>
		</SvgIcon>
		);
	}
	
	export function RefundIcon (props) {
		return (
			<SvgIcon {...props}> 
		<path d="M13,22c-0.276,0-0.5-0.224-0.5-0.5S12.724,21,13,21c4.963,0,9-4.037,9-9s-4.037-9-9-9s-9,4.037-9,9v0.161l2.624-2.999
		C6.719,9.054,6.856,8.992,7,8.992c0.121,0,0.238,0.044,0.329,0.123c0.208,0.182,0.229,0.498,0.047,0.706l-3.5,4
		c-0.009,0.01-0.019,0.02-0.03,0.029c-0.099,0.094-0.221,0.144-0.346,0.144c-0.015,0-0.031-0.002-0.047-0.005
		c-0.01-0.001-0.019-0.003-0.029-0.005c-0.06-0.01-0.112-0.028-0.161-0.054c-0.01-0.005-0.03-0.019-0.039-0.025
		c-0.039-0.026-0.075-0.058-0.105-0.095c-0.013-0.013-0.026-0.028-0.035-0.042l-3-4.5C0.01,9.158-0.017,9.025,0.01,8.894
		C0.036,8.763,0.111,8.65,0.222,8.576C0.304,8.521,0.4,8.492,0.499,8.492c0.168,0,0.323,0.083,0.416,0.223l2.085,3.128
		C3.085,6.401,7.538,2,13,2c5.514,0,10,4.486,10,10C23,17.514,18.514,22,13,22z"/>
		<path d="M12.004,18.5c-0.276,0-0.5-0.224-0.5-0.5v-3.5h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2v-1h-2
		c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h1.54L8.094,7.287C7.936,7.061,7.991,6.749,8.217,6.59
		C8.302,6.531,8.401,6.5,8.504,6.5c0.163,0,0.316,0.08,0.41,0.213l3.09,4.415l3.09-4.415C15.187,6.58,15.341,6.5,15.504,6.5
		c0.103,0,0.202,0.031,0.286,0.09c0.11,0.077,0.183,0.191,0.206,0.323c0.023,0.131-0.006,0.264-0.083,0.373L12.964,11.5h1.54
		c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5h-2v1h2c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-2V18
		C12.504,18.276,12.28,18.5,12.004,18.5z"/>
			</SvgIcon>
		);
	}

	export function SettingIcon (props) {
		return (
			<SvgIcon {...props}> 

	<path d="M12.001,24.003c-0.271,0-0.539-0.047-0.798-0.138c-0.676-0.24-1.201-0.766-1.442-1.442l-0.45-1.265
		c-0.123-0.346-0.374-0.624-0.705-0.781c-0.187-0.089-0.385-0.134-0.589-0.134c-0.157,0-0.312,0.027-0.462,0.08
		c-0.043,0.016-0.087,0.033-0.129,0.053l-1.212,0.577c-0.324,0.155-0.668,0.234-1.023,0.234c-0.268,0-0.534-0.046-0.79-0.136
		c-0.598-0.21-1.078-0.641-1.353-1.213c-0.313-0.652-0.313-1.401,0-2.053l0.575-1.211c0.326-0.684,0.034-1.506-0.65-1.831
		c-0.042-0.02-0.086-0.038-0.13-0.054l-1.266-0.45c-0.598-0.212-1.077-0.644-1.349-1.217c-0.273-0.573-0.306-1.218-0.094-1.815
		c0.24-0.677,0.766-1.203,1.444-1.444l1.265-0.45c0.345-0.123,0.622-0.373,0.78-0.705C3.781,8.275,3.8,7.902,3.678,7.557
		C3.662,7.513,3.644,7.47,3.624,7.428L3.048,6.216c-0.565-1.179-0.065-2.6,1.115-3.166c0.324-0.156,0.67-0.234,1.026-0.234
		S5.892,2.895,6.216,3.05l1.211,0.575c0.186,0.088,0.383,0.133,0.587,0.133c0.53,0,1.018-0.308,1.245-0.785
		c0.02-0.041,0.037-0.083,0.052-0.126l0.45-1.265c0.336-0.945,1.236-1.579,2.239-1.579c0.271,0,0.538,0.046,0.795,0.138
		c0.676,0.24,1.201,0.766,1.441,1.441l0.45,1.265c0.123,0.346,0.373,0.623,0.704,0.781c0.187,0.089,0.385,0.134,0.589,0.134
		c0.156,0,0.311-0.027,0.461-0.08c0.045-0.016,0.088-0.034,0.131-0.054l1.212-0.578c0.322-0.154,0.665-0.232,1.02-0.232
		c0.912,0,1.754,0.53,2.146,1.351c0.311,0.65,0.311,1.396,0,2.047l-0.578,1.213c-0.157,0.331-0.177,0.704-0.054,1.05
		c0.123,0.346,0.373,0.623,0.704,0.781c0.042,0.02,0.086,0.038,0.13,0.054l1.265,0.45c0.598,0.212,1.077,0.645,1.35,1.217
		c0.273,0.573,0.306,1.217,0.094,1.816c-0.24,0.677-0.767,1.203-1.444,1.444l-1.265,0.45c-0.346,0.123-0.623,0.373-0.781,0.704
		c-0.157,0.331-0.177,0.704-0.054,1.05c0.016,0.045,0.034,0.089,0.054,0.131l0.577,1.21c0.273,0.572,0.308,1.217,0.096,1.815
		c-0.211,0.598-0.643,1.078-1.216,1.352c-0.324,0.155-0.668,0.233-1.024,0.233s-0.7-0.078-1.023-0.233l-1.212-0.575
		c-0.188-0.089-0.386-0.135-0.59-0.135c-0.156,0-0.311,0.027-0.461,0.08c-0.346,0.123-0.623,0.373-0.781,0.704
		c-0.02,0.042-0.038,0.086-0.054,0.13l-0.45,1.266c-0.213,0.598-0.646,1.077-1.219,1.349C12.696,23.925,12.354,24.003,12.001,24.003
		z M8.018,19.242c0.353,0,0.696,0.078,1.018,0.231c0.573,0.272,1.005,0.752,1.218,1.349l0.45,1.265
		c0.139,0.391,0.443,0.695,0.834,0.834c0.15,0.054,0.306,0.081,0.462,0.081c0.204,0,0.402-0.045,0.589-0.134
		c0.332-0.158,0.583-0.435,0.706-0.781l0.45-1.265c0.027-0.077,0.059-0.152,0.093-0.225c0.273-0.573,0.752-1.005,1.349-1.217
		c0.258-0.092,0.526-0.138,0.796-0.138c0.353,0,0.696,0.078,1.019,0.231l1.212,0.576c0.188,0.09,0.388,0.135,0.594,0.135
		c0.206,0,0.405-0.045,0.593-0.135c0.331-0.158,0.581-0.436,0.703-0.782c0.123-0.346,0.103-0.719-0.055-1.051l-0.578-1.211
		c-0.035-0.074-0.066-0.149-0.094-0.226c-0.212-0.597-0.178-1.241,0.094-1.814c0.273-0.573,0.751-1.006,1.348-1.218l1.265-0.45
		c0.392-0.139,0.697-0.444,0.836-0.836c0.123-0.346,0.104-0.719-0.054-1.051c-0.158-0.332-0.436-0.582-0.781-0.705l-1.265-0.45
		c-0.076-0.027-0.152-0.058-0.225-0.093c-0.573-0.273-1.005-0.752-1.217-1.349c-0.212-0.597-0.179-1.242,0.093-1.815l0.578-1.213
		c0.18-0.377,0.18-0.809,0-1.185c-0.227-0.475-0.715-0.782-1.243-0.782c-0.205,0-0.404,0.045-0.59,0.134l-1.213,0.578
		c-0.074,0.035-0.15,0.066-0.226,0.094c-0.256,0.091-0.522,0.137-0.792,0.137c-1.004,0-1.903-0.635-2.239-1.58l-0.45-1.265
		c-0.139-0.391-0.443-0.695-0.834-0.834c-0.148-0.053-0.303-0.079-0.459-0.079c-0.581,0-1.103,0.367-1.297,0.914l-0.45,1.265
		c-0.027,0.075-0.057,0.148-0.091,0.22C9.891,3.975,9.412,4.408,8.814,4.621C8.555,4.713,8.287,4.759,8.016,4.759
		c-0.353,0-0.695-0.078-1.017-0.231L5.786,3.953C5.596,3.862,5.396,3.816,5.19,3.816c-0.206,0-0.406,0.046-0.594,0.136
		c-0.331,0.159-0.58,0.437-0.702,0.783c-0.122,0.346-0.102,0.719,0.057,1.05L4.528,7C4.562,7.072,4.593,7.146,4.62,7.222
		c0.213,0.598,0.18,1.242-0.093,1.815s-0.751,1.005-1.349,1.218l-1.265,0.45c-0.392,0.139-0.697,0.444-0.836,0.836
		c-0.123,0.346-0.104,0.719,0.054,1.051s0.435,0.582,0.781,0.705l1.266,0.45c0.076,0.027,0.151,0.058,0.225,0.093
		c1.182,0.563,1.686,1.982,1.124,3.164l-0.576,1.213c-0.182,0.379-0.182,0.812-0.001,1.19c0.159,0.331,0.437,0.581,0.783,0.702
		c0.148,0.052,0.302,0.079,0.457,0.079c0.205,0,0.405-0.046,0.593-0.136l1.212-0.577c0.073-0.035,0.148-0.066,0.224-0.093
		C7.479,19.289,7.747,19.242,8.018,19.242z"/>
	<path d="M11.999,17.002c-2.757,0-5-2.243-5-5c0-2.757,2.243-5,5-5c2.757,0,5,2.243,5,5C16.999,14.759,14.756,17.002,11.999,17.002z
		 M11.999,8.002c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S14.205,8.002,11.999,8.002z"/>
		 		</SvgIcon>
		);
	}



	export function ContractIcon (props) {
		return (
			<SvgIcon {...props}> 

	<path d="M13.241,24.002c-0.318,0-0.625-0.13-0.847-0.357l-2.162-1.644H9.414c-0.209,0.59-0.774,1-1.414,1H6
		c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h2c0.572,0,1.077,0.319,1.329,0.816l2.32-0.987
		c0.24-0.097,0.486-0.145,0.737-0.145c0.426,0,0.829,0.132,1.17,0.382l0.003,0.002l1.74-0.845C15.592,14.078,15.925,14,16.259,14
		c0.253,0,0.501,0.043,0.737,0.13l3.093,1.183c0.262-0.202,0.58-0.312,0.911-0.312h2c0.276,0,0.5,0.224,0.5,0.5v7
		c0,0.276-0.224,0.5-0.5,0.5h-2c-0.827,0-1.5-0.673-1.5-1.5v-0.238l-3.236,0.647l-2.277,1.826
		C13.778,23.907,13.513,24.002,13.241,24.002z M10.4,21.001c0.109,0,0.217,0.036,0.303,0.102l2.33,1.771
		c0.027,0.021,0.051,0.043,0.072,0.067c0.034,0.038,0.083,0.061,0.136,0.061c0.03,0,0.074-0.007,0.117-0.042l3.504-2.81
		c0.074-0.061,0.085-0.172,0.024-0.251l-1.663-1.832l-0.385,0.158c-0.207,0.084-0.421,0.126-0.64,0.126
		c-0.228,0-0.451-0.046-0.664-0.136c-0.418-0.177-0.742-0.507-0.912-0.928c-0.202-0.501-0.147-1.075,0.134-1.531
		c-0.112-0.044-0.237-0.069-0.362-0.069c-0.138,0.003-0.249,0.024-0.36,0.069L9.5,16.832v4.169H10.4z M21,16.001
		c-0.276,0-0.5,0.224-0.5,0.5v5c0,0.276,0.224,0.5,0.5,0.5h1.5v-6H21z M8,22.001c0.276,0,0.5-0.224,0.5-0.5v-4.999
		c0-0.277-0.224-0.501-0.5-0.501H6.5v6H8z M17.649,19.255c0.319,0.406,0.35,0.928,0.124,1.334l1.727-0.345v-3.742
		c0-0.112,0.012-0.222,0.037-0.329l-2.891-1.106C16.526,15.023,16.395,15,16.26,15c-0.18,0-0.36,0.042-0.519,0.122l-1.859,0.902
		c-0.025,0.012-0.048,0.029-0.072,0.046c-0.014,0.011-0.034,0.027-0.061,0.042c-0.232,0.194-0.315,0.52-0.202,0.8
		c0.07,0.174,0.203,0.309,0.375,0.382c0.088,0.037,0.18,0.056,0.274,0.056c0.09,0,0.178-0.017,0.262-0.052l1.851-0.76
		c0.06-0.025,0.124-0.038,0.19-0.038c0.204,0,0.385,0.122,0.463,0.311c0.051,0.123,0.05,0.259-0.001,0.383
		c-0.052,0.123-0.148,0.219-0.271,0.27l-0.485,0.199L17.649,19.255z"/>
	<path d="M1.5,24.001c-0.551,0-1-0.448-1-1v-19.5c0-1.93,1.57-3.5,3.5-3.5h12.5c1.766,0,3,1.439,3,3.5c0,0.276-0.224,0.5-0.5,0.5
		h-4.5v9c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-10c0-0.744,0.279-1.455,0.768-2H4c-1.379,0-2.5,1.121-2.5,2.5v19.5H4
		c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H1.5z M18.467,3.001c-0.165-1.207-0.931-2-1.967-2c-1.103,0-2,0.897-2,2H18.467z"/>
	<path d="M4,5.001c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h7c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4z"/>
	<path d="M4,8.001c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h7c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4z"/>
	<path d="M4,11.001c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3.5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H4z"/>
	</SvgIcon>
		);
	}

	export function PrintIcon (props) {
		return (
			<SvgIcon {...props}> 
	<path d="M7.5,19.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h9c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7.5z"/>
	<path d="M7.5,16.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h9c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H7.5z"/>
	<path d="M6.5,24C5.673,24,5,23.327,5,22.5V17H2.5c-1.375-0.004-2.496-1.125-2.5-2.499V8.5C0.004,7.125,1.125,6.004,2.499,6H21.5
		c1.375,0.004,2.496,1.125,2.5,2.499V14.5c-0.004,1.375-1.125,2.496-2.499,2.5H19v5.5c0,0.827-0.673,1.5-1.5,1.5H6.5z M6,22.5
		C6,22.776,6.224,23,6.5,23h11c0.276,0,0.5-0.224,0.5-0.5V14H6V22.5z M21.5,16c0.824-0.002,1.498-0.676,1.5-1.501V8.5
		c-0.002-0.824-0.676-1.497-1.501-1.5H2.5C1.676,7.003,1.003,7.676,1,8.502V14.5c0.003,0.824,0.676,1.498,1.501,1.5H5v-2.5
		C5,13.225,5.224,13,5.5,13h13c0.276,0,0.5,0.224,0.5,0.5V16H21.5z"/>
	<path d="M18.479,4.999H14.5c-0.276,0-0.5-0.224-0.5-0.5V1H6v3.5C6,4.776,5.776,5,5.5,5S5,4.776,5,4.5v-4C5,0.224,5.224,0,5.5,0
		h8.979C14.486,0,14.493-0.001,14.5-0.001S14.514,0,14.521,0h0.564c0.401,0,0.777,0.156,1.061,0.439l2.414,2.414
		C18.844,3.137,19,3.514,19,3.914V4.5C19,4.776,18.776,5,18.5,5C18.493,5,18.486,5,18.479,4.999z M18,3.999V3.914
		c0-0.133-0.052-0.259-0.146-0.354l-2.414-2.414C15.345,1.052,15.219,1,15.086,1H15v2.999H18z"/>
	<path d="M3.5,10.999c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5S5,8.672,5,9.499S4.327,10.999,3.5,10.999z M3.5,8.999
		c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5S4,9.775,4,9.499S3.776,8.999,3.5,8.999z"/>
		</SvgIcon>
		);
	}

	export function DataImportIcon (props) {
		return (
			<SvgIcon {...props}> 
	<path d="M1.49,24.014c-0.827,0-1.5-0.673-1.5-1.5v-21c0-0.827,0.673-1.5,1.5-1.5h13.293c0.401,0,0.777,0.156,1.061,0.439
		l3.71,3.707c0.283,0.284,0.439,0.66,0.439,1.061v3.293c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V5.221
		c0-0.133-0.052-0.259-0.146-0.353l-3.71-3.707c-0.093-0.093-0.222-0.147-0.354-0.147H1.49c-0.276,0-0.5,0.224-0.5,0.5v21
		c0,0.276,0.224,0.5,0.5,0.5h8c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H1.49z"/>
	<path d="M3.49,16.014c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h0.5v-4c0-0.551,0.449-1,1-1h1c0.551,0,1,0.449,1,1v4h1v-7
		c0-0.551,0.449-1,1-1h1c0.551,0,1,0.449,1,1v4.5c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-4.5h-1v7h0.5
		c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H3.49z M5.99,15.014v-4h-1l0,4H5.99z"/>
	<path d="M17.49,24.014c-3.584,0-6.5-2.916-6.5-6.5c0-3.584,2.916-6.5,6.5-6.5s6.5,2.916,6.5,6.5
		C23.99,21.098,21.074,24.014,17.49,24.014z M17.49,12.014c-3.033,0-5.5,2.467-5.5,5.5s2.467,5.5,5.5,5.5s5.5-2.467,5.5-5.5
		S20.523,12.014,17.49,12.014z"/>
	<path d="M17.49,21.014c-0.276,0-0.5-0.224-0.5-0.5v-4.793l-1.396,1.396c-0.094,0.094-0.22,0.146-0.354,0.146
		s-0.259-0.052-0.354-0.146s-0.146-0.22-0.146-0.354s0.052-0.259,0.146-0.354l2.25-2.25c0.045-0.046,0.101-0.083,0.163-0.109
		c0.06-0.025,0.125-0.038,0.191-0.038s0.131,0.013,0.191,0.038c0.062,0.025,0.117,0.062,0.163,0.109l2.249,2.249
		c0.094,0.094,0.146,0.22,0.146,0.354s-0.052,0.259-0.146,0.354s-0.22,0.146-0.354,0.146s-0.259-0.052-0.354-0.146l-1.396-1.396
		v4.793C17.99,20.789,17.766,21.014,17.49,21.014z"/>
		</SvgIcon>
		);
	}

	export function SelectedIcon (props) {
		return (
			<SvgIcon {...props}> 
			<path d="M7.001,23.999c-0.132,0-0.261-0.053-0.354-0.147l-6.5-6.5c-0.195-0.195-0.195-0.512,0-0.707
		c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146l6.083,6.083L23.094,0.208c0.094-0.13,0.246-0.208,0.406-0.208
		c0.105,0,0.206,0.032,0.291,0.094c0.109,0.078,0.18,0.193,0.202,0.325c0.022,0.132-0.009,0.264-0.087,0.373l-16.5,23
		c-0.086,0.119-0.219,0.195-0.365,0.207C7.035,23.998,7.008,23.999,7.001,23.999z"/>
	</SvgIcon>
		);
	}

	export function SelectIcon (props) {
		return (
			<SvgIcon {...props}> 
		<path d="M7 10l5 5 5-5z"></path>
	</SvgIcon>
	);
}

export function ValidateDataIcon (props) {
	return (
		<SvgIcon {...props}>
<path d="M11.465,16.233c-0.132,0-0.261-0.053-0.354-0.147l-4.578-4.58c-0.094-0.094-0.146-0.22-0.146-0.354
	c0-0.134,0.052-0.259,0.147-0.354c0.094-0.094,0.22-0.146,0.353-0.146s0.259,0.052,0.354,0.146l4.154,4.156l5.694-8.238
	C17.182,6.581,17.336,6.5,17.5,6.5c0.102,0,0.2,0.031,0.284,0.089c0.11,0.076,0.183,0.19,0.207,0.321
	c0.024,0.131-0.005,0.264-0.081,0.374l-6.035,8.732c-0.085,0.122-0.218,0.2-0.366,0.214C11.503,16.231,11.473,16.233,11.465,16.233
	z"/>
<path d="M12,24.001c-0.441,0-0.873-0.081-1.285-0.24l-1.067-0.414C4.765,21.446,1.491,16.65,1.5,11.412V3.776
	c-0.006-0.795,0.457-1.526,1.18-1.86C5.575,0.644,8.65,0,11.823,0l0.182,0.001L12.217,0c3.146,0,6.206,0.643,9.094,1.912
	c0.73,0.339,1.195,1.071,1.189,1.868v7.633c0.009,5.237-3.265,10.033-8.146,11.934l-1.068,0.414
	C12.873,23.921,12.441,24.001,12,24.001z M11.795,1C8.78,1,5.852,1.615,3.091,2.827C2.731,2.995,2.497,3.366,2.5,3.772v7.641
	c-0.008,4.829,3.01,9.25,7.509,11.002l1.066,0.413c0.297,0.115,0.608,0.173,0.924,0.173s0.628-0.058,0.924-0.173l1.067-0.414
	c4.499-1.752,7.516-6.173,7.509-11.001V3.776c0.003-0.409-0.232-0.782-0.6-0.952C18.147,1.614,15.22,1,12.206,1l-0.202,0.001
	L11.795,1z"/>
	</SvgIcon>
	);
}

export function RevokeIcon (props) {
	return (
		<SvgIcon {...props}>
<path d="M17.785,23.998c-0.159,0-0.31-0.076-0.404-0.204c-0.079-0.108-0.111-0.24-0.091-0.372c0.021-0.132,0.091-0.248,0.199-0.327
		c0.944-0.692,1.768-1.526,2.449-2.478c3.53-4.938,2.384-11.827-2.554-15.357c-1.885-1.339-4.11-2.047-6.433-2.047
		c-3.261,0-6.298,1.373-8.413,3.786h5.957c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-7c-0.015,0-0.032-0.002-0.049-0.005
		C1.404,7.989,1.365,7.98,1.326,7.966C1.304,7.958,1.286,7.949,1.267,7.94c0,0,0,0,0,0c-0.003,0-0.032-0.011-0.065-0.035
		c-0.015-0.011-0.036-0.03-0.051-0.047C1.138,7.845,1.126,7.83,1.113,7.815c-0.02-0.024-0.044-0.056-0.064-0.097
		C1.038,7.697,1.03,7.674,1.023,7.65C1.015,7.624,1.006,7.592,1.002,7.555C0.998,7.536,0.995,7.514,0.995,7.498v-7
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v5.61c2.294-2.483,5.521-3.895,8.958-3.895c2.531,0,4.956,0.772,7.011,2.232
		c2.606,1.863,4.331,4.633,4.858,7.8c0.527,3.167-0.208,6.347-2.071,8.952c-0.742,1.039-1.641,1.949-2.672,2.704
		C17.994,23.965,17.892,23.998,17.785,23.998z"/>
		</SvgIcon>
	);
}

export function AssignClassIcon (props) {
	return (
		<SvgIcon {...props}>
<path d="M6.5,16C4.57,16,3,14.43,3,12.5c0-0.604,0.16-1.202,0.462-1.73c0.011-0.022,0.022-0.038,0.029-0.048
		C4.118,9.661,5.272,9,6.5,9c1.743,0,3.233,1.304,3.465,3.033c0.001,0.006,0.005,0.021,0.006,0.043C9.991,12.233,10,12.368,10,12.5
		C10,14.43,8.43,16,6.5,16z M4.099,11.808C4.033,12.033,4,12.264,4,12.5C4,13.878,5.121,15,6.5,15c1.281,0,2.339-0.968,2.483-2.21
		c-0.484,0.137-0.977,0.206-1.468,0.206C6.269,12.996,5.069,12.576,4.099,11.808z M4.575,10.909
		c0.813,0.701,1.848,1.084,2.938,1.084c0.472,0,0.935-0.073,1.38-0.218C8.576,10.735,7.603,10,6.5,10
		C5.754,10,5.049,10.336,4.575,10.909z"/>
	<path d="M13.858,16.144c-0.092,0-0.182-0.025-0.26-0.073c-0.114-0.069-0.194-0.179-0.225-0.309c-0.031-0.13-0.01-0.264,0.059-0.378
		c0.349-0.573,0.522-1.232,0.5-1.904l0-0.908c0-1.968,1.601-3.569,3.569-3.569s3.569,1.601,3.569,3.569v0.892
		c-0.023,0.686,0.15,1.347,0.5,1.921c0.07,0.114,0.091,0.248,0.059,0.378c-0.031,0.13-0.112,0.24-0.226,0.309
		c-0.078,0.048-0.168,0.073-0.26,0.073c-0.174,0-0.337-0.092-0.427-0.24c-0.254-0.416-0.436-0.872-0.541-1.346
		c-0.523,0.793-1.38,1.327-2.354,1.424c-0.108,0.011-0.216,0.016-0.322,0.016c-1.082,0-2.086-0.564-2.671-1.442
		c-0.105,0.475-0.287,0.931-0.542,1.349C14.193,16.054,14.033,16.144,13.858,16.144z M15.399,13.468c0.29,0.9,1.136,1.53,2.1,1.53
		c0.073,0,0.147-0.004,0.221-0.011c0.871-0.087,1.612-0.698,1.88-1.519c-0.79-0.171-1.508-0.54-2.101-1.081
		C16.907,12.927,16.19,13.297,15.399,13.468z M17.5,10.002c-1.405,0-2.551,1.134-2.569,2.535c0.854-0.121,1.624-0.538,2.19-1.193
		c0.093-0.107,0.231-0.169,0.378-0.169s0.285,0.062,0.378,0.169c0.566,0.655,1.336,1.072,2.191,1.193
		C20.051,11.136,18.905,10.002,17.5,10.002z"/>
	<path d="M1.5,22C1.224,22,1,21.776,1,21.5c0-0.369,0.037-0.739,0.111-1.101c0.294-1.439,1.131-2.678,2.356-3.488
		c0.904-0.598,1.949-0.914,3.021-0.914c0.371,0,0.745,0.038,1.112,0.113c0.131,0.027,0.243,0.103,0.317,0.214
		S8.017,16.569,7.99,16.7c-0.047,0.232-0.253,0.4-0.49,0.4c-0.033,0-0.066-0.003-0.099-0.01c-0.301-0.062-0.607-0.093-0.91-0.093
		c-0.878,0-1.732,0.259-2.472,0.747c-1.003,0.663-1.687,1.676-1.928,2.854C2.064,20.731,2.043,20.865,2.028,21H7.5
		C7.776,21,8,21.224,8,21.5S7.776,22,7.5,22H1.5z"/>
	<path d="M16.5,22c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5.472c-0.015-0.135-0.036-0.269-0.063-0.401
		c-0.24-1.178-0.925-2.191-1.928-2.854c-0.74-0.489-1.594-0.747-2.472-0.747c-0.303,0-0.609,0.031-0.909,0.092
		c-0.033,0.007-0.067,0.01-0.101,0.01c-0.236,0-0.442-0.168-0.489-0.4c-0.027-0.131-0.001-0.264,0.073-0.376
		c0.074-0.111,0.186-0.188,0.317-0.214c0.367-0.075,0.741-0.113,1.111-0.113c1.073,0,2.117,0.316,3.021,0.913
		c1.226,0.81,2.062,2.049,2.356,3.488C22.963,20.759,23,21.13,23,21.5c0,0.276-0.224,0.5-0.5,0.5H16.5z"/>
	<path d="M12,20.5c-1.516,0-2.75-1.234-2.75-2.75S10.484,15,12,15s2.75,1.234,2.75,2.75S13.516,20.5,12,20.5z M12,16
		c-0.965,0-1.75,0.785-1.75,1.75S11.035,19.5,12,19.5c0.965,0,1.75-0.785,1.75-1.75S12.965,16,12,16z"/>
	<path d="M9,24c-0.149,0-0.289-0.065-0.384-0.18c-0.095-0.115-0.135-0.264-0.108-0.41c0.308-1.686,1.776-2.91,3.49-2.91
		c1.704,0.015,3.172,1.237,3.493,2.906c0.028,0.148-0.011,0.298-0.106,0.413C15.291,23.934,15.15,24,15,24H9z M14.317,23
		c-0.415-0.889-1.325-1.491-2.32-1.5l0,0c-1.003,0-1.914,0.602-2.321,1.5H14.317z"/>
	<path d="M12,9C9.519,9,7.5,6.981,7.5,4.5S9.519,0,12,0c2.481,0,4.5,2.019,4.5,4.5S14.481,9,12,9z M12,1c-1.93,0-3.5,1.57-3.5,3.5
		S10.07,8,12,8s3.5-1.57,3.5-3.5S13.93,1,12,1z"/>
	<path d="M12,7c-0.276,0-0.5-0.224-0.5-0.5V5H10C9.724,5,9.5,4.776,9.5,4.5S9.724,4,10,4h1.5V2.5C11.5,2.224,11.724,2,12,2
		c0.276,0,0.5,0.224,0.5,0.5V4H14c0.276,0,0.5,0.224,0.5,0.5S14.276,5,14,5h-1.5v1.5C12.5,6.776,12.276,7,12,7z"/>
		</SvgIcon>
		);
	}


	export function LockIcon (props) {
		return (
			<SvgIcon {...props}>
				<path d="M5.5,24C4.121,24,3,22.878,3,21.5v-10C3,10.121,4.121,9,5.5,9H6V6c0-3.308,2.692-6,6-6c3.308,0,6,2.692,6,6v3h0.5
				c1.378,0,2.5,1.121,2.5,2.5v10c0,1.378-1.122,2.5-2.5,2.5H5.5z M5.5,10C4.673,10,4,10.673,4,11.5v10C4,22.327,4.673,23,5.5,23h13
				c0.827,0,1.5-0.673,1.5-1.5v-10c0-0.827-0.673-1.5-1.5-1.5H5.5z M17,9V6c0-2.757-2.243-5-5-5C9.243,1,7,3.243,7,6v3H17z"/>
				<path d="M12,18.25c-0.965,0-1.75-0.785-1.75-1.75s0.785-1.75,1.75-1.75c0.965,0,1.75,0.785,1.75,1.75S12.965,18.25,12,18.25z
					M12,15.75c-0.414,0-0.75,0.336-0.75,0.75s0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75S12.414,15.75,12,15.75z"/>
		</SvgIcon>
		);
	}

	export function CampaignIcon (props) {
		return (
			<SvgIcon {...props}>
				<path d="M7.614,23.397c-0.76,0-1.482-0.328-1.98-0.899c-0.072-0.082-0.14-0.171-0.202-0.264L4.765,21.23l-2.044,0.611
				c0.048,0.098,0.063,0.209,0.041,0.318c-0.026,0.131-0.102,0.244-0.214,0.317c-0.082,0.054-0.177,0.083-0.276,0.083
				c-0.168,0-0.324-0.083-0.417-0.224L1.52,21.833l-1.344-2.025c-0.074-0.111-0.1-0.244-0.074-0.375
				c0.026-0.131,0.102-0.244,0.214-0.317c0.082-0.055,0.178-0.083,0.276-0.083c0.146,0,0.282,0.063,0.376,0.171L9.592,8.737
				L9.461,8.54c-0.074-0.111-0.1-0.245-0.074-0.376C9.414,8.034,9.49,7.921,9.601,7.847c0.082-0.055,0.178-0.083,0.276-0.083
				c0.168,0,0.324,0.084,0.417,0.224l6.381,9.613c0.074,0.111,0.1,0.244,0.074,0.375c-0.027,0.131-0.103,0.244-0.214,0.317
				c-0.082,0.054-0.177,0.083-0.276,0.083c-0.168,0-0.324-0.083-0.417-0.224l-0.131-0.197l-5.72,1.711
				c0.493,1.051,0.241,2.305-0.653,3.085C8.86,23.168,8.248,23.397,7.614,23.397z M6.263,21.679c0.037,0.055,0.08,0.111,0.125,0.162
				c0.308,0.354,0.755,0.556,1.226,0.556c0.392,0,0.771-0.142,1.066-0.399c0.587-0.512,0.72-1.378,0.337-2.04l-3.25,0.972
				L6.263,21.679z M2.147,20.969l2.577-0.771c0.058-0.032,0.123-0.052,0.194-0.058l10.215-3.055l-4.964-7.477L1.55,20.069
				L2.147,20.969z"/>
			<path d="M13.095,22.219c-0.256,0-0.5-0.097-0.686-0.272l-0.361-0.342c-0.097-0.091-0.153-0.215-0.156-0.349
				c-0.004-0.134,0.045-0.261,0.136-0.358c0.094-0.1,0.226-0.157,0.363-0.157c0.128,0,0.25,0.049,0.343,0.136l0.361,0.341l1.899-1.8
				c0.093-0.088,0.215-0.137,0.344-0.137c0.038,0,0.076,0.004,0.114,0.013l2.546,0.6l0.748-2.507c0.048-0.163,0.174-0.289,0.337-0.337
				l2.508-0.747l-0.605-2.545c-0.039-0.165,0.007-0.337,0.124-0.46l1.8-1.9l-1.8-1.898c-0.116-0.123-0.163-0.294-0.124-0.458
				l0.596-2.535l-2.508-0.753c-0.163-0.049-0.289-0.175-0.337-0.337l-0.747-2.508l-2.546,0.6c-0.037,0.009-0.076,0.014-0.115,0.014
				c-0.128,0-0.25-0.049-0.344-0.137l-1.9-1.8l-1.899,1.8c-0.093,0.088-0.215,0.137-0.344,0.137c-0.039,0-0.078-0.005-0.116-0.014
				L8.183,2.906L7.447,5.418C7.398,5.58,7.273,5.705,7.111,5.754L4.603,6.501l0.6,2.546c0.039,0.164-0.007,0.336-0.123,0.458l-1.8,1.9
				l0.672,0.71c0.189,0.201,0.18,0.518-0.02,0.707c-0.094,0.088-0.215,0.136-0.343,0.136c-0.137,0-0.27-0.057-0.364-0.156
				l-0.671-0.711c-0.363-0.385-0.363-0.988,0-1.372l1.615-1.705L3.629,6.731C3.507,6.215,3.81,5.693,4.317,5.543l2.249-0.67
				l0.67-2.249c0.125-0.421,0.519-0.715,0.96-0.715c0.07,0,0.14,0.007,0.208,0.022l2.293,0.545L12.4,0.862
				c0.187-0.176,0.431-0.273,0.687-0.273c0.256,0,0.5,0.097,0.686,0.272l1.705,1.615l2.283-0.538c0.075-0.018,0.153-0.027,0.23-0.027
				c0.438,0,0.832,0.294,0.958,0.715l0.67,2.249l2.249,0.67c0.514,0.151,0.816,0.678,0.687,1.2l-0.533,2.266l1.615,1.704
				c0.363,0.385,0.364,0.988,0.001,1.373l-1.615,1.704l0.542,2.282c0.123,0.516-0.179,1.039-0.688,1.19l-2.248,0.669l-0.67,2.249
				C18.83,20.61,18.445,20.897,18,20.897l0,0c-0.078,0-0.156-0.009-0.233-0.028l-2.281-0.538l-1.704,1.614
				C13.596,22.122,13.352,22.219,13.095,22.219z"/>
		</SvgIcon>);
	}

	export function MarketingIcon (props) {
		return (
			<SvgIcon {...props}>
			<path d="M13.778,5.982c-0.089,0-0.177-0.024-0.254-0.07c-0.237-0.14-0.316-0.447-0.176-0.684C13.795,4.47,14.62,4,15.5,4
		C15.776,4,16,4.224,16,4.5S15.776,5,15.5,5c-0.528,0-1.023,0.282-1.292,0.737C14.119,5.888,13.954,5.982,13.778,5.982z"/>
	<path d="M10.222,5.982c-0.176,0-0.341-0.094-0.431-0.246C9.523,5.282,9.028,5,8.5,5C8.224,5,8,4.776,8,4.5S8.224,4,8.5,4
		c0.88,0,1.705,0.47,2.152,1.228c0.068,0.115,0.087,0.25,0.054,0.379c-0.033,0.129-0.115,0.238-0.23,0.306
		C10.399,5.958,10.311,5.982,10.222,5.982z"/>
	<path d="M12,24c-5.293,0-8.581-2.669-8.581-6.965c0-0.617,0.071-1.347,0.207-2.12C1.931,13.836,1,12.451,1,11V6.5
		C1,5.121,2.121,4,3.5,4c0.736,0,1.427,0.326,1.898,0.875c0.158-0.376,0.359-0.726,0.6-1.047V1.042c0-0.185,0.051-0.366,0.148-0.523
		c0.14-0.228,0.36-0.387,0.62-0.449C6.843,0.052,6.921,0.042,7,0.042c0.184,0,0.364,0.051,0.522,0.149
		c2.28,1.401,2.947,1.699,3.105,1.76C11.055,1.914,11.516,1.895,12,1.895c0.453,0,0.886,0.016,1.289,0.049l3.22-1.815
		C16.659,0.044,16.829,0,17,0c0.551,0,1,0.449,1,1v2.828c0.633,0.843,0.987,1.883,0.998,2.938c0,0.09-0.003,0.171-0.009,0.252
		c-0.055,0.87-0.367,1.682-0.906,2.361c0.011,0.012,0.022,0.024,0.032,0.036C21.176,10.519,23,12.386,23,14.417v3.5
		c0,0.408-0.101,0.814-0.293,1.174c-0.313,0.59-0.838,1.022-1.477,1.217c-0.239,0.073-0.485,0.11-0.733,0.11
		c-0.24,0-0.478-0.035-0.71-0.106C18.484,22.692,15.73,24,12,24z M4.661,14.764c-0.158,0.815-0.242,1.601-0.242,2.271
		C4.419,20.77,7.253,23,12,23s7.581-2.23,7.581-5.965c0-2.167-0.827-5.282-2.086-6.805c-0.093,2.234-1.561,4.214-3.668,4.959
		C13.941,15.443,14,15.719,14,16c0,1.103-0.897,2-2,2s-2-0.897-2-2c0-0.281,0.059-0.557,0.173-0.812
		c-2.107-0.745-3.575-2.725-3.668-4.96c-0.781,0.952-1.469,2.627-1.838,4.507h0C4.665,14.747,4.662,14.758,4.661,14.764z
		 M20.2,19.387c0.099,0.021,0.2,0.031,0.301,0.031c0.147,0,0.294-0.022,0.437-0.066c0.383-0.117,0.698-0.377,0.886-0.73
		C21.939,18.405,22,18.162,22,17.917v-3.5c0-1.297-1.041-2.543-2.884-3.483c0.954,1.902,1.465,4.458,1.465,6.101
		C20.581,17.873,20.453,18.662,20.2,19.387z M11.292,15.296C11.104,15.486,11,15.735,11,16c0,0.552,0.449,1,1,1c0.552,0,1-0.448,1-1
		s-0.448-1-1-1c-0.244,0-0.481,0.093-0.667,0.262C11.322,15.273,11.308,15.284,11.292,15.296z M12,14
		c0.409,0,0.799,0.122,1.134,0.355c1.75-0.457,3.056-1.935,3.314-3.707c-0.537,0.236-1.118,0.359-1.706,0.359
		c-0.092,0-0.184-0.003-0.276-0.009L9.498,11c-0.047,0.004-0.129,0.007-0.211,0.008c-0.6,0-1.192-0.125-1.735-0.362
		c0.258,1.773,1.565,3.252,3.316,3.709C11.202,14.122,11.591,14,12,14z M2,11c0,1.009,0.652,2.016,1.846,2.861
		c0.279-1.145,0.914-3.227,2.066-4.482C5.327,8.637,5.004,7.721,4.998,6.774c0-0.006,0.001-0.032,0.002-0.039L5,6.5
		C5,5.673,4.327,5,3.5,5S2,5.673,2,6.5v1h1.5C3.776,7.5,4,7.724,4,8S3.776,8.5,3.5,8.5H2V11z M7.364,9.397
		c0.557,0.4,1.211,0.611,1.894,0.611c0.083-0.001,0.145-0.003,0.207-0.007L14.498,10c0.086,0.005,0.157,0.007,0.228,0.007
		c0.702,0,1.368-0.216,1.928-0.624c0.008-0.007,0.016-0.013,0.021-0.017c0.077-0.057,0.148-0.112,0.217-0.172
		c0.654-0.576,1.044-1.371,1.1-2.24c0.004-0.062,0.006-0.125,0.007-0.187c-0.009-0.886-0.324-1.759-0.886-2.453
		C17.039,4.225,17,4.114,17,4V1L13.646,2.89c-0.074,0.042-0.159,0.065-0.244,0.065c-0.009,0-0.042-0.002-0.05-0.003
		C12.937,2.914,12.482,2.895,12,2.895c-0.479,0-0.935,0.02-1.354,0.058c-0.008,0.001-0.015,0.001-0.023,0.001
		c-0.01,0-0.019-0.001-0.029-0.002C10.313,2.941,9.77,2.746,6.998,1.043H6.997L6.998,4c0,0.114-0.04,0.226-0.112,0.315
		C6.332,4.998,6.017,5.86,6,6.742v0.029C6,6.78,5.999,6.79,5.998,6.8c0.01,0.85,0.354,1.66,0.97,2.267
		c0.077,0.076,0.166,0.154,0.271,0.237C7.287,9.329,7.329,9.36,7.364,9.397z"/>
		</SvgIcon>);
	}


	export function PreviewIcon (props) {
		return (
			<SvgIcon {...props}>
	<path d="M12,19c-6.519,0-11.676-6.416-11.892-6.689c-0.145-0.183-0.145-0.438,0-0.621C0.325,11.416,5.483,5,12,5
		c6.514,0,11.676,6.416,11.892,6.689c0.145,0.183,0.145,0.438,0,0.621C23.676,12.584,18.516,19,12,19z M1.155,12
		C2.304,13.315,6.763,18,12,18c5.239,0,9.697-4.684,10.845-6C21.697,10.684,17.236,6,12,6C6.76,6,2.303,10.684,1.155,12z"/>
	<path d="M12,16.5c-2.481,0-4.5-2.019-4.5-4.5c0-2.481,2.019-4.5,4.5-4.5c2.481,0,4.5,2.019,4.5,4.5
		C16.5,14.481,14.481,16.5,12,16.5z M12,8.5c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S13.93,8.5,12,8.5z"/>
	<path d="M12,14.5c-1.379,0-2.5-1.122-2.5-2.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,0.827,0.673,1.5,1.5,1.5
		s1.5-0.673,1.5-1.5s-0.673-1.5-1.5-1.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5c1.378,0,2.5,1.121,2.5,2.5
		C14.5,13.378,13.378,14.5,12,14.5z"/>
		</SvgIcon>);
	}

	export function SMSIcon (props) {
		return (
		<SvgIcon {...props}>
			<circle cx="9" cy="21.5" r="0.75"/>
	<path d="M4.5,24C3.121,24,2,22.878,2,21.5v-19C2,1.121,3.121,0,4.5,0h3C7.776,0,8,0.224,8,0.5S7.776,1,7.5,1h-3
		C3.673,1,3,1.673,3,2.5V19h12v-3.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6c0,1.378-1.122,2.5-2.5,2.5H4.5z M3,21.5
		C3,22.327,3.673,23,4.5,23h9c0.827,0,1.5-0.673,1.5-1.5V20H3V21.5z"/>
	<path d="M10.5,13C9.673,13,9,12.327,9,11.5v-10C9,0.673,9.673,0,10.5,0h10C21.327,0,22,0.673,22,1.5v10c0,0.827-0.673,1.5-1.5,1.5
		H10.5z M10.5,1C10.224,1,10,1.224,10,1.5v10c0,0.276,0.224,0.5,0.5,0.5h10c0.276,0,0.5-0.224,0.5-0.5v-10C21,1.224,20.776,1,20.5,1
		H10.5z"/>
	<path d="M12.5,4C12.224,4,12,3.776,12,3.5S12.224,3,12.5,3h6C18.776,3,19,3.224,19,3.5S18.776,4,18.5,4H12.5z"/>
	<path d="M12.5,7C12.224,7,12,6.776,12,6.5S12.224,6,12.5,6h6C18.776,6,19,6.224,19,6.5S18.776,7,18.5,7H12.5z"/>
	<path d="M12.5,10C12.224,10,12,9.776,12,9.5S12.224,9,12.5,9h2.25c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H12.5z"/>
		</SvgIcon>
		)
	}

	export function SMSSuccessIcon (props) {
		return (
		<SvgIcon {...props}>
			<circle cx="9" cy="21.5" r="0.75"/>
	<path d="M4.5,24C3.121,24,2,22.878,2,21.5v-19C2,1.121,3.121,0,4.5,0H10c0.276,0,0.5,0.224,0.5,0.5S10.276,1,10,1H4.5
		C3.673,1,3,1.673,3,2.5V19h12v-3.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6c0,1.378-1.122,2.5-2.5,2.5H4.5z M3,21.5
		C3,22.327,3.673,23,4.5,23h9c0.827,0,1.5-0.673,1.5-1.5V20H3V21.5z"/>
	<path d="M15.5,13C11.916,13,9,10.084,9,6.5S11.916,0,15.5,0C19.084,0,22,2.916,22,6.5S19.084,13,15.5,13z M15.5,1
		C12.467,1,10,3.467,10,6.5s2.467,5.5,5.5,5.5S21,9.533,21,6.5S18.533,1,15.5,1z"/>
	<path d="M14.671,9.429c-0.272,0-0.532-0.087-0.751-0.252c-0.045-0.034-0.089-0.072-0.13-0.113L12.29,7.563
		c-0.094-0.094-0.146-0.22-0.146-0.354s0.052-0.259,0.146-0.354s0.22-0.146,0.354-0.146l0,0c0.134,0,0.259,0.052,0.354,0.146
		l1.5,1.5c0.007,0.007,0.015,0.014,0.024,0.021c0.045,0.034,0.097,0.052,0.152,0.052c0.012,0,0.024-0.001,0.037-0.003
		c0.066-0.009,0.125-0.044,0.166-0.098l2.899-3.872c0.094-0.125,0.244-0.2,0.401-0.2c0.109,0,0.213,0.035,0.3,0.1
		c0.22,0.165,0.265,0.479,0.1,0.7l-2.9,3.873c-0.202,0.268-0.495,0.441-0.826,0.488C14.789,9.425,14.73,9.429,14.671,9.429z"/>
		</SvgIcon>
		)
	}

	export function SMSFailedIcon (props) {
		return (
		<SvgIcon {...props}>
		<circle cx="8.999" cy="21.5" r="0.75"/>
	<path d="M4.499,24c-1.379,0-2.5-1.122-2.5-2.5v-19c0-1.379,1.121-2.5,2.5-2.5h6c0.276,0,0.5,0.224,0.5,0.5S10.775,1,10.499,1h-6
		c-0.827,0-1.5,0.673-1.5,1.5V19h12v-3.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6c0,1.378-1.122,2.5-2.5,2.5H4.499z
		 M2.999,21.5c0,0.827,0.673,1.5,1.5,1.5h9c0.827,0,1.5-0.673,1.5-1.5V20h-12V21.5z"/>
	<circle cx="15.499" cy="9.75" r="0.75"/>
	<path d="M15.499,8c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3C15.999,7.776,15.775,8,15.499,8z"
		/>
	<path d="M10.443,13c-0.506,0-0.966-0.256-1.232-0.684c-0.263-0.427-0.282-0.968-0.049-1.411l5.056-9.637
		c0.137-0.257,0.344-0.464,0.598-0.599c0.21-0.112,0.445-0.171,0.681-0.171c0.143,0,0.285,0.021,0.423,0.063
		c0.37,0.112,0.674,0.361,0.856,0.702c0.001,0.002,5.059,9.644,5.059,9.644c0.233,0.443,0.214,0.982-0.048,1.408
		C21.521,12.744,21.061,13,20.556,13H10.443z M15.498,1.497c-0.074,0-0.145,0.018-0.211,0.053c-0.079,0.042-0.143,0.106-0.186,0.185
		l-5.054,9.634c-0.07,0.132-0.064,0.293,0.014,0.42c0.082,0.131,0.224,0.21,0.38,0.21h10.113c0.157,0,0.3-0.079,0.382-0.212
		c0.077-0.125,0.083-0.286,0.013-0.418l-5.056-9.638c-0.055-0.104-0.15-0.181-0.265-0.216C15.587,1.504,15.542,1.497,15.498,1.497z"
		/>
		</SvgIcon>
		)
	}

	export function DobuleRightArrowIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M12.155,19c-0.255,0-0.498-0.096-0.684-0.271c-0.402-0.377-0.423-1.011-0.046-1.414l5.02-5.347l-5.007-5.281
		c-0.176-0.186-0.274-0.43-0.274-0.686c0-0.267,0.103-0.518,0.292-0.708C11.644,5.105,11.895,5,12.162,5h4.759
		c0.273,0,0.538,0.114,0.726,0.312l5.66,5.968c0.365,0.386,0.365,0.989,0,1.374l-5.661,6.03C17.459,18.885,17.193,19,16.917,19
		H12.155z M17.334,11.452c0.274,0.289,0.274,0.742,0,1.031L12.155,18l4.762,0l5.662-6.031L16.922,6h-4.757L17.334,11.452z"/>
	<path d="M1.422,19c-0.255,0-0.498-0.096-0.684-0.271c-0.402-0.377-0.423-1.011-0.046-1.414l5.019-5.343L0.704,6.688
		C0.527,6.501,0.43,6.257,0.43,6c0-0.551,0.449-1,1-1h4.755C6.459,5,6.723,5.114,6.91,5.312l5.663,5.968
		c0.365,0.386,0.365,0.99,0.001,1.375l-5.661,6.03C6.724,18.885,6.458,19,6.183,19H1.422z M6.6,11.456
		c0.273,0.289,0.273,0.742,0,1.03L1.422,18l4.761,0l5.662-6.031L6.184,6L1.43,6L6.6,11.456z"/>
		</SvgIcon>
		)
	}

	export function RightArrowIcon (props) {
		return (
		<SvgIcon {...props}>

	<path d="M11.257,23.5c-0.144,0-0.281-0.062-0.375-0.169c-0.182-0.207-0.162-0.524,0.045-0.706L22.428,12.5H0.757
		c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h21.669L10.926,1.375c-0.1-0.088-0.16-0.21-0.169-0.343
		c-0.008-0.133,0.036-0.262,0.124-0.362c0.095-0.108,0.232-0.17,0.375-0.17c0.122,0,0.239,0.044,0.33,0.125L23.23,10.876
		c0.047,0.042,0.092,0.086,0.134,0.134c0.218,0.247,0.344,0.555,0.365,0.893c0.007,0.026,0.013,0.058,0.013,0.097
		c0,0.026-0.003,0.048-0.007,0.065c0,0.009,0,0.018,0,0.028c-0.025,0.402-0.205,0.768-0.505,1.033l-11.643,10.25
		C11.496,23.456,11.378,23.5,11.257,23.5z"/>
		</SvgIcon>
		)
	}

	export function AdjustRelationIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M2,24c-0.827,0-1.5-0.673-1.5-1.5C0.5,22.224,0.724,22,1,22s0.5,0.224,0.5,0.5S1.724,23,2,23s0.5,0.224,0.5,0.5&#10;&#9;&#9;S2.276,24,2,24z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M10,24c-0.276,0-0.5-0.224-0.5-0.5S9.724,23,10,23s0.5-0.224,0.5-0.5S10.724,22,11,22s0.5,0.224,0.5,0.5&#10;&#9;&#9;C11.5,23.327,10.827,24,10,24z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M1,21c-0.276,0-0.5-0.224-0.5-0.5v-1C0.5,19.224,0.724,19,1,19s0.5,0.224,0.5,0.5v1C1.5,20.776,1.276,21,1,21z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M4,24c-0.276,0-0.5-0.224-0.5-0.5S3.724,23,4,23h1c0.276,0,0.5,0.224,0.5,0.5S5.276,24,5,24H4z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M4,14c-0.276,0-0.5-0.224-0.5-0.5S3.724,13,4,13h1c0.276,0,0.5,0.224,0.5,0.5S5.276,14,5,14H4z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M7,24c-0.276,0-0.5-0.224-0.5-0.5S6.724,23,7,23h1c0.276,0,0.5,0.224,0.5,0.5S8.276,24,8,24H7z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M7,14c-0.276,0-0.5-0.224-0.5-0.5S6.724,13,7,13h1c0.276,0,0.5,0.224,0.5,0.5S8.276,14,8,14H7z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M11,15c-0.276,0-0.5-0.224-0.5-0.5S10.276,14,10,14s-0.5-0.224-0.5-0.5S9.724,13,10,13c0.827,0,1.5,0.673,1.5,1.5&#10;&#9;&#9;C11.5,14.776,11.276,15,11,15z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M11,18c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C11.5,17.776,11.276,18,11,18z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M1,15c-0.276,0-0.5-0.224-0.5-0.5C0.5,13.673,1.173,13,2,13c0.276,0,0.5,0.224,0.5,0.5S2.276,14,2,14s-0.5,0.224-0.5,0.5&#10;&#9;&#9;S1.276,15,1,15z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M1,18c-0.276,0-0.5-0.224-0.5-0.5v-1C0.5,16.224,0.724,16,1,16s0.5,0.224,0.5,0.5v1C1.5,17.776,1.276,18,1,18z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M11,21c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C11.5,20.776,11.276,21,11,21z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M2,11c-0.827,0-1.5-0.673-1.5-1.5v-8C0.5,0.673,1.173,0,2,0h8c0.827,0,1.5,0.673,1.5,1.5v8c0,0.827-0.673,1.5-1.5,1.5H2z&#10;&#9;&#9; M2,1C1.724,1,1.5,1.224,1.5,1.5v8C1.5,9.776,1.724,10,2,10h8c0.276,0,0.5-0.224,0.5-0.5v-8C10.5,1.224,10.276,1,10,1H2z" transform="matrix(1, 0, 0, 1, 11.29438489662645, -1.4210854715202004e-14)"/>
	<path d="M19,19.483c-0.066,0-0.13-0.013-0.191-0.039c-0.062-0.025-0.117-0.062-0.163-0.109l-3.999-3.999&#10;&#9;&#9;c-0.094-0.094-0.146-0.22-0.146-0.354s0.052-0.259,0.146-0.354s0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146l3.146,3.146V3.5&#10;&#9;&#9;C18.5,3.224,18.724,3,19,3s0.5,0.224,0.5,0.5v14.276l3.146-3.146c0.094-0.094,0.22-0.146,0.354-0.146s0.259,0.052,0.354,0.146&#10;&#9;&#9;s0.146,0.22,0.146,0.354s-0.052,0.259-0.146,0.354l-4,4c-0.045,0.046-0.101,0.083-0.163,0.109C19.13,19.47,19.066,19.483,19,19.483&#10;&#9;&#9;z" transform="matrix(1, 0, 0, 1, -13.15150525965226, 0)"/>
		</SvgIcon>
		)
	}

	export function StockInIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M17.5,24c-3.584,0-6.5-2.916-6.5-6.5s2.916-6.5,6.5-6.5s6.5,2.916,6.5,6.5S21.084,24,17.5,24z M17.5,12
		c-3.033,0-5.5,2.467-5.5,5.5s2.467,5.5,5.5,5.5s5.5-2.467,5.5-5.5S20.533,12,17.5,12z"/>
	<path d="M17.5,21c-0.276,0-0.5-0.224-0.5-0.5V18h-2.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H17v-2.5
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V17h2.5c0.276,0,0.5,0.224,0.5,0.5S20.776,18,20.5,18H18v2.5
		C18,20.776,17.776,21,17.5,21z"/>
	<path d="M1.5,16C0.673,16,0,15.327,0,14.5V4.914c0-0.207,0.044-0.411,0.13-0.605C0.133,4.3,0.146,4.275,0.151,4.267
		c0.071-0.152,0.17-0.295,0.288-0.413l3.414-3.414C4.137,0.156,4.513,0,4.914,0h5.172c0.401,0,0.777,0.156,1.061,0.439l3.414,3.414
		c0.118,0.118,0.217,0.261,0.294,0.426c0.003,0.002,0.011,0.018,0.018,0.036C14.957,4.504,15,4.707,15,4.914V8.5
		C15,8.776,14.776,9,14.5,9S14,8.776,14,8.5V5H1v9.5C1,14.776,1.224,15,1.5,15h7C8.776,15,9,15.224,9,15.5S8.776,16,8.5,16H1.5z
		 M13.293,4l-2.854-2.854C10.345,1.052,10.219,1,10.086,1H8v3H13.293z M7,4V1H4.914C4.781,1,4.655,1.052,4.561,1.146L1.707,4H7z"/>
		 </SvgIcon>
		 )
	 }

	 export function StockOutIcon (props) {
		return (
		<SvgIcon {...props}>
			<path d="M17.5,24c-3.584,0-6.5-2.916-6.5-6.5s2.916-6.5,6.5-6.5s6.5,2.916,6.5,6.5S21.084,24,17.5,24z M17.5,12
				c-3.033,0-5.5,2.467-5.5,5.5s2.467,5.5,5.5,5.5s5.5-2.467,5.5-5.5S20.533,12,17.5,12z"/>
			<path d="M14.5,18c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5S20.776,18,20.5,18H14.5z"/>
			<path d="M1.5,16C0.673,16,0,15.327,0,14.5V4.914c0-0.207,0.044-0.411,0.13-0.605C0.133,4.3,0.146,4.275,0.151,4.267
				c0.071-0.152,0.17-0.295,0.288-0.413l3.414-3.414C4.137,0.156,4.513,0,4.914,0h5.172c0.401,0,0.777,0.156,1.061,0.439l3.414,3.414
			c0.118,0.118,0.217,0.261,0.294,0.426c0.003,0.002,0.011,0.018,0.018,0.036C14.957,4.504,15,4.707,15,4.914V8.5
			C15,8.776,14.776,9,14.5,9S14,8.776,14,8.5V5H1v9.5C1,14.776,1.224,15,1.5,15h7C8.776,15,9,15.224,9,15.5S8.776,16,8.5,16H1.5z
			M13.293,4l-2.854-2.854C10.345,1.052,10.219,1,10.086,1H8v3H13.293z M7,4V1H4.914C4.781,1,4.655,1.052,4.561,1.146L1.707,4H7z"/>
 		</SvgIcon>
		 )
	 }

	 export function GreatIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M5.649,24c-0.143,0-0.279-0.061-0.374-0.168c-0.183-0.207-0.163-0.524,0.043-0.706L17.893,12L5.318,0.875
		C5.111,0.692,5.092,0.375,5.274,0.169C5.37,0.062,5.506,0,5.649,0c0.122,0,0.24,0.045,0.331,0.125l12.576,11.126
		c0.029,0.026,0.056,0.052,0.081,0.08c0.369,0.416,0.332,1.051-0.08,1.416L5.98,23.875C5.888,23.956,5.771,24,5.649,24z"/>
			</SvgIcon>
		 )
	 }

	 export function LessIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M18.401,24c-0.122,0-0.24-0.044-0.331-0.125L5.495,12.748c-0.03-0.027-0.058-0.055-0.084-0.084
		c-0.366-0.413-0.329-1.047,0.083-1.412L18.069,0.125C18.161,0.044,18.279,0,18.401,0c0.143,0,0.28,0.062,0.375,0.169
		c0.182,0.206,0.163,0.523-0.043,0.705L6.157,12l12.575,11.125c0.206,0.183,0.226,0.5,0.043,0.706C18.68,23.939,18.544,24,18.401,24
		z"/>
		</SvgIcon>
		 )
	 }

	 export function TransferStockIcon (props) {
		return (
		<SvgIcon {...props}>
		<path d="M17.638,24.005c-0.664,0-1.322-0.109-1.955-0.325c-1.689-0.576-3.034-1.893-3.683-3.526v1.844c0,0.276-0.224,0.5-0.5,0.5
			s-0.5-0.224-0.5-0.5v-3.5c0-0.276,0.224-0.5,0.5-0.5H15c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-2.313
			c0.373,1.741,1.623,3.157,3.319,3.735c0.529,0.18,1.078,0.272,1.633,0.272c2.173,0,4.104-1.381,4.805-3.435
			c0.069-0.203,0.259-0.339,0.473-0.339c0.055,0,0.11,0.009,0.162,0.028c0.125,0.042,0.227,0.132,0.286,0.252
			c0.059,0.12,0.068,0.256,0.024,0.382C22.552,22.352,20.24,24.005,17.638,24.005z"/>
		<path d="M20,16.998c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.312c-0.373-1.741-1.622-3.157-3.318-3.735
			c-0.533-0.182-1.086-0.274-1.642-0.274c-0.776,0-1.527,0.176-2.233,0.522c-1.216,0.597-2.126,1.633-2.563,2.915
			c-0.069,0.203-0.259,0.339-0.473,0.339c-0.055,0-0.11-0.009-0.163-0.027c-0.125-0.042-0.227-0.132-0.286-0.252
			s-0.068-0.255-0.025-0.382c0.524-1.535,1.614-2.775,3.069-3.49c0.845-0.415,1.744-0.626,2.673-0.626
			c0.666,0,1.327,0.11,1.965,0.328c1.688,0.576,3.034,1.893,3.683,3.526v-1.844c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3.5
			c0,0.276-0.224,0.5-0.5,0.5H20z"/>
		<path d="M1.5,15.998c-0.827,0-1.5-0.673-1.5-1.5V4.912c0-0.207,0.044-0.411,0.13-0.605c0.004-0.009,0.017-0.034,0.022-0.042
			c0.071-0.152,0.17-0.295,0.288-0.413l3.414-3.414c0.283-0.283,0.66-0.439,1.06-0.44h5.172c0.401,0,0.777,0.156,1.061,0.44
			l3.414,3.414c0.118,0.118,0.217,0.261,0.294,0.425c0.004,0.005,0.012,0.02,0.019,0.037C14.957,4.502,15,4.706,15,4.912v3.586
			c0,0.276-0.224,0.5-0.5,0.5S14,8.774,14,8.498v-3.5H1v9.5c0,0.276,0.224,0.5,0.5,0.5h7c0.276,0,0.5,0.224,0.5,0.5
			s-0.224,0.5-0.5,0.5H1.5z M13.293,3.998l-2.854-2.853c-0.094-0.094-0.22-0.146-0.354-0.147H8v3H13.293z M7,3.998v-3H4.914
			c-0.134,0-0.259,0.052-0.353,0.146L1.707,3.998H7z"/>
			</SvgIcon>
		)
	}

	export function CalendarClassIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M1.5,24.001c-0.827,0-1.5-0.673-1.5-1.5v-19c0-0.827,0.673-1.5,1.5-1.5H5v-1.5c0-0.276,0.224-0.5,0.5-0.5S6,0.225,6,0.501
		v1.5h12v-1.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1.5h3.5c0.827,0,1.5,0.673,1.5,1.5v19c0,0.827-0.673,1.5-1.5,1.5H1.5z
		 M1,22.501c0,0.276,0.224,0.5,0.5,0.5h21c0.276,0,0.5-0.224,0.5-0.5v-14.5H1V22.501z M23,7.001v-3.5c0-0.276-0.224-0.5-0.5-0.5H19
		v2.5c0,0.276-0.224,0.5-0.5,0.5S18,5.777,18,5.501v-2.5H6v2.5c0,0.276-0.224,0.5-0.5,0.5S5,5.777,5,5.501v-2.5H1.5
		c-0.276,0-0.5,0.224-0.5,0.5v3.5H23z"/>
	<path d="M12,20c-0.179,0-0.355-0.032-0.524-0.095l-3.025-1.19C7.874,18.488,7.5,17.941,7.5,17.321v-2.153l-1-0.417v2.75
		c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-3.5c0-0.058,0.011-0.115,0.034-0.175c0.004-0.01,0.019-0.042,0.024-0.052
		c0.017-0.034,0.041-0.069,0.068-0.101c0.013-0.016,0.026-0.029,0.039-0.041c0.032-0.031,0.072-0.058,0.116-0.079
		c0.013-0.007,0.026-0.013,0.039-0.018l5.987-2.495c0.061-0.026,0.126-0.038,0.192-0.038s0.131,0.013,0.192,0.038l6,2.5
		c0.187,0.078,0.308,0.259,0.308,0.461s-0.121,0.384-0.308,0.461L16.5,15.168v2.152c0,0.621-0.374,1.168-0.951,1.395l-3.015,1.187
		C12.359,19.968,12.18,20,12,20z M8.5,17.32c0,0.207,0.125,0.389,0.317,0.465l3.017,1.187C11.884,18.99,11.941,19,12,19
		c0.06,0,0.12-0.01,0.176-0.031l3.007-1.184c0.193-0.076,0.317-0.258,0.317-0.465v-1.735l-3.308,1.378
		c-0.061,0.025-0.125,0.039-0.192,0.039s-0.132-0.013-0.193-0.039L8.5,15.584V17.32z M12,15.959l4.7-1.958L12,12.043l-4.7,1.958
		L12,15.959z"/>
		</SvgIcon>
		)
	}

	export function ScheduleClassBatchIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M1.5,24.001c-0.827,0-1.5-0.673-1.5-1.5v-19c0-0.827,0.673-1.5,1.5-1.5H5v-1.5c0-0.276,0.224-0.5,0.5-0.5S6,0.225,6,0.501
		v1.5h12v-1.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1.5h3.5c0.827,0,1.5,0.673,1.5,1.5v19c0,0.827-0.673,1.5-1.5,1.5H1.5z
		 M1,22.501c0,0.276,0.224,0.5,0.5,0.5h21c0.276,0,0.5-0.224,0.5-0.5v-14.5H1V22.501z M23,7.001v-3.5c0-0.276-0.224-0.5-0.5-0.5H19
		v2.5c0,0.276-0.224,0.5-0.5,0.5S18,5.777,18,5.501v-2.5H6v2.5c0,0.276-0.224,0.5-0.5,0.5S5,5.777,5,5.501v-2.5H1.5
		c-0.276,0-0.5,0.224-0.5,0.5v3.5H23z"/>
	<path d="M9.755,20.001c-0.203,0-0.398-0.062-0.564-0.178l-2.879-2.021c-0.183-0.146-0.285-0.328-0.307-0.535
		c-0.022-0.204,0.037-0.404,0.166-0.563c0.029-0.04,0.078-0.089,0.131-0.129l1.18-1.042c0.148-0.121,0.323-0.184,0.501-0.184
		c0.087,0,0.173,0.015,0.255,0.044c0.03,0.01,0.06,0.025,0.088,0.042l1.236,0.734l1.032-0.72l-2.993-1.85
		c-0.023-0.014-0.044-0.029-0.063-0.046c-0.346-0.297-0.387-0.822-0.092-1.17c0.038-0.045,0.081-0.085,0.128-0.122l0.898-0.663
		c0.144-0.107,0.314-0.163,0.493-0.163c0.099,0,0.197,0.018,0.291,0.052l4.776,1.774l2.106-1.438
		c0.298-0.205,0.649-0.313,1.013-0.313c0.132,0,0.264,0.015,0.392,0.043c0.634,0.148,1.138,0.601,1.352,1.212
		C18.964,12.958,19,13.163,19,13.371c0.012,0.642-0.321,1.258-0.87,1.604l-7.856,4.878C10.118,19.949,9.939,20.001,9.755,20.001z
		 M9.765,19.004l7.834-4.877c0.252-0.159,0.406-0.446,0.401-0.746c0-0.102-0.016-0.192-0.047-0.281
		c-0.101-0.29-0.337-0.502-0.632-0.571c-0.054-0.012-0.111-0.018-0.169-0.018c-0.161,0-0.316,0.048-0.448,0.139l-2.321,1.584
		c-0.083,0.057-0.181,0.087-0.282,0.087c-0.06,0-0.119-0.011-0.175-0.032l-4.932-1.832l-0.61,0.45l3.384,2.092
		c0.144,0.089,0.232,0.243,0.237,0.412c0.005,0.168-0.075,0.327-0.213,0.424l-1.92,1.34c-0.085,0.059-0.184,0.09-0.286,0.09
		c-0.09,0-0.178-0.024-0.256-0.07L8.011,16.41l-0.859,0.758L9.765,19.004z"/>
		</SvgIcon>
		)
	}

	export function ScheduleClassIcon (props) {
		return (
		<SvgIcon {...props}>
<path d="M1.5,24.001c-0.827,0-1.5-0.673-1.5-1.5v-19c0-0.827,0.673-1.5,1.5-1.5H5v-1.5c0-0.276,0.224-0.5,0.5-0.5S6,0.225,6,0.501
		v1.5h12v-1.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1.5h3.5c0.827,0,1.5,0.673,1.5,1.5v19c0,0.827-0.673,1.5-1.5,1.5H1.5z
		 M1,22.501c0,0.276,0.224,0.5,0.5,0.5h21c0.276,0,0.5-0.224,0.5-0.5v-14.5H1V22.501z M23,7.001v-3.5c0-0.276-0.224-0.5-0.5-0.5H19
		v2.5c0,0.276-0.224,0.5-0.5,0.5S18,5.777,18,5.501v-2.5H6v2.5c0,0.276-0.224,0.5-0.5,0.5S5,5.777,5,5.501v-2.5H1.5
		c-0.276,0-0.5,0.224-0.5,0.5v3.5H23z"/>
	<path d="M7,21.5c-0.134,0-0.259-0.052-0.354-0.146c-0.119-0.119-0.17-0.288-0.137-0.452l0.625-3.125
		c0.019-0.097,0.067-0.185,0.137-0.255l6.359-6.359c0.427-0.427,0.995-0.662,1.6-0.662s1.173,0.235,1.599,0.661
		c0.43,0.43,0.668,1,0.669,1.606c0.001,0.606-0.233,1.175-0.661,1.602l-6.359,6.359c-0.07,0.071-0.159,0.118-0.256,0.137
		L7.098,21.49C7.065,21.497,7.032,21.5,7,21.5z M7.637,20.363l2.241-0.447l6.252-6.252c0.492-0.493,0.492-1.294,0-1.787
		c-0.242-0.242-0.563-0.375-0.903-0.375c-0.337,0-0.653,0.131-0.89,0.368l-6.252,6.252L7.637,20.363z"/>
		</SvgIcon>
		)
	}


	export function InfoIcon (props) {
		return (
		<SvgIcon {...props}>

	<path d="M12,24C5.383,24,0,18.617,0,12C0,5.383,5.383,0,12,0c6.617,0,12,5.383,12,12C24,18.617,18.617,24,12,24z M12,1
		C5.935,1,1,5.935,1,12s4.935,11,11,11s11-4.935,11-11S18.065,1,12,1z"/>
			<path d="M12.004,13.975c-0.276,0-0.5-0.224-0.5-0.5V6.494c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v6.981
		C12.504,13.751,12.28,13.975,12.004,13.975z"/>
			<path d="M12,16.725c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75c0.414,0,0.75,0.336,0.75,0.75S12.414,16.725,12,16.725z"/>

	</SvgIcon>
		)
	}

	export function LessonsListIcon (props) {
		return (
		<SvgIcon {...props}>

	<path d="M4,24c-0.827,0-1.5-0.673-1.5-1.5v-16C2.5,5.673,3.173,5,4,5h9c0.276,0,0.5,0.224,0.5,0.5S13.276,6,13,6H4
	C3.724,6,3.5,6.224,3.5,6.5v16C3.5,22.776,3.724,23,4,23h11.025c0.156,0,0.313-0.007,0.475-0.022V19.5c0-0.827,0.673-1.5,1.5-1.5
	h3.478c0.015-0.162,0.022-0.319,0.022-0.475V6.5C20.5,6.224,20.276,6,20,6h-1.5C18.224,6,18,5.776,18,5.5S18.224,5,18.5,5H20
	c0.827,0,1.5,0.673,1.5,1.5v11.025c0,3.57-2.905,6.475-6.475,6.475H4z M17,19c-0.276,0-0.5,0.224-0.5,0.5v3.297
	c1.836-0.514,3.282-1.961,3.797-3.797H17z"/>
<path d="M12,10c-0.134,0-0.259-0.052-0.354-0.146S11.5,9.634,11.5,9.5s0.052-0.259,0.146-0.354l6.132-6.132
	C17.596,2.707,17.5,2.36,17.5,2c0-1.103,0.897-2,2-2s2,0.897,2,2s-0.897,2-2,2c-0.36,0-0.707-0.096-1.014-0.279l-6.132,6.132
	C12.259,9.948,12.134,10,12,10z M19.5,1c-0.552,0-1,0.449-1,1s0.448,1,1,1s1-0.449,1-1S20.052,1,19.5,1z"/>
<path d="M6,13c-0.276,0-0.5-0.224-0.5-0.5C5.5,12.224,5.724,12,6,12h11c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5H6z"/>
<path d="M6,16c-0.276,0-0.5-0.224-0.5-0.5S5.724,15,6,15h11c0.276,0,0.5,0.224,0.5,0.5S17.276,16,17,16H6z"/>
<path d="M6,19c-0.276,0-0.5-0.224-0.5-0.5S5.724,18,6,18h5.5c0.276,0,0.5,0.224,0.5,0.5S11.776,19,11.5,19H6z"/>
	</SvgIcon>
	)
}

export function CloneIcon (props) {
	return (
	<SvgIcon {...props}>

<path d="M1.5,22C0.673,22,0,21.327,0,20.5v-16C0,3.673,0.673,3,1.5,3h1.586C3.295,2.41,3.86,2,4.5,2h2c0,0,0-0.001,0-0.001
		c0-1.103,0.897-2,2-2s2,0.897,2,2c0,0,0,0.001,0,0.001h2c0.64,0,1.205,0.41,1.414,1H15.5C16.327,3,17,3.673,17,4.5V8
		c0,0.276-0.224,0.5-0.5,0.5S16,8.276,16,8V4.5C16,4.224,15.776,4,15.5,4H14v0.5C14,5.327,13.327,6,12.5,6h-8C3.673,6,3,5.327,3,4.5
		V4H1.5C1.224,4,1,4.224,1,4.5v16C1,20.776,1.224,21,1.5,21h8c0.276,0,0.5,0.224,0.5,0.5S9.776,22,9.5,22H1.5z M4.5,3
		C4.224,3,4,3.224,4,3.5v1C4,4.776,4.224,5,4.5,5h8C12.776,5,13,4.776,13,4.5v-1C13,3.224,12.776,3,12.5,3h-2.271
		C9.873,3.612,9.211,3.999,8.5,3.999S7.127,3.612,6.771,3H4.5z M8.5,0.999c-0.551,0-1,0.449-1,1s0.449,1,1,1s1-0.449,1-1
		S9.051,0.999,8.5,0.999z"/>
	<path d="M12.5,24c-0.827,0-1.5-0.673-1.5-1.5V11c0-0.827,0.673-1.5,1.5-1.5h7.086c0.401,0,0.777,0.156,1.061,0.439l1.914,1.914
		C22.84,12.133,23,12.519,23,12.914V22.5c0,0.827-0.673,1.5-1.5,1.5H12.5z M12.5,10.5c-0.276,0-0.5,0.224-0.5,0.5v11.5
		c0,0.276,0.224,0.5,0.5,0.5h9c0.276,0,0.5-0.224,0.5-0.5v-9.586c0-0.132-0.053-0.26-0.146-0.353l-1.914-1.914
		c-0.094-0.094-0.22-0.146-0.354-0.146H12.5z"/>
	<path d="M14.5,14.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H14.5z"/>
	<path d="M14.5,17.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H14.5z"/>
	</SvgIcon>
	)
}


export function PauseLearningIcon (props) {
	return (
	<SvgIcon {...props}>

<path d="M12,23.999c-6.617,0-12-5.383-12-12c0-6.617,5.383-12,12-12c6.617,0,12,5.383,12,12C24,18.616,18.617,23.999,12,23.999z
		 M12,0.999c-6.065,0-11,4.935-11,11s4.935,11,11,11s11-4.935,11-11S18.065,0.999,12,0.999z"/>
	<path d="M8.5,17.999c-0.827,0-1.5-0.673-1.5-1.5v-8c0-0.827,0.673-1.5,1.5-1.5h1c0.827,0,1.5,0.673,1.5,1.5v8
		c0,0.827-0.673,1.5-1.5,1.5H8.5z M8.5,7.999c-0.276,0-0.5,0.224-0.5,0.5v8c0,0.276,0.224,0.5,0.5,0.5h1c0.276,0,0.5-0.224,0.5-0.5
		v-8c0-0.276-0.224-0.5-0.5-0.5H8.5z"/>
	<path d="M14.5,17.999c-0.827,0-1.5-0.673-1.5-1.5v-8c0-0.827,0.673-1.5,1.5-1.5h1c0.827,0,1.5,0.673,1.5,1.5v8
		c0,0.827-0.673,1.5-1.5,1.5H14.5z M14.5,7.999c-0.276,0-0.5,0.224-0.5,0.5v8c0,0.276,0.224,0.5,0.5,0.5h1
		c0.276,0,0.5-0.224,0.5-0.5v-8c0-0.276-0.224-0.5-0.5-0.5H14.5z"/>
		</SvgIcon>
		)
	}
	

	export function ResumeLearningIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M12.04,23.999c-6.617,0-12-5.383-12-12c0-6.617,5.383-12,12-12c6.617,0,12,5.383,12,12
		C24.04,18.616,18.657,23.999,12.04,23.999z M12.04,0.999c-6.065,0-11,4.935-11,11s4.935,11,11,11s11-4.935,11-11
		S18.105,0.999,12.04,0.999z"/>
	<path d="M9.103,17.16c-0.057,0-0.115-0.005-0.172-0.014c-0.561-0.091-0.957-0.618-0.891-1.18V9.034
		C8.009,8.771,8.079,8.498,8.234,8.283c0.167-0.232,0.414-0.385,0.696-0.43c0.058-0.009,0.116-0.014,0.173-0.014
		c0.209,0,0.411,0.061,0.585,0.175l7.182,3.592c0.561,0.281,0.645,0.681,0.645,0.894s-0.084,0.613-0.644,0.894l-7.182,3.591
		C9.513,17.1,9.312,17.16,9.103,17.16z M9.09,8.84C9.072,8.843,9.057,8.852,9.045,8.868c-0.01,0.014-0.015,0.034-0.012,0.051
		C9.038,8.945,9.04,8.973,9.04,9v7c0,0.026-0.002,0.053-0.006,0.079c-0.004,0.026,0.006,0.044,0.012,0.053
		c0.008,0.01,0.022,0.024,0.045,0.028c0.003,0,0.006,0,0.009,0c0.021,0,0.032-0.004,0.043-0.012
		c0.021-0.015,0.043-0.029,0.067-0.041l7.214-3.607c0,0-7.212-3.607-7.212-3.607C9.187,8.881,9.164,8.867,9.142,8.852
		C9.13,8.843,9.116,8.839,9.102,8.839L9.09,8.84z"/>
			</SvgIcon>
			)
		}

export function HomeworkIcon (props) {
			return (
			<SvgIcon {...props}>
				<path d="M10.949,20.714c-0.148,0-0.287-0.065-0.382-0.178c-1.064-1.263-4.013-2.671-9.099-2.836C0.66,17.688,0,17.015,0,16.201
				V2.011C0,1.61,0.156,1.234,0.439,0.95C0.722,0.667,1.099,0.511,1.5,0.511l0.037,0c3.963,0.132,8.47,1.241,9.986,3.422
				c1.414-1.861,5.391-3.247,9.936-3.421l0.045-0.001c0.391,0,0.749,0.144,1.033,0.416c0.29,0.277,0.454,0.65,0.463,1.051
				c0,0.002,0,13.014,0,13.014c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V2.012c-0.003-0.143-0.055-0.267-0.154-0.361
				c-0.094-0.09-0.213-0.139-0.336-0.139C15.261,1.75,12,4.105,12,5.3v11.193c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V5.301
				c0-1.212-2.786-3.567-9.499-3.79c-0.134,0-0.259,0.051-0.355,0.147C1.052,1.752,1,1.877,1,2.011v14.19
				c0,0.271,0.221,0.496,0.492,0.5c5.421,0.175,8.637,1.763,9.839,3.191c0.086,0.102,0.127,0.232,0.116,0.365
				c-0.011,0.133-0.074,0.254-0.176,0.34C11.181,20.672,11.067,20.714,10.949,20.714z"/>
			<path d="M9,6.493c-0.057,0-0.114-0.01-0.168-0.029C7.087,5.841,5.269,5.418,3.429,5.208c-0.274-0.031-0.471-0.28-0.44-0.554
				c0.029-0.253,0.243-0.443,0.498-0.443c0.018,0,0.036,0.001,0.055,0.003c1.917,0.219,3.81,0.659,5.627,1.308
				c0.26,0.093,0.395,0.38,0.303,0.639C9.4,6.36,9.211,6.493,9,6.493z"/>
			<path d="M14.5,10.993c-0.211,0-0.4-0.134-0.471-0.332c-0.045-0.125-0.038-0.261,0.019-0.382c0.057-0.121,0.158-0.212,0.284-0.257
				c1.817-0.649,3.71-1.089,5.626-1.308c0.019-0.002,0.038-0.003,0.057-0.003c0.254,0,0.468,0.191,0.496,0.443
				c0.015,0.132-0.022,0.263-0.105,0.368c-0.083,0.104-0.202,0.17-0.334,0.186c-1.841,0.21-3.659,0.633-5.404,1.256
				C14.614,10.983,14.557,10.993,14.5,10.993z"/>
			<path d="M9,10.993c-0.057,0-0.114-0.01-0.168-0.029c-1.745-0.623-3.563-1.046-5.403-1.256c-0.274-0.031-0.471-0.28-0.44-0.554
				c0.029-0.253,0.243-0.443,0.497-0.443c0.019,0,0.038,0.001,0.057,0.003c1.916,0.219,3.808,0.659,5.625,1.308
				c0.26,0.093,0.395,0.38,0.303,0.639C9.4,10.86,9.211,10.993,9,10.993z"/>
			<path d="M9,14.993c-0.058,0-0.115-0.01-0.169-0.029c-1.76-0.629-3.595-1.055-5.453-1.267c-0.133-0.015-0.252-0.081-0.335-0.185
				s-0.121-0.235-0.105-0.368C2.967,12.89,3.18,12.7,3.433,12.7c0.02,0,0.04,0.001,0.061,0.004c1.931,0.22,3.841,0.664,5.674,1.319
				c0.126,0.045,0.226,0.136,0.284,0.257c0.057,0.121,0.064,0.256,0.019,0.382C9.4,14.859,9.211,14.993,9,14.993z"/>
			<path d="M14.5,6.493c-0.211,0-0.4-0.134-0.471-0.332c-0.045-0.125-0.038-0.261,0.019-0.382c0.057-0.121,0.158-0.212,0.283-0.257
				c1.834-0.655,3.744-1.099,5.679-1.319C20.029,4.201,20.05,4.2,20.07,4.2c0.253,0,0.465,0.191,0.494,0.444
				c0.015,0.133-0.022,0.263-0.105,0.368c-0.083,0.104-0.202,0.17-0.335,0.186c-1.858,0.211-3.694,0.637-5.455,1.267
				C14.614,6.483,14.557,6.493,14.5,6.493z"/>
			<path d="M23.5,23.493c-0.276,0-0.5-0.224-0.5-0.5v-1.1c0.007-1.284-0.991-2.338-2.271-2.401l-2.229,0c-0.276,0-0.5-0.224-0.5-0.5
				v-4.75c0-0.414-0.336-0.75-0.75-0.75s-0.75,0.336-0.75,0.75v7.25c0,0.219-0.14,0.41-0.348,0.476
				c-0.048,0.016-0.099,0.024-0.152,0.024c-0.162,0-0.314-0.079-0.407-0.21l-1.521-2.139c-0.016-0.022-0.028-0.043-0.039-0.065
				c-0.094-0.186-0.282-0.302-0.492-0.302c-0.086,0-0.169,0.02-0.247,0.059c-0.271,0.137-0.38,0.468-0.244,0.739l1.413,2.686
				c0.062,0.118,0.075,0.254,0.035,0.381c-0.04,0.127-0.126,0.232-0.245,0.294c-0.072,0.038-0.152,0.058-0.233,0.058
				c-0.187,0-0.356-0.102-0.443-0.267L12.16,20.53c-0.387-0.768-0.079-1.702,0.684-2.087c0.219-0.111,0.453-0.167,0.696-0.167
				c0.165,0,0.328,0.026,0.486,0.078c0.38,0.125,0.692,0.387,0.88,0.738l0.594,0.835v-5.684c0-0.965,0.785-1.75,1.75-1.75
				S19,13.278,19,14.243v4.25h1.753c1.836,0.089,3.257,1.584,3.247,3.403v1.097C24,23.268,23.776,23.493,23.5,23.493z"/>
				</SvgIcon>
		)
	}

	export function PlayIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M12.04,23.999c-6.617,0-12-5.383-12-12c0-6.617,5.383-12,12-12c6.617,0,12,5.383,12,12
		C24.04,18.616,18.657,23.999,12.04,23.999z M12.04,0.999c-6.065,0-11,4.935-11,11s4.935,11,11,11s11-4.935,11-11
		S18.105,0.999,12.04,0.999z"/>
	<path d="M9.103,17.16c-0.057,0-0.115-0.005-0.172-0.014c-0.561-0.091-0.957-0.618-0.891-1.18V9.034
		C8.009,8.771,8.079,8.498,8.234,8.283c0.167-0.232,0.414-0.385,0.696-0.43c0.058-0.009,0.116-0.014,0.173-0.014
		c0.209,0,0.411,0.061,0.585,0.175l7.182,3.592c0.561,0.281,0.645,0.681,0.645,0.894s-0.084,0.613-0.644,0.894l-7.182,3.591
		C9.513,17.1,9.312,17.16,9.103,17.16z M9.09,8.84C9.072,8.843,9.057,8.852,9.045,8.868c-0.01,0.014-0.015,0.034-0.012,0.051
		C9.038,8.945,9.04,8.973,9.04,9v7c0,0.026-0.002,0.053-0.006,0.079c-0.004,0.026,0.006,0.044,0.012,0.053
		c0.008,0.01,0.022,0.024,0.045,0.028c0.003,0,0.006,0,0.009,0c0.021,0,0.032-0.004,0.043-0.012
		c0.021-0.015,0.043-0.029,0.067-0.041l7.214-3.607c0,0-7.212-3.607-7.212-3.607C9.187,8.881,9.164,8.867,9.142,8.852
		C9.13,8.843,9.116,8.839,9.102,8.839L9.09,8.84z"/>
		</SvgIcon>
		)
	}


	export function AssetFileIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M3.5,24C2.673,24,2,23.327,2,22.5v-21C2,0.673,2.673,0,3.5,0h13.293c0.401,0,0.777,0.156,1.061,0.439l3.707,3.707
		C21.844,4.43,22,4.807,22,5.207V22.5c0,0.827-0.673,1.5-1.5,1.5H3.5z M3.5,1C3.224,1,3,1.224,3,1.5v21C3,22.776,3.224,23,3.5,23h17
		c0.276,0,0.5-0.224,0.5-0.5V5.207c0-0.133-0.052-0.259-0.146-0.354l-3.707-3.707C17.053,1.053,16.925,1,16.793,1H3.5z"/>
	<path d="M7.5,18C6.673,18,6,17.327,6,16.5v-5C6,10.673,6.673,10,7.5,10h5c0.827,0,1.5,0.673,1.5,1.5v0.71l2.354-1.116
		c0.152-0.085,0.342-0.135,0.533-0.135c0.047,0,0.094,0.003,0.141,0.009c0.296,0.038,0.56,0.189,0.743,0.426
		c0.175,0.226,0.256,0.506,0.229,0.79v3.632c0.021,0.229-0.029,0.463-0.143,0.661c-0.198,0.347-0.572,0.563-0.973,0.563l0,0
		c-0.194,0-0.386-0.051-0.555-0.147L14,15.79v0.71c0,0.827-0.673,1.5-1.5,1.5H7.5z M7.5,11C7.224,11,7,11.224,7,11.5v5
		C7,16.776,7.224,17,7.5,17h5c0.276,0,0.5-0.224,0.5-0.5V15c0-0.173,0.087-0.331,0.233-0.423c0.079-0.05,0.171-0.077,0.266-0.077
		c0.073,0,0.147,0.017,0.214,0.048l3.077,1.459c0.042,0.023,0.069,0.031,0.095,0.031c0.044,0,0.081-0.021,0.102-0.058
		c0.013-0.022,0.018-0.049,0.015-0.075C17.001,15.884,17,15.864,17,15.843v-3.686c0-0.02,0.001-0.041,0.004-0.061
		c0.004-0.035-0.004-0.065-0.023-0.091s-0.047-0.041-0.079-0.045c-0.002,0-0.005,0-0.007,0c-0.031,0-0.051,0.006-0.068,0.015
		c-0.007,0.004-0.031,0.016-0.038,0.02l-3.074,1.457C13.647,13.483,13.573,13.5,13.5,13.5c-0.095,0-0.188-0.027-0.268-0.078
		C13.087,13.331,13,13.173,13,13v-1.5c0-0.276-0.224-0.5-0.5-0.5H7.5z"/>
		</SvgIcon>
		)
	}


	export function SendIcon (props) {
		return (
		<SvgIcon {...props}>
<path d="M10.015,22.501c-0.561,0-1.018-0.456-1.019-1.017v-6.101l-8.123-2.257c-0.354-0.101-0.637-0.352-0.779-0.688
		c-0.257-0.61,0.029-1.315,0.639-1.572l22.573-9.325c0.015-0.006,0.041-0.013,0.06-0.017c0.024-0.007,0.055-0.015,0.093-0.018
		c0.006,0,0.021-0.001,0.027-0.001c0.013,0,0.027,0.001,0.04,0.002c0.034,0.002,0.065,0.006,0.096,0.014
		c0.021,0.005,0.042,0.013,0.062,0.022c0.006,0.002,0.03,0.009,0.057,0.024c0.013,0.007,0.029,0.019,0.042,0.03
		c0.014,0.01,0.029,0.022,0.044,0.036c0.021,0.019,0.041,0.039,0.058,0.061c0.011,0.013,0.023,0.027,0.031,0.039
		c0.012,0.019,0.02,0.035,0.025,0.048c0.012,0.025,0.022,0.05,0.031,0.078c0.007,0.02,0.013,0.042,0.016,0.064
		c0.005,0.029,0.006,0.061,0.005,0.094c0,0.017-0.001,0.033-0.002,0.048c-0.001,0.013-0.002,0.027-0.005,0.039l-3.85,18.289
		c-0.138,0.643-0.714,1.108-1.369,1.108c-0.097,0-0.196-0.01-0.294-0.031c-0.171-0.036-0.332-0.104-0.478-0.2l-4.806-3.204
		l-2.293,3.931c-0.182,0.311-0.518,0.504-0.877,0.505C10.017,22.501,10.015,22.501,10.015,22.501z M9.996,21.483
		c0,0.01,0.009,0.018,0.019,0.018l2.339-3.992l-2.358-1.572V21.483z M18.548,20.435c0.04,0.026,0.086,0.046,0.135,0.056
		c0.027,0.006,0.055,0.009,0.083,0.009c0.188,0,0.353-0.133,0.392-0.316l3.525-16.743L10.301,14.938L18.548,20.435z M1.118,11.788
		c-0.099,0.042-0.146,0.159-0.103,0.261c0.024,0.056,0.071,0.098,0.13,0.115l8.216,2.283L21.123,3.524L1.118,11.788z"/>
		</SvgIcon>
		)
	}

	export function OnlineLearningIcon (props) {
		return (
			<SvgIcon {...props}>
				<path d="M7,22.501c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3v-2H1.5c-0.827,0-1.5-0.673-1.5-1.5v-15
				c0-0.827,0.673-1.5,1.5-1.5h21c0.827,0,1.5,0.673,1.5,1.5v15c0,0.827-0.673,1.5-1.5,1.5H14v2h3c0.276,0,0.5,0.224,0.5,0.5
				s-0.224,0.5-0.5,0.5H7z M13,21.501v-2h-2v2H13z M1.5,2.501c-0.276,0-0.5,0.224-0.5,0.5v15c0,0.276,0.224,0.5,0.5,0.5h21
				c0.276,0,0.5-0.224,0.5-0.5v-15c0-0.276-0.224-0.5-0.5-0.5H1.5z"/>
			<path d="M4.361,16.5c-0.089,0-0.176-0.015-0.259-0.046c-0.389-0.146-0.587-0.578-0.443-0.965L5,11.909c0,0,0-3.409,0-3.409
				c0-0.059,0.011-0.117,0.034-0.177c0.003-0.008,0.013-0.03,0.017-0.038c0.021-0.044,0.048-0.084,0.08-0.119
				C5.135,8.162,5.15,8.145,5.154,8.141C5.202,8.096,5.25,8.063,5.303,8.04l6.606-2.83C12.096,5.13,12.295,5.09,12.5,5.09
				s0.404,0.041,0.591,0.121l5.534,2.372c0.237,0.101,0.424,0.288,0.525,0.525c0.105,0.245,0.109,0.517,0.01,0.765
				c-0.099,0.248-0.289,0.443-0.534,0.548L17,10.117v3.07c0.001,0.381-0.21,0.724-0.552,0.895l-3.277,1.638
				c-0.206,0.103-0.438,0.157-0.671,0.157s-0.464-0.054-0.67-0.157l-3.277-1.638C8.211,13.911,7.999,13.568,8,13.186v-3.07L6,9.258
				v2.651l1.341,3.578c0.032,0.084,0.048,0.174,0.048,0.266C7.387,16.165,7.051,16.5,6.64,16.5H4.361z M4.722,15.501L6.278,15.5
				L5.5,13.425L4.722,15.501z M9,13.187l3.277,1.639c0.068,0.034,0.146,0.052,0.223,0.052s0.155-0.018,0.223-0.052L16,13.188l0-2.642
				l-2.911,1.248c-0.186,0.079-0.384,0.119-0.588,0.119c-0.205,0-0.404-0.04-0.591-0.12L9,10.544V13.187z M12.303,10.873
				c0.062,0.026,0.129,0.04,0.198,0.04c0.069,0,0.135-0.013,0.198-0.04l5.532-2.371l-5.534-2.372c-0.062-0.026-0.129-0.04-0.197-0.04
				s-0.135,0.014-0.197,0.04L6.769,8.5L12.303,10.873z"/>
		</SvgIcon>
		)
	}

	export function CopyIcon (props) {
		return (
		<SvgIcon {...props}>
	<path d="M1.5,22C0.673,22,0,21.327,0,20.5v-16C0,3.673,0.673,3,1.5,3h1.586C3.295,2.41,3.86,2,4.5,2h2c0,0,0-0.001,0-0.001
		c0-1.103,0.897-2,2-2s2,0.897,2,2c0,0,0,0.001,0,0.001h2c0.64,0,1.205,0.41,1.414,1H15.5C16.327,3,17,3.673,17,4.5V8
		c0,0.276-0.224,0.5-0.5,0.5S16,8.276,16,8V4.5C16,4.224,15.776,4,15.5,4H14v0.5C14,5.327,13.327,6,12.5,6h-8C3.673,6,3,5.327,3,4.5
		V4H1.5C1.224,4,1,4.224,1,4.5v16C1,20.776,1.224,21,1.5,21h8c0.276,0,0.5,0.224,0.5,0.5S9.776,22,9.5,22H1.5z M4.5,3
		C4.224,3,4,3.224,4,3.5v1C4,4.776,4.224,5,4.5,5h8C12.776,5,13,4.776,13,4.5v-1C13,3.224,12.776,3,12.5,3h-2.271
		C9.873,3.612,9.211,3.999,8.5,3.999S7.127,3.612,6.771,3H4.5z M8.5,0.999c-0.551,0-1,0.449-1,1s0.449,1,1,1s1-0.449,1-1
		S9.051,0.999,8.5,0.999z"/>
	<path d="M12.5,24c-0.827,0-1.5-0.673-1.5-1.5V11c0-0.827,0.673-1.5,1.5-1.5h7.086c0.401,0,0.777,0.156,1.061,0.439l1.914,1.914
		C22.84,12.133,23,12.519,23,12.914V22.5c0,0.827-0.673,1.5-1.5,1.5H12.5z M12.5,10.5c-0.276,0-0.5,0.224-0.5,0.5v11.5
		c0,0.276,0.224,0.5,0.5,0.5h9c0.276,0,0.5-0.224,0.5-0.5v-9.586c0-0.132-0.053-0.26-0.146-0.353l-1.914-1.914
		c-0.094-0.094-0.22-0.146-0.354-0.146H12.5z"/>
	<path d="M14.5,14.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H14.5z"/>
	<path d="M14.5,17.999c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H14.5z"/>
	</SvgIcon>
	)
}

	
export function UpgradeIcon (props) {
	return (
	<SvgIcon {...props}>
<path d="M11,20c-0.551,0-1-0.448-1-1v-7.784L7.217,14c-0.182,0.182-0.439,0.286-0.707,0.286c-0.268,0-0.526-0.104-0.707-0.286
		l-1.29-1.29c-0.39-0.39-0.39-1.024,0-1.414l6.78-6.779c0.189-0.189,0.44-0.293,0.707-0.293s0.518,0.104,0.707,0.293l6.78,6.779
		c0.39,0.39,0.39,1.024,0,1.414L18.196,14c-0.189,0.189-0.44,0.292-0.707,0.292S16.971,14.189,16.782,14L14,11.216V19
		c0,0.552-0.448,1-1,1H11z M10.5,9.508c0.066,0,0.13,0.013,0.191,0.038C10.879,9.624,11,9.805,11,10.008V19h2v-8.992
		c0-0.203,0.121-0.384,0.309-0.462c0.062-0.025,0.126-0.038,0.191-0.038c0.134,0,0.259,0.052,0.354,0.146l3.636,3.639l1.29-1.29
		l-6.78-6.779l-6.78,6.779l1.29,1.29l3.637-3.639C10.241,9.56,10.366,9.508,10.5,9.508z"/>
	<path d="M11.999,23.999c-3.19,0-6.194-1.239-8.457-3.488C1.268,18.253,0.011,15.243,0,12.038c-0.01-3.205,1.228-6.223,3.488-8.497
		C5.747,1.268,8.757,0.01,11.962,0h0.039C18.596,0,23.979,5.365,24,11.961c0.01,3.2-1.23,6.216-3.491,8.493
		c-2.262,2.276-5.27,3.535-8.47,3.546C12.038,23.999,11.999,23.999,11.999,23.999z M11.965,1C9.027,1.009,6.268,2.162,4.197,4.246
		C2.126,6.331,0.991,9.097,1,12.035c0.009,2.938,1.162,5.697,3.247,7.768c2.075,2.062,4.83,3.197,7.759,3.197h0.03
		c2.933-0.009,5.691-1.164,7.764-3.25c2.073-2.087,3.21-4.851,3.201-7.785C22.981,5.918,18.047,1,12.001,1H11.965z"/>
		</SvgIcon>
		)
	}
