import React from 'react';
import { DeleteButton, SaveButton, Toolbar, Button } from 'react-admin';
import {
    DeleteIcon, SaveIcon, BackIcon
} from '../Icons';
import {
    withStyles,
} from '@material-ui/core/styles';

const styles = theme => ({
    toolbar: {
        backgroundColor: '#fff',
        paddingTop: 24,
        paddingBottom: 24,
    }
})

const FormToolbar = props => {
    let user = localStorage.getItem('user');
    if (user) {
        user = JSON.parse(user)
    }
    console.log(user)
    return (
        <Toolbar {...props} >
            {
                props.hideSaveButton ? null :
                    <SaveButton
                        redirect={props.hasOwnProperty('redirect') ? props.redirect : 'list'}
                        submitOnEnter={true}
                        icon={<SaveIcon />}
                    />
            }

            {
                !props.dialogMode &&
                <Button

                    style={{ marginLeft: 16 }}
                    variant="flat"
                    // redirect="list"
                    // component={Link}
                    // to={props.basePath}
                    onClick={() => window.history.back()}
                    label="返回" >
                    {<BackIcon />}
                </Button>
            }
            {
                props.hideDeleteButton || user.name !== 'admin' ? null : (
                    props.record &&
                    Object.entries(props.record).length > 0 &&
                    <DeleteButton
                        redirect="list"
                        style={{ marginLeft: 16 }}
                        variant="flat"
                        undoable={props.resource !== 'courses' && 
                                    props.resource !=='students' }
                        basePath={props.basePath}
                        record={props.record}
                        resource={props.resource}
                        icon={<DeleteIcon />}
                    />)
            }

        </Toolbar>
    );
}

export default withStyles(styles)(FormToolbar)