import React, { Fragment } from 'react';
import {
    required, DateField, NumberInput, withDataProvider, GET_LIST,
    AutocompleteInput, List, TextInput, SelectInput, Edit, Filter, Button,
    ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput, SimpleForm
} from 'react-admin';
import compose from 'recompose/compose';
import ListActions from '../../../components/Widgets/ListActions';
import FormToolbar from '../../../components/Widgets/FormToolbar';
import FormContainerActions from "../../../components/Widgets/FormContainerActions";
import { Link } from 'react-router-dom';
import { EditIcon, GreatIcon, LessIcon, TransferStockIcon } from '../../../components/Icons';
import formCardStyle from '../../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import { StockInIcon, StockOutIcon } from '../../../components/Icons';
import DateInput from '../../../components/Inputs/DateInput';
import { formValueSelector } from 'redux-form';
import PositiveNumberFormat from '../../../util/PositiveNumberFormat';
import ParseNumber from '../../../util/ParseNumber';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import ShowFilterWhenHasOp from '../../../util/ShowFilterWhenHasOp';
import FromNowField from '../../../components/Fields/FromNowField';
import CloneButton from '../../../components/CloneButton';

const selector = formValueSelector('global')


const validateUserCreation = (values) => {
    const errors = {};
    // if (!values.name) {
    //     errors.name = ['不能为空'];
    // } 
    // if (!values.courseTypeId) {
    //     errors.courseTypeId = ['不能为空'];
    // } 
    // if (!values.purchasePrice) {
    //     errors.purchasePrice = ['不能为空'];
    // } 
    // if (!values.sellingPrice) {
    //     errors.sellingPrice = ['不能为空'];
    // } 
    // if (!values.status) {
    //     errors.status = ['不能为空'];
    // } 
    return errors
};
const CustomCreateButtonIn = ({ basePath, record = {} }) => <Button component={Link} label="入库" to={`${basePath}/create/in`}><StockInIcon /></Button>;
const CustomCreateButtonOut = ({ basePath, record = {} }) => <Button component={Link} label="出库" to={`${basePath}/create/out`}><StockOutIcon /></Button>;


const InventoryFilter = props => (
    <Filter {...ShowFilterWhenHasOp(props)}>
         <ReferenceInput  label="教材" source="materialId" reference="materials"
            filterToQuery={searchText => ({ 'name[$iLike]': `%${searchText}%` })} >
            <AutocompleteInput  optionValue="id" optionText={ material => material.name || '' } />
        </ReferenceInput>
        <DateInput label="出入库日期从" source="recordDate.$gt" />
        <DateInput label="出入库日期至" source="recordDate.$lt" />
        <NumberInput 
            label="数量(大于)" source="quantity[$gt]" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <GreatIcon  style={{ width: 16, height: 16}}/>
                </InputAdornment>
            ) }} />
        <NumberInput
            label="数量(小于)" source="quantity[$lt]" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <LessIcon style={{ width: 16, height: 16}}/>
                </InputAdornment>
            )}} />
        <ReferenceInput label="领取学员" source="studentId"
            reference="students" 
            filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="name" optionText={s => s.name || '' } />
        </ReferenceInput>
        <ReferenceInput label="经办人" source="employeeId"
            reference="employees" 
            filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="name" optionText={e => e.name || '' } />
        </ReferenceInput>
    </Filter>
)


export const InventoryLogList = props =>　(
    <Fragment>
        <List filters={<InventoryFilter />}
          
            actions={<ListActions addtionalFlags={{ isHideCreate: true }} customActions={
                <Fragment>
                    <Button　style={{display: 'none'}} component={Link} label="调拨" 
                        to={`${props.basePath}/create/tranfser`}><TransferStockIcon /></Button>
                    
                    <CustomCreateButtonIn basePath={props.basePath} />
                    <CustomCreateButtonOut basePath={props.basePath} />
                    
                </Fragment>} />
            } {...props}>

            <Datagrid rowClick="edit">

                <ReferenceField label="所属库房" source="branchId" reference="branches" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="教材名称" source="materialId" reference="materials">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="type" label="业务类型" />
                <TextField source="quantity" label="数量" />
                <ReferenceField label="单价" source="materialId" reference="materials" linkType={false} >
                    <TextField source="sellingPrice" />
                </ReferenceField>
                <DateField source="recordDate" label="出入库日期" showTime locales="ZH"  options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
                <ReferenceField label="经办人" allowEmpty={true} source="employeeId" reference="employees" linkType="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="学员" allowEmpty={true} source="studentId" reference="students" linkType="materials" >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="订单" allowEmpty={true}  source="transactionId" reference="transactions" linkType={'list'} allowEmpty>
                    <TextField source="type" />
                </ReferenceField>
                <TextField source="memo" label="备注" />
                <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                <EditButton icon={<EditIcon />} />
                <CloneButton urlBuilder={(record, basePath) => 
                    `${basePath}/create/${record.type === '出库' ? 'out': 'in'}` }/>
            </Datagrid>
        </List>
    </Fragment>
)

const typeFormatter = function (v) {
    if (v) {
        var retVal = v == "入库" ? "1" : "2"
        return retVal;
    }
    else {
        return null;
    }
}

const absParser = v => { return Math.abs(v) }
const absFormatter = v => { return Math.abs(v) }

class Form extends React.Component {
    state = {
        specificBranch: false
    }

    // applyBranchChanged = (e, value) => {
    //     if (value === '指定仓库') {
    //         this.setState({
    //             specificBranch: true
    //         })
    //     } else {
    //         this.setState({
    //             specificBranch: false
    //         })
    //         this.props.dispatch(change("record-form", "branchId", "null"))
    //     }
    // }

    componentWillMount() {
        if (this.props.type == "create") {
            //inventryType=1 加在query string里 form的toolbar就出错
            var strType = /in(\/?)$/.test(this.props.pathname) ? "入库" : 
                         /out(\/?)$/.test(this.props.pathname) ? "出库" : "调拨";
            this.setState({ type: strType });
        }

        if (this.props.record &&
            this.props.record.branches &&
            this.props.record.branches.length > 0) {
            this.setState({
                applyBranch: '默认仓库'
            })
        }

        this.props.dataProvider(GET_LIST, 'branches',
            {
                pagination: { page: 1, perPage: 100000 },
                filter: { root: true }
            }).then(
                result => this.setState({ branches: result.data })
            )

            this.setState({ recordDate: new Date() });
    }

    render() {
        return (
            <SimpleForm {...this.props} defaultValue={{ type: this.state.type, recordDate: this.state.recordDate }} 
                toolbar={<FormToolbar />} submitOnEnter={true} redirect="list" validate={validateUserCreation} >
                <TextInput source="type" label="出入库类型" disabled />
                {/* <RadioButtonGroupInput label="指定仓库" onChange={this.applyBranchChanged} defaultValue="默认仓库" options={{ row: true }} choices={[
                    { id: '默认仓库', name: '默认仓库' },
                    { id: '指定仓库', name: '指定仓库' }
                ]} />
                {
                    this.state.specificBranch &&
                    <ReferenceInput label="仓库" source="branchId" perPage={1000} reference="branches" filter={{ root: true }} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                } */}
                 <ReferenceInput  label="教材" source="materialId" sort={{ field: 'name', order: 'ASC' }} reference="materials" perPage={100} 
                    filterToQuery={searchText => ({ 'name[$iLike]': `%${searchText}%` })} >
                    <AutocompleteInput  optionValue="id" optionText={ material => material.name || '' } />
                </ReferenceInput>
                {
                    this.state.type === '调拨' &&
                    <ReferenceInput filter={{ 'id[$ne]': this.props.workingBranch.id }} source="targetBranch" 
                         label="转入分校" perPage={10000} reference="branches" validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                }
                <DateInput source="recordDate" label="出入库日期" validate={required()} />
                <TextInput validate={required()}  InputProps={{
                            inputComponent: PositiveNumberFormat
                        }} parse={ParseNumber} source="quantity" label="数量"  />
                        
                <TextInput source="memo" label="备注" />
            </SimpleForm>
        )
    }
}

// export const CreateInventory = (props) => {
//     return  <CreateInventoryClass {...props} />
// }
const InventoryForm = compose(withDataProvider, 
                             withStyles(formCardStyle),
                             connect(state =>  ({ workingBranch: state.global.workingBranch }) )
                             )(Form)
export const CreateInventory = compose(withDataProvider,
    withStyles(formCardStyle))((props) =>
        <Create actions={<FormContainerActions />} title={<span>
            {
                /in(\/?)$/.test(props.location.pathname) ? "入库" : 
                /out(\/?)$/.test(props.location.pathname) ? "出库" : "调拨"
            }
        </span>} {...props} >
            <InventoryForm type="create" pathname={props.location.pathname} />
        </Create>
    )

export const EditInventory = compose(withDataProvider,
    withStyles(formCardStyle))((props) =>
        <Edit actions={<FormContainerActions />} title="编辑" {...props}>
            <InventoryForm type="edit" pathname={props.location.pathname} />
        </Edit>
    )





// (props => (
//     <Edit actions={<FormContainerActions />} title="编辑" {...props}>
//         <SimpleForm submitOnEnter={true} redirect="list"
//             toolbar={<FormToolbar />} validate={validateUserCreation}>

//             <TextInput source="type" label="出入库类型" disabled />
//             <ReferenceInput source="materialId" label="教材" perPage={1000} reference="materials">
//                 <SelectInput optionText="name" />
//             </ReferenceInput>
//             <DateInput source="recordDate" label="出入库日期" />
//             <NumberInput source="quantity" label="数量" step={1} format={absFormatter} parse={absParser} InputProps={{ inputProps: { min: 0 } }} />
//             <TextInput source="memo" label="备注" />
//         </SimpleForm>
//     </Edit>
// ))