import React from 'react';
import { PhoneIcon } from '../Icons';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const PhoneField = props =>  (
   
        <Typography component="body1" >
             
            {
               props.record[props.source]
           }
           {
               props.record[props.source] && 
               <Tooltip title="致电">
                    <IconButton aria-label="Call" color="primary" disabled={!props.record[props.source]}
                                component="a" href={`tel:${props.record[props.source]}`}><PhoneIcon/></IconButton>

                </Tooltip>
           }
          
        </Typography>
    )

export default PhoneField;