import React from 'react';
import { List, AutocompleteInput, ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput,
    SimpleForm, TextInput, SelectInput, Edit, Filter,　FunctionField, NumberInput, BulkDeleteButton } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListActions from '../../../components/Widgets/ListActions';
import FormToolbar from '../../../components/Widgets/FormToolbar';
import FormContainerActions from "../../../components/Widgets/FormContainerActions";
import { EditIcon, GreatIcon, LessIcon } from '../../../components/Icons';
import formCardStyle from '../../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import MoneyFormat from '../../../util/MoneyFormat';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import ShowFilterWhenHasOp from '../../../util/ShowFilterWhenHasOp';
import CloneButton from '../../../components/CloneButton';
import FromNowField from '../../../components/Fields/FromNowField';

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['不能为空'];
    } 
    if (!values.courseTypeId) {
        errors.courseTypeId = ['不能为空'];
    } 
    if (!values.purchasePrice) {
        errors.purchasePrice = ['不能为空'];
    } 
    if (!values.sellingPrice) {
        errors.sellingPrice = ['不能为空'];
    } 
    if (!values.status) {
        errors.status = ['不能为空'];
    } 
    return errors
};

const MaterialFilter = props =>(
    <Filter {...ShowFilterWhenHasOp(props)}>
         <ReferenceInput label="material.name" source="name"
            reference="materials" 
            filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="name" optionText={m => m.name || '' } />
        </ReferenceInput>

        <ReferenceInput label="course.type" source="courseTypeId"
            reference="course_types" >
            <SelectInput />
        </ReferenceInput>
        <NumberInput
            label="进价(大于)" source="purchasePrice[$gte]" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <GreatIcon  style={{ width: 16, height: 16}}/>
                </InputAdornment>
            )}} />
        <NumberInput 
            label="进价(小于)" source="purchasePrice[$lte]" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <LessIcon style={{ width: 16, height: 16}}/>
                </InputAdornment>
            ) }} />
        <NumberInput 
            label="售价(大于)"　source="sellingPrice[$gt]" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <GreatIcon  style={{ width: 16, height: 16}}/>
                </InputAdornment>
            )}} />
        <NumberInput 
            label="售价(小于)"　source="sellingPrice[$lt]" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <LessIcon style={{ width: 16, height: 16}}/>
                </InputAdornment>
            ) }} />
        <SelectInput source="status" label="状态" choices={[
             { id: '正常', name: '正常' },
             { id: '下架', name: '下架' }
        ]}/>
    </Filter>
);

export const MaterialList = props => {
    let user = localStorage.getItem("user");
    let bulkActionButtons = <div></div>
    if (user) {
        user = JSON.parse(user);
        if (user.name === "admin") {
            bulkActionButtons = <BulkDeleteButton {...props} />
        }
        
    }
     return  <List actions={<ListActions  />} filters={<MaterialFilter />} {...props} bulkActionButtons={bulkActionButtons}>
                <Datagrid >
                    <TextField source="name" label="material.name"/>
                    <ReferenceField linkType={false} label="course.type" source="courseTypeId" reference="course_types">
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField  label="校区库存"
                            render={record =>
                                <Link to={`/inventories?filter=${encodeURIComponent(`{"materialId": "${record.id}"}`)}`}>  <NumberFormat displayType="text" 
                                value={ record.stock } thousandSeparator={true} /> </Link>} />

                    <FunctionField label="进价" sortBy="purchasePrice"
                            render={record => <NumberFormat displayType="text" 
                                value={ record.purchasePrice } thousandSeparator={true} prefix={'¥'} /> } />

                    <FunctionField  label="售价" sortBy="sellingPrice"
                            render={record =>
                                  <NumberFormat displayType="text" 
                                value={ record.sellingPrice } thousandSeparator={true} prefix={'¥'} /> } />

                    <TextField source="status" label="状态"/>
                    <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                    { user.name  === "admin" ? <EditButton icon={<EditIcon />}/> : null} 
                    <CloneButton />
                </Datagrid>
            </List>
};


export const CreateMaterial = (withStyles)(formCardStyle) (props => (
    <Create actions={<FormContainerActions />} {...props}  title="material.create">
        <SimpleForm  toolbar={<FormToolbar />}  submitOnEnter={true} redirect="list" validate={validateUserCreation}>
            <TextInput source="name" label="material.name"/>
            <ReferenceInput source="courseTypeId" label="course.type" reference="course_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="purchasePrice" InputProps={{ inputComponent: MoneyFormat }}   label="进价"/>
            <TextInput source="sellingPrice" InputProps={{ inputComponent: MoneyFormat }} label="售价"/>
            <SelectInput source="status" label="状态" choices={[
                { id: '正常', name: '正常' },
                { id: '下架', name: '下架' }
            ]} />
        </SimpleForm>
    </Create>
))

export const EditMaterial = (withStyles)(formCardStyle) (props => (
    <Edit actions={<FormContainerActions />} {...props} title="material.edit">
        <SimpleForm toolbar={<FormToolbar />} submitOnEnter={true} redirect="list"  validate={validateUserCreation}>
            <TextInput source="name" label="material.name"/>
            <ReferenceInput source="courseTypeId" label="course.type" reference="course_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="purchasePrice"  InputProps={{ inputComponent: MoneyFormat }}   label="进价"/>
            <TextInput source="sellingPrice"  InputProps={{ inputComponent: MoneyFormat }} label="售价"/>
            <SelectInput source="status" label="状态" choices={[
                { id: '正常', name: '正常' },
                { id: '下架', name: '下架' }
            ]} />
        </SimpleForm>
    </Edit>
))