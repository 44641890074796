import React, { Component, Fragment} from 'react'
import { SketchPicker } from 'react-color'

 class ColorPicker extends Component {
    state = {}

    componentWillMount () {
        this.setState({
            color: this.props.color
        })
    }

    componentWillReceiveProps = props => {
        this.setState({
            color: props.color
        })
    }

    render () {
        return (
            <div>
                <div style={{
                            padding: '5px',background: '#fff',
                            borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                            display: 'inline-block',cursor: 'pointer',
                            }} onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker }) }>
                <div style={{
                            width: '36px',height: '14px', borderRadius: '2px',
                            background: this.state.color,
                            }} />
                </div>
                { this.state.displayColorPicker ? <div style={{ position: 'absolute', zIndex: '2', } }>
                <div style={{
                        position: 'fixed',top: '0px',right: '0px',
                        bottom: '0px', left: '0px'}}
                        onClick={() => this.setState({ displayColorPicker: false }) }/>
                <SketchPicker color={ this.state.color } onChange={ this.props.colorChange　 } />
                </div> : null }
            </div>
        )
    }
}

export default ColorPicker