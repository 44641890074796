import React, { Component } from 'react';
import { Admin, Resource, Layout } from 'react-admin';
import { BranchTreeList, CreateBranch } from './containers/organization/branches/branch';
import { EmployeeList, CreateEmployee, EditEmployee, EmployeeShow } from './containers/organization/employees/employee';
import { MaterialList, CreateMaterial, EditMaterial } from './containers/organization/materials/material';
import { IncidentalList, CreateIncidental, EditIncidental } from './containers/organization/materials/incidental';
import { ClassList, CreateClass, EditClass } from './containers/operation/class';
import { ClassScheduleList, CreateClassSchedule, EditClassSchedule, TeacherClassScheduleList,TeacherClassScheduleEdit } from './containers/operation/classSchedule';
import { ClassScheduleGroupList, CreateClassScheduleGroup, EditClassScheduleGroup } from './containers/operation/classScheduleGroup';
import { InventoryLogList, CreateInventory, EditInventory } from './containers/organization/materials/inventory';
import { StudentList, CreateStudent, EditStudent } from './containers/students';
import { IntentStudentList } from './containers/students/intents';
import { ListCourse, CreateCourse, EditCourse } from './containers/operation/course';
import { TransactionList } from './containers/students/transactions';
import { CreateContact, ContractList, EditContact } from './containers/system/contracts';
import { CreateNotification, NotificationList, EditNotification, NotifcationShow } from './containers/operation/notification';
import { CampaignList, CreateCampaign, EditCampaign } from './containers/marketing/campaigns';
import { MediaList, CreateMedia, EditMedia } from './containers/system/medias';
import { OnlineCoursetList, CreateOnlineCourse, EditOnlineCourse } from './containers/operation/onlineCourse';
import { HomeworkList, CommentHomework } from './containers/operation/homework';
import ClassScheduleTimetable from './containers/operation/timetable'
import Menu from './components/Layout/Menu';
import { restClient } from 'ra-data-feathers';
import { feathersClient, socketClient } from './feathersClient';
import chineseMessages from 'ra-language-chinese';
import authClientOptions from './acl/authClientOptions';
import authClient from './acl/auth';
import theme from './assets/style/theme';
import MyAppBar from './components/Layout/AppBar';
import { reducer as tree } from 'ra-tree-ui-materialui';
import cnDomainMessage from './i18n/cn';
import { withStyles, createStyles } from '@material-ui/core/styles';
import studentReducer from './containers/students/reducer'
import globalReducer from './redux/global/reducer';
import { Dashboard } from './containers/dashboard';
import customRoutes from './routes';
import Notification from './components/Widgets/Notification';
import Login from './components/Widgets/Login';
import { LearningList } from './containers/students/learnings';
import { StudentLeaveApplicationList } from './containers/operation/studentLeaveApplications';
import * as qiniu from 'qiniu-js';
import { WechatUserList } from  './containers/wechat/users';

const styles = theme => createStyles({
  content: {
    maxWidth: '100vw'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '8em',
    padding: '32px 16px'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
  },
});


chineseMessages.ra.notification.logged_out = '您的登录时效过期, 请重新登录';
chineseMessages.ra.action.confirm = '确定'
chineseMessages.configuration = '个人信息设置'

const messages = {
  cn: { ...chineseMessages, ...cnDomainMessage },
};
const i18nProvider = locale => messages[locale];

const MyLayout = props => (<Layout {...props} appBar={MyAppBar} notification={Notification} />);

const featherOptions = { id: 'id', usePatch: true };

const MyLoginPage = () => <Login />;

const dataProviderWrapper = requestHandler => async (type, resource, params) => {
  if (type === 'GET_LIST' && params.pagination && params.pagination.limit === 0) {
    return requestHandler(type, resource, params).then(d => {
      if (params.complete) {
        params.complete(d);
        return { "total": 0, "limit": 0, "skip": 0, "data": [] }
      }
    });
  }
  else if (type === "PATCH" && resource === "class-schedules") {
     if (params.data.teachingLogs) {
      params.data.teachingLogs = params.data.teachingLogs.filter(log => parseFloat(log.leftLessons) > 0)
     }
  }
   
  else if ((type === 'CREATE' || type === 'UPDATE') && resource === 'online-courses' ) {
    if (Array.isArray( params.data.videos) &&
        params.data.videos.length > 0 ) 
    {
      let data = await requestHandler('GET_LIST', 'media-materials', {
            filter: { getToken: "1"  }
        }).then(res => JSON.parse(res.data[0].id));
      if (data && data.token) {
        let d = (new Date()).getTime();
        params.data.videos.filter(
        p => (p.file && p.file.rawFile instanceof File)).map(p => p.file)
        .concat
        (params.data.videos.filter(
          p => (p.poster && p.poster.rawFile instanceof File))
          .map(p => p.poster))
          .map(file => {
            let putExtra = {
              fname: '', params: {}, mimeType: null,
            };
            let config = {
              useCdnDomain: true, disableStatisticsReport: false,
              retryCount: 6, region: qiniu.region.z0,
            };
            let subObject = {
                next: res => {},
                error(err){}, 
                complete: res => console.log(res)
            };
            file.src = `${process.env.REACT_APP_ASSETS_DOMAIN}/online-courses/${d}/${file.title}`
            let observable = qiniu.upload(file.rawFile,
              `online-courses/${d}/${file.title}`, data.token, putExtra, config);
            observable.subscribe(subObject);
          })
      }
     

      // var cos = new COS({
      //   getAuthorization: function (options, callback) {
      //     let token = JSON.parse(data.token);
      //       callback({
      //         TmpSecretId: token.Credentials.TmpSecretId,
      //         TmpSecretKey: token.Credentials.TmpSecretKey,
      //         XCosSecurityToken: token.Credentials.Token,
      //         StartTime: data.time,
      //         ExpiredTime: token.ExpiredTime
      //       });
      //   }
      // });

      // let d = (new Date()).getTime();
      // params.data.videos.filter(
      //   p => (p.file && p.file.rawFile instanceof File)).map(p => p.file)
      //   .concat
      //   (params.data.videos.filter(
      //     p => (p.poster && p.poster.rawFile instanceof File)).map(p => p.poster)).map(file => {
      //     console.log(file)
      //     file.src = `${data.clientUrl}/${data.path}/${data.domain}/${d}/${file.title}`
      //     cos.putObject({
      //       Bucket: data.bucket,
      //       Region: data.region, 
      //       Key: `${data.path}/${data.domain}/${d}/${file.title}`,
      //       StorageClass: 'STANDARD',
      //       Body: file.rawFile, 
      //       onProgress: function(progressData) {}
      //     }, function(err, data) {
      //         console.log(err || data);
      //     });
      //   }
      //  )
    }
  }
  return requestHandler(type, resource, params);
}


class App extends Component {

  state = {
    loading: true
  }

  loginSucceed = data => {
    if (data && window && window.Rollbar) {
      window.Rollbar.configure({
        payload: {
          person: {
            id: data.id, username: data.name
          }
        }
      });
    }
    
    this.setState({ admin: data.name, isTeacher: data.isTeacher })
    localStorage.setItem('user', JSON.stringify(data));
  }

  componentWillMount() {
    authClientOptions.loginSucceed = this.loginSucceed;

    feathersClient.authenticate()
      .then(data => data.user && this.loginSucceed(data.user))
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render = () => {
    return this.state.loading ?
      (<div className="loader-container">
        <div className="loader">Loading...</div>
      </div>) :
      (<Admin
        customReducers={{ student: studentReducer, tree, global: globalReducer }}
        locale="cn" theme={theme} dataProvider={dataProviderWrapper(restClient(feathersClient, featherOptions))}
        authProvider={authClient(feathersClient, authClientOptions)}
        menu={Menu} loginPage={withStyles(styles)(MyLoginPage)}
        customRoutes={customRoutes}
        appLayout={withStyles(styles)(MyLayout)}
        dashboard={this.state.isTeacher ? null : Dashboard}
        i18nProvider={i18nProvider}
        messages={messages}>
         {permissions =>  permissions == 'teacher' ?
          [
            <Resource name="class-schedules" list={TeacherClassScheduleList} edit={TeacherClassScheduleEdit} />,
            <Resource name="homeworks" list={HomeworkList} edit={CommentHomework}  />,
            <Resource name="students" />,
            <Resource name="courses" />,
            <Resource name="branches" />,
            <Resource name="employees" />,
            <Resource name="classes" />,
            <Resource name="homeworks" />,
            <Resource name="class-schedules" />,
            <Resource name="student-leave-applications" />,
            <Resource name="classes" />,
            <Resource name="students-learnings" />,
            <Resource name="students-learnings-classes" />,
            <Resource name="teaching-logs" />,
          ]
          : [
            <Resource name="students" list={StudentList} edit={EditStudent} create={ CreateStudent} />,
            <Resource name="intent-students" list={IntentStudentList} edit={EditStudent} create={CreateStudent} />,
            <Resource name="courses" list={ListCourse} edit={EditCourse} create={CreateCourse} />,
            <Resource name="branches" list={BranchTreeList} create={CreateBranch} />,
            <Resource name="employees" show={EmployeeShow} list={EmployeeList} edit={EditEmployee} create={CreateEmployee} />,
            <Resource name="materials" list={MaterialList} edit={this.state.admin === "admin" ? EditMaterial : false} create={CreateMaterial} />,
            <Resource name="incidentals" list={IncidentalList} edit={this.state.admin === "admin" ? EditIncidental : false } create={CreateIncidental}/>,
            <Resource name="classes" list={ClassList} edit={EditClass} create={CreateClass} />,
            <Resource name="inventories" list={InventoryLogList} edit={EditInventory} create={CreateInventory} />,
            <Resource name="class-schedules" list={ClassScheduleList} edit={EditClassSchedule} create={CreateClassSchedule} />,
            <Resource name="sms-code" />,
            <Resource name="homeworks" list={HomeworkList}  />,
            <Resource name="class-schedule-groups" list={ClassScheduleGroupList} edit={EditClassScheduleGroup} create={CreateClassScheduleGroup} />,
            <Resource name="class-schedule-timetable" list={ClassScheduleTimetable} />,
            <Resource name="transactions" list={TransactionList} />,
            <Resource name="contracts" list={ContractList} edit={EditContact} create={CreateContact} />,
            <Resource name="notifications" show={NotifcationShow} list={NotificationList} create={CreateNotification} edit={EditNotification}/>,
            <Resource name="campaigns" list={CampaignList} create={CreateCampaign} edit={EditCampaign} />,
            <Resource name="media-materials" list={MediaList} create={CreateMedia} edit={EditMedia}/>,
            <Resource name="online-courses" list={OnlineCoursetList} create={CreateOnlineCourse} edit={EditOnlineCourse}/>,
            <Resource name="course_types" />,
            <Resource name="acquisition-channels" />,
            <Resource name="payment-methods" />,
            <Resource name="students-learnings" list={LearningList} />,
            <Resource name="students-learnings-classes" />,
            <Resource name="teaching-logs" />,
            <Resource name="transaction-items" />,
            <Resource name="system-configurations" />,
            <Resource name="courses-subjects" />,
            <Resource name="subjects" />,
            <Resource name="classrooms" />,
            <Resource name="wechat-users" list={WechatUserList} />,
            <Resource name="student-leave-applications" list={StudentLeaveApplicationList} />
         ]
        }
        
      </Admin>)
  }
}

export default App;
