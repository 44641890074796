import React, {Component} from 'react';
import { ArrayInput, NumberField, Datagrid, ReferenceField, DateField, FunctionField,  TextField, Create, ImageInput, ImageField, Button,
    List,　TabbedForm, withDataProvider, GET_LIST, SelectInput, Edit, Filter, LongTextInput } from 'react-admin';
import { PlayIcon } from '../../components/Icons';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

const styles = theme => ({
    leftInput: { display: 'inline-block',  },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
       // width: 500
    },
    card: {
        boxShadow: 'none'
    }
})


class Homeworks extends Component {
    state = {
       
    }
    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };
    render () {
        const { props } = this;
        return (
            // <List title="作业" bulkActionButtons={false}　 {...this.props} >
                <Datagrid {...this.props} style={{ width: '100%' }}>
                    
                    <ReferenceField label="班级" source="classSchedule.classId" reference="classes">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="上课教师" source="classSchedule.logTeacherId" reference="employees">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField label="上课主题" source="classSchedule.topic" />
                    <DateField locales="ZH" showTime sortBy={false}
                                        options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'}} 
                                    label="上课时间" source="classSchedule.beginAt" />
                    <DateField locales="ZH" showTime sortBy={false}
                                        options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'}} 
                                    label="作业上传时间" source="updatedAt" /> 

                    <FunctionField render={
                        record =>  <Button label="预览" onClick={() => 
                            {
                                console.log(record)
                               // props.dispatch({ type: 'TOGGLE_MEDIA_PREVIEW', payload: { url: res.data[0].id } })
                                                // props.dataProvider(GET_LIST, "qiniu", {
                                                //     filter: { getVideoUrl: "1", name: `${record.key}/${record.fileName}` },
                                                //     pagination: { page: 0, limit: 1000, perPage: 1 }
                                                // }).then(res => {
                                                    
                                                // })
                                            }
                                } ><PlayIcon /></Button>  
                    }>

                    </FunctionField>
                                
                    {/* <Button label="播放"><PlayIcon/></Button>  */}
                </Datagrid> 
            // </List>
       )
    }
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const mapStateToProps = state => ({
    showSelectCourse: state.student.showSelectCourse
});

export const HomeworkList = compose(
    withDataProvider,
    connect(null, mapDispatchToProps)
)(Homeworks);