import React, { Component, Fragment, Children } from 'react'
import PropTypes from 'prop-types';
import { withDataProvider, LinearProgress, GET_ONE, Link, TextField } from 'react-admin';
import compose from 'recompose/compose';
import classnames from 'classnames';

const stopPropagation = e => e.stopPropagation();

const objectToUrlParams = record => 
    record ? Object.keys(record).map(key => `${key}=${record[key]}`).join('&') : ''

class AggregationField extends Component {

    state = {}

    componentWillMount () {
        this.setState({
            isLoading: true
        });
        this.props.dataProvider(GET_ONE, `${this.props.reference}`, 
            {
                id: this.props.record.id + `?${objectToUrlParams(this.props.filter)}`
            }).then(result => this.setState({
                data: result.data,
                isLoading: false
            }));
    }

    render() {
        const filter = `{"${this.props.targetId}" : "${this.props.record.id}"}`;
        return (
            <Fragment>
                {
                    this.state.isLoading ? <LinearProgress /> :
                    <Link
                        to={`${this.props.target}?filter=${encodeURIComponent(filter)}`}
                        onClick={stopPropagation}
                    >
                    <TextField record={this.state.data} source="aggregate" />
                      
                　　</Link>
                }

            </Fragment>
        )

    }

}


AggregationField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default compose(withDataProvider)(AggregationField);