import React, { Children, cloneElement, Component, isValidElement } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {AddCircleIcon, RemoveCircleIcon} from '../Icons';
import { translate } from 'ra-core';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';

import {FormInput} from 'react-admin';
import TableCell from '@material-ui/core/TableCell';

const styles = theme => createStyles({
    root: {
        marginTop: '1em'
    },
    button: {
        whiteSpace: 'nowrap',
    },
    cell: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        padding: 0
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
});

export class TableIterator extends Component {
    constructor(props) {
        super(props);
        // we need a unique id for each field for a proper enter/exit animation
        // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
        // so we keep an internal map between the field position and an autoincrement id
        this.nextId = props.fields.length
            ? props.fields.length
            : props.defaultValue
            ? props.defaultValue.length
            : 0;

        // We check whether we have a defaultValue (which must be an array) before checking
        // the fields prop which will always be empty for a new record.
        // Without it, our ids wouldn't match the default value and we would get key warnings
        // on the CssTransition element inside our render method
        this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
    }

    removeField = index => () => {
        const { fields } = this.props;
        this.ids.splice(index, 1);
        fields.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determing if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    disableRemoveField = (record, disableRemove) => {
        if (typeof disableRemove === "boolean") {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    }

    addField = () => {
        let item = {};
        if (this.props.beforeCreateItem) {
            let result = this.props.beforeCreateItem();　
            
            if (typeof result === 'object') {
                item = result
            } else {
                return;
            }
        }
        
        const { fields } = this.props;
        this.ids.push(this.nextId++);
        fields.push(item);
    };

    render() {
        const {
            basePath,
            classes = {},
            children,
            fields,
            meta: { error, submitFailed },
            record,
            resource,
            source,
            translate,
            disableAdd,
            disableRemove,
        } = this.props;
        const records = get(record, source);
        return fields ? (
            <Table classes={{root: classes.root}}>
                {submitFailed && error && (
                    <FormHelperText error>{error}</FormHelperText>
                )}
              
                <TableBody> 
                    {fields.map((member, index) => ( 
                            <TableRow > 
                                    {
                                        Children.map(children, (input, index2) => isValidElement(input) ? (
                                        <TableCell align="right" component="div" padding="checkbox">
                                            <FormInput
                                                basePath={
                                                    input.props.basePath || basePath
                                                }
                                                input={cloneElement(input, {
                                                    source: input.props.source
                                                        ? `${member}.${
                                                                input.props.source
                                                            }`
                                                        : member,
                                                    index: input.props.source
                                                        ? undefined
                                                        : index2,
                                                    label:
                                                        input.props.label ||
                                                        input.props.source,
                                                })}
                                                record={
                                                    (records && records[index]) ||
                                                    {}
                                                }
                                                resource={resource}
                                            />    
                                            </TableCell>
                                        ) :  
                                        <TableCell classes={{root: classes.cell}} component="div" padding="checkbox">{input}</TableCell>)
                                    }
                              
                                {!(this.disableRemoveField((records && records[index]) || {}, disableRemove)) && (
                                   <TableCell classes={{root: classes.cell}} component="div" >
                                        <Button
                                            className={classNames(
                                                'button-remove',
                                                `button-remove-${source}-${index}`
                                            )}
                                            size="small"
                                            onClick={this.removeField(index)}
                                        >
                                            <RemoveCircleIcon
                                                className={classes.leftIcon}
                                            />
                                            {translate('ra.action.remove')}
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow> 
                    ))} 
                {!disableAdd && ( 
                      <TableFooter>
                           <TableRow>
                               <TableCell component="div" padding="dense" classes={{root: classes.cell}}>
                                    <Button
                                       classes={{root: classes.button}}
                                        size="small"
                                        onClick={this.addField}
                                    >
                                        <AddCircleIcon className={classes.leftIcon} />
                                        {translate('ra.action.add')}
                                    </Button>
                                    </TableCell>
                            </TableRow>
                     </TableFooter>
                   
                )}
            </TableBody>
            </Table>
        ) : null;
    }
}

TableIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

TableIterator.propTypes = {
    defaultValue: PropTypes.any,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    fields: PropTypes.object,
    meta: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default compose(
    translate,
    withStyles(styles)
)(TableIterator);
