import React, { Component, Fragment } from 'react';
import { FunctionField, required, Loading, GET_LIST, withDataProvider, CheckboxGroupInput, 
    NumberInput, ReferenceField, DateField, List, AutocompleteInput, Datagrid, EditButton, 
    TextField, Create, ReferenceInput, SimpleForm, TextInput, SelectInput, Edit, Filter } from 'react-admin';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DrawerInput from '../../components/Inputs/DrawerInput'
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon } from '../../components/Icons';
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import PositiveNumberFormat from '../../util/PositiveNumberFormat';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { formValueSelector, change } from 'redux-form'
// import { DateInput, TimeInput } from "react-admin-date-inputs"
import zhLocale from "date-fns/locale/zh-CN";
import DateFnsUtils from '@date-io/date-fns';
import DateInput from '../../components/Inputs/DateInput';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';
import FromNowField from '../../components/Fields/FromNowField';

const selector = formValueSelector('record-form')
DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth


const validateUserCreation = (values) => {
    // const errors = {};
    // if (!values.name) {
    //     errors.name = ['不能为空'];
    // } 
    // if (!values.mobile) {
    //     errors.mobile = ['不能为空'];
    // } 
    // if (!values.gender) {
    //     errors.gender = ['不能为空'];
    // } 
    // if (!values.status) {
    //     errors.status = ['不能为空'];
    // } 
    // if (!values.branchid) {
    //     errors.branchid = ['不能为空'];
    // } 
    // return errors
};
const ClassScheduleGroupFilter = (props) => {
    var user = JSON.parse(localStorage.getItem("user"))
    var teacherFilterQueryObj = { isTeacher: true , status: '正式员工'}

    if (user && user.selectedBranch) {
        teacherFilterQueryObj.branchId = user.selectedBranch
    }

    return (
        <Filter {...ShowFilterWhenHasOp(props)}>
            <TextInput label="班级名称" source="class.name.$substring" alwaysOn />
            <ReferenceInput perPage={10000} label="教师"
                source="teacherId"
                reference="employees"
                filter={teacherFilterQueryObj}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput label="班级状态" optionText="name"
                optionValue="id" choices={[{ id: "开放", name: "开放" }, { id: "结班", name: "结班" }, { id: "停用", name: "停用" }]} source="class[type]" alwaysOn />
            <DateInput source="beginAt.$gte" label="上课日期从" />
            <DateInput source="endAt.$lte" label="上课日期至" />
        </Filter>
    );
}
export const ClassScheduleGroupList = props => {
    return <List actions={<ListActions />} filters={<ClassScheduleGroupFilter />} filterDefaultValues={{ "class": { "type": ["开放"] } }} {...props} bulkActionButtons={<Fragment></Fragment>}>
        <Datagrid>
            {/* <TextField source="id" label="id" /> */}
            <ReferenceField label="课程" source="class.courseId" reference="courses">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="班级" source="classId" reference="classes">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="class.type" label='班级状态' />
            <ReferenceField label="老师" source="teacherId" reference="employees">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="repeatBeginAt" label="周期开始日期" locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }} />
            <DateField source="repeatEndAt" label="周期结束日期" locales="ZH" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }} />
            <DateField source="beginAt" label="上课开始时间" showTime locales="ZH" options={{ hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
            <DateField source="endAt" label="上课结束时间" showTime locales="ZH" options={{ hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }} />
            <FromNowField sortBy="createdAt" source="createdAt" label="创建时间" />
            {/* <EditButton icon={<EditIcon />} />
            <CloneButton omitProps={['classSchedules']} /> */}

            <FunctionField render={record => (record.class && record.class.type != "结班" && record.class.type != "停用") ?
                <Fragment>
                    <EditButton record={record} basePath={props.basePath} icon={<EditIcon />} />
                    <CloneButton record={record} basePath={props.basePath} omitProps={['classSchedules', 'class']} />
                </Fragment>
                : null} />
        </Datagrid>
    </List>
};

class Form extends React.Component {
    state = {
        repeatSettings: undefined,
        isTeacherFetched: true,
    }
    handleChangeEndAt(e, value) {
        //return new Date()
    }
    changeClass = (e, value) => {
        if (value != this.props.classId && this.props.type) {
            this.setState({ isTeacherFetched: false })
        }

    }
    componentWillMount() {
        //this.today = new Date();
        //去除时分秒
        var today = new Date();
        today.setHours(0)
        today.setMinutes(0)
        today.setSeconds(0)
        this.today = today
        if (localStorage) {
            var user = JSON.parse(localStorage.getItem("user"))
            if (user && user.selectedBranch) {
                this.setState({ selectedBranchId: user.selectedBranch })
            }
        }
    }
    modifyRepeatSettings = (e, value) => {
        //this.props.dispatch(change('record-form', 'repeatSettings', value.sort().join('')))
    }
    componentWillReceiveProps(props) {
        if (props.classId !== this.props.classId && this.props.type == "create") {
            this.props.dataProvider(GET_LIST, 'classes',
                {
                    pagination: { page: 1, perPage: 100000 },
                    filter: { id: props.classId }
                }).then(result => {
                    if (result.data.length > 0 && result.data[0].teachers && result.data[0].teachers.length > 0) {
                        var isTeacherLoaded = false;
                        var isAssistantLoaded = false;
                        result.data[0].teachers.forEach((item, index) => {
                            if (item.teacherType == "教师" && !isTeacherLoaded) {
                                isTeacherLoaded = true;
                                this.props.dispatch(change('record-form', 'teacherId', item.teacherId))
                            }
                            else if (item.teacherType == "助教" && !isAssistantLoaded) {
                                isAssistantLoaded = true;
                                this.props.dispatch(change('record-form', 'assistantId', item.teacherId))
                            }
                        })
                    }
                    else {
                        this.props.dispatch(change('record-form', 'teacherId', null))
                        this.props.dispatch(change('record-form', 'assistantId', null))
                    }
                    this.setState({ isTeacherFetched: true })
                }
                )
        }
    }
    render() {

        return (
            <SimpleForm {...this.props} submitOnEnter={true} redirect="list" toolbar={<FormToolbar />}
                validate={validateUserCreation} >
                <ReferenceInput perPage={100} source="classId" label="班级" 
                    filter={{ idAndName: true }}
                    filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })}
                    reference="classes" onChange={this.changeClass} validate={required()}>
                    <AutocompleteInput optionText="name" options={{placeholder: "输入班级名称以筛选"}} />
                </ReferenceInput>
                <DateInput defaultValue={this.today} source="repeatBeginAt" label="周期开始日期" showTimeSelect={false} selectsStart
                    startDate={this.props.repeatBeginAt} endDate={this.props.repeatEndAt} validate={required()} />
                <DateInput source="repeatEndAt" label="周期结束日期" showTimeSelect={false} selectsEnd
                    startDate={this.props.repeatBeginAt} endDate={this.props.repeatEndAt} validate={required()} />
                <DateInput source="beginAt" label="上课开始时间" timeIntervals={5} showTimeSelect showTimeSelectOnly validate={required()} />
                <DateInput source="endAt" label="上课结束时间" timeIntervals={5} showTimeSelect showTimeSelectOnly validate={required()} />
                {/* 
                <TimeInput source="beginAt" label="上课开始时间" options={{ format: 'HH:mm', ampm: false }} />
                <TimeInput source="endAt" label="上课结束时间" options={{ format: 'HH:mm', ampm: false }} onChange={this.handleChangeEndAt} /> */}
                <SelectInput source="repeatType" label="重复类型" defaultValue="每周重复" choices={[
                    { id: '每周重复', name: '每周重复' },
                ]} />
                {/* <TextInput source="repeatSettings" label="重复设置" style={{ display: "block" }} /> */}
                <CheckboxGroupInput source="repeatSettings" format={v => v ? v.split('') : null} parse={v => v.sort().join('')} onChange={this.modifyRepeatSettings} label="重复设置" choices={[
                    { id: '0', name: '周日' },
                    { id: '1', name: '周一' },
                    { id: '2', name: '周二' },
                    { id: '3', name: '周三' },
                    { id: '4', name: '周四' },
                    { id: '5', name: '周五' },
                    { id: '6', name: '周六' },
                ]} validate={required()} />
                <TextInput InputProps={{ inputComponent: PositiveNumberFormat }}
                    source="repeatTimes" label="最多重复次数" />
                {
                    this.state.isTeacherFetched ? <Fragment>
                        <ReferenceInput perPage={10000} source="teacherId" label="教师" reference="employees" filter={{ isTeacher: true, branchId: this.state.selectedBranchId }} validate={required()}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput perPage={10000} source="assistantId" label="助教" reference="employees" filter={{ isTeacher: true, branchId: this.state.selectedBranchId }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </Fragment> : <Loading />
                }
                <ReferenceInput perPage={10000} source="classroomId" label="教室" reference="classrooms" validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput perPage={10000} source="subjectId" label="科目" reference="subjects">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="topic" label="上课主题" />
            </SimpleForm>
        )
    }
}
const ClassGroupScheduleGroupForm = compose(withDataProvider,
    connect(state => ({
        repeatSettings: selector(state, 'repeatSettings'),
        repeatBeginAt: selector(state, 'repeatBeginAt'),
        repeatendAt: selector(state, 'repeatendAt'),
        classId: selector(state, 'classId'),
        data: state
    })))(Form)

export const CreateClassScheduleGroup = (withStyles)(formCardStyle)(props => (
    <Create actions={<FormContainerActions />} title="新建排课日程" {...props}>
        <ClassGroupScheduleGroupForm type="create" />
    </Create>
))

export const EditClassScheduleGroup = (withStyles)(formCardStyle)(props => (
    <Edit actions={<FormContainerActions />} title={<span>编辑排课日程</span>} {...props}>
        <ClassGroupScheduleGroupForm type="edit" />
    </Edit>
))