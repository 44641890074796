import React, { Fragment, useState, useEffect } from 'react';
import {
    required, Loading, DateField, ArrayField, GET_MANY, Button as RaButton, refreshView,
    SingleFieldList, ChipField, FunctionField, ArrayInput, TabbedForm, FormTab, GET_LIST, withDataProvider,
    List, UPDATE, ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput, showNotification, TextInput, SelectInput, Edit, Filter, Show
} from 'react-admin';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';

import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import { EditIcon, UpgradeIcon } from '../../components/Icons';
import compose from 'recompose/compose';
import DateInput from '../../components/Inputs/DateInput';
import { CreateClassroom } from './classroom';
import DrawerInput from '../../components/Inputs/DrawerInput';
import PositiveNumberFormat from '../../util/PositiveNumberFormat';
import { ClassStudentList } from './classStudent';
import TableIterator from '../../components/Iterators/TableIterator';
import RadioGroup from '@material-ui/core/RadioGroup';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import Calendar from '../../components/Widgets/Calendar'
import { connect } from 'react-redux';
import { SubmissionError, formValueSelector, startSubmit, change, submit, reset } from 'redux-form';
import moment from 'moment'
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    arraySlim: {
        width: '400px !important'
    }
});

const selector = formValueSelector('record-form')

const validateUserCreation = (values) => {
    const errors = {};
    return errors
};


const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const ClassFilter = (props) => {
    var user = JSON.parse(localStorage.getItem("user"))
    var teacherFilterQueryObj = { isTeacher: true, status: '正式员工' }

    if (user && user.selectedBranch) {
        teacherFilterQueryObj.branchId = user.selectedBranch
    }

    return (
        <Filter {...ShowFilterWhenHasOp(props)}>
            <TextInput label="班级名称" source="name.$substring" alwaysOn />

            <ReferenceInput label="教师"
                //source="teachers.teacherId$contains"
                perPage={1000}
                source="teachers[@>][teacherId]"
                reference="employees"
                filter={teacherFilterQueryObj}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput label="班级状态" optionText="name"
                optionValue="id" choices={[{ id: "开放", name: "开放" }, { id: "结班", name: "结班" }, { id: "停用", name: "停用" }]} source="type" alwaysOn />
        </Filter>)
}


const TeachersGrid = ({ ids, data, basePath }) => {
    return ids.map(id =>
        <Fragment>
            <ReferenceField label="" record={data[id]} source="teacherId" resource="classes" reference="employees" basePath={basePath}>
                <TextField source="name" style={{ display: "inline-block" }} />
            </ReferenceField>
            (<TextField record={data[id]} source="teacherType" style={{ display: "inline-block" }} />)
                <br></br>
        </Fragment>
    )
};

const HeadCountField = ({ record = { students: [], maxHeadcount: 0 } }) =>
    <span>{record.studentsLearningsClasses ? record.studentsLearningsClasses.length : 0}/{record.maxHeadcount}</span>

export const ClassList = compose(withDataProvider,
    connect(null, mapDispatchToProps)) (props => {
    const [teachers, setTeachers] = useState(new Array());
    const [ open, setOpen ] = useState(false);
    const [ cls, setCls ] = useState(null);
    const [ courses, setCourses ] = useState(null)
    const [ selectedCourse, setSelectedCourse ] = useState(null)
    const save = () => {
        if (cls.studentsLearningsClasses) {
            Promise.all(cls.studentsLearningsClasses.map(c => {
               return props.dataProvider(UPDATE, 'students-learnings', { 
                   id: c.studentsLearnings.id, 
                    data: {
                     courseId: selectedCourse,
                     history: c.studentsLearnings.history ? 
                     [...c.studentsLearnings.history, c.studentsLearnings.courseId] : [c.studentsLearnings.courseId]
                    } 
                })
            })).then(() => {
                props.dispatch(showNotification('升级成功'));
                props.dispatch(refreshView());
            })
        }
        console.log(selectedCourse)
    }
    useEffect(() => {
        if (open) {
            props.dataProvider(GET_LIST, 'courses', { pagination: { page: 1, perPage: 100000 } } ).then(res => {
                setCourses(res.data)
            })
        }
    }, [open])

    return <Fragment>
        <List actions={<ListActions />} filters={<ClassFilter />} filterDefaultValues={{ "type": ["开放"] }}  {...props} bulkActionButtons={<Fragment></Fragment>}>
            <Datagrid >
                <TextField source="name" label="班级名称" />
                <HeadCountField label='人数' />
                <TextField source="decutionStudent" label="学生课时" />
                <TextField source="decutionTeacher" label="教师课时" />
                {teachers && teachers.map(row => (
                    <TableRow>
                        <TableCell padding="dense" align="right">{row.teacherType}</TableCell>
                        <TableCell padding="dense" align="right">{row.teacherId}</TableCell>
                    </TableRow>
                ))}
                <ArrayField label="教师" source="teachers">
                    <TeachersGrid />
                </ArrayField>
                <TextField source="course.name" label="所属课程" />
                <ReferenceField label="校区" source="branchId" reference="branches">
                    <TextField source="name" />
                </ReferenceField>
                <DateField locales="ZH" options={{ year: 'numeric', month: 'long', day: 'numeric' }} source="openAt" label="开班日期" />
                <ArrayField source="classScheduleGroups" label="上课时间">
                    <SingleFieldList>
                        <ChipField source="repeatDesc" />
                    </SingleFieldList>
                </ArrayField>
                <TextField source="type" label="班级状态" />
                <FunctionField label="升级" render={
                    r => <RaButton label="升级" onClick={() => {
                        setOpen(true)
                        setCls(r)
                    }}>
                        <UpgradeIcon />
                    </RaButton>
                } />
                <EditButton icon={<EditIcon />} />
                <CloneButton  omitProps={['classScheduleGroups', 'course', 'studentsLearningsClasses', 'teachers']}  />
            </Datagrid>
        </List>
        <Dialog fullScreen={true}
                open={open}
                onExited={() => setOpen(false)} >
                <DialogTitle >
                    升级
                    </DialogTitle>
                <DialogContent dividers>
             
                          {
                            courses && courses.map(c =>
                                <RadioGroup name="selectClass"
                                style={{ marginLeft: 48 }} value={selectedCourse}
                                onChange={e => setSelectedCourse(e.target.value)}
                                // onChange={event => this.setState({ value: event.target.value })} 
                                // value={this.state.value}
                                >
                                    <FormControlLabel value={c.id} control={<Radio />} label={c.name} />
                            {
                                // this.state.selectedCourse &&  this.state.selectedCourse.classes.map(cls => 
                                //     <FormControlLabel value={cls.id} control={<Radio />} label={cls.name}
                                //         disabled={this.props.learningClasses && this.props.learningClasses.some(c => c.classId === cls.id)} />
                                //     )
                            }
                            </RadioGroup>
                              )
                          }
                       
                      
                    {/* <FormLabel component="legend">{
                        this.state.data && 
                        this.state.data.length === 0 ?
                            <span>该课程下没有可用的班级,
                                <Button href="/classes" color="primary" >
                                    <ClassIcon /> 请先添加班级
                                </Button>
                            </span>
                        : '请选择班级'
                    }</FormLabel>
                      */}
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} >
                        关闭
                        </Button>
                    <Button onClick={save} color="primary">
                        保存
                        </Button>
                </DialogActions>
        </Dialog>
    </Fragment>
});


const validateDuplicateClassName = async (values, dispatch, props, field) => {
    var branchId = values.branchId
    var courseId = values.courseId
    var name = values.name
    var id = values.id
    if (branchId && courseId && name) {
        var resultClass = await props.dataProvider(GET_LIST, 'classes', { pagination: { page: 1, perPage: 100000 }, filter: { courseId: courseId, branchId: branchId, name: name } });
        if (resultClass.data.length > 0) {
            if (!id || resultClass.data[0].id !== id)
                throw { name: '班级名称已存在，请更换名称' }
        }
    }

}

class Form extends React.Component {
    state = {
        courses: undefined,
        selectedBranchId: undefined,
        classSchedules: [],
    }

    initData = async (value) => {
        var resultCourse = await this.props.dataProvider(GET_LIST, 'courses', { pagination: { page: 1, perPage: 100000, limit: 1000 } });
        this.setState({ courses: resultCourse.data, selectedBranchId: value })
        this.props.dispatch(change("record-form", "branchId", value))
    }

    changeClassName = async (event, value) => {
        if(window.location.href.toLowerCase().indexOf("create")>=0){
            var resultClass = await this.props.dataProvider(GET_LIST, 'classes', { pagination: { page: 1, perPage: 100000 }, filter: { branchId: this.selectedBranchId, courseId: value } });
            var count = Number(resultClass.data.length)
            var courseObj = this.state.courses.find(obj => obj.id == value)
            var className = courseObj.name + (count + 1) + "班"
            this.props.dispatch(change("record-form", "name", className))
        }
    }

    getSubjectsByCourse = courseId => {
        if (!courseId) { return; }
        this.props.dataProvider(GET_LIST, 'courses-subjects',
            {
                pagination: { page: 1, perPage: 100000 },
                filter: { courseId: courseId }
            })
            .then(mapping => {
                this.props.dataProvider(GET_MANY, 'subjects',
                    { ids: mapping.data.map(d => d.subjectId) })
                    .then(result => this.setState({ subjects: result.data }))
            })
            .catch(e => console.log(e));
    }

    componentWillReceiveProps(props) {

        if (props.classSchedules !== this.props.classSchedules) {
            this.setState({ classSchedules: props.classSchedules })
        }
    }
    async componentWillMount() {
        if (localStorage) {
            var user = JSON.parse(localStorage.getItem("user"))
            if (user && user.selectedBranch) {
                await this.initData(user.selectedBranch);
            }
        }
        // if (this.props.record.branchId) {
        //     await this.initData(this.props.record.branchId)
        // }
    }

    setCalendarEventStyle = (event, start, end, isSelected) => {
        if (event.isLogged) {
            if (isSelected) {
                return {
                    style: {
                        backgroundColor: "grey", color: 'black', border: 0
                    }
                }
            }
            else {
                return {
                    style: {
                        backgroundColor: "lightgrey", color: 'black', border: 0
                    }
                }
            }
        }
    }

    changeSubject = (args, id) => {
        let subject = this.state.subjects.find(s => s.id === id);
        this.props.dispatch(change("record-form", "decutionStudent", subject.decutionStudent));
        this.props.dispatch(change("record-form", "decutionTeacher", subject.decutionTeacher));
    }
   
    render() {
        return (
            <TabbedForm {...this.props} toolbar={<FormToolbar hideDeleteButton={true} />} submitOnEnter={true} redirect="list" validate={validateUserCreation} asyncValidate={validateDuplicateClassName} asyncBlurFields={['name']} defaultValue={{ type: "开放", decutionStudent: 1, decutionTeacher: 1 }}>
                <FormTab label="基本信息">
                    {/* <ReferenceInput label="course.branches"
                        source="branchId" perPage={1000} reference="branches"
                        filter={{ root: true }} onChange={this.applyBranchChanged}>
                        <SelectInput optionText="name" />
                    </ReferenceInput> */}
                    {this.state.courses && this.state.selectedBranchId ?
                        <div>
                            {(this.props.record.type != "结班" && this.props.record.type != "停用") ?
                                <div>
                                    < SelectInput label="课程" source="courseId" choices={this.state.courses} optionText="name" optionValue="id" onChange={this.changeClassName} validate={required()} />
                                    <TextInput InputProps={{ inputComponent: PositiveNumberFormat }} parse={val => Number((val.toString()).replace(/[^0-9.-]+/g, ""))}
                                        source="decutionStudent" label="每次上课学生扣除课时" validate={required()} />
                                    <TextInput InputProps={{ inputComponent: PositiveNumberFormat }} parse={val => Number((val.toString()).replace(/[^0-9.-]+/g, ""))}
                                        source="decutionTeacher" label="每次上课老师记录课时" validate={required()} />
                                </div> : null}
                            <SelectInput source="type" label="班级状态" choices={[
                                { id: '开放', name: '开放' },
                                // { id: '满班后停止', name: '满班后停止' },
                                { id: '结班', name: '结班' },
                                { id: '停用', name: '停用' },
                            ]} />
                            {(this.props.record.type != "结班" && this.props.record.type != "停用") ?
                                <div>
                                    <DrawerInput input={
                                        <ReferenceInput label="教室" source="classroomId" reference="classrooms" filter={{ branchId: this.state.selectedBranchId }} validate={required()} >
                                            <SelectInput optionText="name" />
                                        </ReferenceInput>}
                                        buttonLabel="新建教室" drawerContent={<CreateClassroom branchId={this.state.selectedBranchId} />} />
                                    <ArrayInput className={this.props.classes.arraySlim}
                                        label="教师" source="teachers">
                                        <TableIterator>
                                            <ReferenceInput label="选择教师"
                                                source="teacherId" perPage={1000} reference="employees"
                                                filter={{ isTeacher: true, branchId: this.state.selectedBranchId }} validate={required()}>
                                                <SelectInput style={{ width: 130, minWidth: 130 }} optionText="name" label="" />
                                            </ReferenceInput>
                                            <SelectInput source="teacherType"
                                                style={{ width: 130, minWidth: 130 }}
                                                label="职责" choices={[{
                                                    id: "教师", name: "教师",
                                                }, {
                                                    id: "助教", name: "助教",
                                                }]} validate={required()} />
                                        </TableIterator>
                                    </ArrayInput>
                                </div> : null}
                        </div> : <Loading />

                    }
                    {(this.props.record.type != "结班" && this.props.record.type != "停用") ?
                        <div>
                            <TextInput source="name" label="班级名称" validate={required()} />
                            <DateInput source="openAt" label="开班日期" validate={required()} />
                            <DateInput source="closeAt" label="结班日期" validate={required()} />
                            <TextInput parse={val => Number((val.toString()).replace(/[^0-9.-]+/g, ""))} InputProps={{ inputComponent: PositiveNumberFormat }}
                                source="minHeadcount" label="成班人数" validate={required()} />
                            <TextInput InputProps={{ inputComponent: PositiveNumberFormat }} parse={val => Number((val.toString()).replace(/[^0-9.-]+/g, ""))}
                                source="maxHeadcount" label="满班人数" validate={required()} />

                            <TextInput source="memo" label="备注" />
                        </div> : null}
                </FormTab>
                <FormTab label="学员" disabled={this.props.type == "edit" ? false : true}>
                    {(this.props.record.type != "结班" && this.props.record.type != "停用") ?
                        <ClassStudentList />
                        : null}
                </FormTab>
                <FormTab label="课程表" disabled={this.props.type == "edit" ? false : true}>
                    {(this.props.record.type != "结班" && this.props.record.type != "停用") ?
                        <Calendar
                            events={this.state.classSchedules}
                            eventPropGetter={this.setCalendarEventStyle} />
                        : null}
                </FormTab>
            </TabbedForm>
        )
    }
}

const EventComponent = (event) => { return (<span> <strong> here we go! </strong> </span>) }

const mapStateToProps = state => {
    return {
        state: state
    }
};

const ClassForm = compose(withDataProvider,
    withStyles(styles),
    connect(state =>
        ({ courseId: selector(state, 'courseId') })
    ))(Form)

export const CreateClass = compose(withDataProvider,
    connect(null, mapDispatchToProps),
    withStyles(formCardStyle), connect(state => ({ classSchedules: selector(state, 'classSchedules') })))(props => (
        <Create actions={<FormContainerActions />} title="创建班级" {...props}>
            <ClassForm {...props} type="create" />
        </Create>
    ))

export const EditClass = compose(withDataProvider,
    connect(null, mapDispatchToProps),
    withStyles(formCardStyle), connect(state => ({ classSchedules: selector(state, 'classSchedules') })))(props => (
        <Edit actions={<FormContainerActions />} title="编辑班级" {...props}>
            <ClassForm {...props} type="edit" />
        </Edit>
    ))

export const SimpleClassShow = (props) => (
    <Show {...props}>
        {/* <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="teaser" />
            <RichTextField source="body" />
            <DateField label="Publication date" source="created_at" />
        </SimpleShowLayout> */}
    </Show>
);