export default {
    storageKey: 'token', // The key in localStorage used to store the authentication token
    authenticate: { // Options included in calls to Feathers client.authenticate
      strategy: 'local', // The authentication strategy Feathers should use
    },
    permissionsKey: 'permissions', // The key in localStorage used to store permissions from decoded JWT
    permissionsField: 'roles', // The key in the decoded JWT containing the user's role
    passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
    usernameField: 'name', // The key used to provide the username to Feathers client.authenticate
    redirectTo: '/login', // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
}