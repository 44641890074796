export default props => {
    if (props.displayedFilters && props.filterValues) {
        let newProps = Object.assign({}, props);
        Object.keys(newProps.filterValues).map(key => {
            let val = newProps.filterValues[key];
            if (typeof val === 'object') {
                Object.keys(newProps.filterValues[key]).forEach(k => {
                    props.displayedFilters[`${key}.${k}`] = true;
                    props.displayedFilters[`${key}[${k}]`] = true;
                })
            }
        })
        return newProps
    }
    return props
}