import React from 'react';
import { BooleanInput, NumberInput, GET_ONE, Create, SimpleForm, DisabledInput, TextInput, SelectInput } from 'react-admin';
import { parse } from "query-string";
import { restClient } from 'ra-data-feathers';
import { feathersClient } from '../../../feathersClient';
import Typography from '@material-ui/core/Typography';
import FormToolbar from '../../../components/Widgets/FormToolbar';
import FormContainerActions from "../../../components/Widgets/FormContainerActions";
import formCardStyle from '../../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';

const validateBranchCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['名字不能为空'];
    }
    return errors
};

class CreateBranchClass extends React.Component {
    state = {
        parentName: "",
        parentid: ""
    }

    componentWillMount() {
        const props = this.props;
        var { parentid: strPid } = parse(props.location.search);
        //console.log(parentid_string);
        if (strPid) {
            this.setState({ parentid: strPid });
            var api = restClient(feathersClient)
            api(GET_ONE, "branches", { id: strPid }).then(response => {
                this.setState({ parentName: response.data.name });
            }
            );
        }
        else {
            this.setState({ parentid: undefined });
        }
    }
    render() {
        const Aside = () => (
            this.state.parentid && this.state.parentid !== "" ?
                <div style={{ width: 200, margin: '1em' }}>
                    <Typography variant="title">隶属于部门：</Typography>
                    <Typography variant="body1">
                        {this.state.parentName}
                    </Typography>
                </div>
                : <div></div>
        );
        const props = this.props;
        return (
            <Create title={this.state.parentName} actions={<FormContainerActions />}
                aside={<Aside />} {...props}>
                <SimpleForm defaultValue={{ isOrganization: false, type: "直营", parentId: this.state.parentid }} submitOnEnter={true} redirect="list" validate={validateBranchCreation}>
                    {/* <TextInput source="parentId"/> */}
                    <TextInput label="branch.name" source="name" />
                    <TextInput label="branch.code" source="branchCode" />
                    <BooleanInput label="是否为组织" source="isOrganization" />
                    <NumberInput label="branch.seed" source="orderNumberSeed" />
                    <TextInput label="联系电话1" source="tel" />
                    <TextInput label="联系电话2" source="tel2" />
                    <TextInput label="地址" source="address" options={{ multiLine: true }} />
                    <SelectInput label="类型" source="type" choices={[
                        { id: '直营', name: '直营' },
                        { id: '加盟', name: '加盟' },
                        { id: '其他', name: '其他' },
                    ]} />
                </SimpleForm>
            </Create>
        );
    }
}

export default withStyles(formCardStyle)(CreateBranchClass);
