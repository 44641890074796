import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { GirlIcon, BoyIcon } from '../Icons';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    smallAvatar: {
        width: 35,
        height: 35
      },
    name: {
        backgroundColor: theme.palette.secondary.main
    }
})

const GenderAvatar = ({ person, classes }) => (
    !person ? null :
    person.gender === '男' ?
    <Avatar 
        className={classNames(classes.smallAvatar)} style={{ backgroundColor: '#347DC1' }}>
            <BoyIcon  /></Avatar> :
    person.gender === '女' ?
        <Avatar className={classNames(classes.smallAvatar)} style={{ backgroundColor: '#E6A6C7' }}><GirlIcon /></Avatar> :
        <Avatar className={classNames(classes.smallAvatar, classes.name)} >{ person && person.name && person.name.length > 0 ? person.name[0] : '?'}</Avatar>
)

export default withStyles(styles)(GenderAvatar);