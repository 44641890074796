import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {get} from 'lodash';

const moment = require('moment');

const FromNowField = ({ record, source, className, showTime = true }) => 
(
    record && get(record, source) ? 
    <Tooltip title={moment(get(record, source)).fromNow()}>
        <Typography component="body1" className={className} > 
           {
               (new Date(get(record, source)))
               .toLocaleString('zh-CN',
               {
                   ...{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, timeZone: 'Asia/Shanghai'},
                   ...showTime ?{hour: 'numeric', minute : 'numeric'} : {}
               })
           }
        </Typography>
    </Tooltip> : null
)


export default FromNowField;