import React, { Component, Fragment} from 'react'
import PropTypes from 'prop-types';
import { addField, AutocompleteArrayInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SaveIcon, PreviewIcon } from '../../components/Icons';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import compose from 'recompose/compose';
import { withDataProvider, GET_LIST } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import phone from '../../assets/images/phone.png';
import ColorPicker from '../Widgets/ColorPicker';
import Switch from '@material-ui/core/Switch';

class FormMaker extends Component {
    state = {
        backgroundColor: '#fff',
        fontColor: '#222',
        smsValidation: true,
        formShadow: true,
        formBackground: '#fff',
        formFontColor: '#9DAAB6',
        buttonBackgroundColor: '#3884FF',
        buttonFontColor: '#fff',
        title: '感谢您对我们的课程感兴趣',
        description: '请填写您的信息, 我们的课程顾问会尽快联系您',
        rows: {
            name: { name: '学员姓名', type: 'text', required: true, value: null, enabled: true },
            gender: { name: '学员性别', type: 'select', required: true, value: ['女', '男'], enabled: true },
            phone: { name: '联系电话', type: 'tel', required: true, value: null, enabled: true },
            age: { name: '学员年龄', type: 'select', required: true, value: [3, 4, 5, 6,7,8,9,10, 11, 12, 13,14, 15, 16, 17, 18], enabled: true },
            birthday: { name: '学员生日', type: 'date', required: true, enabled: false },
            intentCourse: { name: '意向课程', type: 'multiple', required: true, value: [], enabled: true },
            wechat: { name: '微信号', type: 'text', required: false, value: null, enabled: true },
            email: { name: '电子邮件', type: 'email', required: true, value: null, enabled: false },
            address: { name: '家庭住址', type: 'text', required: true, value: null, enabled: false }
        }
     }

    componentWillMount () {

        let params = {
            filter: { idAndName: true },
            pagination: { page: 1, perPage: 100000 },
        }
        return this.props.dataProvider(GET_LIST, 'courses', params)
        .then(data => {
            if(data.data) {
                this.state.rows['intentCourse'].value = data.data.map(c => c.name)
                this.setState(this.state.rows)
            }
        })
    }

    deleteValue = (key, i) => {
        this.state.rows[key].value.splice(i, 1);
        this.setState(this.state.rows);
    }

    save = () => {
        let html = encodeURIComponent(document.getElementById('preview').innerHTML);
        this.props.input.value = this.props.input.onChange({
            design: this.state,
            html: html
        }) 
        this.setState({ open: false })
    }

    enabledChange = (key, event, checked) => {
        this.state.rows[key].enabled = checked
        this.setState(this.state.rows);
    }

    requiredChange = (key, event, checked) => {
        this.state.rows[key].required = checked
        this.setState(this.state.rows);
    }
    
    openNewValue = key => {
        this.key = key;
        this.setState({
            newValue: '',
            newValueOpen: true
        });
    }

    addNewValue = () => {
        this.state.rows[this.key].value.push(this.state.newValue)
        this.setState(this.state.rows);
    }

    render () {
        const {
            className,
            meta,
            input,
            isRequired,
            label,
            options,
            source,
            resource,
            locale,
            style,
            classes,
            ...rest
        } = this.props;
        const { rows } = this.state;
        return (
            <Fragment>
               <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                    {label}
                </Typography>

                <Typography style={{marginTop: 16}} variant="body1" gutterBottom gutterTop>
                    {
                        input.value ? '表单设置成功' : '还未设置表单'
                    }
                </Typography>
                 <Button variant="outlined" color="primary"
                        onClick={() => this.setState({ open: true })}>
                        {
                            input.value ? '修改表单' : '设置表单'
                         }
                </Button>
                   
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    >
                    <AppBar>
                        <Toolbar>
                        <IconButton color="inherit" onClick={() => this.setState({ open: false })} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="title" color="inherit" >
                            表单设置
                        </Typography>

                        <span style={{ position: 'absolute', right: 16 }}>
                            <Button color="inherit" onClick={this.save}>
                                <SaveIcon />
                                保存
                            </Button>
                          </span>
                        </Toolbar>
                    </AppBar>
                     
                   <div style={{ marginTop: 60, padding: 20, display: 'flex', flexDirection: 'row' }}>
                    <div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                页面背景色
                            </Typography>

                            <ColorPicker color={this.state.backgroundColor} 
                              colorChange={color =>this.setState({ backgroundColor: color.hex })} />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                字体颜色
                            </Typography>

                            <ColorPicker color={this.state.fontColor} 
                              colorChange={color =>this.setState({ fontColor: color.hex })} />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                表单背景色
                            </Typography>
                            <ColorPicker color={this.state.formBackground} 
                              colorChange={color =>this.setState({ formBackground: color.hex })} />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                表单字体颜色
                            </Typography>
                            <ColorPicker color={this.state.formFontColor} 
                              colorChange={color =>this.setState({ formFontColor: color.hex })} />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                表单阴影效果
                            </Typography>
                            <Switch
                                checked={this.state.formShadow}
                                onChange={event => this.setState({ formShadow: event.target.checked })}
                                />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                按钮背景色
                            </Typography>
                            <ColorPicker color={this.state.buttonBackgroundColor} 
                              colorChange={color =>this.setState({ formBackground: color.hex })} />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                按钮字体颜色
                            </Typography>
                            <ColorPicker color={this.state.buttonFontColor} 
                              colorChange={color =>this.setState({ formFontColor: color.hex })} />
                       </div>
                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                发送表单时需要短信验证码
                            </Typography>
                            <Switch
                                checked={this.state.smsValidation}
                                onChange={event => this.setState({ smsValidation: event.target.checked })}
                                />
                       </div>

                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                标题
                            </Typography>
                            <TextField value={this.state.title}
                                onChange={event => this.setState({ title: event.target.value })}
                                />
                       </div>

                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                描述
                            </Typography>
                            <TextField multiline value={this.state.description}
                                onChange={event => this.setState({ description: event.target.value })}
                                />
                       </div>

                       <div>
                            <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                                表单阴影效果
                            </Typography>
                            <Switch
                                checked={this.state.formShadow}
                                onChange={event => this.setState({ formShadow: event.target.checked })}
                                />
                       </div>

                       <Typography style={{marginTop: 16}} variant="caption" gutterBottom gutterTop>
                            表单字段
                        </Typography>
                       
                            <Table　style={{ flex: 'auto' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"　style={{width: 80}}>启用</TableCell>
                                        <TableCell style={{width: 180}}>表单字段</TableCell>
                                        <TableCell style={{width: 180}}>字段类型</TableCell>
                                        <TableCell style={{width: 180}}>是否为必选项</TableCell>
                                        <TableCell >下拉选项</TableCell>
                                        <TableCell style={{width: 180}}>新选项</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { Object.keys(rows).map(key => {
                                    return (
                                    <TableRow key={key}>
                                        <TableCell style={{width: 80}} padding="checkbox">
                                            <Checkbox onChange={this.enabledChange.bind(this, key)} checked={rows[key].enabled} />
                                        </TableCell>
                                        <TableCell style={{width: 180}} component="th" scope="row">
                                            {rows[key].name}
                                        </TableCell>
                                        <TableCell style={{width: 180}}>
                                            <Select value={rows[key].type}>
                                                <MenuItem value="text">文本框</MenuItem>
                                                <MenuItem value="tel">电话</MenuItem>
                                                <MenuItem value="email">电子邮件</MenuItem>
                                                <MenuItem value="select">下拉框-单选</MenuItem>
                                                <MenuItem value="multiple">下拉框-多选</MenuItem>
                                                <MenuItem value="checkbox">是或否</MenuItem>
                                                <MenuItem value="date">日期框</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell style={{width: 180}} component="th" scope="row">
                                            <Checkbox onChange={this.requiredChange.bind(this, key)} checked={rows[key].required} />
                                        </TableCell>
                                        <TableCell >
                                            {
                                                rows[key].value &&
                                                rows[key].value.map((v, i) => 
                                                    <Chip
                                                        label={v}
                                                        onDelete={this.deleteValue.bind(this, key, i)}
                                                    />)
                                            }
                                        </TableCell>
                                        <TableCell style={{width: 180}} component="th" scope="row">
                                        {
                                            (rows[key].type === 'select' || rows[key].type === 'multiple') &&
                                            <Button variant="outlined" color="primary"
                                                onClick={this.openNewValue.bind(this, key)}>新增选项</Button>
                                        }
                                        </TableCell>
                                    </TableRow>
                                    );
                                })}
                                </TableBody>
                            </Table>
                       </div>
                     <div style={{ margin: 20, textAlign: 'center'}}>
                         <Typography style={{marginTop: 16}} variant="display1" gutterBottom gutterTop>
                                预览
                            </Typography>
                        <div style={{ width: 360, height: 730, backgroundSize: 'cover',
                                         backgroundImage: `url(${phone})`,
                                         padding: '70px 20px'}}>
                            
                            <div id="preview" style={{ width: '100%', height: '100%', 
                                        color: this.state.fontColor, 
                                        overflow: 'auto',
                                        padding: 20, border: '1px solid #aaa',
                                        backgroundColor: this.state.backgroundColor }}>
                                       
                                        <h3>
                                            {this.state.title}
                                        </h3>
                                        <span class="description">
                                            {this.state.description}
                                        </span>

                                        <div style={{ backgroundColor: this.state.formBackground,
                                                      color: this.state.formFontColor,
                                                      boxShadow: this.state.formShadow ? 
                                                        '1px 2px 16px 0 rgba(10, 10, 10, 0.5)' : 'none' }} 
                                                className="form-box">
                                            {
                                                Object.keys(this.state.rows)
                                                    .filter(key => (key === 'phone' && this.state.smsValidation) || this.state.rows[key].enabled )
                                                    .map(key => {
                                                       return this.state.smsValidation && key === 'phone' ? 
                                                            <Fragment>
                                                                <div className="form-group"> 
                                                                    <div class="flexWrapper"><span class="label" >{this.state.rows[key].name}{this.state.rows[key].required ? '*' : ''}</span></div>
                                                                    <input　style={{width: '100%', color: this.state.formFontColor}}
                                                                        required={this.state.rows[key].required} type={this.state.rows[key].type} name={key} placeholder={'请输入' + this.state.rows[key].name} />
                                                                </div>
                                                                <div class="flexWrapper"><span class="label">验证码*</span></div>
                                                                <div className="form-group" style={{display: 'flex', position: 'relative'}}> 
                                                                    <input required type="tel" name="code" placeholder="请输入验证码" style={{ flex: 'auto', width: '120px', color: this.state.formFontColor}} />
                                                                    <button type="button" class="btn" id="sendSMS" style={{ flex: 1, width: 120, backgroundColor: this.state.buttonBackgroundColor, color: this.state.buttonFontColor }} >获取验证码</button>
                                                                </div>
                                                            </Fragment> :
                                                            <div className="form-group"> 
                                                                 <div class="flexWrapper"><span class="label">{this.state.rows[key].name}{this.state.rows[key].required ? '*' : ''}</span></div>
                                                            {
                                                                this.state.rows[key].type === 'select' || this.state.rows[key].type === 'multiple' ?
                                                                 <select required={this.state.rows[key].required} style={{width: '100%', color: this.state.formFontColor}} name={key} placeholder={'请输入' + this.state.rows[key].name} multiple={this.state.rows[key].type === 'multiple'}>
                                                                    {
                                                                         this.state.rows[key].value && Array.isArray(this.state.rows[key].value) &&
                                                                         this.state.rows[key].value.map(v => 
                                                                            <option value={v}>{v}</option> )
                                                                    }
                                                                 </select>
                                                                : <input required={this.state.rows[key].required} style={{width: '100%', color: this.state.formFontColor}} type={this.state.rows[key].type} name={key} 
                                                                            placeholder={'请输入' + this.state.rows[key].name} /> 
                                                            }
                                                            </div>
                                                    })
                                            }
                                            <div style={{textAlign: 'left', fontSize:' .7em', marginBottom: '8px'}}>有*的字段为必填字段</div>
                                           <button type="submit" style={{ width: '100%', backgroundColor: this.state.buttonBackgroundColor, color: this.state.buttonFontColor }} class="btn">提交</button>
                                             <script>
                                                {
                                                    `$("#lead-form").validate({rules: {
                                                        ${Object.keys(this.state.rows)
                                                            .filter(key => this.state.rows[key].enabled)
                                                            .map(key => 
                                                                   `${key}: ${JSON.stringify({
                                                                        required: this.state.rows[key].required,
                                                                        email: this.state.rows[key].type === 'email',
                                                                        date: this.state.rows[key].type === 'date'
                                                                    })}` 
                                                            ).join(',')}
                                                     }});`
                                                }
                                             </script>  
                                        </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </Dialog>

                 <Dialog
                    open={this.state.newValueOpen}
                    onClose={() => this.setState({ newValueOpen: false })}>
                    <DialogTitle id="form-dialog-title">新字段选项</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            新建的字段将会插入
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="新选项"
                            style={{ minWidth: 300 }}
                            value={this.state.newValue}
                            onChange={event => this.setState({ newValue: event.target.value })}
                            fullWidth  />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ newValueOpen: false })} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.addNewValue} color="primary">
                        　　保存
                        </Button>
                    </DialogActions>
                    </Dialog>

                    {/*
                    <Dialog
                    open={this.state.previewOpen}
                    onClose={() => this.setState({ previewOpen: false })}>
                    <DialogTitle id="form-dialog-title">预览表单</DialogTitle>
                    <DialogContent >
                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ previewOpen: false })} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                    </Dialog> */}

                </Fragment>
          )
    }
}
    
  
FormMaker.propTypes = {
    addField: PropTypes.bool.isRequired,
    addLabel: PropTypes.bool.isRequired,
    input: PropTypes.object,
    label: PropTypes.string,
    source: PropTypes.string
  }
  
FormMaker.defaultProps = {
    addField: true,
    addLabel: true,
    record: {},
}
  
export default compose(withDataProvider, addField)(FormMaker)
  