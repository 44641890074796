import React from 'react';
import { List,FunctionField, required, ReferenceField, Datagrid, 
    EditButton, TextField, Create, ReferenceInput,SimpleForm, TextInput, 
    withDataProvider, ArrayInput, FileInput,FileField,NumberInput,Edit, ArrayField,
    SingleFieldList, BooleanInput, Filter } from 'react-admin';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import { EditIcon } from '../../components/Icons';
import MoneyFormat from '../../util/MoneyFormat';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';
import FromNowField from '../../components/Fields/FromNowField';
import DrawerInput from '../../components/Inputs/DrawerInput';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import TinymceInput from '../../components/Inputs/TinymceInput';
import DateInput from '../../components/Inputs/DateInput';
import Chip from '@material-ui/core/Chip';
import TableIterator from '../../components/Iterators/TableIterator';

const CompoundFilter = (props) => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        <TextInput label="员工名" source="name" />
    </Filter>
);

export const OnlineCoursetList = compose(
    withDataProvider)(props => {
     return  <List actions={<ListActions />} filters={<CompoundFilter />} {...props}>
                <Datagrid rowClick="edit">
                    {/* <ReferenceField label="小区" source="compoundId" reference="compounds">
                        <TextField source="name" />
                    </ReferenceField> */}
                    <TextField source="title" label="课程" />
                    <TextField source="brief" label="简要信息" />
                    <ArrayField source="videos" label="视频" >
                        <SingleFieldList>
                        <FunctionField 
                            render={record => {
                                return <Chip clickable label={record.title} />
                            }} /> 
                        </SingleFieldList>
                    </ArrayField>
                    <FunctionField label="有效期"
                        render={record => {
                           return !record.beginAt && !record.endAt ?
                           '永久有效' :
                           `${record.beginAt ?( '从 ' +  new Date(record.beginAt).toLocaleString('zh-CN', {  year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' })) : ''} 
                                ${record.endAt ?( '至 ' +  new Date(record.endAt).toLocaleString('zh-CN', {  year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' })) : ''}`
                        }} /> 
                    <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                    <EditButton icon={<EditIcon/>} />
                    <CloneButton />
                    
                </Datagrid>
            </List>
});


export const CreateOnlineCourse = 
compose(
    withDataProvider,
    withStyles(formCardStyle), connect(state => state ))
     (props => (
    <Create actions={<FormContainerActions />}  title="新增在线课程"  {...props}>
        <SimpleForm  toolbar={<FormToolbar />}  submitOnEnter={true} redirect="list" >
            <TextInput source="title" label="课程名称" 
                 validate={required()}/>
            <TextInput source="brief" label="课程简介" fullWidth />
                
            <FileInput multiple source="videos" label="课程视频" accept="video/*">
                <FileField source="src" title="title" />
            </FileInput>

            <BooleanInput source="memberOnly" label="付费视频" />
            <DateInput source="beginAt" timeIntervals={5} label="有效期-开始时间（如果永久有效, 请忽略此处）" showTimeSelect  />
            <DateInput source="endAt" timeIntervals={5} label="有效期-结束时间（如果永久有效, 请忽略此处）" showTimeSelect />
            <TinymceInput source="description" label="详细图文介绍" init={{
                            language: 'zh_CN',
                            height: '19cm',
                            width: '450px',
                            skin: "oxide",
                            mobile: {
                                theme: 'silver'
                            },
                            fontsize_formats: "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 16pt 24pt 28pt 36pt 48pt",
                             plugins: 'preview table lists save paste',
                             toolbar: 'media | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
                            paste_data_images: true, 
                            content_style: 'p { margin: 0 } table td, table th { padding: 2px }'
                        }}/>
        </SimpleForm>
    </Create>
))

export const EditOnlineCourse = 
compose(
    withDataProvider,
    withStyles(formCardStyle), connect(state => state ))
     (props => (
    <Edit actions={<FormContainerActions />}  title="编辑在线课程"  {...props}>
        <SimpleForm  toolbar={<FormToolbar />}  submitOnEnter={true} redirect="list" >
            <TextInput source="title" label="课程名称" 
                 validate={required()}/>
            <TextInput source="brief" label="课程简介" fullWidth />
                
            {/* <FileInput multiple source="videos" label="课程视频" accept="video/*">
                <FileField source="src" title="title" />
            </FileInput> */}
            <ArrayInput  source="videos" label="课程视频">
                <TableIterator>
                    <FileInput validate={required()} source="file" label="视频文件" accept="video/*"
                         placeholder={<p>将视频文件拖拽到这里, 或点击这里选择文件</p>}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <FileInput source="poster" label="视频封面" accept="image/*"
                         placeholder={<p>将封面文件拖拽到这里, 或点击这里选择文件</p>}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <TextInput style={{width: 120}} source="title" label="视频标题" validate={required()} />
                    <TextInput source="description" label="视频简介" />
                </TableIterator>
            </ArrayInput>
            <BooleanInput source="memberOnly" label="付费视频" />
            <DateInput source="beginAt" timeIntervals={5} label="有效期-开始时间（如果永久有效, 请忽略此处）" showTimeSelect  />
            <DateInput source="endAt" timeIntervals={5} label="有效期-结束时间（如果永久有效, 请忽略此处）" showTimeSelect />
            <TinymceInput source="description" label="详细图文介绍" init={{
                            language: 'zh_CN',
                            height: '19cm',
                            width: '450px',
                            skin: "oxide",
                            mobile: {
                                theme: 'silver'
                            },
                            fontsize_formats: "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 16pt 24pt 28pt 36pt 48pt",
                             plugins: 'preview table lists save paste',
                             toolbar: 'media | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
                            paste_data_images: true, 
                            content_style: 'p { margin: 0 } table td, table th { padding: 2px }'
                        }}/>
        </SimpleForm>
    </Edit>
))
