import React, { Component, Fragment } from 'react';
import {
    List, RadioButtonGroupInput, Datagrid, BulkDeleteButton, ArrayInput,
    EditButton, ReferenceField, withDataProvider, GET_LIST, FunctionField,
    TextField, Create, SimpleForm, TextInput, SelectInput, Edit, Filter,
    ReferenceArrayInput, LongTextInput, ReferenceInput,
    ArrayField, SingleFieldList, ChipField, AutocompleteInput, required, Show,
    translate, FormDataConsumer,　Responsive, SimpleList
} from 'react-admin';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import TableIterator from '../../components/Iterators/TableIterator';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon, InfoIcon, RightArrowIcon } from '../../components/Icons';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import formCardStyle from '../../assets/style/formCard';
import DrawerInput from '../../components/Inputs/DrawerInput';
import { CreateSubject } from './subject';
import { CreateCourseType } from './courseType';
import { formValueSelector, change, destroy } from 'redux-form'
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PositiveNumberFormat from '../../util/PositiveNumberFormat';
import MoneyFormat from '../../util/MoneyFormat';
import ParseNumber from '../../util/ParseNumber';
import ExtractIndexFromId from '../../util/ExtractIndexFromId';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import FromNowField from '../../components/Fields/FromNowField';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import CloneButton from '../../components/CloneButton';

const selector = formValueSelector('record-form')

const styles = theme => ({
    array: {
        width: '400px !important'
    },
    dense: {
        width: '130px !important',
    },
    arraySlim: {
        width: '80px !important',
    }
});

const validateCourse = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['课程名不能为空'];
    }
    if (!values.studyMode) {
        errors.studyMode = ['授课模式不能为空'];
    }
    // if (!values.subjects || values.subjects.length == 0) {
    //     errors.subjects = ['科目不能为空'];
    // }
    if (!values.courseTypeId) {
        errors.courseTypeId = ['课程类型不能为空'];
    }
    if (!values.chargeMode) {
        errors.chargeMode = ['收费模式不能为空'];
    }

    if (!values.rules || values.rules.length === 0) {
        errors.rules = ['收费规则不能为空'];
    }
    if (values.rules) {
        values.rules.forEach((r, i) => {
            if (!r.price || r.price <= 0) {
                if (!errors.rules) {
                    errors.rules = {}
                }
                errors.rules[i] = ['收费规则不能为空'];
            }
        })
    }
    return errors
};

const CourseFilter = (props) => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        
        <TextInput style={{ width: 180 }} label="course.name" source="name" />
        <SelectInput choices={[
            { id: '班课', name: '班课' },
            { id: '一对一', name: '一对一' }
        ]} style={{ width: 180 }} label="course.studyMode" source="studyMode" />

        <SelectInput choices={[
            { id: '按课时', name: '按课时' },
            { id: '按期', name: '按期' },
            { id: '按时间', name: '按时间' }
        ]} style={{ width: 180 }} label="course.chargeMode" source="chargeMode" />

        <ReferenceInput label="course.type" style={{ width: 180 }}
            source="courseTypeId"
            reference="course_types"  >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="course.subject" style={{ width: 180 }}
            source="subjects"
            reference="subjects"  >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgb(255, 235, 230)' : 'rgb(235, 236, 240)',
    display: 'flex',
    padding: 16,
    overflow: 'auto',
  });

 const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 16,
    margin: `0 ${8}px 0 0`,
  
    // change background colour if dragging
    background: isDragging ? 'rgb(255, 250, 230)' : 'white',
  
    // styles we need to apply on draggables
    ...draggableStyle,
  });

const SubjectsField = ({ record }) => (
    <span>
        {/* {console.log(record) && record.subjects.map(item => (
            <li key={item.name}>{item.name}</li>
        ))} */}
        {
            record.name
        }
    </span>
)

class CourseList extends Component {
    state = {
        open: false
    }
    render() {
        const { translate } = this.props;
        const RuleButton = ({record, ...props}) => <Button variant="outlined" color="primary"
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.setState({
                                        open: true,
                                        anchorEl: e.target,
                                        rules: record.rules
                                    })
                                }} >定价详情</Button>

        return (
            <Fragment>
                <List title="课程列表" filter={{ onlyClass: true }}
                    filters={<CourseFilter />} actions={<ListActions />} bulkActionButtons={this.props.permissions === 'admin' ? <BulkDeleteButton /> : false} {...this.props}>
                    <Responsive
                        small={
                            <SimpleList
                                primaryText={record => record.name}
                                secondaryText={record => `${record.studyMode} | ${record.chargeMode}`}
                                rightIcon={record => <RuleButton record={record} />}
                            />
                        }
                        medium={
                            <Datagrid >
                                <TextField label="course.name" source="name" />
                                <TextField label="course.studyMode" source="studyMode" />
                                <TextField label="course.chargeMode" source="chargeMode" />
                                <ReferenceField label="course.type" source="courseTypeId" linkType={false} reference="course_types">
                                    <TextField source="name" />
                                </ReferenceField>
                                <ArrayField linkType={false} label="course.subject" source="subjects">
                                    <SingleFieldList>
                                        <ChipField source="name" />
                                    </SingleFieldList>
                                </ArrayField>

                                <TextField style={{ maxWidth: 150 }} label="comment" source="comment" />
                                <FunctionField label="course.rules" render={
                                    record => <RuleButton record={record} />}
                                />
                                <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                                <EditButton icon={<EditIcon />} />
                                <CloneButton omitProps={['courseType', 'classes']} />
                            </Datagrid>
                        }
                    />
                </List>
                <Popover open={this.state.open} anchorEl={this.state.anchorEl}
                    onClose={() => {
                        this.setState({ open: false })
                    }} transition >

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="dense" align="right">{
                                    translate('课时')   
                                }</TableCell>
                                <TableCell padding="dense" align="right"></TableCell>
                                <TableCell padding="dense" align="right">元</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rules && this.state.rules.map(row => (
                                <TableRow>
                                    <TableCell padding="dense" align="right">
                                    <NumberFormat displayType="text" 
                                             value={ row.lessons } thousandSeparator={true} />
                                    </TableCell>
                                    <TableCell padding="dense"　>=</TableCell>
                                    <TableCell padding="dense" align="right">
                                    <NumberFormat displayType="text" 
                                             value={ row.price  } thousandSeparator={true} prefix={'¥'} />
                                   </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Popover>
            </Fragment>
        )
    }
};

class Form extends Component {

    state = {
        specificBranch: false
    }

    applyBranchChanged = (e, value) => {
        if (value === '指定分校') {
            this.setState({
                specificBranch: true
            })
        } else {
            this.setState({
                specificBranch: false
            })
        }
    }

    componentWillMount() {
        if (this.props.record.subjects &&
            Array.isArray(this.props.record.subjects)) {
            this.props.record.subjects = this.props.record.subjects.map(s => s.id ? s.id : s);
        }
        if (this.props.type && this.props.type === 'edit') {
            
            if (this.props.record.branches &&
                Array.isArray(this.props.record.branches)) {
                this.props.record.branches = this.props.record.branches.map(b => b.id ? b.id : b);
            }
        }

        if (this.props.record &&
            this.props.record.branches &&
            this.props.record.branches.length > 0) {
            this.setState({
                applyBranch: '所有分校'
            })
        }
        this.props.dataProvider(GET_LIST, 'branches',
            {
                pagination: { page: 1, perPage: 100000 },
                filter: { root: true }
            }).then(
                result => this.setState({ branches: result.data })
            )
    }

    componentWillUnmount () {
        this.props.dispatch(destroy('record-form'));
    }

    componentWillReceiveProps(props) {
        if (props.record.subjects
            && Array.isArray(props.record.subjects)) {
            props.record.subjects = props.record.subjects.map(s => s.id ? s.id : s);
        }
        if (props.type && props.type === 'edit') {
            if (props.record.branches
                && Array.isArray(props.record.branches)) {
                props.record.branches = props.record.branches.map(b => b.id ? b.id : b);
            }

            if (this.props.cm && props.cm !== this.props.cm) {
                this.props.dispatch(change('record-form', 'rules', [{}]))
            }
        }
    }

    getMaterialPrice = formData => {
        if (this.props.type === "create") {
            return formData.id ? this.props.data.admin.resources.materials.data[formData.id].sellingPrice : null
        }
    }

    getIncidentalPrice = formData => {
        if (this.props.type === "create" && formData.id) {
            return this.props.data.admin.resources.incidentals.data[formData.id].sellingPrice;
        }
    }

    render() {
        const { classes, cm, translate } = this.props; 
        return (
            <Fragment>
                <SimpleForm submitOnEnter={true} defaultValue={{
                    rules: [{}]  }} 
                {...this.props} redirect={
                    this.props.dialogMode ? '' : 'list'
                } validate={validateCourse}
                toolbar= { <FormToolbar undoable={false} dialogMode={this.props.dialogMode}/>} >
                <TextInput validate={required()} label="course.name" source="name" />
                <DrawerInput input={<ReferenceInput validate={required()} label="course.type"
                    source="courseTypeId"
                    reference="course_types"  >
                    <SelectInput optionText="name" />
                </ReferenceInput>}
                    buttonLabel="新建类别" drawerContent={<CreateCourseType />} />

                <DrawerInput input={
                    <ReferenceArrayInput label="course.subjects" source="subjects" reference="subjects">
                        <SelectArrayInput optionText={
                            (record, index) => <span> {`${index >=0 ? index+1 + '.' : ''} ${record ? record.name : ''}`} </span>
                        } />
                        
                    </ReferenceArrayInput>}
                    buttonLabel="新建科目" drawerContent={<CreateSubject />} />
                <RadioButtonGroupInput label="course.studyMode" validate={required()} defaultValue="班课" prePage={1000} options={{ row: true }} source="studyMode" choices={[
                    { id: '班课', name: '班课' },
                    { id: '一对一', name: '一对一' }
                ]} />

                {/* <RadioButtonGroupInput label="开课校区" validate={required()} onChange={this.applyBranchChanged} defaultValue="所有分校" options={{ row: true }} choices={[
                    { id: '所有分校', name: '所有分校' },
                    { id: '指定分校', name: '指定分校' }
                ]} />
                {
                    this.state.specificBranch &&
                    <ReferenceArrayInput label="course.branches" source="branches" perPage={1000} reference="branches" filter={{ root: true }}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                } */}

                <RadioButtonGroupInput defaultValue="按课时" validate={required()} label="course.chargeMode" prePage={1000} options={{ row: true }} source="chargeMode" choices={[
                    { id: '按课时', name: '按课时' },
                    { id: '按期', name: '按期' },
                    { id: '按时间', name: '按时间' }
                ]} />
                
                <ArrayInput className={classes.array}
                    label="course.rules" source="rules">
                    <TableIterator >
                        {
                            cm === '按时间' ?
                                <SelectInput choices={[
                                    { id: '月', name: '月' },
                                    { id: '季度', name: '季度' },
                                    { id: '半年', name: '半年' },
                                    { id: '年', name: '年' }
                                ]} isRequired={true} InputProps={{
                                    startAdornment: <InputAdornment position="start">每</InputAdornment>
                                }} source="lessons" label=" " classes={{ input: classes.dense }} /> :

                                <TextInput validate={required()} parse={ParseNumber} inputProps={{ min: 1 }} InputProps={{
                                    inputComponent: PositiveNumberFormat,
                                    startAdornment: <InputAdornment position="start">{cm === '按期' ? '每期' : '每'}</InputAdornment>,
                                    endAdornment: <InputAdornment position="end">{translate('课时')}</InputAdornment>
                                }} source="lessons" label=" " classes={{ root: classes.dense }} />
                        }

                        <TextInput validate={required()} inputProps={{ min: 1 }} parse={ParseNumber} InputProps={{
                            inputComponent: MoneyFormat,
                            startAdornment: <InputAdornment position="start">=</InputAdornment>,
                            endAdornment: <InputAdornment position="end">元</InputAdornment>
                        }} source="price" label=" " classes={{ root: classes.dense }} />
                    </TableIterator>
                </ArrayInput>

                <ArrayInput className={classes.array}
                    label="教材" source="materials">
                    <TableIterator beforeCreateItem={() => ({ materials_courses: { quantity: 1 } })}>
                        <FormDataConsumer>
                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                return (<ReferenceInput perPage={10000}
                                    onChange={(model, key) => {
                                        formData.materials[ExtractIndexFromId(rest.id)].materials_courses.price =
                                            this.props.data.admin.resources.materials.data[key].sellingPrice
                                        this.props.dispatch(change('record-form', 'materials', formData.materials))
                                    }}
                                    filter={
                                        !scopedFormData["materials_courses"] || !scopedFormData["materials_courses"].createdAt
                                            ? { status: '正常' } :
                                            {
                                                'id[$nin]': formData.materials.map(item => item.id),
                                                status: '正常'
                                            }
                                    }
                                    validate={required()} formClassName={classes.dense} label="教材名称"
                                    filterToQuery={searchText => ({ 'name[$iLike]': `%${searchText}%` })}
                                    source={getSource('id')} reference="materials">
                                        <AutocompleteInput optionText="name"
                                        options={{placeholder: "输入名称以筛选", classes:{ root: classes.dense } }}  />
                                </ReferenceInput>)
 
                            }
                            }
                        </FormDataConsumer>

                        <TextInput validate={required()} classes={{ root: classes.arraySlim }} InputProps={{
                            inputComponent: PositiveNumberFormat
                        }} parse={ParseNumber} label="数量" source="materials_courses.quantity" />

                        <TextInput validate={required()} classes={{ root: classes.arraySlim }} InputProps={{
                            inputComponent: MoneyFormat
                        }} parse={ParseNumber} label="价格" source="materials_courses.price" />
                    </TableIterator>
                </ArrayInput>

                <ArrayInput className={classes.array}
                    label="杂费" source="incidentals">
                    <TableIterator beforeCreateItem={() => ({ incidentals_courses: { quantity: 1 } })}>
                        <FormDataConsumer>
                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                return (<ReferenceInput perPage={10000}
                                    source={getSource('id')} reference="incidentals"
                                    filter={
                                        !scopedFormData["incidentals_courses"] || !scopedFormData["incidentals_courses"].createdAt
                                            ? { status: '正常' } :
                                            {
                                                'id[$nin]': formData.incidentals.map(item => item.id),
                                                status: '正常'
                                            }
                                    }
                                    onChange={(model, key) => {
                                        formData.incidentals[ExtractIndexFromId(rest.id)].incidentals_courses.price =
                                            this.props.data.admin.resources.incidentals.data[key].sellingPrice
                                        this.props.dispatch(change('record-form', 'incidentals', formData.incidentals))
                                    }}
                                    validate={required()} formClassName={classes.dense} label="incidental.name"  >
                                    <AutocompleteInput optionText="name"
                                        options={{placeholder: "输入名称以筛选", classes:{ root: classes.dense } }}  />
                                </ReferenceInput>)
                            }
                            }
                        </FormDataConsumer>

                        <TextInput validate={required()} classes={{ root: classes.arraySlim }} InputProps={{
                            inputComponent: PositiveNumberFormat
                        }} parse={ParseNumber} defaultValue={1} label="数量" source="incidentals_courses.quantity" />

                        <TextInput validate={required()} classes={{ root: classes.arraySlim }} InputProps={{
                            inputComponent: MoneyFormat
                        }} parse={ParseNumber} label="价格" source="incidentals_courses.price" />
                    </TableIterator>
                </ArrayInput>

                <LongTextInput label="comment" source="comment" resettable />
            </SimpleForm>
            </Fragment>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        state: state
    }
};
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const CourseForm = compose(withDataProvider,
    connect(null, mapDispatchToProps),
    withStyles(styles),
    withRouter,
    translate,
    connect(state => ({ cm: selector(state, 'chargeMode'), subjects: selector(state, 'subjects'), data: state })))(Form)

const create = (props) => (
    <Create title="course.create" actions={<FormContainerActions /> } {...props}>
        <CourseForm type="create" dialogMode={props.dialogMode} />
    </Create>
)

const edit = (props) => (
    <Edit title="course.edit" actions={<FormContainerActions />} {...props}>
        <CourseForm type="edit" />
    </Edit>
)


const simpleShowstyles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

export const SimpeCourseShow = compose(withStyles(simpleShowstyles), translate)((props) => {
    const { classes, record, translate } = props;
    return (
        <Card className={classes.card}>
            <CardContent>
                {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
        {record.name}
      </Typography>
      */}
                <Typography color="textSecondary" gutterBottom>
                    课程名
      </Typography>
                <Typography component="p" className={classes.pos}>
                    {record.name}
                </Typography>

                <Typography gutterBottom color="textSecondary">
                    课程类型
      </Typography>
                <Typography component="p" className={classes.pos}>
                    {record.courseType.name}
                </Typography>

                <Typography gutterBottom color="textSecondary">
                    科目
      </Typography>
                <Typography component="p" className={classes.pos}>
                    {record.subjects.map(s => s.name).join(', ')}
                </Typography>

                <Typography gutterBottom color="textSecondary">
                    定价规则
      </Typography>
                {
                    record.rules.map(r => (
                        <Typography component="p" >
                            {
                                record.chargeMode === '按课时' ?
                                    `每 ${r.lessons}课时 = ${r.price}元` :
                                    record.chargeMode === '按期' ?
                                        `每期 ${r.lessons}课时 = ${r.price}元` :
                                        record.chargeMode === '按时间' ?
                                            `每 ${r.lessons} = ${r.price}元` : null
                            }
                        </Typography>
                    ))
                }
            </CardContent>
            <CardActions>
                <Button size="small">关闭</Button>
            </CardActions>
        </Card>
    )
});

export const EditCourse = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(formCardStyle))(edit);

export const CreateCourse = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(formCardStyle))(create);

export const ListCourse = compose(withDataProvider, translate)(CourseList);

export const courseRulesToString = course => {
    return course.chargeMode === '按课时' ?
        course.rules.map(rule => `每${rule.lessons}课时 = ${rule.price}元`) :
        course.chargeMode === '按期' ?
            course.rules.map(rule => `每期${rule.lessons}课时 = ${rule.price}元`) :
            course.chargeMode === '按时间' ?
                course.rules.map(rule => `每${rule.lessons} = ${rule.price}元`) : []
}


export const courseRuleToString = (cm, detail) => {
    return cm === '按课时' ?
        `每${detail.purchasedLessons}课时 = ${detail.purchasedPrice}元` :
        cm === '按期' ?
        `每期${detail.purchasedLessons}课时 = ${detail.purchasedPrice}元`:
        cm === '按时间' ?
            `每${detail.duration} = ${detail.purchasedPrice}元` : '';
}