import React, {Component} from 'react';
import { ArrayInput, List, Datagrid, DateField, DateInput, SimpleFormIterator,  TextField, Create, ImageInput, ImageField, Button,
     SimpleForm,　TabbedForm, FormTab, TextInput, SelectInput, Edit, Filter, LongTextInput } from 'react-admin';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { AddIcon, CalendarIcon, OrderListIcon, CommunicationIcon,
    StudentBasicInfoIcon, ClassAttendanceLogIcon } from '../../components/Icons';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import formCardStyle from '../../assets/style/formCard';
import ContactField from '../../components/Fields/ContactField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import Drawer from '@material-ui/core/Drawer';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin'
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TinyMCEInput from '../../components/Inputs/TinymceInput';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';

const styles = theme => ({
    leftInput: { display: 'inline-block',  },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
       // width: 500
    },
    card: {
        boxShadow: 'none'
    }
})

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['分校名不能为空'];
    }
    return errors
};

const fields = [

    { source: "extra_attributes.prop1", label: "属性1" },
    { source: "extra_attributes.prop2", label: "属性2" },
    { source: "extra_attributes.prop3", label: "属性3" },
]

const StudentFilter = (props) => (
    // <div style={{ }}> 
    <Filter {...ShowFilterWhenHasOp(props)}>
        {/* <TextInput label="属性1" source="extra_attributes.prop1" />
        <TextInput label="属性2" source="extra_attributes.prop2" /> */}
          <TextInput label="姓名" source="name" />
        {
            fields.map(f =>
                <TextInput label={f.label} source={f.source} />
            )
        }
    </Filter>
    // </div>
);

class Communications extends Component {
    state = {
        showPanel: false
    }
    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };
    render () {
        const { classes } = this.props;
        return <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>沟通方式</TableCell>
                            <TableCell >沟通主题</TableCell>
                            <TableCell >沟通内容</TableCell>
                            <TableCell >沟通结果</TableCell>
                            <TableCell >沟通日期</TableCell>
                            <TableCell >后续步骤</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* <TableRow>
                            <TableCell>电话</TableCell>
                            <TableCell >邀请参加试听课</TableCell>
                            <TableCell >打电话给客户， 邀请参加试听课</TableCell>
                            <TableCell >客户同意参加</TableCell>
                            <TableCell >2019/03/23</TableCell>
                            <TableCell >xx班级 舞蹈课</TableCell>
                        </TableRow> */}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Button onClick={this.handleClick} label="新建沟通"><AddIcon/></Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>

                     <Dialog
                        open={this.state.showPanel}
                        onClose={() => this.setState({ showPanel: false })}
                        // classes={{ paper: classes.dialogRoot }}
                        onExited={() => this.setState({ showPanel: false })} >
                        <DialogTitle id="responsive-dialog-title">
                            <Typography style={{marginTop: 6}} color="textSecondary">
                                 新建沟通
                           </Typography>
                        </DialogTitle>

                        <DialogContent>
                            {
                                React.createElement(CreateCommunication)
                            }
                        </DialogContent>
                         {/*<DialogActions>
                            <Button color="primary">
                                关闭
                            </Button>
                            <Button color="primary">
                                保存
                            </Button>
                        </DialogActions> */}
                    </Dialog>
            </Table>
    }
};


const create = (props) => 
{
    const {classes} = props
    return (
        <div style={{ minWidth: 500 }} > 
            <Create resource="communications" location="/communications" 
                    basePath="communications" actions={<FormContainerActions />} {...props}> 
                <SimpleForm>
                        <SelectInput label="沟通主题" 
                                 choices={[
                                { id: '售前沟通', name: '售前沟通' },
                                { id: '试听邀请', name: '试听邀请' },
                                { id: '活动邀请', name: '活动邀请' },
                                { id: '家访', name: '家访' },
                                { id: '反馈意见', name: '反馈意见' },
                                { id: '其他', name: '其他' }
                         ]} />
                        <SelectInput label="沟通方式" 
                                source="gender" choices={[
                                { id: '电话', name: '电话' },
                                { id: '当面拜访', name: '当面拜访' },
                                { id: '网络交流', name: '网络交流' },
                                { id: '电子邮件', name: '电子邮件' },
                                { id: '其他', name: '其他' }
                         ]} />

                        <ReferenceArrayInput source="tag_ids" reference="courses">
                            <SelectArrayInput optionText="name" />
                        </ReferenceArrayInput>

                        <TinyMCEInput label="沟通内容" source="detail"/>
                        
                        
                        
                </SimpleForm>
            </Create> 
        </div>
    )
}


const edit = (props) => (
    <Edit title="编辑学员" actions={<FormContainerActions />} {...props}>
        <SimpleForm submitOnEnter={true} redirect="list" validate={validateUserCreation} 
        toolbar={<FormToolbar />}>

            <TextInput source="name" />
            <DateInput source="birthday" />
            <SelectInput source="gender" choices={[
                { id: '男', name: '男' },
                { id: '女', name: '女' },
                { id: '未知', name: '其他' },
            ]} />
            <TextInput source="phone" />
        </SimpleForm>
    </Edit>
)

export const EditStudent = compose(withStyles(styles))(edit);
export const CreateCommunication = compose(withStyles(styles))(create);
export const CommunicationList = compose(withStyles(styles))(Communications)