import React, { Fragment, Component } from 'react';
import { NumberInput, FormDataConsumer, showNotification, UPDATE, BooleanInput, TextInput, SimpleForm, GET_LIST, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { Field, formValueSelector, startSubmit, initialize, change, submit, reset } from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {  RightArrowIcon } from '../../components/Icons';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
};


const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgb(255, 235, 230)' : 'rgb(235, 236, 240)',
    display: 'flex',
    padding: 16,
    overflow: 'auto',
  });

 const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 16,
    margin: `0 ${8}px 0 0`,
  
    // change background colour if dragging
    background: isDragging ? 'rgb(255, 250, 230)' : 'white',
  
    // styles we need to apply on draggables
    ...draggableStyle,
  });

class CourseRelation extends Component {

    state = { }

    componentWillMount = () => {
        this.props.dataProvider(GET_LIST, 'course_types',
            {
                pagination: { page: 1, perPage: 100000 },
            }).then(
                result => {
                    this.setState({ data: result.data });
                    if (result.data.length > 0) {
                        this.setState({ type: result.data[0], typeId: result.data[0].id });
                       
                    }
                }
         )
    }

    render() {
        return (
            <Card style={{padding: 32}}>
                <Select style={{width: 250}}
                    value={this.state.typeId} 
                    onChange={event => this.setState({ typeId: event.target.value, type: this.state.data.find(d => d.id === event.target.value) })} >
                  {
                       this.state.data && this.state.data.map(c => 
                        <MenuItem value={c.id}>{c.name}</MenuItem>
                       )
                  }
                </Select>
                {
                    this.state.type &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                    >
                                    {this.state.type.courses.map((course, index) => (
                                    　<div style={{ display: 'flex', alignItems: 'center', marginRight: 16  }}>
                                        <Draggable key={course.id} draggableId={course.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                >
                                                   {
                                                        course.name
                                                    } 
                                                </div>
                                            )}
                                            </Draggable>
                                            
                                            {
                                                index < this.state.type.courses.length-1 &&
                                                <RightArrowIcon/>
                                            }
                                        </div>
                                        )
                                    )}
                                    {provided.placeholder}
                                    </div>
                                )
                            }
                                </Droppable>
                            </DragDropContext>
                }
            </Card>
        )
    }
}

export default compose(withDataProvider, withStyles(styles))(CourseRelation);
