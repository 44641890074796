
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import { withRouter } from 'react-router-dom';
import {
    translate,
    MenuItemLink,
    Responsive,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import SubMenu from './SubMenu';
import {
    NotificationIcon,IncidentalIcon, InventoryIcon, DashboardIcon, SchoolAdminIcon, StudentIcon, CourseIcon,
    ClassIcon, StructureIcon, EmployeeIcon, MaterialIcon, TransactionsIcon,
    InternalSettingIcon, IntentStudentIcon, DataImportIcon, HomeworkIcon,OnlineLearningIcon,
    StudentCenterIcon, ContractIcon, SettingIcon, MarketingIcon, CampaignIcon,
    ScheduleClassBatchIcon, ScheduleClassIcon, AssetFileIcon, LessonsListIcon
} from '../Icons';




const styles = theme => ({
    active: {
        color: theme.palette.primary.main,
        fontWeight: 900
    }
});

class Menu extends Component {
    state = {
        menus: {}
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        if (this.state.menus.hasOwnProperty(menu)) {
            this.state.menus[menu] = !this.state.menus[menu];
        } else {
            this.state.menus[menu] = true;
        }
        this.setState(this.state.menus);
        if (localStorage) {
            localStorage.setItem('selectedMenu', JSON.stringify(this.state.menus));
        }
    };

    componentWillMount() {
        if (localStorage &&
            Object.entries(this.state.menus).length === 0) {
            let menus = localStorage.getItem('selectedMenu');
            if (menus) {
                try {
                    this.setState({ menus: JSON.parse(menus) })
                }
                catch (err) { }
            }
        }
    }

    render() {
        const { onMenuClick, open, logout, translate, theme, classes } = this.props;
        return (
            <Fragment>
                   {!this.props.user.isTeacher ? 
                   <div>
                        <MenuItemLink
                            to="/" onClick={onMenuClick}
                            primaryText={translate('ra.page.dashboard')}
                            leftIcon={<DashboardIcon />} exact
                        />
                        <SubMenu
                            handleToggle={() => this.handleToggle('menuMarketing')}
                            isOpen={this.state.menus['menuMarketing']}
                            sidebarIsOpen={open}
                            name="marketing"
                            icon={<MarketingIcon />}  >
                            <MenuItemLink
                                to={`/campaigns`}
                                primaryText={translate(`campaigns`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<CampaignIcon />}
                            />
                        </SubMenu>
                        <SubMenu
                            handleToggle={() => this.handleToggle('menuStudent')}
                            isOpen={this.state.menus['menuStudent']}
                            sidebarIsOpen={open}
                            name="customer-center"
                            icon={<StudentCenterIcon />}  >
                            <MenuItemLink
                                to={`/intent-students`}
                                primaryText={translate(`intents`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<IntentStudentIcon />}
                            />

                            <MenuItemLink
                                to={`/students`}
                                primaryText={translate(`customers`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<StudentIcon />}
                            />

                            <MenuItemLink
                                to={`/students-learnings`}
                                primaryText={translate(`学员剩余课时`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<LessonsListIcon />}
                            />

                            <MenuItemLink
                                to={`/transactions`}
                                primaryText={translate(`transactions`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<TransactionsIcon />}
                            />
                            <MenuItemLink
                                to={`/notifications`}
                                primaryText={translate(`家长端通知`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<NotificationIcon />}
                            />
                            <MenuItemLink
                                to={`/student-leave-applications`}
                                primaryText={translate(`请假申请`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<NotificationIcon />}
                            />
                            <MenuItemLink
                                to={`/homeworks`}
                                primaryText={translate(`课后作业`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<HomeworkIcon />}
                            />
                        </SubMenu>

                        <SubMenu
                            handleToggle={() => this.handleToggle('menuSchool')}
                            isOpen={this.state.menus['menuSchool']}
                            sidebarIsOpen={open}
                            name="service-center"
                            icon={<SchoolAdminIcon />}  >
                            {/* <MenuItemLink
                                to={`/students`}
                                primaryText={translate(`学员`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<StudentIcon />}
                            />  */}
                            <MenuItemLink
                                to={`/courses`}
                                primaryText={translate(`courses`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<CourseIcon />}
                            />

                            {
                                this.props.user && this.props.workingBranch &&
                                this.props.user.branch &&
                                this.props.user.branch.isOrganization &&
                                this.props.user.branch.id === this.props.workingBranch.id &&
                                <MenuItemLink
                                    to={`/online-courses`}
                                    primaryText={translate(`在线课程`, {
                                        smart_count: 2,
                                    })}
                                    activeClassName={classes.active}
                                    onClick={onMenuClick}
                                    leftIcon={<OnlineLearningIcon />}
                                />
                            }
                            
                            {/* <MenuItemLink
                                to={`/courses-relation`}
                                primaryText={translate('课程升期关系', {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<AdjustRelationIcon />}
                            /> */}
                            <MenuItemLink
                                to={`/classes`}
                                primaryText={translate(`class`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<ClassIcon />}
                            />
                            <MenuItemLink
                                to={`/class-schedules`}
                                primaryText={translate(`class-scheudle`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<ScheduleClassIcon />}
                            />
                            <MenuItemLink
                                to={`/class-schedule-groups`}
                                primaryText={translate(`class-scheudle-batch`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<ScheduleClassBatchIcon />}
                            />
                            {/* <MenuItemLink
                                to={`/class-schedule-timetable`}
                                primaryText={translate(`class-timetable`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<CalendarClassIcon />}
                            /> */}
                        </SubMenu>

                        <SubMenu
                            handleToggle={() => this.handleToggle('menuOperation')}
                            isOpen={this.state.menus['menuOperation']}
                            sidebarIsOpen={open}
                            name="内部管理"
                            icon={<InternalSettingIcon />}
                        >
                            {
                                this.props.user && this.props.workingBranch &&
                                this.props.user.branch &&
                                this.props.user.branch.isOrganization &&
                                this.props.user.branch.id === this.props.workingBranch.id &&
                                <MenuItemLink
                                    to={`/branches`}
                                    primaryText={translate(`组织架构`, {
                                        smart_count: 4,
                                    })}
                                    activeClassName={classes.active}
                                    onClick={onMenuClick}
                                    leftIcon={<StructureIcon />}

                                />
                            }

                            <MenuItemLink
                                to={`/employees`}
                                primaryText={translate(`员工`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<EmployeeIcon />}

                            />
                            <MenuItemLink
                                to={`/materials`}
                                primaryText={translate(`教材`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<MaterialIcon />}

                            />
                            <MenuItemLink
                                to={`/incidentals`}
                                primaryText={translate(`杂费`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<IncidentalIcon />}

                            />
                            <MenuItemLink
                                to={`/inventories`}
                                primaryText={translate(`库存`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<InventoryIcon />}

                            />
                        </SubMenu>

                        <SubMenu
                            handleToggle={() => this.handleToggle('menuSetting')}
                            isOpen={this.state.menus['menuSetting']}
                            sidebarIsOpen={open}
                            name="系统设置"
                            icon={<SettingIcon />}
                        >
                            {
                                this.props.user && this.props.workingBranch &&
                                this.props.user.branch &&
                                this.props.user.branch.isOrganization &&
                                this.props.user.branch.id === this.props.workingBranch.id &&
                                <MenuItemLink
                                    to={`/contracts`}
                                    primaryText={translate(`合同模版`, {
                                        smart_count: 4,
                                    })}
                                    activeClassName={classes.active}
                                    onClick={onMenuClick}
                                    leftIcon={<ContractIcon />} />
                            }

                            <MenuItemLink
                                to={`/data-import`}
                                primaryText={translate(`数据导入`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<DataImportIcon />}
                            />
                            <MenuItemLink
                                to={`/system-configuration`}
                                primaryText={translate(`系统设置`, {
                                    smart_count: 4,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<SettingIcon />}
                            />
                            {
                                this.props.user && this.props.workingBranch &&
                                this.props.user.branch &&
                                this.props.user.branch.isOrganization &&
                                this.props.user.branch.id === this.props.workingBranch.id &&
                                <MenuItemLink
                                    to={`/media-materials`}
                                    primaryText={translate(`通知文件`, {
                                        smart_count: 4,
                                    })}
                                    activeClassName={classes.active}
                                    onClick={onMenuClick}
                                    leftIcon={<AssetFileIcon />}  />
                            }
                        </SubMenu>
                        {
                                this.props.user && this.props.workingBranch &&
                                this.props.user.branch &&
                                this.props.user.branch.isOrganization &&
                                this.props.user.branch.id === this.props.workingBranch.id &&
                        <SubMenu
                            handleToggle={() => this.handleToggle('menuWechat')}
                            isOpen={this.state.menus['menuWechat']}
                            sidebarIsOpen={open}
                            name="微信"
                            icon={<SettingIcon />}
                        >
                                <MenuItemLink
                                    to={`/wechat-users`}
                                    primaryText={translate(`微信用户`, {
                                        smart_count: 4,
                                    })}
                                    activeClassName={classes.active}
                                    onClick={onMenuClick}/>
                        </SubMenu>}
                    </div> :
                    <div>
                         <MenuItemLink
                                to={`/class-schedules`}
                                primaryText={translate(`班级`, {
                                    smart_count: 2,
                                })}
                                activeClassName={classes.active}
                                onClick={onMenuClick}
                                leftIcon={<ScheduleClassIcon />}
                         />
                        <MenuItemLink
                            to={`/homeworks`}
                            primaryText={translate(`学生作业`, {
                                smart_count: 4,
                            })}
                            activeClassName={classes.active}
                            onClick={onMenuClick}
                            leftIcon={<HomeworkIcon />}
                        />
                     </div>
                    }

                    <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText={translate('configuration')}
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                    <Responsive
                        small={logout}
                        medium={null} // Pass null to render nothing on larger devices
                    />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    workingBranch: state.global.workingBranch,
    user: state.global.user,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate,
    withStyles(styles)
);

export default enhance(Menu);
