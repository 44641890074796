import React, { Component, Fragment } from 'react';
import {
    ArrayInput, GET_LIST, ReferenceArrayField, SingleFieldList, Datagrid, FormDataConsumer, fetchStart, fetchEnd, showNotification, withDataProvider, LongTextInput, FunctionField, CheckboxGroupInput,
    SimpleForm, NumberInput, required, ReferenceInput, RadioButtonGroupInput, TextInput, SelectInput, Labeled, Filter, CREATE, List, ShowController, ReferenceField, TextField, AutocompleteInput
    ,minValue, maxValue, DatagridLoading, Button as RaButton, translate, DateField, refreshView as refreshViewAction, NumberField
} from 'react-admin';
import { PrintIcon, GreatIcon, LessIcon } from '../../components/Icons';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { courseRulesToString } from '../operation/course';
import PositiveNumberFormat from '../../util/PositiveNumberFormat';
import ConvertNumToChinese from '../../util/ConvertNumToChinese';
import ParseNumber from '../../util/ParseNumber';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { destroy, change, submit, getFormValues } from 'redux-form';
import TableIterator from '../../components/Iterators/TableIterator';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MoneyFormat from '../../util/MoneyFormat';
import { isArray } from 'util';
import ExtractIndexFromId from '../../util/ExtractIndexFromId';
import DateInput from '../../components/Inputs/DateInput';
import DateFnsUtils from '@date-io/date-fns';
import { refreshView } from 'ra-core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ListActions from '../../components/Widgets/ListActions';
import { arrayPush } from 'redux-form';
import { courseRuleToString } from "../operation/course";
import {ShowContract} from '../system/contracts';
import { GET_ONE, GET_MANY } from 'ra-core/lib/dataFetchActions';
import LoadingIndicator from '../../components/Widgets/LoadingIndicator';
import printJS from 'print-js'
import { Writer } from 'mustache';
import FromNowField from '../../components/Fields/FromNowField';
import Collapse from '@material-ui/core/Collapse';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ListItem from '@material-ui/core/ListItem';
import {List as MuiList} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isDate } from 'lodash';
import NameAvatarField from '../../components/Fields/NameAvatarField';

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

const moment = require('moment');

const discounts = () => {
    let i = 9.9, all = [];
    while (i > 0) {
        all.push({ id: i.toFixed(1), name: i.toFixed(1) + '折' })
        i -= .1;
    }
    return all;
}

const styles = theme => ({
    leftInput: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    dialogRoot: {
        width: 700,
        maxWidth: 700
    },
    drawerRoot: {
        width: 500
    },
    arrayDense: {
        width: '130px !important',
    },
    arraySlim: {
        width: '80px !important',
    },
    arrayExtraSlim: {
        width: '60px !important',
    },
    table: {
        tableLayout: 'auto',
    },
    printDialog: {
        height: '29cm', width: '23cm', maxWidth: '23cm'
    }
})

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['分校名不能为空'];
    }
    return errors
};

const fields = [

    // { source: "extra_attributes.prop1", label: "属性1" },
    // { source: "extra_attributes.prop2", label: "属性2" },
    // { source: "extra_attributes.prop3", label: "属性3" },
]

const TransactionsFilter = props => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        <ReferenceInput label="学员" filterToQuery={searchText => ({ 'hanzi_pinyin[$like]': `%${searchText}%` })} 
                            source="studentId" reference="students">
            <AutocompleteInput optionText={student => student.name } />
        </ReferenceInput>
        <SelectInput source="type" label="交易类型" choices={[
            { id: '报名', name: '报名' },
            { id: '续费', name: '续费' },
            { id: '退费', name: '退费' },
            { id: '教材杂费', name: '教材杂费' }
        ]} />
        <ReferenceInput label="课程" source="items.detail.referenceId" reference="courses">
            <SelectInput optionText={c => c.name } />
        </ReferenceInput>
        <TextInput source="subtotal.$gt" label="总价 (大于)" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <GreatIcon  style={{ width: 16, height: 16}}/>
                </InputAdornment>
            )}}/>
        <TextInput source="subtotal.$lt" label="总价 (小于)" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <LessIcon  style={{ width: 16, height: 16}}/>
                </InputAdornment>
            )}}/>
        <DateInput  source="transaction_date.$gte" label="交易日期从" />
        <DateInput source="transaction_date.$lt" label="交易日期至" />
    </Filter>
);

const exportMetadata = {
    name: '订单流水',
    sheets: [
        {
            name: '订单流水',
            columns: {
                '学员': record => record.student ? record.student.name : '',
                '交易类型': 'type',
                '合同编号': 'contract',
                '分校': record => record.branch ? record.branch.name : '',
                '交易日期': record => isDate(new Date(record.transaction_date)) ? (new Date(record.transaction_date)).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' }) : '',
                '应收/退': 'subtotal',
                '实收/退': record => Array.isArray(record.payments) ? record.payments.reduce((sum, i) => sum + Number(i.received), 0) : '',
                '交易内容': record => Array.isArray(record.items) ? 
                            record.items.map(item => {
                                if (item.type === '杂费' || item.type === '教材') {
                                    return `${item.detail.referenceName} * ${item.detail.quantity} = ${item.total}`
                                } else if (item.type === '课程学费') {
                                    if (item.learning) {
                                        return `${item.learning.course ? item.learning.course.name : ''} * ${item.detail.quantity} = ${item.total}`
                                    } else if (item.detail.courseName) {
                                        return item.detail.courseName;
                                    }
                                 return '';
                                }
                            }).filter(item => item).join('\n') : '',
                '购买/退出课时': record => Array.isArray(record.items) ? record.items.filter(item => item.type === '课程学费')
                                                .map(item => {
                                                    if (record.type === '退费') {
                                                        return item.detail.refundLessons
                                                    } else {
                                                        let discounts = getDiscounts(item);
                                                        if (discounts.length > 0) {
                                                            return `${item.learning && item.learning.course ? item.learning.course.name : ''}: ${discounts.join(', ')}`
                                                        }
                                                    }
                                                    return ''
                                                }).join('\n') : '',
                '交易方式': record => Array.isArray(record.payments) ? record.payments.map(p =>  `${p.payment_method ? p.payment_method.name: ''}`).join('，'): '',
                '课程折扣': record => Array.isArray(record.items) ? record.items.filter(item => item.type === '课程学费')
                                    .map(item => {
                                        let discounts = getDiscounts(item);
                                        if (discounts.length > 0) {
                                            return `${item.learning && item.learning.course ? item.learning.course.name : ''}: ${discounts.join(', ')}`
                                        }
                                        return ''
                                    }).join('\n') : '',
                '课程顾问': record => record.saleEmployee ? record.saleEmployee.name : '',
                '经办人': record => record.createdUser && record.createdUser.employees ? record.createdUser.employees.name : '',
                '内部备注':'internal_comment',
                '外部备注': 'external_comment',
            }
        }
    ]
}

class Transactions extends Component
{
    state = {}

    componentWillMount () {
        
        let id = this.props.location.pathname.replace(this.props.basePath, '')
        if (id && id[0] === '/') {
            this.setState({ filter: { id: id.replace(/\//, '') } })
        }
    }
    
    render () {
        const props = this.props;
        
        return (
            <Fragment>
                 <List filter={this.state.filter ? this.state.filter : {}} title="交易流水" 
                    filters={<TransactionsFilter/>} bulkActionButtons={false}
                     actions={<ListActions exportMetadata={exportMetadata} />}  {...props} >
                <Datagrid expand={<TransactionItemsShow />}>
                    <ReferenceField sortBy={false} allowEmpty={true} label="student.name" source="studentId" reference="students">
                      <FunctionField render={ record =><NameAvatarField record={record}/> } />
                    </ReferenceField>
                    <TextField label="订单类型" source="type" />
                    <ReferenceField allowEmpty={true}  label="课程顾问" 
                                reference="employees" source="saleEmployeeId">
                                    <TextField source="name" />
                     </ReferenceField>
                    <FunctionField  label="应收/退" sortBy="subtotal"
                        render={record => <NumberFormat displayType="text" 
                            value={ record.subtotal } thousandSeparator={true} prefix={'¥'} /> } />

                    <FunctionField label="实收/退"  
                                render={record => <NumberFormat value={record.payments ? record.payments.reduce((sum, item) => sum + Number(item.received), 0): 0}
                                                        displayType="text" thousandSeparator={true} prefix={'¥'} />} />

                    <FunctionField label="交易渠道" 
                                render={record => record.payments && record.payments.map(p => p.payment_method.name).join(', ') } />
                    {/* <FromNowField sortBy="transaction_date" source="transaction_date" label="交易日期"/> */}
                    <DateField locales="ZH" source="transaction_date" label="交易日期" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}} />
                    <TextField label="订单编号" source="contract" />
                    <FunctionField render={record => 
                                            {
                                                return record.type === '报名' || record.type === '续费' ?
                                                <RaButton label="打印合同" onClick={() => props.dispatch({ type: 'PRINT_CONTRACT', payload: { show: true, transaction: record } })}><PrintIcon/></RaButton> : null
                                            }} />
                </Datagrid> 
            </List>
                <PrintContract />
            </Fragment>
        )
    }
}

export const TransactionItemsShow = translate(props =>
     <ShowController {...props} title=" ">
        {({ record }) =>
            !console.log(record) && record && (
                <Card style={{ margin: 'auto', maxWidth: 600 }}>
                    <CardContent>
                        <Grid container spacing={16}>
                            <Grid item xs={6}>
                                <Typography variant="title" gutterBottom>
                                    订单详情
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subheading"
                                    gutterBottom
                                    style={{textAlign: 'right'}}
                                >
                                    <Typography  color="textSecondary">
                                         合同：
                                     </Typography>  {record.contract}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={6}>
                                <Labeled style={{width: '80%'}}
                                        record={record}
                                        label="内部备注"
                                        disabled={false}
                                    >
                                     <Typography
                                        component="span"
                                        body1="body1"
                                    >
                                        {record.internal_comment}
                                    </Typography>
                                    </Labeled>
                                
                                </Grid>

                                <Grid item xs={6}>
                                    <Labeled
                                        style={{width: '80%'}}
                                        record={record}
                                        label="外部备注"
                                        disabled={false}
                                    >
                                       <Typography
                                        component="span"
                                        body1="body1"  >
                                        {record.external_comment}
                                    </Typography>
                                    </Labeled>
                                
                                </Grid>
                            </Grid>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding: 8}} >
                                    {
                                        props.translate('transaction.course')
                                    }</TableCell>
                                    <TableCell style={{padding: 8}} numeric align="right">{
                                       props.translate( record.type === '退费' ? '退出课时' : '购买课时')
                                    }</TableCell>
                                     <TableCell style={{width:120, padding: 8}} numeric align="right">赠送课时</TableCell>
                                    <TableCell  style={{width:120, padding: 8}} numeric align="right">价格</TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            {
                              record.items.filter(item => item.type === '课程学费').map(item => (
                                    <TableRow>
                                        <TableCell style={{padding: 8}}>
                                            <ReferenceField basePath={`/${getReferenceFromItemDetail(item)}`}
                                                 source="detail.referenceId" record={item} 
                                                    reference={getReferenceFromItemDetail(item)}>
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </TableCell> 
                                        <TableCell style={{padding: 8}} numeric align="right">
                                            {
                                                item.detail.chargeMode === '按时间' ? 
                                                ` ${item.detail.quantity} ${item.detail.rule.lessons}` :
                                                <Fragment>
                                                    <NumberFormat value={ item.detail.price}
                                                        displayType="text" thousandSeparator={true} />
                                                        {
                                                        record.type === '退费' ? item.detail.refundLessons : `${item.detail.rule.lessons} * ${item.detail.quantity}`
                                                        }课时
                                                </Fragment>

                                            }
                                        </TableCell>
                                        <TableCell   style={{width:120, padding: 8}} numeric align="right">
                                           {
                                               <NumberFormat value={item.detail.freeLessons}
                                               displayType="text" thousandSeparator={true} /> 
                                           }
                                        </TableCell>
                                        <TableCell style={{width:120, padding: 8}} numeric align="right">
                                           {
                                               <NumberFormat value={ item.total}
                                               displayType="text" thousandSeparator={true} prefix={'¥'} /> 
                                           }
                                        </TableCell>
                                    </TableRow> 
                                ))　
                            }
                        </Table >
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding: 8}}>
                                    {
                                        props.translate('transaction.material')
                                    }
                                    </TableCell>
                                    <TableCell style={{padding: 8}} numeric align="right">数量</TableCell>
                                    <TableCell style={{padding: 8}} style={{width:120}} numeric align="right">价格</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                record.items.filter(item => item.type === '教材' || item.type === '教材退费').map(item => (
                                    <TableRow>
                                        <TableCell style={{padding: 8}}>
                                            <ReferenceField basePath='/materials' record={item}
                                                 source="detail.referenceId" reference="materials">
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </TableCell>
                                        <TableCell style={{padding: 8}} numeric align="right">
                                           {
                                               item.detail.quantity
                                           }
                                        </TableCell>
                                        <TableCell style={{padding: 8}} style={{width:120}} numeric align="right">
                                            <NumberFormat value={ item.detail.price}
                                            displayType="text" thousandSeparator={true} prefix={'¥'} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </Table >

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding: 8}}>{
                                        props.translate('transaction.incidental')
                                    }</TableCell>
                                    <TableCell style={{padding: 8}} numeric align="right">数量</TableCell>
                                    <TableCell style={{padding: 8}} style={{width:120}} numeric align="right">价格</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                record.items.filter(item => item.type === '杂费'|| item.type === '杂费退费').map(item => (
                                    <TableRow>
                                        <TableCell style={{padding: 8}}>
                                            <ReferenceField basePath='/incidentals' record={item}
                                                 source="detail.referenceId" reference="incidentals">
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </TableCell>
                                        <TableCell style={{padding: 8}} numeric align="right">
                                           {
                                               item.detail.quantity
                                           }
                                        </TableCell>
                                        <TableCell style={{padding: 8}} style={{width:120}} numeric align="right">
                                            <NumberFormat value={ item.detail.price}
                                            displayType="text" thousandSeparator={true} prefix={'¥'} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </Table >
                    </CardContent>
                </Card>
            )
        }
    </ShowController>
)

const validateTransaction = (values) => {
    const errors = {};
    if (values.payments) {
        if (!values.payments[0].hasOwnProperty('paymentMethod')) {
            errors.payments = [];
            errors.payments.push({
                paymentMethod: '不能为空'
            })
        }
    }
    return errors
}

class AddTransaction extends Component {

    handleCloseClick = () => {
        this.props.dispatch({ type: 'START_TRANSACTION', payload: { showApplyCourse: false } });
    }

    handleSave = () => {
        if (this.props.state.form['transaction-form'].syncErrors) {
            this.props.dispatch(showNotification('表单有错误', 'warning'));
        }
        this.props.dispatch(submit('transaction-form'));
    }

    closed = () => {
        this.props.dispatch(destroy('transaction-form'));
    }

    handleSubmit = values => {
        this.props.dispatch(fetchStart());
        this.props.dataProvider(CREATE, 'transactions', {
            data: {
                ...setTotal(values),
                type: this.props.applyType,
                students: this.props.state.student.studentIds
            }
        })
            .then(({ data }) => {
                let message = this.props.applyType === '报名' ? '报名成功, 学员已成为正式学员'
                    : this.props.applyType === '退费' ? '退费成功' :
                    this.props.applyType === '续费' ? '续费成功' : '购买成功'

                this.props.dispatch(showNotification(message));
                this.props.dispatch(refreshView());
                this.handleCloseClick();
            })
            .catch(error => {
                this.props.dispatch(showNotification(error.message, 'error'));
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                this.props.dispatch(fetchEnd());
            });
    };
 
    componentWillReceiveProps = props => {
        if (props.showDialog && !this.props.showDialog) {
            setTimeout(() => {
                this.props.dispatch(change('transaction-form', 'payments', [{ received: null }]));
            }, 100);
        }
    }

    render() {
        const { classes, translate } = this.props;
        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.showDialog}
                fullWidth={true}
                onClose={this.handleCloseClick}
                classes={{ paper: classes.dialogRoot }}
                onExited={this.closed} >
                <DialogTitle id="responsive-dialog-title">
                    {translate(`${this.props.applyType}`)}
                    <Typography style={{marginTop: 6}} color="textSecondary">
                      正在为<ReferenceArrayField source="ids" 
                                    basePath={`/${this.props.resource}`}
                                    resources={`${this.props.resource}`}
                                    record={{ ids: this.props.state.student.studentIds }} 
                                    reference={`${this.props.resource}`} >
                                    <SingleFieldList style={{ display: 'inline' }}>
                                        <TextField style={{ display: 'inline' }} source="name" />
                                    </SingleFieldList>
                            </ReferenceArrayField>
                            {
                                translate(`办理${this.props.applyType}`)
                            }
                        </Typography>
                        {
                            this.props.state.student.transactionMessage
                        }
                </DialogTitle>
                <DialogContent dividers>
                    {
                        this.props.showDialog ?
                            <SimpleForm defaultValue={this.props.defaultValue} onSubmit={this.handleSubmit} toolbar={null} 
                                validate={validateTransaction} resource="transactions" form="transaction-form" >
                               
                                <DateInput
                                  showTimeSelect={true}
                                  timeIntervals={15}
                                  validate={required()}
                                  defaultValue={Date()} 
                                  source="transaction_date" label="办理日期" />

                                {(this.props.applyType == '报名' || this.props.applyType == '续费') && 
                                <TuitionArray {...this.props} disableRemove={this.props.applyType == '续费'} 
                                    disableAdd={this.props.applyType == '续费'} />}

                                {this.props.applyType == '报名' ||
                                    this.props.applyType == '续费' ||
                                    this.props.applyType == '教材杂费' ?
                                    <MaterialArray {...this.props} /> : null}

                                {this.props.applyType == '报名' ||
                                    this.props.applyType == '续费' ||
                                    this.props.applyType == '教材杂费' ?
                                    <IncidentalArray {...this.props} /> : null}
                                {
                                    this.props.applyType == '退费' &&
                                      <RefundArray {...this.props} />
                                }
                                <PaymentMethod {...this.props} />
                                <ReferenceInput label="课程顾问" source="saleEmployeeId" perPage={200} reference="employees" >
                                    <SelectInput optionText="name" />
                                </ReferenceInput>
                                <LongTextInput label="内部备注" source="internal_comment" resettable />
                                <LongTextInput label="外部备注" source="external_comment" resettable />
                               
                            </SimpleForm> : null
                    }

                </DialogContent>
                <DialogActions style={{ boxShadow: '0px -6px 10px -5px rgba(0, 0, 0, 0.2)'}}>
                    <Total calcTotal={this.calcTotal} {...this.props} />
                </DialogActions>
                <DialogActions>
                   
                    <Button onClick={this.handleCloseClick} color="primary">
                        关闭
                        </Button>
                    <Button onClick={this.handleSave} color="primary">
                        保存
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const getCourseQuantity = item => {
    if (item && item.detail) {
        if (item.detail.chargeMode === ' 按课时') {
            return `按课时: ${item.detail.rule.lessons} * ${item.detail.quantity}`
        } else if (item.detail.chargeMode === ' 按期') {
            return `按期: ${item.detail.rule.lessons} * ${item.detail.quantity}`
        } else {
            return `按时间: ${item.detail.rule.lessons} * ${item.detail.quantity}`
        }
    }
    return null;
}

const getDiscounts = item => {
    let discounts = [];
    if (item && item.detail) {
        if (item.detail.discount_off) {
            discounts.push('优惠金额: ¥' + item.detail.discount_off)
        }
        if (item.detail.discount_pct) {
            discounts.push('优惠折扣: ' + item.detail.discount_pct + '折')
        }
        if (item.detail.freeLessons) {
            discounts.push('赠送: ' + item.detail.freeLessons + '课时')
        }
    }
    return discounts;
}

class ContractDialog extends Component
{
    state ={}

    handleCloseClick = () => {
        this.props.dispatch({ type: 'PRINT_CONTRACT', payload: { show: false } });
        document.title = this.title;

    }

    componentWillReceiveProps = props => {
        if (props.transaction)
        {
            this.props.dataProvider(GET_ONE, 'students', { id: props.transaction.studentId })
                .then(response => {
                    const mustacheWriter = new Writer();
                   
                    let item = props.transaction.items.find(i => i.type === '课程学费');
                    
                    let courseId, cls, start, end, monthSpan;
                    if (item.detail.type === 'class' && 
                        Array.isArray(response.data.learnings)) {
                        let learning = response.data.learnings.find(c => c.id === item.studentsLearningId);
                        if (learning && Array.isArray(learning.studentsLearningsClasses)) {
                            cls = learning.studentsLearningsClasses.find(c => c.classId === item.detail.referenceId);
                            courseId = cls ? cls.courseId : '';
                            start = cls && cls.openAt ? moment(cls.openAt) : null
                            end = cls && cls.closeAt ? moment(cls.closeAt) : null
                        }
                        if (start && end) {
                            monthSpan = end.diff(start, 'months', true).toFixed(1);
                        }
                    } else {
                        courseId = item.detail.referenceId;
                    }
                    let course = response.data.courses.find(c => c.id === courseId);
                    let discount = [];
                    discount.push(...getDiscounts(item))
                    
                    const html = mustacheWriter.render(
                        this.state.template, {
                            '订单编号': props.transaction.contract,
                            '学员姓名': response.data.name,
                            '学员电话': response.data.phone,
                            '出生日期': response.data.birthday,
                            '学员性别': response.data.gender,
                            '合同编号': this.props.transaction.contract,
                            '报读课程': course ? course.name : '',
                            '课程费用': '¥' + props.transaction.subtotal + '  '  + ConvertNumToChinese(Number(props.transaction.subtotal)) + '元',
                            '付费方式': props.transaction.payments ? props.transaction.payments.map(p => p.payment_method ? p.payment_method.name : '').join(', ') : '',
                            '优惠活动': discount.join(', '),
                            '单价': course && course.chargeMode !== '按时间' ? ( item.detail.rule.price / item.detail.rule.lessons).toFixed(2) : '',
                            '就读时间': `从${start ? start.format('YYYY-MM-DD') : ''}起至${ end ? end.format('YYYY-MM-DD') : ''}为止，
                                        ${course && course.chargeMode !== '按时间' ? '共计课时'+ ((item.detail.rule.lessons * item.detail.quantity) )　+ (course ? course.unit : '') :''}
                                        ${monthSpan ? '，共计' + monthSpan + '月': ''}`
                    })

                    this.setState({
                        transaction: this.props.transaction,
                        student: response.data,
                        html: html
                    })
                })
        }
    }

    componentWillMount () { 
        this.props.dataProvider(GET_LIST, 'contracts',
            {
                pagination: { page: 1, perPage: 100000 }
            }).then(
            result => 
            {
                if ( result && result.data && result.total > 0) {
                    this.setState({ template: decodeURIComponent(result.data[0].content) })
                }
            }
        )
    }

    render () {
        const { classes } = this.props
        return (
            <Dialog 
                open={this.props.showDialog} 
                onClose={this.handleCloseClick}
                onEnter={ () => {
                    this.title = document.title;
                    document.title = ''; 
                }}
                classes={{ paper: classes.printDialog }} >
                <DialogTitle id="responsive-dialog-title">
                请直接修改下面的合同， 如有地方不正确
                </DialogTitle>
                <DialogContent classes={{ root: {height: '29cm', width: '21cm'}}} dividers>
                    <RaButton label="打印" onClick={() => printJS({printable: 'contract-print', type: 'html', 
                                                        documentTitle: '',
                                                        scanStyles: false,  targetStyle: ['color'],
                                                        style: 'p { margin: 0 } table td, table th { padding: 2px }',
                                                        css: ['/tinymce/js/tinymce/skins/ui/oxide/skin.min.css', 
                                                                '/tinymce/js/tinymce/skins/ui/oxide/content.min.css'], 
                                                                targetStyles: ['*']})} ><PrintIcon/></RaButton>
                    {
                        this.state.html && this.state.transaction && this.state.student ? 
                        <ShowContract value={this.state.html} /> :
                        <LoadingIndicator />
                    }
                </DialogContent>
            </Dialog>
        )
    }
}


class Refunds extends Component
{
    componentWillMount () {
        if (this.props.applyType === '退费') {
                this.props.dispatch({type: 'SET_TRANSACTION_MESSAGE', payload: {
                    message: (
                        <Typography style={{marginTop: 6}} color="textSecondary">
                                该学员共在此课程参加
                                <Typography color="primary" style={{display: 'inline', fontSize: '1rem', fontWeight: '700'}}> 
                                {
                                    this.props.defaultValue.period
                                }
                                </Typography>课时
                                
                        </Typography>
                    )
                }});
                Promise.all([this.props.dataProvider(GET_LIST, 'transaction-items',
                            {
                                pagination: { page: 1, perPage: 100000, sort: ''  },
                                filter: { studentsLearningId: this.props.defaultValue.learningId }
                        }),
                        this.props.dataProvider(GET_LIST, 'courses',
                        {
                            pagination: { page: 1, perPage: 1, sort: ''  },
                            filter: { id: this.props.defaultValue.courseId, idAndName: true }
                         })
                        ])
                .then(result => {
                    let data = result[0];
                    if (data.total > 0) {
                        let period = this.props.defaultValue.period;
                        data.data.map(d => {
                            d.createdAt = d.createdAt ? moment(d.createdAt) : null;
                            return d })
                            .sort((a, b) => {
                                if (!a.createdAt) {
                                    return -1;
                                } else if (!b.createdAt) {
                                    return 1;
                                } else {
                                    return a.createdAt > b.createdAt ? 1 : -1
                                }
                        }).forEach(d => 
                            {
                                let detail = null;
                                if (d.detail.chargeMode === '按时间') {
                                    detail = {
                                        duration: d.detail.rule.lessons,
                                        refundAmount: Number(d.total),
                                        purchasedPrice: Number(d.total)
                                    }
                                } else {
                                    let purchasedLessons = d.detail.rule.lessons * d.detail.quantity;
                                    let refundLessons = purchasedLessons - 
                                        d.refundItems.reduce((t, r) => t + r.detail.refundLessons ,0);
                                    if (refundLessons - period >= 0) {
                                        refundLessons = refundLessons - period
                                        period = 0;
                                    } else {
                                        refundLessons = 0;
                                        period -= refundLessons
                                    }
                                    let amountPerlesson = refundLessons === 0 ? 0 : 
                                        Number((Number(d.total)/purchasedLessons).toFixed(2)); 
                                    detail = {
                                        refundLessons: refundLessons,
                                        maxRefundLessons: refundLessons,
                                        freeLessons: d.detail.freeLessons || 0,
                                        purchasedLessons: purchasedLessons,
                                        purchasedPrice: Number(d.total),
                                        amountPerlesson: amountPerlesson,
                                        refundAmount: refundLessons === purchasedLessons ? 
                                                    Number(d.total): 
                                                    refundLessons * amountPerlesson,
                                        courseName: result[1] ? result[1].data[0].name : null
                                    };
                                }
                                if (d.detail.freeLessons > 0) {
                                    this.props.dispatch(arrayPush('transaction-form', 'refunds', 
                                        {
                                            type: '课程学费',
                                            transactionType:  '赠送课时',
                                            chargeMode: d.detail.chargeMode,
                                            itemId: d.id,
                                            detail: {
                                                refundLessons: d.detail.freeLessons,
                                                maxRefundLessons: d.detail.freeLessons,
                                                purchasedLessons: d.detail.freeLessons,
                                                purchasedPrice: 0,
                                                amountPerlesson: 0,
                                                refundAmount: 0,
                                                courseName: result[1] ? result[1].data[0].name : null
                                            }
                                        }
                                    ));
                                }
                                this.props.dispatch(arrayPush('transaction-form', 'refunds', 
                                        {
                                            type: '课程学费',
                                            transactionType: d.transaction.type,
                                            chargeMode: d.detail.chargeMode,
                                            itemId: d.id,
                                            detail: detail
                                        }
                                    ));
                            })
                    }
                })
        }
    }

    componentWillReceiveProps (props) {
        if (this.props.state.form['transaction-form'].values.endCourse === 'partial' &&
            props.state.form['transaction-form'].values.endCourse === 'all') {
                props.state.form['transaction-form'].values.refunds.forEach((r, i) => 
                    this.props.dispatch(change('transaction-form',
                        `refunds[${i}].detail.refundLessons`,  r.detail.maxRefundLessons))
                    )
            }
    }

    render () {
         const {classes, translate} = this.props;
            return (             
                <Fragment> 
                    <RadioButtonGroupInput source="endCourse" 
                      label="退多少课时?" choices={[
                        { id: 'all', name: '全部退费并结业' },
                        { id: 'partial', name: '退部分课时，继续上课' }
                    ]}>
                    </RadioButtonGroupInput>

                    <RadioButtonGroupInput source="pricePerLesson" 
                      label="如何计算退款金额?" choices={[
                        { id: 'regular', name: '按照付费时的课单价退款' },
                        { id: 'custom', name: '自己定义退费的课时和金额' }
                    ]}>
                    </RadioButtonGroupInput>
                {
                    this.props.state.form['transaction-form'] && 
                    this.props.state.form['transaction-form'].values.refunds ?
                    <Fragment>
                   
                        <ArrayInput label="交易记录" source="refunds">
                            <TableIterator disableAdd={true} disableRemove={true}>
                            <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) =>
                                        <TextInput style={{display: 'none'}} source={getSource('detail.courseName')} />
                                    }
                                </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) =>
                                    <div>{
                                        translate(scopedFormData.transactionType)
                                    }
                                    <Typography color="textSecondary" >
                                        {
                                            courseRuleToString(scopedFormData.chargeMode, 
                                                                scopedFormData.detail, 
                                                                scopedFormData.detail)
                                        }
                                        </Typography>
                                    </div>
                                }
                            </FormDataConsumer>

                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) =>
                                        <NumberInput disabled={scopedFormData.end || 
                                                this.props.state.form['transaction-form'].values.endCourse === 'all'
                                                || (!scopedFormData.end && 
                                                    !this.props.state.form['transaction-form'].values.endCourse === 'all' && 
                                                    this.props.state.form['transaction-form'].values.pricePerLesson === 'custom')} 
                                                label="退出课时" classes={{ root: classes.arraySlim }}
                                                step={1} 
                                                validate={
                                                    [
                                                        maxValue(getSource('detail.maxRefundLessons')),
                                                        minValue(0)
                                                    ]
                                                } inputProps={{
                                                    max: scopedFormData.detail.maxRefundLessons,
                                                    min: 0
                                                }}
                                                onChange={(e, refundLessons) => {
                                                    this.props.dispatch(change('transaction-form',
                                                        `refunds[${ExtractIndexFromId(rest.id)}].detail.refundLessons`,  refundLessons));

                                                    this.props.dispatch(change('transaction-form', 
                                                        `refunds[${ExtractIndexFromId(rest.id)}].detail.refundAmount`,  
                                                        Number((formData.refunds[ExtractIndexFromId(rest.id)].detail.amountPerlesson * refundLessons).toFixed(2))))
                                                }}
                                            source={getSource('detail.refundLessons')} />
                                        }
                                </FormDataConsumer>

                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) =>
                                            <TextInput disabled={ scopedFormData.end || 
                                                this.props.state.form['transaction-form'].values.pricePerLesson === 'regular'} 
                                                validate={required()} label="退款金额"
                                                classes={{ root: classes.arraySlim }}
                                                validate={
                                                    [
                                                        maxValue(getSource('detail.refundAmount')),
                                                        minValue(0)
                                                    ]
                                                }
                                                inputProps={{
                                                    max: scopedFormData.detail.refundAmount,
                                                    min: 0
                                                }}
                                                InputProps={{
                                                    inputComponent: MoneyFormat
                                            }} parse={ParseNumber} source={getSource('detail.refundAmount')} />
                                    }
                                    </FormDataConsumer>
                        </TableIterator>
                    </ArrayInput>
                </Fragment>
                :
                <DatagridLoading nbChildren={5} classes={classes}/>
                }
                </Fragment>
        )
    }
}

const tuitions = ({ classes, beforeCreateItem, ...props }) => (
    <ArrayInput label="applyCourse" source="tuitions">
        <TableIterator disableAdd={props.disableAdd} disableRemove={props.disableRemove}
            beforeCreateItem={() => { props.dispatch({ type: 'SHOW_SELECT_COURSE', payload: { showSelectCourse: true, } }) }}>
            <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                    <SelectInput style={{ width: 160, minWidth: 160 }}
                        classes={{ input: classes.arrayDense }}
                        source={getSource('detail.rule')}
                        validate={required()}
                        onChange={model => {
                            formData.tuitions[ExtractIndexFromId(rest.id)].detail.price = model.price;
                            props.dispatch(change('transaction-form', 'tuitions', formData.tuitions))
                        }}
                        choices={scopedFormData && courseRulesToString(scopedFormData.course)
                            .map((s, i) => ({ id: scopedFormData.course.rules[i], name: s }))}
                        {...rest}

                        label={
                            scopedFormData.classId ?
                                scopedFormData.course.classes.find(c => c.id == scopedFormData.classId).name :
                                scopedFormData.course.name
                        }
                    />}
            </FormDataConsumer>

            <TextInput label="数量" validate={required()}
                classes={{ root: classes.arrayExtraSlim }}
                InputProps={{
                    inputComponent: PositiveNumberFormat
                }} parse={ParseNumber} source="detail.quantity" />
            <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                    <SelectArrayInput style={{ width: 100, minWidth: 100 }} label="优惠" validate={required()}
                        source={getSource("detail.hasDiscount")} choices={
                            scopedFormData.course.chargeMode === '按时间' ? 
                            [ { id: 'pct', name: '学费折扣' },
                            { id: 'off', name: '学费优惠' }]
                            :
                            [ { id: 'freeLessons', name: '赠送课时' },
                            { id: 'pct', name: '学费折扣' },
                            { id: 'off', name: '学费优惠' }]
                        } />}
            </FormDataConsumer>

            <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => <div>{
                      scopedFormData.detail 
                        ? <div>
                            {
                                scopedFormData.detail.hasDiscount.some(d => d === 'freeLessons') &&
                                <TextInput style={{ marginTop: 0, width: 100, minWidth: 100 }} label="赠送课时" 
                                    source={getSource("detail.freeLessons")} 
                                    InputProps={{
                                    inputComponent: NumberFormat }} 
                                    parse={ParseNumber} /> 
                                }
                                <br />
                            {
                                scopedFormData.detail.hasDiscount.some(d => d === 'pct') &&
                                <SelectInput style={{marginTop: 0}} label="优惠折扣" style={{ width: 100, minWidth: 100 }}
                                    source={getSource("detail.discount_pct")} choices={discounts()} /> 
                            }
                            <br />
                            {
                                scopedFormData.detail.hasDiscount.some(d => d === 'off') &&
                                <TextInput style={{ marginTop: 0, width: 100, minWidth: 100 }} label="优惠金额" 
                                    source={getSource("detail.discount_off")} 
                                    InputProps={{
                                    inputComponent: MoneyFormat }} 
                                    parse={ParseNumber} /> 
                                }
                            
                                </div>: null
                }</div>}
            </FormDataConsumer>
        </TableIterator>
    </ArrayInput>
)


const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        refreshView: refreshViewAction
    }
}


const mapStateToProps = state => {
    return {
        showDialog: state.student.showApplyCourse,
        applyType: state.student.applyType,
        defaultValue: state.student.transactionDefaultValue,
        state: state
    }
};

const TuitionArray = compose(connect(mapStateToProps, mapDispatchToProps))(tuitions);

const MaterialArray = props => (
    <SimpleProductItemArray source="materials" label="教材" productSource="materials.id"
        productReference="materials" {...props} />
)

const IncidentalArray = props => (
    <SimpleProductItemArray source="incidentals" label="杂费" productSource="incidentals.id"
        productReference="incidentals" {...props} />
)

const getError = (props, list, index, obj, prop)  => {
    return  props.state.form &&
            props.state.form['transaction-form'] &&
            props.state.form['transaction-form'].syncErrors &&
            props.state.form['transaction-form'].syncErrors[list] &&
            props.state.form['transaction-form'].syncErrors[list][index] &&
            props.state.form['transaction-form'].syncErrors[list][index][obj] &&
            props.state.form['transaction-form'].syncErrors[list][index][obj].hasOwnProperty(prop) ?
            props.state.form['transaction-form'].syncErrors[list][index][obj][prop] : ''
}

const SimpleProductItemArray = compose(connect(null, mapDispatchToProps))(
    ({ classes, source, label, productLabel, productSource, productReference, ...props }) => (
        <ArrayInput label={label} source={source}>
            <TableIterator beforeCreateItem={() => ({ type: source === 'materials' ? '教材' : '杂费', detail: { quantity: 1 } })}>
                <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }) =>
                        <ReferenceInput validate={required()} perPage={10} onChange={(model, id) => {
                            formData[source][ExtractIndexFromId(rest.id)].detail.price = props.state.admin.resources[source].data[id].sellingPrice;
                            formData[source][ExtractIndexFromId(rest.id)].detail.referenceId = id;
                            formData[source][ExtractIndexFromId(rest.id)].detail.type = source;
                            props.dispatch(change('transaction-form', source, formData[source]))
                        }}
                           
                            filterToQuery={searchText => ({ 'name[$iLike]': `%${searchText}%` })}
                            label={`${label}名称`} source={getSource(productSource)} reference={productReference}>
                                {
                                <AutocompleteInput optionText="name"
                                        options={{placeholder: "输入名称以筛选", style: { width: 252, minWidth: 252 },
                                        error: getError(props, source, ExtractIndexFromId(rest.id), source, 'id'),
                                       helperText:getError(props, source, ExtractIndexFromId(rest.id), source, 'id'),
                                        suggestionsContainerProps: { 
                                            style: {zIndex: 1600}
                                         } }}  />}
                        </ReferenceInput>}

                </FormDataConsumer>

                <TextInput validate={required()} label="数量"
                    classes={{ root: classes.arraySlim }}
                    InputProps={{
                        inputComponent: PositiveNumberFormat
                    }} parse={ParseNumber} source="detail.quantity" />

                <TextInput validate={required()} label="金额"
                    classes={{ root: classes.arraySlim }}
                  
                    InputProps={{
                        inputComponent: MoneyFormat
                    }} parse={ParseNumber} source="detail.price" />

            </TableIterator>
        </ArrayInput>
    )
)

const PaymentMethod = connect(state => ({ formData: getFormValues('transaction-form')(state) }))
    (({ classes, ...props }) => (
        <ArrayInput label="支付信息" source="payments">
            <TableIterator  beforeCreateItem={() => {
                let subtotal = calcTotal(props.formData);
                return {
                    received: subtotal - (props.formData.payments ? 
                            props.formData.payments.reduce((sum, p) => sum+p.received, 0) : 0)
                }
            }}>
                <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }) =>
                        <ReferenceInput
                            filter={{
                                'id[$nin]': formData.payments.filter(item => item.paymentMethod)
                                    .map(item => item.paymentMethod.id)
                            }}
                            source={getSource("paymentMethod.id")} reference="payment-methods"
                            validate={required()} label="支付方式" > 
                             {
                                    <SelectInput optionText="name" 
                                    error={
                                        getError(props, 'payments', ExtractIndexFromId(rest.id), 'paymentMethod', 'id')
                                    }
                                    helperText={
                                        getError(props, 'payments', ExtractIndexFromId(rest.id), 'paymentMethod', 'id')
                                    }
                                    style={{ width: 252, minWidth: 252 }} />
                             }
                            
                        </ReferenceInput>
                    }
                </FormDataConsumer>
                    <TextInput validate={required()}
                        label="支付金额" classes={{ root: classes.arraySlim }}
                        InputProps={{
                            inputComponent: MoneyFormat
                        }} parse={ParseNumber}
                        defaultValue={props.formData.subtotal}
                        source="received" />

            </TableIterator>
        </ArrayInput>
    ))



const Total = connect(mapStateToProps, mapDispatchToProps)(({ ...props }) => {
     if (!props.state.form['transaction-form']) {
         return null;
     }
    let formData = props.state.form['transaction-form'].values;
     return (
        <Card style={{ width: '100%', boxShadow: 'none'}}>
            <Collapse in={props.state.student.showTransactionDetail} timeout="auto" unmountOnExit>
                <CardContent>

               {
                    formData.tuitions && formData.tuitions.length >0 && 
                    <Fragment>
                        <MuiList dense={true} component="nav"
                            subheader={<ListSubheader component="div">{
                            props.translate('courses')
                            }</ListSubheader>}  > 
                            {
                                formData.tuitions.map(t => 
                                <ListItem >
                                <ListItemText primary={t.course.name}　
                                    secondary={ 
                                    `总${props.translate('课时')}: 
                                    ${t.detail.chargeMode === '按时间' ?
                                        t.detail.quantity + ' * ' + t.detail.rule.lessons :
                                        t.detail.quantity * t.detail.rule.lessons + (t.detail.freeLessons||0)}`
                                    } /> 
                                <ListItemSecondaryAction>
                                <Typography style={{ display: 'inline-block', fontSize: '1em' }} variant="display1">
                                        小计: <MoneyFormat displayType="text" 
                                                value={ t.total } thousandSeparator={true} prefix={'¥'} />
                                    </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )} 
                    </MuiList>
                    <Divider />
                 </Fragment>
            }
               
                {
                   formData.materials  &&  formData.materials.length >0 && 
                   <Fragment>
                        <MuiList dense={true} component="nav"
                            subheader={<ListSubheader component="div">{
                                props.translate('教材')
                            }</ListSubheader>}  >
                                {
                                formData.materials.filter(m => m.materials).map(t => 
                                    <ListItem >
                                    <ListItemText primary={ 
                                    props.state.admin.resources.materials.data[t.materials.id] ?
                                    props.state.admin.resources.materials.data[t.materials.id].name : ''
                                    }
                                        secondary={ 
                                        ` 数量: ${t.detail.quantity}` } /> 
                                    <ListItemSecondaryAction>
                                    <Typography style={{ display: 'inline-block', fontSize: '1em' }} variant="display1">
                                            小计: <MoneyFormat displayType="text" 
                                                    value={ t.total } thousandSeparator={true} prefix={'¥'} />
                                        </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) 
                            }
                        </MuiList> 
                        <Divider />
                    </Fragment>
                    }
                   
                   {
                    formData.incidentals  &&  formData.incidentals.length >0 && 
                    <Fragment>
                            <MuiList dense={true} component="nav"
                                subheader={<ListSubheader component="div">{
                                    props.translate('教材')
                                }</ListSubheader>}  >
                                    {
                                    formData.incidentals.filter(m => m.incidentals).map(t => 
                                        <ListItem >
                                        <ListItemText primary={ 
                                        props.state.admin.resources.incidentals.data[t.incidentals.id] ?
                                        props.state.admin.resources.incidentals.data[t.incidentals.id].name : ''
                                        }
                                            secondary={ 
                                            ` 数量: ${t.detail.quantity}` } /> 
                                        <ListItemSecondaryAction>
                                        <Typography style={{ display: 'inline-block', fontSize: '1em' }} variant="display1">
                                                小计: <MoneyFormat displayType="text" 
                                                        value={ t.total } thousandSeparator={true} prefix={'¥'} />
                                            </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ) 
                                }
                            </MuiList> 
                            <Divider />
                        </Fragment>
                    }
                </CardContent>
            </Collapse>   

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '0px 16px', width: '100%' }}> 
                   <div > 费用总计: <Button onClick={() => props.dispatch({ type: 'SHOW_TRANSACTION_DETAIL', payload: { show: !props.state.student.showTransactionDetail}})} style={{ width: 110, whiteSpace: 'nowrap', padding: '4px 8px' }}>
                        　　　显示详情  
                            {
                                props.state.student.showTransactionDetail ?
                                <ExpandMoreIcon />: <ExpandLessIcon />
                            }
                            
                         </Button> </div> <Typography style={{ display: 'inline-block' }} variant="display2">
                        <NumberFormat value={calcTotal(formData).toFixed(2)}
                            displayType="text" thousandSeparator={true} prefix={'¥'} />
                    </Typography>
                </div>
                
        </Card>         
        
    )
})

const calcTotal = formData => {
    setTotal(formData);
    let subtotal = 0; 
    if (formData.tuitions && isArray(formData.tuitions)) {
        subtotal = formData.tuitions.reduce((sum, item) => {
            return sum + item.total;
        }, subtotal);
    }
    if (formData.materials && isArray(formData.materials)) {
        subtotal = formData.materials.reduce((sum, item) => {
            return sum + item.total;
        }, subtotal);
    }
    if (formData.incidentals && isArray(formData.incidentals)) {
        subtotal = formData.incidentals.reduce((sum, item) => {
            return sum + item.total;
        }, subtotal);
    }

    if (formData.refunds && isArray(formData.refunds)) {
        subtotal = formData.refunds.reduce((sum, item) => {
            return sum + item.detail.refundAmount;
        }, subtotal);
    }
    
    return subtotal;
}

const setTotal = formData => {
    let subtotal = 0;
    if (formData.tuitions && isArray(formData.tuitions)) {
        formData.tuitions.forEach(item => {
            let total = item.detail.rule && item.detail.quantity ?
                (item.detail.rule.price * item.detail.quantity) : 0;
            if (item.detail.hasDiscount.some(d => d === 'pct') && item.detail.discount_pct) {
                total = Math.round((total * (ParseNumber(item.detail.discount_pct) * 10)) / 100);
            } 
            if (item.detail.hasDiscount.some(d => d === 'off') && item.detail.discount_off) {
                total -= item.detail.discount_off;
            }
            subtotal += total;
            item.total = total;
        });
    }
    if (formData.materials && isArray(formData.materials)) {
        formData.materials.forEach(item => {
            item.total = item.detail && item.detail.price > 0 && item.detail.quantity > 0 ?
                item.detail.price * item.detail.quantity : 0;
            subtotal += item.total;
        });
    }
    if (formData.incidentals && isArray(formData.incidentals)) {
        formData.incidentals.forEach(item => {
            item.total = item.detail && item.detail.price > 0 && item.detail.quantity > 0 ?
                item.detail.price * item.detail.quantity : 0;
            subtotal += item.total;
        });
    }
    if (formData.refunds && isArray(formData.refunds)) {
        formData.refunds.forEach(item => {
            item.total = item.detail.refundAmount;
            subtotal += item.total;
        });
    }
    formData.subtotal = subtotal;
    return formData;
}

const getReferenceFromItemDetail = item => {
    if (item.type === '课程学费') {
        return item.detail.type === 'class' ? 'classes' : 'courses';
    } else if (item.type === '教材') {
        return 'materials';
    } else {
        return 'incidentals';
    } 
}


//export const EditStudent = compose(withStyles(styles))(edit);
export const CreateTransaction = compose(withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withDataProvider,
    translate,
    withMobileDialog())(AddTransaction);

export const TransactionList = compose(withStyles(styles), connect(null, mapDispatchToProps))(Transactions)

const RefundArray = compose(withStyles(styles),
                            connect(mapStateToProps, mapDispatchToProps),
                            translate,
                            withDataProvider)(Refunds);

export const PrintContract = compose(
    withStyles(styles),
    withDataProvider,
    connect(state => {
        return {
            showDialog: state.student.showPrintContract,
            transaction: state.student.transaction
        }
    }, mapDispatchToProps))(ContractDialog);