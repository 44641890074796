
const findWorkingBranchById = (branch, id) => {
    if (!branch)  { return false }
    if (branch.id === id) {
        return branch;
    } else if (branch.children) {
        for(let child of branch.children) {
            let b = findWorkingBranchById(child, id);
            if (b !== false) {
                return b;
            }
        }
    }
    return false;
}

export default (previousState = { ui: { showMediaPreview: false } }, { type, payload }) => {
   
    if (type === 'SET_USER') {
        return {
            ...previousState,
            user: payload.user
        }
    } else if (type === 'SET_WORKING_BRANCH') {
        let startFrom = previousState.user ? previousState.user.branch: null;
        return {
            ...previousState,
            workingBranch: findWorkingBranchById(startFrom, payload.branchId)
        }
    } else if (type === 'TOGGLE_MEDIA_PREVIEW') {
        console.log(previousState)
        return {
            ...previousState,
            ui: {
                showMediaPreview: !previousState.ui.showMediaPreview,
                mediaPreivewUrl: payload.url
            }
        }
    }
    return previousState;
}
