import React, { Component} from 'react'
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'react-admin';
import { Labeled } from 'react-admin';
import DatePicker from "react-datepicker";
import TextField from '@material-ui/core/TextField';
import "react-datepicker/dist/react-datepicker.css";
import zhLocale from "date-fns/locale/zh-CN";
import Input from '@material-ui/core/Input';

const moment = require('moment');

const toLocaleStringSupportsLocales = (() => {
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    try {
        new Date().toLocaleString('i');
    } catch (error) {
        return error instanceof RangeError;
    }
    return false;
})();

class DateInput extends Component {
   
    formatDate = props => {
        const val = props.value;
        if (!val || val.length === 0) {
            return <Input />;
        }
        const date = val instanceof Date ? val : 
        moment(val).toDate()

        const dateString = 
        this.props.customFormat ?
            date.toLocaleString('zh-CN', this.props.customFormat)
            : this.props.showTimeSelect
            ? this.props.showTimeSelectOnly ? toLocaleStringSupportsLocales ?
            date.toLocaleString('zh-CN', { hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' }) 
            :  date.toLocaleString()
            : toLocaleStringSupportsLocales
                ? date.toLocaleString('zh-CN', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' })
                : date.toLocaleString()
            : toLocaleStringSupportsLocales
            ? date.toLocaleDateString('zh-CN', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Shanghai' })
            : date.toLocaleDateString();
            return  <Input style={{width: '100%'}} value={dateString}/>;
    }

    render () {
        const {
            className,
            meta,
            input,
            isRequired,
            label,
            options,
            source,
            resource,
            locale,
            datePickerProps,
            textFieldProps,
            timeFormat,
            dateFormat,
            timeCaption,
            showTimeSelect,
            timeIntervals,
            style,
            classes,
            ...rest
        } = this.props;
        return (
              <DatePicker 
                    selected={input && input.value ? new Date(input.value) : null}
                    onChange={e => { input.value = input.onChange(e)}}
                    locale={locale|| 'cn-ZH'}
                    timeFormat={timeFormat}
                    utcOffset={0}
                    dateFormat={dateFormat}
                    timeCaption={timeCaption}
                    showTimeSelect={showTimeSelect}
                    timeIntervals={timeIntervals}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="scroll"
                    {...rest}
                    customInput={
                        <TextField
                            className={className}
                            margin="normal"
                            // error={!!(touched && error)}
                            // helperText={touched && error}
                            {...textFieldProps}
                            label={
                                <FieldTitle
                                    label={label}
                                    source={source}
                                    resource={resource}
                                    isRequired={isRequired}
                                />
                            }
                            style={style}
                            classes={classes}
                            InputProps={{
                                inputComponent: this.formatDate,
                                shrink: true
                            }}
                            onChange={e => {input.value = input.onChange(e)} }
                        />
                    }
                />
             
          )
    }
}
    
  
DateInput.propTypes = {
    addField: PropTypes.bool.isRequired,
    addLabel: PropTypes.bool.isRequired,
    input: PropTypes.object,
    label: PropTypes.string,
    source: PropTypes.string,
    timeFormat:　PropTypes.string,
    //dateFormat:　PropTypes.string,
    timeCaption:　PropTypes.string,
    showTimeSelect: PropTypes.bool,
    timeIntervals: PropTypes.number
  }
  
DateInput.defaultProps = {
    addField: true,
    addLabel: true,
    record: {},
    defaultValue: (new Date()).getTime(),
    locale: zhLocale,
    timeFormat:"HH:mm",
    dateFormat:"yyyy-MM-dd HH:mm",
    timeCaption:"时刻",
    timeIntervals: 60,
    showTimeSelect: false
}
  
export default addField(DateInput)
  