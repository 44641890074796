import React from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        maxWidth: 120
    },
	avatar: {
		display: 'inline-block'
	}
});

const AvatarField = ({ record, size, avatarSource="avatar", nameSource="name", classes }) => {
    return (
        <Grid container wrap="nowrap" className={classes.container}
            alignItems="center" direction="row" spacing={2}>
            <Grid item xs={6}>
                <Avatar className={classes.avatar}
                    src={`${get(record, avatarSource)}`}
                />
                
            </Grid>
            <Grid>
            {get(record, nameSource)}
            </Grid>
        </Grid>
)};

export default withStyles(styles)(AvatarField);