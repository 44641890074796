import React, {Component} from 'react';
import { BooleanInput, List, NumberField, ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput,SimpleForm, TextInput, SelectInput, Edit, Filter } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon } from '../../components/Icons';
import TinyMCEInput from '../../components/Inputs/TinymceInput';
import {Editor} from '@tinymce/tinymce-react';
import { Writer } from 'mustache';
import FromNowField from '../../components/Fields/FromNowField';
import CloneButton from '../../components/CloneButton';

export const ContractList = props => {
    return  <List actions={<ListActions />}  {...props}>
               <Datagrid rowClick="edit">
                   <TextField source="name" label="合同模版"/>
                   <ReferenceField label="创建人" allowEmpty={true} 
                    source="createdBy" reference="employees" linkType="show">
                        <TextField source="name" />
                    </ReferenceField>
                  
                   <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                   <EditButton icon={<EditIcon />}/>
                   <CloneButton />
               </Datagrid>
           </List>
};

export const CreateContact = props => 
<Create actions={<FormContainerActions />} title="创建合同模版"  {...props}>   
    <Form/>
</Create>

export const EditContact = props => 
<Edit actions={<FormContainerActions />}  title="编辑合同模版"  {...props}>   
    <Form/>
</Edit>

const Form = props => <SimpleForm {...props} toolbar={<FormToolbar />}  
                            submitOnEnter={true} redirect="list" defaultValue={{isUseBranchCode:false}}>
                            <TextInput source="name" label="模版名" />
                            <BooleanInput source="isUseBranchCode" label="订单编号是否添加分校编号" />
                            <TextInput source="orderNumberPrefix" label="订单编号前缀" />
                            <TinyMCEInput source="content" 
                                label="模版内容"
                                init={{ language: 'zh_CN',
                                        height: '29cm',
                                        width: '21cm',
                                        skin: "oxide",
                                        fontsize_formats: "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 16pt 24pt 28pt 36pt 48pt",
                                        plugins: 'print preview template table advlist image imagetools lists save paste',
                                        toolbar: 'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
                                        paste_data_images: true,
                                        templates: [
                                            { title: '学员姓名', description: '报名学员的姓名', content: '{{ name }}' }
                                        ],
                                        template_cdate_format: '[CDATE: %m/%d/%Y : %H:%M:%S]',
                                        template_mdate_format: '[MDATE: %m/%d/%Y : %H:%M:%S]',
                                        content_style: 'p { margin: 0 } table td, table th { padding: 2px }'
                                }}
                            />
                            </SimpleForm>

export const ShowContract = props => 
{
    return (
        <Editor id="contract-print"
                  apiKey='mikf6vz3vkm1o6w6rfezfyjzlagw83yk01k6pwndakjfdzvd'
                   inline
                   initialValue={props.value}
                   init={{ language: 'zh_CN',
                                            menubar:false,
                                            statusbar: false,
                                            height: '29cm',
                                            width: '21cm',
                                            plugins: "print",
                                            menubar: "file",
                                            toolbar: "print",
                                            //content_style: ''
                                        }}
                            />
    )
}
                            
