import React, { Component } from 'react';
import { GET_LIST, Responsive, withDataProvider } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders';
//import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version) {
            this.fetchData();
        }
    }

    fetchData() {
        this.fetchOrders();
        this.fetchCustomers();
    }

    async fetchOrders() {
        const { dataProvider } = this.props;
        const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);
        const data = await dataProvider(GET_LIST, 'transactions',
            {
                filter: { 'transaction_date[$gt]': aMonthAgo.toISOString(), 
                          'type[$in]': ['报名', '续费', '扩科'],
                          
                          'exclude': ['transactionItems', 'payments'],
                          'attributes': [{ operator: 'sum', col: 'subtotal' },
                          { operator: 'count', col: 'subtotal' }],
                        },
                pagination: { page: 0, limit: 0, perPage: 1 },
                complete: data => {
                    this.setState({ last30days: data.data[0] })
                }
            }
        )
    }

    async fetchCustomers() {
        const { dataProvider } = this.props;
        const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);
        const { data: newCustomers } = await dataProvider(
            GET_LIST,
            'students',
            {
                sort: { field: 'createdAt', order: 'DESC' },
                pagination: { page: 1, perPage: 30 },
            }
        );
        this.setState({
            newCustomers,
            nbNewCustomers: newCustomers.reduce(nb => ++nb, 0),
        });
    }

    render() {
        const {
            nbNewCustomers,
            newCustomers,
            pendingOrders,
            pendingOrdersCustomers,
            revenue,
        } = this.state;
        return (
            <Responsive
                xsmall={
                    <div>
                        <div style={styles.flexColumn}>
                            <div style={{ marginBottom: '2em' }}>
                                <Welcome />
                            </div>
                            <div style={styles.flex}> 
                                <MonthlyRevenue value={this.state.last30days ? this.state.last30days.sum_subtotal: 0}  />
                                <NbNewOrders value={this.state.last30days ? this.state.last30days.count_subtotal: 0} />
                            </div>
                            <div style={styles.singleCol}>
                                <PendingOrders
                                    orders={pendingOrders}
                                    customers={pendingOrdersCustomers}
                                />
                            </div>
                        </div>
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                            <Welcome />
                        </div>
                        <div style={styles.flex}>
                             <MonthlyRevenue value={this.state.last30days ? this.state.last30days.sum_subtotal: 0}  />
                                <NbNewOrders value={this.state.last30days ? this.state.last30days.count_subtotal: 0} />
                        </div>
                        <div style={styles.singleCol}>
                            <PendingOrders
                                orders={pendingOrders}
                                customers={pendingOrdersCustomers}
                            />
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                            <MonthlyRevenue value={this.state.last30days ? this.state.last30days.sum_subtotal: 0}  />
                                <NbNewOrders value={this.state.last30days ? this.state.last30days.count_subtotal: 0} />
                            </div>
                            <div style={styles.singleCol}>
                                <Welcome />
                            </div>
                            <div style={styles.singleCol}>
                                <PendingOrders
                                    orders={pendingOrders}
                                    customers={pendingOrdersCustomers}
                                />
                            </div>
                        </div>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                {/* <PendingReviews
                                    nb={nbPendingReviews}
                                    reviews={pendingReviews}
                                    customers={pendingReviewsCustomers}
                                /> */}
                                <NewCustomers
                                    nb={nbNewCustomers}
                                    visitors={newCustomers}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps),
    withDataProvider
)(Dashboard);
