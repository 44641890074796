import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export default createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#47506d',
          },
        secondary: {
            main: '#FF6C4D',
          },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    spacing: {
        unit: 10
    },
    typography: {
        fontSize: 13,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Open Sans',
            'BlinkMacSystemFont',
            '-apple-system',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'Helvetica',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        a: {
            MuiTypography: {
                body1: {
                    color: '#000 !important'
                }
            }
        }
        
        ,
        MuiAppBar: { // override the styles of all instances of this component
            root: {
              
            },
            colorPrimary: {
                color: '#fff'
            }
        },
        MuiButton: {
            raisedPrimary: { // Name of the rule
                color: '#fff', // Some CSS
            },
            raisedSecondary: { // Name of the rule
                color: '#fff', // Some CSS
            }
        },
        MuiInput: {
            root: {
                fontSize: '0.85rem'
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.85rem'
            }
        },
        MuiFormControl: {
            root: {
                width: 320
            },
            fullWidth: {
                width: '100% !important'
            }
        },
        MuiTextField: {
            root: {
                fontSize: 15,
                width: 320,
                backgroundColor: '#ccc',
            },
            input: {
                width: 320
            }
        },
        MuiFormGroup: {
            root: {
                whiteSpace: 'nowrap',
                flexWrap: 'nowrap'
            }
        },
        MuiTabs: {
            scroller: {
                overflowX: 'auto'
            }
        },
        MuiTab: {
            root: {
                marginTop: 16
            },
            wrapper: {
                display: 'flex',
                flexDirection: 'row',
                '@media (max-width:500px)': {
                    flexDirection: 'column'
                },
            },
            labelContainer: {
                '@media (min-width:960px)': {
                    paddingLeft: 8
                },
                paddingLeft: 8
            }
        },
        MuiDrawer: {
            paperAnchorRight: {
                width: '30vw',
                minWidth: 360,
                maxWidth: 500,
                '@media (max-width:500px)': {
                    width: '100vw',
                    minWidth: '100vw'
                },
            }
            
        },
        MuiDialogContent: {
            root: {
                '@media (max-width:500px)': {
                    //paddingLeft: '0px'
                },
                // '@media print': {
                //     position: 'absolute',
                // }
            },
            // container: {
            //     '@media print': {
            //         height: 'auto',
            //     }
            // }
        },
        MuiTypography: {
            h3: {
                color: 'rgba(0, 0, 0, 0.87)',
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        },
        MuiTableCell: {
            head: {
                fontSize: '.72rem'
            }
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: 'rgba(0,0,0,0)'
            }
        },
        MuiCard: {
            root: {
                overflow: 'visible'
            }
        }
    },
});