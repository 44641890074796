import React, {Component, Fragment} from 'react';
import { withDataProvider,ReferenceField, required, TextField as RaTextField, Button as RaButton,
    GET_LIST, DatagridLoading, ReferenceInput, SelectInput, CREATE} from 'react-admin';
import { RefundIcon } from '../../components/Icons';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PositiveNumberFormat from '../../util/PositiveNumberFormat';
import { connect } from 'react-redux';
import MoneyFormat from '../../util/MoneyFormat';
import ParseNumber from '../../util/ParseNumber';
import Typography from '@material-ui/core/Typography';
import { showNotification } from 'ra-core';

const styles = theme => ({
    leftInput: { display: 'inline-block',  },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
       // width: 500
    },
    arraySlim: {
        width: '80px !important',
        marginRight: 16
    },
    card: {
        boxShadow: 'none'
    }
})


class Materials extends Component {
    state = {
       
    }

    submitRefund = record => {
        if (!this.state.paymentMethod) {
            this.setState({ error: '支付方式是必填的' });
            return;
        }
        this.props.dataProvider(CREATE, 'transactions', 
        {
            data: { 
                type: '教材杂费退费',
                students: [this.props.record.id],
                paymentMethod: this.state.paymentMethod,
                transaction: {
                    type: '教材杂费退费',
                    branchId: this.props.workingBranch.id,
                    internal_comment: this.state.internalComment,
                    external_comment: this.state.externalComment,
                    subtotal: this.state.quantity * this.state.price
                },
                transactionItem: {
                    type: this.state.refundItem.type + '退费',
                    detail: this.state.refundItem.detail,
                    total: this.state.quantity * this.state.price,
                    purchaseItemId: this.state.refundItem.id
                }
             }
        }).then(() => {
            this.props.dispatch(showNotification('退费成功'));
            this.fetchData();
            this.setState({
                open:false
            })
        })
    }

    componentWillMount () {
       this.fetchData();
    }

    fetchData () {
        if (this.props.record) {
            this.props.dataProvider(GET_LIST, 'transactions', 
            {
                filter: { studentId: this.props.record.id, 'type[$in]': ['续费', '报名', '教材杂费'] ,attributes: ['id'] },
                pagination: { page: 1, perPage: 100000 },
            }).then(transations => {
                if (transations.total > 0) {
                    this.props.dataProvider(GET_LIST, 'transaction-items', 
                        {
                            filter: { 'transactionId[$in]':  transations.data.map(d => d.id), 
                                    'type[$in]': ['教材', '杂费']  },
                            pagination: { page: 1, perPage: 100000 },
                        }).then(items =>  this.setState({ data: items.data  }))
                }
            });
        }
    }

    refund = item => {
        this.setState({
            open: true, 
            price: item.detail.price, 
            quantity: item.detail.quantity,
            refundItem: item
        })
    }

    render () {
        
        return (
            <Fragment> 
            {
                this.state.data ? 
                <Table>
                    <TableHead>
                       <TableRow>
                            <TableCell padding="dense">类型</TableCell>
                            <TableCell padding="dense">教材杂费名称</TableCell>
                            <TableCell padding="dense" align="right">价格</TableCell>
                            <TableCell padding="dense" align="right">数量</TableCell>
                            <TableCell padding="dense" ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        this.state.data &&
                        this.state.data.map(item => 
                            <TableRow>
                                <TableCell padding="dense">{item.type}</TableCell>
                                <TableCell padding="dense">
                                    <ReferenceField label="" record={item.detail} 
                                        source="referenceId" resource={item.detail.type} reference={item.detail.type} basePath={`/${item.detail.type}`}>
                                        <RaTextField source="name"  />
                                    </ReferenceField>
                                </TableCell>
                                <TableCell padding="dense" align="right">
                                    <NumberFormat displayType="text" 
                                        value={item.total} thousandSeparator={true} prefix={'¥'} />
                                </TableCell>
                                <TableCell padding="dense" align="right">
                                    <NumberFormat displayType="text" 
                                        value={item.detail.quantity} thousandSeparator={true} />
                                </TableCell>
                                <TableCell padding="dense" >
                                {
                                   Array.isArray(item.refundItems) &&
                                     item.refundItems.length > 0 ? <span>已退费</span>　:
                                    <RaButton onClick={this.refund.bind(this, item)}
                                         label="退费"><RefundIcon /></RaButton>
                                }
                                </TableCell>
                             </TableRow>
                        )
                    }
                    </TableBody>
                 </Table> : <DatagridLoading nbChildren={5} classes={this.props.classes}/>
            }

             <Dialog
                onClose={() => this.setState({open: false})}
                onExited={() => this.setState({open: false})}
                open={this.state.open} >
                <DialogTitle id="responsive-dialog-title">
                     退费
                </DialogTitle>
                { this.state.refundItem &&
                    <DialogContent dividers>
                        <div  style={{ width: 380 }}>
                        <Typography style={{marginRight: 16, marginTop: 6, display: 'inline-block'}} color="textSecondary">
                                
                                    <ReferenceField label="课程" record={ this.state.refundItem.detail }
                                        source="referenceId" basePath={`/${this.state.refundItem.detail.type}`}
                                        reference={this.state.refundItem.detail.type} link={false} >
                                        <RaTextField source="name" />
                                    </ReferenceField>
                            
                            </Typography>
                            <TextField classes={{ root: this.props.classes.arraySlim }}
                                label="退出数量" value={this.state.quantity}
                                type="number"
                                onChange={p => {
                                    if (p > 0) {
                                        this.setState({ quantity: p })
                                    }
                                }} inputProps={{ min: "1", max: this.state.refundItem.detail.quantity, step: "1" }} 
                                InputProps={{ inputComponent: PositiveNumberFormat  }} />
                            
                            <TextField classes={{ root: this.props.classes.arraySlim }} 
                                label="单价" value={this.state.price} onChange={p => {
                                    if (p > 0) {
                                        this.setState({ price: p })
                                    }
                                }} 
                                InputProps={{ inputComponent: MoneyFormat }} />

                            <Typography style={{ marginTop: 6, display: 'inline-block'}} color="textSecondary">
                                总计：
                                <NumberFormat displayType="text" thousandSeparator={true}
                                 value={ this.state.quantity * this.state.price } prefix={'¥'} />
                               
                            </Typography>

                            <ReferenceInput onChange={(t, e) => this.setState({ error: null, paymentMethod: e })} 
                               error={this.state.error} label="支付方式*" 
                                reference="payment-methods" > 
                             {
                                <SelectInput error={this.state.error} optionText="name"  />
                             }    
                            </ReferenceInput>

                            <TextField label="内部备注" value={this.state.internalComment} 
                                    multiline rows="3" rowsMax="3" style={{marginTop: 16}}
                                    onChange={e => this.setState({internalComment: e.target.value  }) } />
                            <TextField label="外部备注" value={this.state.externalComment} 
                                    multiline rows="3" rowsMax="3" style={{marginTop: 16}}
                                    onChange={e => this.setState({externalComment: e.target.value  }) } />
                                    
                            </div>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={() => this.setState({open: false})} color="primary">
                        关闭
                        </Button>
                    <Button onClick={this.submitRefund} color="primary">
                        保存
                        </Button>
                </DialogActions>
              </Dialog>

             </Fragment>
       )
    }
};

const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
}

const mapStateToProps = state => ({
    workingBranch: state.global.workingBranch
});

export default compose(withDataProvider, 
                       connect(mapStateToProps, mapDispatchToProps),
                       withStyles(styles))(Materials)