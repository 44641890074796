import React, {Component} from 'react';
import {Button as RaButton, showNotification, Title} from 'react-admin';
import Button from '@material-ui/core/Button';
import XLSX from 'xlsx';
import {
    DragDropFile,
    DataInput,
    OutTable
} from '../../components/Widgets/Sheet';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { SaveIcon, ValidateDataIcon } from '../../components/Icons';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CreateCourse } from '../operation/course';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { validateStudentData } from './validateData';
import {withDataProvider, GET_LIST, CREATE} from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { change } from 'redux-form'

const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
    return o;
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    appBarRoot: {
        height: 64,
        boxShadow: 'none'
    },
    container: { 
        [theme.breakpoints.up('lg')]: {
        flex: '.6 .6 auto',
       },
       [theme.breakpoints.up('xl')]: {
        flex: '.5 .5 auto',
       }
    },
    closeButton: {
        position: 'absolute',
        color: theme.palette.grey[100],
        right: 16
      },
      dialogRoot: {
          padding: 0
      },
      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
      },
      listSection: {
        backgroundColor: 'inherit',
      },
      ul: {
        backgroundColor: 'inherit',
        padding: 0,
      },
});

const period = ['年', '月', '半年', '季', '天'];
const steps = ['课程导入', '班级导入', '学生导入']

class DataImport extends Component {
    state = {
        value: 0,
        importErrors: 0,
        data: [], /* Array of Arrays e.g. [["a","b"],[1,2]] */
        cols: [],  /* Array of column objects e.g. { name: "C", K: 2 } */
        imported: []
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };
    
    handleFile = (file/*:File*/) => {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString; 
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, {type: 'binary'});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1,  raw: false});
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                this.validateStudents().then(() => this.setState({open: true})).catch(e => console.log(e))
            });
		};
        if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	};

    handleDialogOpen = () => {
        this.setState({ open: true });
    };

    handleDialogClose = () => {
        this.setState({ open: false });
    };

    selection = position => {
        let x = position[0], y = position[1];
        window.grid.selectArea({ top: x, left: y, bottom: x, right: y});
        window.grid.gotoCell(y, x);
    }

    validateStudents = () => {
        if (this.state.errors && this.state.errors.count > 0)  {
            this.status = validateStudentData(window.grid.data, this.data[0].data, this.data[1].data, this.data[2].data);
                    
             this.setState({errors: this.status.errors, 
                                showErrors: this.status.errors.count > 0});
            return Promise.resolve({ errors: {count: 0} });
        } else {
            const { dataProvider } = this.props;
            let params = {
                filter: { idAndName: true },
                pagination: { page: 1, perPage: 100000 },
            }
            return Promise.all([dataProvider(GET_LIST, 'courses', params),
                        dataProvider(GET_LIST, 'classes', params),
                        dataProvider(GET_LIST, 'employees', params),
                        dataProvider(GET_LIST, 'payment-methods', params),
                    ])
            .then(data => 
                {
                    this.data = data;
                    this.status = validateStudentData(window.grid.data, this.data[0].data, this.data[1].data, this.data[2].data, this.data[3].data);
                    
                    this.setState({errors: this.status.errors, 
                                showErrors: this.status.errors.count > 0});

                
                    if (this.status.errors.count > 0) {
                       this.props.dispatch( this.props.showNotification(`有${this.status.errors.count}个验证错误， 请先解决错误再导入`, 'warning'));
                       return Promise.reject(this.status)
                    } else {
                        this.props.dispatch(  this.props.showNotification(`没有发现验证错误， 请执行导入`));
                    }
                    return Promise.resolve(this.status);
                }
            )
        }
    }

    createTransaction = (data, studentId, batch) => {
        let courseId = this.data[0].data.find(c => c.name === data[this.status.indexes.course.pos]).id;
        let clas = this.data[1].data.find(c => c.name === data[this.status.indexes.class.pos]);

        let type = data[this.status.indexes['transaction.type'].pos].trim() === '新报' ? '报名' :
            data[this.status.indexes['transaction.type'].pos].trim();
        let chargeMode = data[this.status.indexes['chargeMode'].pos] === '课时' ? '按课时' :
            data[this.status.indexes['chargeMode'].pos] === '课时每期' ? '按期' :
            period.some(v => v === data[this.status.indexes['chargeMode'].pos]) ? '按时间' : '';

        let transaction = {
            data: {
                batchTime: batch,
                subtotal: this.status.indexes['transaction.subtotal'].pos && data[this.status.indexes['transaction.subtotal'].pos] ? 
                            data[this.status.indexes['transaction.subtotal'].pos].trim() : 0,
                transaction_date: new Date(data[this.status.indexes['transaction.date'].pos]),
                tuitions: [{
                    type: "课程学费",
                    detail: {
                        quantity: 1,
                        chargeMode: chargeMode,
                        rule: {
                            "price": this.status.indexes.price.pos && data[this.status.indexes.price.pos] ? 
                                    data[this.status.indexes.price.pos].trim(): 0,
                            "lessons": chargeMode === '按时间' ? data[this.status.indexes.chargeMode.pos].trim() : data[this.status.indexes.lessons.pos].trim(),
                        },
                        type: clas ? 'class' : "course",
                        referenceId: clas ? clas.id : courseId,
                        freeLessons: data[this.status.indexes.freeLessons.pos],
                        remainingLessons: data[this.status.indexes.remainingLessons.pos]
                    },
                    course: {
                        id: courseId
                    },
                    total: data[this.status.indexes['transaction.subtotal'].pos]
                }],
                type: type,
                students: [studentId]
            }
        };
        if (data[this.status.indexes['transaction.payment'].pos]) {
            transaction.data.payments = [{
                paymentMethod: {
                    id: this.data[3].data.find(c => c.name === data[this.status.indexes['transaction.payment'].pos].trim()).id,
                    
                },
                received: data[this.status.indexes['transaction.subtotal'].pos].trim()
            }]
        }
        return transaction;
    }

     importData = () => {
         this.validateStudents().then(status => {
             if (status.errors.count === 0) {
                 const {
                     dataProvider
                 } = this.props;
                
                 let data = window.grid.data.slice(this.status.dataPos, window.grid.data.length)
                     .sort((a, b) => {
                         let aname = a[this.status.indexes.name.pos] || '';
                         let bname = b[this.status.indexes.name.pos] || '';
                         let acourse = a[this.status.indexes.course.pos] || '';
                         let bcourse = b[this.status.indexes.course.pos] || '';

                         let atype = a[this.status.indexes['transaction.type'].pos] || '';
                         let btype = b[this.status.indexes['transaction.type'].pos] || '';

                         if (aname === bname) {
                             if (acourse === bcourse) {
                                 if (atype === btype) {
                                     return 0;
                                 } else if (atype === '续费') {
                                     return 1;
                                 } else if (btype === '续费') {
                                     return -1;
                                 } else {
                                     return 0;
                                 }
                             } else {
                                 return acourse.localeCompare(bcourse);
                             }
                         } else {
                             return aname.localeCompare(bname);
                         }
                     });
                 this.setState({
                     importing: true,
                     showImportingStatus: true,
                     imported: []
                 });
                 let batch = new Date();
                 for (let i = 0; i < data.length; i++) {
                     let name = data[i][this.status.indexes.name.pos];
                     if (data[i][this.status.indexes.name.pos] &&
                         data[i][this.status.indexes['transaction.type'].pos] !== '续费') {
                         dataProvider(CREATE, 'students', {
                                 data: {
                                     name: data[i][this.status.indexes.name.pos],
                                     birthday: data[i][this.status.indexes.birthday.pos],
                                     gender: data[i][this.status.indexes.gender.pos] || '未知',
                                     contact_person: data[i][this.status.indexes.contact_person.pos],
                                     phone: data[i][this.status.indexes.phone.pos],
                                     secondary_contact_person: data[i][this.status.indexes.secondary_contact_person.pos],
                                     secondary_phone: data[i][this.status.indexes.secondary_phone.pos],
                                     address: data[i][this.status.indexes.address.pos],
                                     wechat: data[i][this.status.indexes.wechat.pos],
                                     comment: data[i][this.status.indexes.comment.pos],
                                     batchTime: batch,
                                     registered: (!data[i][this.status.indexes['transaction.type'].pos] || data[i][this.status.indexes['transaction.type'].pos]) ? true : false
                                 }
                             }).then(data => ({
                                 ...data
                             }))
                             .then(student => {
                                 if (data[i][this.status.indexes['transaction.type'].pos] && 
                                     data[i][this.status.indexes['transaction.type'].pos] !== '无') {
                                     return dataProvider(CREATE, 'transactions', this.createTransaction(data[i], student.data.id, batch))
                                         .then(result => ({
                                             ...student.data
                                         }));
                                 } else {
                                     return Promise.resolve();
                                 }
                             })
                             .then(student => {
                                 if (student && student.id) {
                                     let c = i + 1;
                                     let arr = []
                                     while (c < data.length &&
                                         data[c][this.status.indexes.name.pos] === student.name &&
                                         data[c][this.status.indexes['transaction.type'].pos] === '续费') {
                                         arr.push(this.createTransaction(data[c], student.id, batch));
                                         c++;
                                     }
                                     return Promise.all(
                                         arr.map(a =>
                                             dataProvider(CREATE, 'transactions', a)
                                         ))
                                 } else {
                                     Promise.resolve();
                                 }
                             })
                             .then(() => {
                                 this.state.imported.push({
                                     name: name,
                                     failed: false
                                 })
                                 this.setState({
                                     imported: this.state.imported,
                                     importing: this.state.imported.length !== this.status.validData
                                 })
                             })
                             .catch(e => {
                                 this.state.imported.push({
                                     name: name,
                                     failed: true
                                 })
                                 this.setState({
                                     imported: this.state.imported,
                                     importing: this.state.imported.length !== this.status.validData
                                 })
                             })
                     }
                 }
             }
         });
     }

    componentWillReceiveProps = props => {
        let prev = Object.keys(this.props.admin.resources.courses.data);
        let now = Object.keys(props.admin.resources.courses.data);
        if (now.length > prev.length && this.data) {
            this.data[0].data.push(
                {
                    id: now[now.length-1],
                    name: props.admin.resources.courses.data[now[now.length-1]].name
                }
            )
            this.validateStudents();
            this.setState({
                insert: false
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper >
                <Title title="数据导入" />
        <div style={{paddingTop: 32, paddingLeft: 32}} className="row"><div className="col-xs-12">
                                <DataInput handleFile={this.handleFile} />
            </div></div>
            <div style={{ width:'85%', overflow: 'auto', padding: 32}}>

                <DragDropFile handleFile={this.handleFile}>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                <div style={{padding: 15}}>
                                    {/* {
                                        this.state.data && this.state.data.length > 0 &&
                                            <OutTable data={this.state.data} cols={this.state.cols} />
                                    } */}
                                </div>
                                    
                                </Grid>
                        </Grid> 
                    </DragDropFile>
            </div>
            {
                this.state.data && this.state.data.length > 0 &&
                <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, zIndex: 1250 }}>
                     <AppBar classes={{ root: classes.appBarRoot}}>
                        <Toolbar>
                            {
                                this.state.errors && this.state.errors.count > 0 ?
                                    <Badge color="secondary" badgeContent={this.state.errors.count} >
                                        <RaButton buttonRef={node => this.anchorEl = node } color="inherit" label="验证数据" 
                                            onClick={this.validateStudents}>
                                            <ValidateDataIcon />
                                        </RaButton>
                                </Badge> :
                                <RaButton buttonRef={node => this.anchorEl = node } color="inherit" label="验证数据" 
                                     onClick={this.validateStudents}>
                                <ValidateDataIcon />
                            </RaButton>
                            }

                             <RaButton style={{marginLeft: 16}} color="inherit" label="开始导入" onClick={ this.importData }>
                                <SaveIcon />
                             </RaButton>

                                <IconButton className={classes.closeButton}
                                    onClick={() => this.setState({data: null})}
                                    color="inherit">
                                    <CloseIcon />    
                                </IconButton>
                          
                        </Toolbar>
                    　</AppBar>
                     <OutTable data={this.state.data} cols={this.state.cols} />       
                </div>
            }
            
                <Dialog
                    fullScreen={false}
                    open={this.state.insert}
                    fullWidth={true}
                    onClose={() => this.setState( { insert: false } )} 
                    onExited={() => this.setState( { insert: false } )} >
                    {/* <DialogTitle id="responsive-dialog-title">  </DialogTitle> */}
                    <DialogContent classes={{ root: classes.dialogRoot }} dividers>
                    {
                        this.state.insert ?
                            <CreateCourse dialogMode={true} resource="courses" location="courses" basePath="/courses" /> :
                            null
                        }
                    </DialogContent>
                </Dialog>
            
            
            <Popover
                open={this.state.showErrors}
                anchorEl={this.anchorEl}
                onClose={()=> this.setState({showErrors: false})} 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}>
                    <List className={classes.root} subheader={<li />}>
                        {this.state.errors && 
                        Object.keys(this.state.errors).map(key =>
                            this.state.errors[key].length > 0 ? (
                                <li className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        <ListSubheader>{key==='日期格式'?  `以下${key}不是有效的日期格式`:
                                                        key==='购买数量' ||  key==='剩余课时' ? 
                                                        `以下${key}不是有效的数字`
                                                        : `以下${key}在数据库中不存在`}</ListSubheader>
                                        {this.state.errors[key].map((item, i) => (
                                            <ListItem style={{ cursor: 'pointer' }}>
                                                {
                                                    key === '课程' &&
                                                    <ListItemSecondaryAction>
                                                             <Button variant="outlined" color="primary" 
                                                                onClick={() => this.setState({ insert: true }, () => {
                                                                    setTimeout(() => {
                                                                        this.props.dispatch(change('record-form', 'name', item.name))
                                                                    }, 1000)
                                                                })}> 新建此课程 
                                                                </Button>
                                                    </ListItemSecondaryAction>
                                                }

                                                <ListItemText onClick={this.selection.bind(this, item.position)}  
                                                            primary={ key === '课程' ? 
                                                                 item.name  :
                                                                `第${item.position[0]+1}行` } />
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            ): null )}
                        </List>
                </Popover>

                 <Dialog
                    open={this.state.showImportingStatus}
                   
                    scroll="paper" fullWidth={true}>
                    <DialogTitle id="scroll-dialog-title">
                        <div>
                          {
                              this.state.importing ? '导入中... (请不要关闭窗口)' : '导入完成'
                          }
                          <div style={{ marginTop: 8 }}>
                            <LinearProgress variant="determinate" 
                                value={this.state.imported.length/(this.status ? this.status.validData : 1) * 100} />    
                          </div>      

                        </div>
                    </DialogTitle>
                   
                    <DialogContent>
                        <ul style={{listStyleType: 'decimal'}}>
                           {
                               this.state.imported.map(d => 
                                    <li style={{ color: d.failed ? 'indianred' : 'inherit', marginTop: 4 }}>
                                        {
                                            d.failed ? 
                                            `导入 ${d.name} 发生错误`
                                            : `${d.name} 已经成功导入系统`
                                        }
                                    </li>
                                )
                           }
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.importing} onClick={()=> this.setState({showImportingStatus: false})} 
                                color="primary">
                            关闭
                        </Button>
                    </DialogActions>
               </Dialog>  
        </Paper>
           
        )
    }
}

export default compose(withDataProvider,
                       connect(state => state , 
                        dispatch => ({
                            showNotification,
                            dispatch
                         })),
                       withStyles(styles))(DataImport);
