import React, {Component} from 'react';
import { ArrayInput, NumberField, Datagrid, ReferenceField, DateField, FunctionField,  TextField, Create, ImageInput, ImageField, Button,
     SimpleForm,　TabbedForm, FormTab, TextInput, SelectInput, Edit, Filter, LongTextInput } from 'react-admin';


const styles = theme => ({
    leftInput: { display: 'inline-block',  },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
       // width: 500
    },
    card: {
        boxShadow: 'none'
    }
})


export const Rollcalls = props =>
   {   
       console.log(props)
       let data = [], ids = [];
       if (props.data) {
        data = Object.values(props.data)
        
        data.sort((a, b) => {
            if (new Date(a.classSchedule.beginAt) > new Date(b.classSchedule.beginAt)) {
                return -1
            } else {
                return 1
            }
        })
        console.log()
        ids = data.map(d => d.id);
       }

        return (
            <Datagrid {...props}  ids={ids} style={{ width: '100%' }}>
                <TextField sortable={false} label="记录" source="type" />
                <NumberField sortable={false} label="扣除课时" source="period" />
                <FunctionField sortable={false} label="记录情况" render={record => record.classSchedule.isLogged ? '已记录' : '未开课'} />
                <ReferenceField sortable={false} label="班级" source="classSchedule.classId" reference="classes">
                    <TextField source="name" />
                </ReferenceField>
                <TextField sortable={false} label="扣课时课程" source="course.name" />
                <ReferenceField sortable={false} label="上课教师" source="classSchedule.logTeacherId" reference="employees">
                    <TextField source="name" />
                </ReferenceField>
                <DateField sortable={false} locales="ZH" showTime sortBy={false}
                                    options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'}} 
                                label="上课开始时间" source="classSchedule.beginAt" />
                <DateField sortable={false} locales="ZH" showTime sortBy={false}
                                    options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'}} 
                                    label="上课结束时间" source="classSchedule.endAt" />
                {/* <Button label="撤销课时"><RevokeIcon/></Button> */}
            </Datagrid> 
       )
    }