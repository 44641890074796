import React from 'react';
import { CloneButton } from 'react-admin';
import { CloneIcon } from './Icons';
import Tooltip from '@material-ui/core/Tooltip';
import { cloneDeep } from 'lodash';

const omitPropsInRecord = (record = {}, omitProps = []) => {
    let clone = cloneDeep(record); 
    props.concat(omitProps).forEach(key => delete clone[key]);
    return clone;
};

const props = ['id', 'name', 'updatedAt', 'updatedBy', 'createdAt', 'createdBy'];

export default ({
        basePath = '',
        record = {},
        omitProps,
        urlBuilder = null,
        ...rest }) => 
        <CloneButton icon={<CloneIcon />}
            label={'克隆此条记录'}
            to={{
                pathname: urlBuilder ? urlBuilder(record, basePath) : `${basePath}/create`,
                state: { record: omitPropsInRecord(record, omitProps) },
            }}
        />
    