import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { GirlIcon, BoyIcon } from '../Icons';
import GenderAvatar from './GenderAvatar';

const NameAvatarField = props => (
    <div 
    style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', whiteSpace: 'nowrap' }}>
       <GenderAvatar person={props.record} />
       &nbsp;
       <span className={props.className} >{props.record.name} </span>
    </div>
)

export default NameAvatarField;