import React, { Component, Fragment } from 'react';

import { AutocompleteInput, AutocompleteArrayInput, UPDATE, Labeled, LinearProgress, DELETE_MANY, CREATE, UPDATE_MANY, fetchStart, fetchEnd, showNotification, EDIT, ReferenceManyField, GET_ONE, GET_LIST, Button, withDataProvider, List, NumberField, ReferenceField, Datagrid, EditButton, TextField, ReferenceInput, SimpleForm, TextInput, SelectInput, Edit, Filter } from 'react-admin';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Drawer from '@material-ui/core/Drawer';
import { AddIcon } from '../../components/Icons';
import { EditIcon } from '../../components/Icons';
import { startSubmit, change, submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { refreshView, fetchActionsWithArrayOfIdentifiedRecordsResponse } from 'ra-core';
import NameAvatarField from '../../components/Fields/NameAvatarField';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';


const styles = theme => ({
    leftInput: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    drawerRoot: {
        // width: 500
    },
    card: {
        boxShadow: 'none'
    }
})

const validateUserCreation = (values) => {
    // const errors = {};
    // if (!values.name) {
    //     errors.name = ['不能为空'];
    // } 
    // if (!values.courseTypeId) {
    //     errors.courseTypeId = ['不能为空'];
    // } 
    // if (!values.purchasePrice) {
    //     errors.purchasePrice = ['不能为空'];
    // } 
    // if (!values.sellingPrice) {
    //     errors.sellingPrice = ['不能为空'];
    // } 
    // if (!values.status) {
    //     errors.status = ['不能为空'];
    // } 
    // return errors
};

const ClassStudentFilter = (props) => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        {/* <TextInput label="员工名" source="name" /> */}
    </Filter>
);

class ClassStudents extends Component {
    state = {
        showPanel: false,
        students: []
    }
    handleClick = () => {

        this.setState({ showPanel: true });
        this.props.dataProvider(GET_ONE, 'classes',
            { id: this.props.record.id }).then(
                //result => this.setState({ branches: result.data })
                result => {
                    this.props.dispatch(change('students-learnings-form', 'courseId', result.data.courseId))
                    this.props.dispatch(change('students-learnings-form', 'classId', this.props.record.id))

                }
            )
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { classes } = this.props;
        if (!this.props.record || !this.props.record.id) {
            return null;
        }
        return <Fragment>
            <ReferenceManyField perPage={10000} filter={{ classId: this.props.record.id }} label="Students" source="id" reference="students-learnings-classes"
                {...this.props}>
                <Datagrid>
                    <ReferenceField perPage={10000} label="学生姓名" source="studentsLearnings.studentId" reference="students">
                        <NameAvatarField />
                    </ReferenceField>
                    <ReferenceField perPage={10000} label="学生性别" source="studentsLearnings.studentId" reference="students">
                        <TextField source="gender" />
                    </ReferenceField>
                    <ReferenceField perPage={10000} label="学生课程" source="studentsLearnings.courseId" reference="courses">
                        <TextField source="name" />
                    </ReferenceField>
                </Datagrid>
            </ReferenceManyField>
            <Button onClick={this.handleClick} label="修改学员"><EditIcon /></Button>
            <Drawer
                anchor="right"
                classes={{ paperAnchorRight: classes.drawerRoot }}
                open={this.state.showPanel}
                onClose={this.handleCloseClick}>
                <div style={{ marginTop: 16 }}>
                    {
                        <CreateClassStudent
                            courseId={this.props.record.courseId}
                            classId={this.props.record.id} />
                    }
                </div>
            </Drawer>
        </Fragment>

    }
};
class AddStudentInClass extends Component {
    state = {
        fetched: false
    }

    handleSave = () => {
        this.props.dispatch(submit('students-learnings-form'));
    }

    handleSubmit = async values => {
        //首先找出当前报名了该班级的记录
        var result = await this.props.dataProvider(GET_LIST, 'students-learnings-classes', {
            pagination: {
                page: 1,
                perPage: 100000
            },
            filter: {
                classId: values.classId
            }
        })
        this.props.dispatch(fetchStart());

        //找出数据库数据（result.data）与需要保存的数据（values）中的数据差异，只有前者有的数据为需要删除的，只有后者有的数据为需要添加的

        //找出需要添加的数据，插入至students_learnings_classes
        try {
            //1.找出values.studentId中是result.data中没有的studentid记录 
            let studentsLearningsIdArrayInDB = result.data.filter(d =>  d.studentsLearnings).map(d => d.studentsLearnings.id)
            let studentsLearningsIdToAdd = values.studentsLearningsId.filter(d => !studentsLearningsIdArrayInDB.includes(d))
            if (studentsLearningsIdToAdd.length > 0) {
                let arrayToAdd = studentsLearningsIdToAdd.map(d => {
                    return {
                        classId: values.classId,
                        studentsLearningsId: d
                    }
                })
                console.log("需要添加的数据", arrayToAdd)
                for (const data of arrayToAdd) {
                    let responseAdd = await this.props.dataProvider(CREATE, 'students-learnings-classes', {
                        data: data
                    })
                    console.log(responseAdd)
                }
            } else {
                console.log("没有需要添加的数据")
            }


            //找出需要被删除的数据，从students_learnings_classes删除
            let arrayToDelete = result.data.filter(d =>  d.studentsLearnings).filter(d =>
                !values.studentsLearningsId.includes(d.studentsLearnings.id)).map(d => d.id)
            if (arrayToDelete.length > 0) {
                console.log("需要被删除的数据", arrayToDelete)

                let responseDelete = await this.props.dataProvider(DELETE_MANY, 'students-learnings-classes', {
                    ids: arrayToDelete
                })
                console.log(responseDelete)
            } else {
                console.log("没有需要被删除的数据")
            }
            this.props.dispatch(showNotification('添加学员成功'));
            this.props.dispatch(refreshView());
        } catch (error) {
            this.props.dispatch(showNotification(error.message, 'error'));
        } finally {
            this.props.dispatch(fetchEnd());
        }

    };

    componentWillMount() {
        Promise.all(
            [
                this.props.dataProvider(GET_LIST, 'students-learnings',
                    {
                        pagination: { page: 1, perPage: 100000 },
                        filter: { students_learnings_classes: { classId: this.props.classId } }
                    })
                    .then(response => {
                        if (response.total > 0) {
                            this.props.dispatch(change('students-learnings-form', 'studentsLearningsId',
                                response.data.map(data => data.id)));
                        }
                        return response
                    })
                ,
                this.props.dataProvider(GET_LIST, 'students-learnings',
                    {
                        pagination: { page: 1, perPage: 100000 },
                        filter: { courseId: this.props.courseId, useCourseType: 1 }
                    })
                    .then(response => {
                        return response
                    })
            ]).then(response => {
                this.setState({
                    studentsLearnings: response[1].data
                });

            }).finally(() => this.setState({ fetched: true }))
    }

    componentWillUnmount() {
        this.props.dispatch(reset('students-learnings-form'));
    }

    render() {
        const optionRenderer = choice => choice == null ? `` : `${choice.student.name}(${choice.course.name})`;
        //console.log(this.state.studentsLearnings)
        const onChange = data => {

            //防止添加相同的内容
            const arr = Object.values(data);
            if (arr.length > 2) {
                arr.pop();
            }
            const value = arr.pop();
            if (typeof (value) != "function") {
                if (value && (arr.includes(value) || this.state.studentsLearnings.length == 0 || !this.state.studentsLearnings.find(x => x.id == value))) {
                    data.preventDefault();
                }
            }
        };
        return (
            <div>
                {/* <Edit resource="students-learnings" location="/students-learnings"
                basePath="students-learnings" actions={<FormContainerActions />} {...props}> */}
                <SimpleForm onSubmit={this.handleSubmit} save={this.handleSave} resource="students-learnings" form="students-learnings-form">
                    {
                        this.state.fetched ?
                            <AutocompleteArrayInput label="学生"
                                onChange={onChange} source="studentsLearningsId" options={{ suggestionsContainerProps: { style: { zIndex: 1800 } } }} choices={this.state.studentsLearnings}
                                optionText={optionRenderer} optionValue="id" /> :
                            <Labeled label="学生"><LinearProgress /></Labeled>
                    }

                </SimpleForm>
                {/* <Button onClick={this.handleSave} color="primary">
                    保存
                </Button> */}
                {/* </Edit> */}
            </div >
        )
    }
}
const create = (props) => {
    const { classes } = props
    return < AddStudentInClass {...props} />
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export const ClassStudentList = compose(withDataProvider,
    withStyles(styles))(ClassStudents)

export const CreateClassStudent = compose(withDataProvider,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles))(create);
