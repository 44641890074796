import React, { Fragment, Component } from 'react';
import { NumberInput, FormDataConsumer, showNotification, UPDATE, BooleanInput, TextInput, SimpleForm, GET_LIST, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { Field, formValueSelector, startSubmit, initialize, change, submit, reset } from 'redux-form';



const styles = {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
};
class SystemConfig extends Component {
    handleSave = () => {
        this.props.dispatch(submit('record-form'));

    }
    handleSubmit = async values => {
        this.props.dataProvider(UPDATE, 'system-configurations',
            {
                id: values.id,
                data: values
            })
            .then(result => {
                this.props.dispatch(showNotification('已更新'));
                return result.data
            }
            ).catch((e) => {
                this.props.dispatch(showNotification('ra.page.error', 'warning'))
            })

    }

    componentWillMount() {
        this.props.dataProvider(GET_LIST, 'system-configurations',
            {
                pagination: { page: 1, perPage: 100000 },
            }).then(
                result => {
                    var data = result.data[0]
                    this.props.dispatch(initialize('record-form', data))
                }
            )
    }
    
    render() {
        return (
            <SimpleForm onSubmit={this.handleSubmit} save={this.handleSave}>
                <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }) => {
                        var seed = Number(formData.orderNumberSeed ? formData.orderNumberSeed : 0)
                        var digits = Number(formData.orderNumberDigits && formData.orderNumberDigits > 0 ? formData.orderNumberDigits : 1)
                        var isAddCode = formData.isOrderNumberUseBranchCode
                        var prefix = formData.orderNumberPrefix ? formData.orderNumberPrefix : ""
                        var suffix = formData.orderNumberSuffix ? formData.orderNumberSuffix : ""
                        return (
                            <Fragment>
                                <div>当前订单编号举例：</div>
                                <div>{prefix + (isAddCode ? "[分校编号]" : "") + (Array(digits).join(0) + seed).slice(-digits) + suffix}</div>
                            </Fragment>
                        )
                    }}
                </FormDataConsumer>
                <NumberInput source="orderNumberSeed" label="订单开始编号" />
                <NumberInput source="orderNumberDigits" label="订单编号位数" />
                <BooleanInput source="isOrderNumberUseBranchCode" label="订单编号是否添加分校编号" />
                <TextInput source="orderNumberPrefix" label="订单编号前缀" />
                <TextInput source="orderNumberSuffix" label="订单编号后缀" />

                <BooleanInput source="spendClassNotification" label="是否发送课时消耗信息" />
            </SimpleForm>)
    }
}


const SystemConfiguration = (props) => (
    <SystemConfig {...props} />
);

export default compose(withDataProvider, withStyles(styles))(SystemConfiguration);
