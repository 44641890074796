export default {
    company: '伊叶舞蹈',
    comment: '备注',
    contact_person: '联系人',
    birthday: '生日',
    course: '课程',
    student: '学员',
    createdAt: '创建时间',
    'jwt expired': '请重新登录',
    'intents': '意向学员',
    'customers': '正式学员',
    'customer-center': '学员中心',
    'service-center': '教务中心', 
    'materials': '教材',
    'courses': '课程', 
    'class': '班级',
    'class-scheudle': '班级排课',
    'class-scheudle-batch': '班级批量排课',
    'class-timetable': '课程表',
    'transactions': '订单流水',
    'isTeacher': '是否为上课老师',
    'applyCourse': '报名课程',
    'customer': '学员',
    'marketing': '招生中心',
    'campaigns': '营销活动',
    'Campaign': '营销活动',
    'Network Error': '网络异常',
    pos: {
        Configuration: '个人设置'
    },
    branch: {
        name: '分校名',
        type: '分校类型',
        code: '编码',
        seed: '分校订单开始编号'

    },
    incidental: {
        name: '杂费名',
        create: '新建杂费',
        edit: '编辑杂费'
    },
    material: {
        name: '教材名',
        create: '新建教材',
        edit: '编辑教材'
    },
    course: {
        studyMode: '授课模式',
        chargeMode: '收费模式',
        name: '课程名称',
        subjects: '科目', 
        type: '课程类型',
        branches: '校区',
        subject: '科目',
        rules: '定价规则',
        create: '新建课程',
        edit: '编辑课程'
    },
    student: {
        birthday: '生日',
        address: '地址',
        gender: '性别',
        name: '姓名',
        channel: '渠道来源',
        wechat: '微信号',
        learning: '在读课程',
        record: '上课记录',
        create: '新建学员',
    },
    transaction: {
      course : '课程/班级',
      material: '教材',
      incidental: '杂费'
    },
    avatar: '头像',
    phone: '电话',
    branch: {
        'name': '分校名'
    },
    createdAt: '创建日期',
    dashboard: {
        new_students: '新学员',
        pending_orders: '未到账订单',
        new_orders: '近三十天新订单',
        monthly_revenue: '近三十天订单金额'
    },
    error: {
        duplcated: '%{field} 已经存在'
    },
    account: '账号名',
    '办理教材杂费': '办理购买教材杂费',
    '教材杂费': '教材杂费',
    '办理报名': '办理报名',
    '报名': '报名',
    '按课时': '按课时',
    '课时': '课时',
    '已用/全部课时': '已用/全部课时',
    '剩余/总共课时': '剩余/总共课时',
    '扣除课时': '扣除课时',
    '撤销课时': '撤销课时',
    '退出课时': '退出课时',
    '全部退费并结业': '全部退费并结业',
    '退部分课时，继续上课': '退部分课时，继续上课',
    '按照付费时的课单价退款': '按照付费时的课单价退款',
    '自己定义退费的课时和金额': '自己定义退费的课时和金额',
    '退出课时': '退出课时',
    '购买课时': '购买课时',
    '教材': '教材',
    '杂费': '杂费',
    '该学员还没有在读的课程': '该学员还没有在读的课程',
    '点此报名': '点此报名',
    '报名新课程': '报名新课程',
    'Invalid login': '用户名或者密码不正确'
}