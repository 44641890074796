import React, { Component } from 'react';
import { ReferenceField, BulkDeleteButton, List, BooleanInput, Datagrid, EditButton, TextField, 
    Create, ReferenceInput, SimpleForm, TextInput, SelectInput, Edit, Filter,FunctionField,
    Show, SimpleShowLayout, BooleanField, Button } from 'react-admin';
import DrawerInput from '../../../components/Inputs/DrawerInput'
import { CreateUser } from './user'
import ListActions from '../../../components/Widgets/ListActions';
import FormToolbar from '../../../components/Widgets/FormToolbar';
import FormContainerActions from "../../../components/Widgets/FormContainerActions";
import { EditIcon, BackIcon } from '../../../components/Icons';
import formCardStyle from '../../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import PhoneField from '../../../components/Fields/PhoneField';
import CardActions from '@material-ui/core/CardActions';
import ShowFilterWhenHasOp from '../../../util/ShowFilterWhenHasOp';
import CloneButton from '../../../components/CloneButton';
import FromNowField from '../../../components/Fields/FromNowField';

const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['不能为空'];
    }
    if (!values.mobile) {
        errors.mobile = ['不能为空'];
    }
    if (!values.gender) {
        errors.gender = ['不能为空'];
    }
    if (!values.status) {
        errors.status = ['不能为空'];
    }
    if (!values.branchId) {
        errors.branchId = ['不能为空'];
    }
    return errors
};

const EmployeeFilter = (props) => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        <TextInput label="员工名" source="name" />
    </Filter>
);

export const EmployeeList = props => {
    return <List actions={<ListActions />} filters={<EmployeeFilter />} {...props} bulkActionButtons={props.permissions === 'admin' ? <BulkDeleteButton /> : false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="branch.name" source="branchId" 
                linkType={false} reference="branches">
                <TextField source="name" />
            </ReferenceField>
            {/* <ReferenceField label="机构账号名" source="userId" linkType={false} 
                    allowEmpty={true} reference="users">
               <FunctionField render={record => {
                   console.log(record)
                   return <div>{record.name}</div>
               }}>

               </FunctionField>
            </ReferenceField> */}
            <TextField source="name" label="姓名" />
            <PhoneField source="mobile" label="手机号码" />
            <PhoneField source="mobile2" label="备用手机" />
            <TextField source="gender" label="性别" />
            <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
            <EditButton icon={<EditIcon />} />
            <CloneButton />
        </Datagrid>
    </List>
};

class Form extends Component {
    render() {
        return (
            <SimpleForm submitOnEnter={true}
                validate={validateForm} defaultValue={{ isTeacher: false, status: "正式员工" }} {...this.props} redirect="list">

                <TextInput source="name" label="姓名" />
                <TextInput source="mobile" label="手机号码" />
                <TextInput source="mobile2" label="备用手机" />
                <SelectInput source="gender" label="性别" choices={[
                    { id: '男', name: '男' },
                    { id: '女', name: '女' },
                ]} />
                <BooleanInput source="isTeacher" label="isTeacher" />
                <SelectInput source="status" label="人事状态" choices={[
                    { id: '面试期', name: '面试期' },
                    { id: '培训期', name: '培训期' },
                    { id: '试用期', name: '试用期' },
                    { id: '兼职员工', name: '兼职员工' },
                    { id: '正式员工', name: '正式员工' },
                    { id: '转正失败', name: '转正失败' },
                    { id: '停薪留职', name: '停薪留职' },
                    { id: '主动离职', name: '主动离职' },
                    { id: '被解聘', name: '被解聘' },
                ]} />
                <ReferenceInput source="branchId" label="所属部门" reference="branches">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                {
                    this.props.type == "edit" ?
                        <DrawerInput
                            buttonLabel="查看机构账号"
                            drawerContent={
                                <CreateUser />
                            }
                        /> : null
                }
            </SimpleForm>
        )
    }
}

export const CreateEmployee = (withStyles)(formCardStyle)(props => (
    <Create actions={<FormContainerActions />} title="新建员工" {...props}>
        <Form type="create" />
    </Create>
))

export const EditEmployee = (withStyles)(formCardStyle)(props => (
    <Edit actions={<FormContainerActions />} title={<span>编辑员工</span>} {...props}>
        <Form type="edit" />
    </Edit>
))

const Title = ({ record }) => {
    return <span>用户 {record ? `"${record.name}"` : ''}</span>;
};

export const EmployeeShow = props => (
<Show actions={
    <CardActions style={{ float: 'right' }}>
        <Button onClick={()=>window.history.back()} label="后退">{<BackIcon/>}</Button>
    </CardActions>
} {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="姓名" />
            <TextField source="mobile" label="手机号码" />
            <TextField source="mobile2" label="备用手机" />
            <TextField source="gender" label="性别" />
            <ReferenceField source="branchId" label="所属部门" reference="branches">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField label="isTeacher" source="isTeacher" />
            <TextField source="status" label="人事状态"/>
           
        </SimpleShowLayout>
    </Show>
);