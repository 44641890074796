import React, { Component, Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles'; 
import { Button } from 'react-admin';
import {CloseRightIcon, AddCircleIcon} from '../Icons';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
        width: '40%'
    }
});


class DrawerInput extends Component {
    state = { showPanel: false };

    static defaultProps = {
        
    };
    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        const { input, buttonLabel, buttonIcon, drawerContent, classes } = this.props;
        return (
            <Fragment>
                {input}
                <Button onClick={this.handleClick} label={buttonLabel}>
                    {buttonIcon}
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                    classes={classes.root}
                >
                    <div style={{marginTop: 16}}>
                        <Button onClick={this.handleCloseClick} label="关闭">
                            <CloseRightIcon />
                        </Button>
                    </div>
                    {
                        React.cloneElement(drawerContent, Object.assign({}, 
                                            drawerContent.props, 
                                            {
                                                afterSave: this.handleCloseClick
                                            }))
                    }
                    
                </Drawer>
            </Fragment>
        );
    }
}

DrawerInput.defaultProps = {
    buttonLabel: '新建',
    buttonIcon: <AddCircleIcon />,
    persistAfterSave: false
};

export default compose(withStyles(styles))(DrawerInput);
