import React, { Component, Fragment } from 'react';
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import compose from 'recompose/compose';
import {
    RadioButtonGroupInput, required, showNotification, FormDataConsumer,  withDataProvider, GET_LIST, List as RAList,
    Button as RAButton, AutocompleteInput, ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput, ShowButton,
    SimpleForm, TextInput, RichTextField, Show, Edit, SimpleShowLayout, FunctionField, refreshView as refreshViewAction
} from 'react-admin';
import TinyMCEInput from '../../components/Inputs/TinymceInput';
import { EditIcon, PlayIcon, SendIcon, CloneIcon, PreviewIcon } from '../../components/Icons';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { arrayPush, arrayRemoveAll, change } from 'redux-form';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import { SimpeCourseShow } from '../operation/course';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MediaPreview from '../../components/Widgets/MediaPreview';
import { UPDATE} from 'ra-core';
import FromNowField from '../../components/Fields/FromNowField';
import Avatar from '@material-ui/core/Avatar';

const moment = require('moment');

const styles = theme => ({
    leftInput: { display: 'inline-block', },
    rightInput: { display: 'inline-block', marginLeft: 16, width: 152, minWidth: 152 },
    imageField: {
        height: '100%'
    },
    // drawerRoot: {
    //     //width: 500
    //     '@media (max-width:500px)': {

    //     },
    // },
    listRoot: {
        '@media (max-width:500px)': {
            width: '100%'
        }
    },

    card: {
        boxShadow: 'none'
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    table: {
        tableLayout: 'auto',
    },
    dialogRoot: {
        width: 700,
        maxWidth: 700
    },
    inline_block: {
        display: "inline-block"
    },
    card: {
        padding: "1em",
        marginTop: "1em",
        width: "100%",
    },
    chip: {
        width: "80px",
        margin: "5px",
        padding: "5px"
    }
})
const validateUserCreation = (values) => {
    const errors = {};
    if (!values.title) {
        errors.title = ['不能为空'];
    }
    if (!values.content) {
        errors.content = ['不能为空'];
    }
    if (!values.beginAt) {
        errors.beginAt = ['不能为空'];
    }
    if (!values.endAt) {
        errors.endAt = ['不能为空'];
    }
    return errors
};
const getStudentCount = record => record.target === '全校' ? "全校" : record.students != null ? record.students.length : 0

export const NotificationList = compose(withDataProvider, connect(null, dispatch => ({dispatch, refreshView: refreshViewAction})))
 (props => {
    const StudentCountField = ({ record }) =>
        <span>{getStudentCount(record)}</span>
    return (
        <RAList sort={{ field: 'createdAt', order: 'DESC' }} 
            actions={<ListActions />} {...props}>
            <Datagrid>
                <TextField source="title" label="标题" />
                <FunctionField label="状态"
                    render={record =>  
                        <Chip size="small" variant="outlined"
                            color={ record.status === '已发送' ? "primary" : '' } 
                            label={ record.status}  />
                } />
　
                <StudentCountField label='通知人数' />
                <ReferenceField label="创建人" allowEmpty={true}
                    source="createdBy" reference="employees" linkType="show">
                    <TextField source="name" />
                </ReferenceField>
                <FromNowField source="createdAt" label="创建日期"/>
                <FromNowField source="sentAt" label="发送日期"/>
                {/* <ShowButton /> */}
                <FunctionField 
                    render={record => 
                    　<Fragment>
                        <EditButton basePath={props.basePath} record={record}
                        resource={props.resource}  icon={<EditIcon />} />

                        {
                             record.status === '草稿' &&
                             <Button color="primary"
                                 onClick={e => {
                                     props.dataProvider(UPDATE, 'notifications',
                                     { id: record.id, 
                                     　　data: {
                                             status: '已发送',
                                             students: record.students
                                         },
                                         previousData: { status: record.status }
                                 　　　}).then(() =>  {
                                     props.dispatch(props.refreshView());
                                     props.dispatch(showNotification("已发送消息至学生，"));
                                 } )
                                 }}
                             >
                                 <SendIcon />
                                 发送至家长
                             </Button>
                        }
                    </Fragment> 
                } />
　 
            </Datagrid>
        </RAList>
    )
})

class EditNotificationClass extends React.Component {
    state = {videos: []}
    
    componentWillReceiveProps = async props => {
        if (this.props.selectedSingleStudentId != props.selectedSingleStudentId) {
            if (!props.students || !this.props.students.find(x => x.id == props.selectedSingleStudentId)) {
                var resultStudent = await this.props.dataProvider(GET_LIST, 'students',
                    {
                        filter: {
                            id: props.selectedSingleStudentId,
                        }
                    })
                this.props.dispatch(arrayPush("record-form", "students", resultStudent.data[0]))
            }
            else {
                this.props.dispatch(showNotification("该学生已经在列表中，请勿重复添加"));
            }
        }
    }

    listVideo = () => {
        this.props.dataProvider(GET_LIST, "media-materials", {
            filter: { listVideo: 1, branchId: this.props.workingBranch.id },
            pagination: { page: 0, limit: 1000, perPage: 1 }
        }).then(res => 
            this.setState({ videos: res.data, open: true })
       )
    }

    setVideo = record => {
        this.props.dataProvider(GET_LIST, "qiniu", {
            filter: { getVideoUrl: "1", name: `${record.key}/${record.fileName}` },
            pagination: { page: 0, limit: 1000, perPage: 1 }
        }).then(res => {
            navigator.clipboard.writeText(res.data[0].id);
            this.setState({ video: res.data[0].id, open: false });
            this.props.dispatch(showNotification('视频链接已经选择，请Copy至下面的编辑器的菜单中选择 插入->多媒体'))
        }
       )
    }

    preview = record => {
        this.props.dataProvider(GET_LIST, "qiniu", {
            filter: { getVideoUrl: "1", name: `${record.key}/${record.fileName}` },
            pagination: { page: 0, limit: 1000, perPage: 1 }
        }).then(res => {
            this.props.dispatch({ type: 'TOGGLE_MEDIA_PREVIEW', payload: { url: res.data[0].id } })
        })
    }

    render() {
        const { classes } = this.props
        const handleDelete = (student) => {
            let newStudents = this.props.students.filter(x => x.id != student.id)
            this.props.dispatch(change("record-form", "students", newStudents))
        }
        return (
            <SimpleForm submitOnEnter={true}  toolbar={<FormToolbar />}
                validate={validateUserCreation} {...this.props} redirect="list">
               
                    <TextInput source="title" validate={required()} label="标题" />
                    {/* <DateInput source="beginAt" timeIntervals={5} label="通知开始时间" showTimeSelect validate={required()} />
                    <DateInput source="endAt" timeIntervals={5} label="通知结束时间" showTimeSelect validate={required()} /> */}
                    
                    <RadioButtonGroupInput defaultValue="全校" validate={required()} label="发送至"
                         options={{ row: true }} source="target" choices={[
                        { id: '全校', name: '全校' },
                        { id: '班级', name: '班级' },
                        { id: '个人', name: '个人' }
                    ]} />
                {
                       this.props.state.form["record-form"] &&
                       this.props.state.form["record-form"].values &&
                         this.props.state.form["record-form"].values.target === '班级' ? 
                            <RAButton style={{ width: 110 }} label="查找班级" onClick={() => this.props.dispatch({ type: 'SHOW_SELECT_COURSE_NOTIFICATION', payload: { showSelectCourseNotification: true, } })}><EditIcon /></RAButton> :
                            this.props.state.form["record-form"] &&
                            this.props.state.form["record-form"].values &&
                            this.props.state.form["record-form"].values.target === '个人' ?
                            <ReferenceInput label="通过学生姓名查找添加" reference="students" source="selectedSingleStudentId"
                                    filterToQuery={searchText => ({ 'hanzi_pinyin[$like]': `%${searchText}%` })}>
                                    <AutocompleteInput />
                                </ReferenceInput> : null 
                }

                <Card className={classes.card}>
                    <p style={{fontSize: 14, color: "#555"}}>已选择的学生：</p>
                    {
                       this.props.state.form["record-form"] &&
                       this.props.state.form["record-form"].values &&
                         this.props.state.form["record-form"].values.target === '全校' ?
                            "所有学生" :
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                        formData && formData.students ?
                                            (formData.students.length > 0 ? formData.students.map(x => (
                                                <Chip label={x.name} className={classes.chip} onDelete={() => handleDelete(x)} />
                                            )) : <p>尚未选择学生</p>)
                                            : null
                                    )
                                }}
                            </FormDataConsumer>
                    }
                    
                    <Dialog fullScreen={this.props.fullScreen} 
                        open={this.state.open}
                        style={{ maxWidth: '100vw' }}
                        onClose={() => this.setState({ open: false })} >
                        <DialogTitle id="simple-dialog-title">可用的视频</DialogTitle>
                            <List style={{width: 400}}>
                                {
                                    this.state.videos.map(video =>
                                        <ListItem  >
                                        <ListItemText style={{width: 200}} primary={video.fileName} />
                                        <ListItemSecondaryAction style={{backgroundColor: '#fff'}}>
                                            <Button onClick={this.setVideo.bind(this, video)}>
                                                <CloneIcon />
                                                选择视频
                                            </Button>
                                            <IconButton onClick={this.preview.bind(this, video)}>
                                                <PlayIcon />
                                            </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        )
                                }
                            
                            </List>
                        <DialogActions>
                        <Button style={{fontSize: 16}} autoFocus onClick={()=> this.setState({open: false})} color="primary">
                          
                            关闭
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
                    
                    <Button disabled={this.props.state.admin.loading}　
                        style={{marginTop: 32}}
                        variant="contained" color="primary" onClick={this.listVideo } >
                        添加视频
                    </Button>
                    <TinyMCEInput source="content"
                        label="内容"
                        init={{
                            media_live_embeds: true,
                            language: 'zh_CN',
                            height: '19cm',
                            width: this.props.width === 'xs' || this.props.width === 'sm' ? '100%': '15cm',
                            skin: "oxide",
                            mobile: {
                                theme: 'silver'
                            },
                            fontsize_formats: "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 16pt 24pt 28pt 36pt 48pt",
                            plugins: 'print preview table advlist image imagetools lists save paste media',
                            toolbar: 'media | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
                            paste_data_images: true,
                           // templates: [ {title: '视频', description: '请把刚才插入的视频复制在这里', content:  <video src="http://q0mpydq3c.bkt.clouddn.com/pch7mj-ie7.mp4?attname=&e=1573272547&token=-B_xDqXbbhkz1JfIFiI-j_cMbvNdr6elO3r7spKP:CKX7Mgk-KnIUnetTN_XvzwQu8gA"></video>},],
                            // template_replace_values: {
                            //     video_src:  ""
                            // },
                            template_cdate_format: '[CDATE: %m/%d/%Y : %H:%M:%S]',
                            template_mdate_format: '[MDATE: %m/%d/%Y : %H:%M:%S]',
                            content_style: 'p { margin: 0 } table td, table th { padding: 2px }'
                        }}
                    />
                    
                    <NotificationCourse  {...this.props} />
                    <MediaPreview />
            </SimpleForm>
        )
    }
}

class CreateStudentCourse extends Component {

    state = {
        open: false,
        // checked: null,
        showDetail: false,
        expands: [],
        registers: {}
    }

    handleClose = () => {
        this.setState({
            open: false,
            // checked: null,
            showDetail: false,
            expands: [],
            registers: {}
        })
        this.props.dispatch({ type: 'SHOW_SELECT_COURSE_NOTIFICATION', payload: { showSelectCourseNotification: false } });
    }

    componentWillMount = () => {
        this.props.dataProvider(GET_LIST, 'courses',
            { pagination: { page: 1, perPage: 100000 } }).then(data => {
                this.setState({
                    allCourses: data.data
                })
            })
    }

    expandClass = id => {
        if (this.state.expands.indexOf(id) >= 0) {
            this.state.expands.splice(this.state.expands.indexOf(id), 1);
        } else {
            this.state.expands.push(id);
        }
        this.setState(this.state.expands);
    }

    onClassChange = (courseId, classId) => {
        if (this.state.registers[courseId]) {
            if (this.state.registers[courseId].indexOf(classId) >= 0) {
                let i = this.state.registers[courseId].indexOf(classId);
                this.state.registers[courseId].splice(i, 1);
                this.state.registers[courseId] = this.state.registers[courseId].length == 0 ? null : this.state.registers[courseId]
            }
            else {
                this.state.registers[courseId].push(classId);
            }
        } else {
            this.state.registers[courseId] = [classId];
        }
        this.setState(this.state.registers);
    }

    onCourseChange = courseId => {
        if (this.state.registers[courseId]) {
            this.state.registers[courseId] = null;
        } else {
            this.state.registers[courseId] = this.state.allCourses.find(x => courseId == x.id).classes.map(x => x.id)
        }
        this.setState(this.state.registers);
    }

    itemCheckedClass = classId => {
        let isFound = false;
        Object.values(this.state.registers).forEach(arr => {
            if (arr && arr.indexOf(classId) >= 0) {
                isFound = true;
            }
        })
        return isFound
    }
    itemCheckedCourse = courseId => {
        let isFound = false;
        if (this.state.registers[courseId] && this.state.allCourses.find(x => courseId == x.id).classes.length == this.state.registers[courseId].length) {
            isFound = true
        }
        return isFound
    }

    selectCourses = async () => {
        //提供给notification使用 start
        this.props.dispatch(change('notification-form', 'registers', this.state.registers));

        let courseIdList = ["00000000-0000-0000-0000-000000000000"], classIdList = ["00000000-0000-0000-0000-000000000000"];
        let data = this.state.registers;
        Object.keys(data).forEach(function (k) {
            if (data[k]) {
                //如果couresid不为null,则说明是选中的
                if (data[k].length === 0) {
                    //courseid中没有值，说明只选了这个课程，但是这个课程下面没有班级
                    courseIdList.push(k);
                }
                else {
                    //courseid中有值，直接选择这个courseid中的classid
                    data[k].forEach(val => classIdList.push(val))
                }
            }
        });

        var results = await this.props.dataProvider(GET_LIST, 'students-learnings',
            {
                filter: { courseIdList: courseIdList, classIdList: classIdList, getStudentsByCourseOrClassId: 1 }
            })
        this.props.dispatch(change('record-form', 'students', results.data));
        //提供给notification使用 end
        this.handleClose();
    }

    render() {
        const { classes } = this.props
        return (
            <div>
                <Drawer
                    anchor="right"
                    classes={{ paperAnchorRight: classes.drawerRoot }}
                    open={this.props.showSelectCourseNotification}
                    onClose={this.handleClose}>

                    <Typography component="body1"
                        style={{ lineHeight: 'normal', marginTop: 16, marginLeft: 16, backgroundColor: '#fff' }}>
                    </Typography>

                    <div style={{ marginTop: 16, padding: 16, overflowY: 'auto' }}>
                        <List dense={true} classes={{ root: classes.listRoot }} >
                            {this.state.allCourses && this.state.allCourses.map(course => (
                                <Fragment>
                                    <ListItem key={course.id}  >
                                        <Checkbox checked={this.itemCheckedCourse(course.id)} onChange={this.onCourseChange.bind(this, course.id)} />
                                        <ListItemText primary={<Typography onClick={e => this.setState({ showDetail: true, anchorEl: e.target, showData: course })}
                                            variant="h3" gutterBottom>{course.name}</Typography>}
                                            secondary={`${course.studyMode} | ${course.chargeMode}`}
                                        />
                                        <ListItemSecondaryAction>
                                            {
                                                course.classes && course.classes.length > 0 &&
                                                <IconButton onClick={this.expandClass.bind(this, course.id)}>
                                                    {
                                                        this.state.expands.indexOf(course.id) >= 0 ?
                                                            <ExpandLess /> :
                                                            <ExpandMore />
                                                    }
                                                </IconButton>
                                            }

                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {
                                        course.classes && course.classes.length > 0 &&
                                        <Collapse in={this.state.expands.indexOf(course.id) >= 0} timeout="auto" unmountOnExit>
                                            <List dense={true} disablePadding>
                                                {
                                                    course.classes.map(c => (
                                                        <ListItem dense className={classes.nested}>
                                                            <Checkbox checked={this.itemCheckedClass(c.id)} onChange={this.onClassChange.bind(this, course.id, c.id)} />
                                                            <ListItemText primary={<Typography onClick={e => this.setState({ showDetail: true, anchorEl: e.target, showData: c })}
                                                                variant="h3" gutterBottom>{c.name}</Typography>}
                                                                secondary={`${moment(c.openAt).format('YYYY-M-D')} -> ${moment(c.closeAt).format('YYYY-M-D')}`}
                                                            />
                                                        </ListItem>)
                                                    )
                                                }
                                            </List>
                                        </Collapse>
                                    }
                                </Fragment>
                            ))}

                        </List>
                    </div>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={this.handleClose}>关闭</Button>
                        <Button onClick={this.selectCourses}>确定</Button>
                    </CardActions>
                </Drawer>

                <Popover useLayerForClickAway={false}
                    id="mouse-over-popover"
                    open={this.state.showDetail}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => this.setState({ showDetail: false })}
                    disableRestoreFocus
                >
                    <SimpeCourseShow record={this.state.showData} />
                </Popover>
            </div>
        )
    }
}

export const NotifcationShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="target" />
            <RichTextField source="content" />
            <FromNowField label="创建日期" source="createdAt" />
        </SimpleShowLayout>
    </Show>
);


const mapStateToProps = state => {
    let selectedSingleStudentId = null;
    try {
        selectedSingleStudentId = state.form["record-form"]["values"]["selectedSingleStudentId"]
    }
    catch{ }

    let students = [];
    try {
        students = state.form["record-form"]["values"]["students"]
    }
    catch{ }

    return {
        showSelectCourseNotification: state.student.showSelectCourseNotification,
        "notificationForm": state.form["notification-form"],
        "students": students,
        selectedSingleStudentId: selectedSingleStudentId,
        workingBranch: state.global.workingBranch,
        state: state
    }
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

const NotificationCourse = compose(withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withDataProvider)(CreateStudentCourse);

export const CreateNotification = compose((withDataProvider), 
                connect(mapStateToProps, mapDispatchToProps), 
                withStyles(formCardStyle), 
                withStyles(styles),
                withMobileDialog())((props) => (
    <Create actions={<FormContainerActions />} {...props} title="新增通知" redirect="list">
        <EditNotificationClass {...props}></EditNotificationClass>
    </Create>));

export const EditNotification = compose((withDataProvider), 
                connect(mapStateToProps, mapDispatchToProps), 
                withStyles(formCardStyle),
                 withStyles(styles),
                 withMobileDialog())((props) => (
    <Edit actions={<FormContainerActions />} {...props} title="编辑通知" redirect="list">
        <EditNotificationClass {...props}></EditNotificationClass>
    </Edit>));