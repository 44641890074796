import React from 'react';
import XLSX from 'xlsx';
import ReactDOM from 'react-dom';

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
	constructor(props) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	};

	suppress(evt) { evt.stopPropagation(); evt.preventDefault(); };

	onDrop(evt) { evt.stopPropagation(); evt.preventDefault();
		const files = evt.dataTransfer.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() { 
        return (
						<div id="dnd"
							onMouseOver={
								() => {
									document.getElementById('dnd').style.border = "5px dashed #bbb "
								}
							}
							onMouseLeave={
								() => {
									document.getElementById('dnd').style.border = "2px dashed #bbb "
								}
							}
							style={{
								border: '2px dashed #bbb',
								borderRadius: 5,
								padding: 25,
								textAlign: 'center',
								width: '70%',
							}}
						onDrop={this.onDrop} onDragEnter={this.suppress} 
                onDragOver={this.suppress}> 
								{this.props.children}
								
								或者把Excel文件拖入此处
            </div>
	); };
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	};
	handleChange(e) {
		const files = e.target.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() { return (
<form className="form-inline">
	<div className="form-group">
		<label htmlFor="file">请选择导入学员Excel文件:</label>
		<input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
	</div>
</form>
	); };
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
	constructor(props) { super(props); };
 
	render() {
		return (
	  <CanvasDatagrid cols={this.props.cols} data={this.props.data}/>
	); };
};


class CanvasDatagrid extends React.Component {
	constructor(props) {
			super(props);
	}
	updateAttributes(nextProps) {
			Object.keys(this.props).forEach(key => {
					if (!nextProps || this.props[key] !== nextProps[key]) {
							if (this.grid.attributes[key] !== undefined) {
									this.grid.attributes[key] = nextProps ? nextProps[key] : this.props[key];
							} else {
									this.grid[key] = nextProps ? nextProps[key] : this.props[key];
							}
					}
			});
	}
	componentWillReceiveProps(nextProps) {
			this.updateAttributes(nextProps);
	}
	// shouldComponentUpdate() {
	// 		return false;
	// }
	componentWillUnmount() {
			this.grid.dispose();
	}
	componentDidMount() {
			var args = {allowFreezingColumns: true};
			this.grid = ReactDOM.findDOMNode(this);
			this.grid.style.height = 'calc(100% - 64px)';
			this.grid.style.width = '100%';
			this.grid.style.position = 'absolute';
			this.grid.style.top = '64px';
			this.grid.style.scrollBarBoxColor = '#444444';
			this.grid.style.zIndex = 1500;
			
			//this.grid.allowFreezingColumns = true;
		
			//this.grid.schema = this.props.cols;
			// this.grid.addEventListener('selectionchanged', (e) => {
			// 	console.log(e);
			// });
			this.grid.addEventListener('endedit', (e) => {
			});
			window.grid = this.grid;
			this.updateAttributes();
	}
	render() {
			return React.createElement('canvas-datagrid');
	}
}

/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(",");

/* generate an array of column objects */
const make_cols = refstr => {
	let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
	for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o;
};

export  {
    DragDropFile,
    DataInput,
    OutTable
}