import React, { Component } from 'react';
import { CardActions, ListButton } from 'react-admin';
import {
    ListIcon
} from '../Icons';
import {
    withStyles,
} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        width: '100%'
    },
    title: {
        flex: '1 1 auto',
        alignItems: 'flex-start'
    },
    actions: {
        alignItems: 'flex-end'
    }
})

const FormContainerActions = ({ classes,  basePath, data, resource, customActions }) => (
    <div className={classnames(classes.root)}>
        <div className={classnames(classes.title)}>
            <Typography
                variant="title"
                color="inherit"
                id="react-admin-title"
            >
            </Typography>
        </div>
        <div className={classnames(classes.actions)}>
            <ListButton icon={<ListIcon />} basePath={basePath} />

            {customActions}

        </div>
    </div>
);

export default compose(
    withStyles(styles)
)(FormContainerActions);