import React, {
    Component,
    ReactElement,
    ComponentType,
    HtmlHTMLAttributes,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from '@material-ui/core/styles';
import { LockIcon } from '../Icons';

import Notification from './Notification';
import {LoginForm} from 'react-admin';
import deepOrange from '@material-ui/core/colors/deepOrange';
import Typography from '@material-ui/core/Typography';


const styles = theme =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            textAlign: 'center',
            background: 'url(/background.svg) center center / cover no-repeat fixed'
        },
        card: {
            minWidth: 340,
            marginTop: 'calc((100vh - 600px) / 2)',
            padding: 32
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center', 
        },
        icon: {
            backgroundColor: deepOrange[500],
        },
    });

class Login extends Component 
{
    componentDidMount() {
        this.lazyLoadBackgroundImage();
    }

    componentDidUpdate() {
        if (!this.backgroundImageLoaded) {
            this.lazyLoadBackgroundImage();
        }
    }

    lazyLoadBackgroundImage() {
        const { backgroundImage } = this.props;

        if (backgroundImage) {
            const img = new Image();
            img.onload = this.updateBackgroundImage;
            img.src = backgroundImage;
        }
    }

    render() {
        const {
            backgroundImage,
            classes,
            className,
            loginForm,
            staticContext,
            ...rest
        } = this.props;

        return (
            <MuiThemeProvider theme={this.theme}>
                <div
                    className={classnames(classes.main, className)}
                    {...rest}
                    style={{  }}
                    ref={this.containerRef}
                >
                    <Card elevation={4} className={classes.card}>
                        <Typography variant="headline" gutterBottom>
                            您好！ 欢迎使用
                        </Typography>
                        <div className={classes.avatar}>
                            <Avatar color="primary" className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        {loginForm}
                    </Card>
                    <Notification />
                </div>
            </MuiThemeProvider>
        );
    }
}

const EnhancedLogin = withStyles(styles)(Login);

EnhancedLogin.propTypes = {
    backgroundImage: PropTypes.string,
    loginForm: PropTypes.element,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

EnhancedLogin.defaultProps = {
    backgroundImage: '/background.svg',
    loginForm: <LoginForm />,
};
export default EnhancedLogin;
