import React, { Fragment, Component } from 'react';
import { List, AutocompleteInput, ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput,
    SimpleForm, TextInput, SelectInput, Edit, Filter,　FunctionField, NumberInput } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon, GreatIcon, LessIcon } from '../../components/Icons';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import NameAvatarField from '../../components/Fields/NameAvatarField';
import NumberFormat from 'react-number-format';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Paper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FromNowField from '../../components/Fields/FromNowField';

const Filters = props =>(
    <Filter {...ShowFilterWhenHasOp(props)}>
        <ReferenceInput label="姓名(汉字或拼音)" source="studentId" reference="students"
             filterToQuery={searchText => ({ 'hanzi_pinyin[$iLike]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="id" optionText={student => student.name || '' } />
        </ReferenceInput>
        <ReferenceInput label="课程" source="courseId" reference="courses"
             filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="id" optionText={course => course.name || '' } />
        </ReferenceInput>
        <ReferenceInput label="班级" source="classId" reference="classes"
            filter={{idAndName: true}}
             filterToQuery={searchText => ({ 'name[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="id" optionText={c => c.name || '' } />
        </ReferenceInput>
        <NumberInput 
            label="剩余课时(大于)"　source="leftLessons.$gt" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <GreatIcon  style={{ width: 16, height: 16}}/>
                </InputAdornment>
            )}} />
        <NumberInput 
            label="剩余课时(小于)"　source="leftLessons.$lt" InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <LessIcon style={{ width: 16, height: 16}}/>
                </InputAdornment>
            ) }} />
            <SelectInput source="status" label="状态" 
            choices={[
            { id: '在读', name: '在读' },
            { id: '结业', name: '结业' },
            { id: '停课', name: '停课' }
          ]} />
    </Filter>
);


const exportMetadata = {
    name: '学员剩余课时',
    sheets: [
        {
            name: '学员剩余课时',
            columns: {
                '学员': record => record.student,
                '剩余课时': record => record.leftLessons,
                '课程名称': record => record.course,
                '班级': record => record.class,
                '过期日期': record => record.expire_date,
                '状态': 'status'
            }
        }
    ]
}

export class LearningList extends Component {
    constructor(props) {
        super(props);
        this.list = React.createRef();
      }

    f = {
        includeClassHistory: true, type: 'lesson'
    }
    state = {
        value: "lesson",
        filters: { includeClassHistory: true, type: 'lesson' }
    }

    componentDidMount () {
       let list = document.getElementById('list')
       if (list.childNodes && list.childNodes[0]) {
         list.childNodes[0].style.boxShadow = "none";
       }
    }

    render () {
        const props = this.props;
        const { value } = this.state;
        return <Paper>
                        {/* <Tabs fullWidth
                                centered
                                value={value}
                                indicatorColor="primary"
                                onChange={(event, value) => {
                                    this.setState({ filters: {}});
                                    window.setTimeout(
                                        () => {
                                            this.setState({ filters: {
                                                includeClassHistory: true,
                                                type: value
                                            }});
                                            this.setState({ value: value });   
                                        },1000)
                                   }}  >
                                     <Tab value="lesson" label="按课时"></Tab>

                                 <Tab value="time" label="按时间"></Tab>

                            </Tabs> */}
                                <List id="list" ref={this.list} filter={{...this.state.filters}} 
                                    sort={{ field: 'leftLessons', order: 'asc' }}　bulkActionButtons={false}
                                    actions={<ListActions exportMetadata={exportMetadata} />}
                                    filters={<Filters />} style={{ marginTop: 16 }} {...props}>
                                <Datagrid >
                                    <ReferenceField sortBy={false} allowEmpty={true} label="student.name" source="studentId" reference="students">
                                        <FunctionField  render={ record => 
                                        <NameAvatarField record={record}/> }/>
                                    </ReferenceField>
                                    <FunctionField  label="剩余课时" sortBy="leftLessons"
                                            render={record => <NumberFormat displayType="text" 
                                                value={ record.leftLessons } thousandSeparator={true}  /> } />
                                    <ReferenceField sortBy={false}  label="course.name" source="courseId" reference="courses">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <FromNowField showTime={false} source="expire_date"  label="过期日期" />
                                    <ReferenceField sortBy={false}  label="班级" 
                                         
                                        allowEmpty source="classId" reference="classes">
                                        <TextField source="name" />
                                    </ReferenceField> 
                                    <TextField sortable={false}  label="状态" source="status" />
                                </Datagrid>
                            </List>
                          
                       
        </Paper>
    }
}
