import React , { Fragment, Component }  from 'react';
import { Pagination ,List, Datagrid, EditButton, CreateButton, TextField, Create, SimpleForm,DisabledInput, TextInput, SelectInput, Edit, Filter } from 'react-admin';
import { Tree, TreeNode, TreeNodeActions } from 'ra-tree-ui-materialui';
import { Route } from 'react-router';
import BranchEdit from './branchEdit';
import CreateBranchClass from './branchCreate';
import Drawer from '@material-ui/core/Drawer';
import { parse } from "query-string";
import { restClient } from 'ra-data-feathers';
import { feathersClient } from '../../../feathersClient';
import ListActions from '../../../components/Widgets/ListActions';
import { EditIcon, AddIcon } from '../../../components/Icons';
import ShowFilterWhenHasOp from '../../../util/ShowFilterWhenHasOp';


const BranchTreeActions = props => (
    <TreeNodeActions {...props}>
        <CustomCreateButton />
        <EditButton icon={<EditIcon />}/>
    </TreeNodeActions>
);
const BranchFilter = (props) => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        <TextInput label="branch.name" source="name" />
        <TextInput label="电话" source="tel" />
        <TextInput label="地址" source="address" />
        <SelectInput source="type" label="branch.type" choices={[
                { id: '直营', name: '直营' },
                { id: '加盟', name: '加盟' },
                { id: '其他', name: '其他' },
            ]} />
    </Filter>
);

export const BranchList = props => {
     return  <List filters={<BranchFilter />} {...props}
                    actions={<ListActions /> }>
                <Datagrid rowClick="edit">
                    <TextField source="id" label="编号"/>
                    <TextField source="name" label="branch.name"/>
                    <TextField source="tel" label="电话1"/>
                    <TextField source="tel2" label="电话2"/>
                    <TextField source="address" label="branch.address"/>
                    <TextField source="type" label="branch.type"/>
                    <EditButton />
                </Datagrid>
            </List>
};
const CustomCreateButton = ({ basePath, record = {} }) => <CreateButton　icon={<AddIcon />} to={`${basePath}/create?parentid=${record.id}`}/>;
const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />
class BranchTreeListClass extends React.Component {
    handleClose = value => {
      this.setState({ selectedValue: value, open: false });
      this.props.history.push("/branches");
    };
  
    render() {
        const props = this.props;
      return ( 
        <Fragment>
            <List actions={<ListActions /> } {...this.props} perPage={10000} 
                    pagination={<PostPagination />}>
                <Tree parentSource="parentId" >
                    <TreeNode actions={<BranchTreeActions />}>
                        <TextField source="name" label="branch.name"/>
                    </TreeNode>
                </Tree>
            </List>
            <Route path="/branches/:id">
                {({ match }) => {
                    const isMatch =
                        match &&
                        match.params &&
                        match.params.id !== 'create';
                    return (
                        <Drawer
                            variant="persistent"
                            open={isMatch}
                            anchor="right"
                            onClose={this.handleClose}
                        >
                            {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                            {isMatch ? (
                                <BranchEdit
                                    id={match.params.id}
                                    onCancel={this.handleClose}
                                    {...props}
                                />
                            ) : null}
                        </Drawer>
                    );
                }}
            </Route>
        </Fragment>
      );
    }
  }


export const BranchTreeList = props => {
   return  <BranchTreeListClass {...props} />
};

export const CreateBranch = props => {
    return  <CreateBranchClass {...props} />
 };
