import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { translate, changeLocale, showNotification } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { TabbedForm, FormTab } from 'react-admin'
import TextField from '@material-ui/core/TextField';
import { withDataProvider, GET_ONE, UPDATE } from 'react-admin';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
};

class Configuration extends Component {
    state = { value: 0}

    componentWillMount () {
        let u = JSON.parse(localStorage.getItem('user'))
        this.props.dataProvider(
            GET_ONE,
            'users',
            { id: u.id }
        ).then(res => this.setState({ user: res.data }))

        this.props.dataProvider(
            GET_ONE,
            'employees',
            { id: u.id }
        ).then(res => this.setState({ employee: res.data }))
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    };


    validatePassword = () => {
        if (!this.state.password) {
            this.setState({
                passwordError: true,
                passwordErrorText: "新密码不能为空"
            })
        } else if (!this.state.confirmPassword) {
            this.setState({
                cPasswordError: true,
                cPasswordErrorText: "确认密码不能为空"
            })
        } else if (this.state.password !== this.state.confirmPassword) {
            this.setState({
                passwordError: true,
                passwordErrorText: "两次输入的密码不一致",
                cPasswordError: true,
                cPasswordErrorText: "两次输入的密码不一致"
            })
        } else {
            this.setState({
                passwordError: false,
                passwordErrorText: "",
                cPasswordError: false,
                cPasswordErrorText: ""
            })
        }
    }

    phoneChange = () => {
        if (this.state.phone) {
            let data = { phone: this.state.phone }
            if (this.state.employee.isTeacher) {
                data.name = this.state.phone
            }
            this.props.dataProvider(UPDATE, 'users', {
                id: this.state.user.id,
                data: data
            }).then(() =>
                this.props.dataProvider(UPDATE, 'employees', {
                    id: this.state.user.id,
                    data: { mobile: this.state.phone }
                })
            )
            .then(() => {
                this.props.dispatch(showNotification('手机号码保存成功'));
                this.state.user.phone = this.state.phone
                this.setState({ user: this.state.user })
            } )
        }
    }

    passwordChange = () => {
         if (!this.state.passwordError && !this.state.cPasswordError) {
            this.props.dataProvider(UPDATE, 'users', {
                id: this.state.user.id,
                data: { password: this.state.password }
            }).then(() =>  this.props.dispatch(showNotification('密码保存成功')) )
        }
    }

    render() {
        return (
            <div>
                <AppBar style={{color: '#111', backgroundColor: '#fafafa', 
                                borderBottom: '1px solid #aaa', boxShadow: 'none'}} 
                        position="static">
                    <Tabs value={this.state.value} onChange={this.handleChange} >
                        <Tab label="登录密码" />
                        <Tab label="手机号码" />
                    </Tabs>
                </AppBar>
                    {
                        this.state.value == 1 && 
                            <div value={this.state.value} index={1}>
                                <Card style={{ marginTop: 16, width: '100%'}}>
                                        <CardContent>
                                                    <TextField required
                                                        label="手机号码"
                                                        margin="normal"
                                                        onChange={e => {
                                                            this.setState({ phone: e.target.value })
                                                        }}
                                                        defaultValue={this.state.user ?  this.state.user.phone : ''}
                                                    />
                                                <br />
                                            <Button onClick={this.phoneChange} style={{marginTop: 16}} variant="contained" color="primary" >
                                                    保存
                                                </Button>
                                    </CardContent>
                                    </Card>
                            </div>
                    }   
                    {
                        this.state.value == 0 && 
                        <div value={this.state.value} index={0}>
                            <Card style={{ marginTop: 16, width: '100%'}}>
                                    <CardContent>
                                            <TextField required
                                                label="新密码"
                                                error={this.state.passwordError}
                                                margin="normal"
                                                onChange={e => {
                                                    this.setState({ password: e.target.value }, () => { 
                                                        this.validatePassword()
                                                    })
                                                }}
                                                helperText={this.state.passwordErrorText}
                                                />
                                                <br />
                                            <TextField required
                                                label="重复密码"
                                                error={this.state.cPasswordError}
                                                onChange={e => {
                                                    this.setState({ confirmPassword: e.target.value }, () => { 
                                                        this.validatePassword()
                                                    })
                                                }}
                                                margin="normal"
                                                helperText={this.state.cPasswordErrorText}
                                                />
                                            <br />
                                        <Button onClick={this.passwordChange} style={{marginTop: 16}} variant="contained" color="primary" >
                                                保存
                                            </Button>
                                </CardContent>
                                </Card>
                        </div>
                    }
                    
            </div>
        )
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
    locale: state.i18n.locale,
});

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    translate,
    withStyles(styles),
    withDataProvider
);

export default enhance(Configuration);