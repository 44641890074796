import React, { Fragment } from 'react';
import {
    required, withDataProvider, GET_LIST, EditButton,ReferenceInput,AutocompleteInput, SimpleList,
    ReferenceArrayInput, List, TextInput, ReferenceArrayField, Edit, Filter, Button as RaButton,
    FunctionField, Datagrid, SingleFieldList, TextField, Create, ChipField, SimpleForm, Responsive
} from 'react-admin';
import { change } from 'redux-form';
import compose from 'recompose/compose';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { Link } from 'react-router-dom';
import { EditIcon, PlayIcon } from '../../components/Icons';
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import FromNowField from '../../components/Fields/FromNowField';
import CloneButton from '../../components/CloneButton';
import SelectArrayInput from '../../components/Inputs/SelectArrayInput';
import Button from '@material-ui/core/Button';
import * as qiniu from 'qiniu-js';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import MediaPreview from '../../components/Widgets/MediaPreview';
import IconButton from '@material-ui/core/IconButton';

const validateUserCreation = (values) => {
    const errors = {};
    return errors
};

const MediaFilter = props => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        <ReferenceInput label="文件名" alwaysOn source="fileName" reference={"media-materials"} 
            filterToQuery={searchText => ({ 'fileName[$like]': `%${searchText}%` })} >
            <AutocompleteInput optionValue="fileName" optionText={m => m.fileName || '' } />
            
        </ReferenceInput>
    </Filter>
)

export const MediaList = compose(withDataProvider)(props => (
    <Fragment>
        <List filters={<MediaFilter />} actions={<ListActions />} {...props}>
        <Responsive 
             medium={
                <Datagrid> 
                        <TextField label="文件名" source="fileName" />
                        <TextField label="文件类型" source="fileType" />
                        <FunctionField label="文件大小" render={record => `${(record.fileSize/1048576).toFixed(2)}mb `} />

                        <ReferenceArrayField reference="branches" label="可见分校" source="mediaBranches">
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                        </ReferenceArrayField>
                        <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                        <EditButton icon={<EditIcon />}/>
                        <FunctionField 
                                render={record => 
                                    <RaButton label="预览" onClick={() => 
                                            {
                                                props.dataProvider(GET_LIST, "qiniu", {
                                                    filter: { getVideoUrl: "1", name: `${record.key}/${record.fileName}` },
                                                    pagination: { page: 0, limit: 1000, perPage: 1 }
                                                }).then(res => {
                                                    props.dispatch({ type: 'TOGGLE_MEDIA_PREVIEW', payload: { url: res.data[0].id } })
                                                })
                                            }
                                } ><PlayIcon /></RaButton> }/>
                    </Datagrid>
             }
             small={
                <SimpleList primaryText={record => record.fileName}
                    rightIcon={record => (
                        <Fragment>
                            <IconButton
                                onClick={e => 
                                    {
                                        e.preventDefault()
                                        props.dataProvider(GET_LIST, "qiniu", {
                                            filter: { getVideoUrl: "1", name: `${record.key}/${record.fileName}` },
                                            pagination: { page: 0, limit: 1000, perPage: 1 }
                                        }).then(res => {
                                            props.dispatch({ type: 'TOGGLE_MEDIA_PREVIEW', payload: { url: res.data[0].id } })
                                        })
                                    }
                                } >
                                <PlayIcon />
                            </IconButton>
                        </Fragment>　
                    )}
                /> }
             />
        </List>
        <MediaPreview />
    </Fragment>
))

class Form extends React.Component {
    state = {
        open: false
    }

    upload = e => {
        const file = e.target.files[0];
        const putExtra = {
          fname: '',
          params: {},
          mimeType: null,
        };
        const config = {
          useCdnDomain: true,
          disableStatisticsReport: false,
          retryCount: 6,
          region: qiniu.region.z0,
        };
        const subObject = {
            next: res => 
                this.setState({ percent: res.total.percent }),
            error(err){}, 
            complete: res => 
                this.setState({ upload: false })
        };
        this.props.dataProvider(GET_LIST, 'media-materials', {
            filter: { getToken: "1"  }
        }).then(res => {
            const token = 'QZGAu0Il6tDFTKWPSIrjTzSRnYiveTggUvMhqPTG';
            const key = file.name;
            this.setState({ upload: true })
            const observable = qiniu.upload(file, `${this.props.workingBranch.name}/${key}`, token, putExtra, config);
            this.props.dispatch(change('record-form', 'fileName', file.name ));
            this.props.dispatch(change('record-form', 'fileType', file.type ));
            this.props.dispatch(change('record-form', 'fileSize', file.size ));
            this.props.dispatch(change('record-form', 'key', this.props.workingBranch.name ));
            observable.subscribe(subObject);
        });
    }

    render() {
        return (
            <Fragment>
                <SimpleForm {...this.props}  toolbar={<FormToolbar />} submitOnEnter={true} redirect="list" 
                    validate={validateUserCreation} >
                    <ReferenceArrayInput validate={required()} label="可见校区" source="mediaBranches" reference="branches">
                        <SelectArrayInput optionText="name" /> 
                    </ReferenceArrayInput>

                    <Button style={{marginTop: 16}} onClick={() => document.querySelector('#uploader').click()} 
                        variant="contained"color="primary">上传文件</Button>
                    
                    <input style={{ display: 'none' }} onChange={this.upload}
                        accept="video/*, image/png, image/jpeg, image/bmp, image/jpg, image/gif, audio/mp3, audio/wma, audio/wav, audio/amr" type="file" id="uploader" />
                    {
                        this.state.upload && 
                        <LinearProgress　style={{marginTop: 16}} variant="determinate" value={this.state.percent} />
                    }
                     <Typography component="body1" >
                    {
                        this.state.percent ? 
                        this.state.percent < 100 ?
                          `上传中: ${this.state.percent.toFixed(2)}%` : '上传成功' : null
                    }</Typography>
                    <TextInput validate={required()} label="文件名" source="fileName" disabled/>
                    <TextInput validate={required()} label="文件类型" source="fileType" disabled/>
                    <TextInput validate={required()} source="fileSize" disabled/>
                </SimpleForm>
                 
            </Fragment>
        )
    }
}

const MeidaForm = compose(withDataProvider, 
                             withStyles(formCardStyle),
                             connect(state =>  ({ user: state.global.user,
                                                 workingBranch: state.global.workingBranch }) )
                             )(Form)

export const CreateMedia = compose(withDataProvider,
    withStyles(formCardStyle))((props) =>
        <Create actions={<FormContainerActions />} title="新增" {...props} >
            <MeidaForm type="create" />
        </Create>
    )

export const EditMedia = compose(withDataProvider,
    withStyles(formCardStyle))((props) =>
        <Edit actions={<FormContainerActions />} title="编辑" {...props}>
            <MeidaForm type="edit"  />
        </Edit>
    )
