import React, { Component } from 'react';
import {
  showNotification,
  withDataProvider,
  Button,
  CREATE
} from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import { SaveIcon, AddToListIcon } from '../../components/Icons'
import { withStyles } from '@material-ui/core/styles';
import { change } from 'redux-form';

const styles = theme => (
  {
    container: {
      display: 'flex',
      marginTop: 16
    },
    button: {
      margin: 5
    }
  })

class ClassroomCreateDrawer extends Component {
  state = {}

  onSave = () => {
    return this.props.dataProvider(CREATE, 'classrooms',
      { data: { name: this.state.name, branchId: this.props.branchId } })
      .then(result => {
        this.props.dispatch(showNotification('ra.notification.created'));
        return result.data
      }
      ).then(data => {
        if (this.props.afterSave) {
          this.props.afterSave(data);
        }
        return data;
      }).catch((e) => {
        this.props.dispatch(showNotification('ra.page.error', 'warning'))
      })
  }

  onSaveAndAddList = () => {
    if (!this.state.name) {
      this.setState({ error: true })
      return false;
    }
    this.onSave().then(data => {
      this.props.dispatch(change('record-form', 'classroomId', data.id))
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={{ padding: 16, marginTop: 32 }}>

        <Typography
          variant="title"
          color="inherit"
          className={classes.title}
        > 新建教室</Typography>

        <TextField
          label="教室"
          value={this.state.name}
          error={this.state.error}
          onChange={e => this.setState({ name: e.target.value })}
          margin="normal"
        />

        <div className={classes.container}>
          <Button className={classes.button} variant="outlined"
            label="保存并添加至列表" onClick={this.onSaveAndAddList}>
            <AddToListIcon />
          </Button>
          {/* <Button className={classes.button} variant="outlined"
            label="保存" onClick={this.onSave} >
            <SaveIcon />
          </Button> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  // Get the record by its id from the react-admin state.
  data: state,
  version: state.admin.ui.viewVersion
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export const CreateClassroom = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider,
  translate,
  withStyles(styles)
)(ClassroomCreateDrawer);
