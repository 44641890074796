import React, { Component } from 'react';
import {
  showNotification,
  withDataProvider,
  Button,
  CREATE
} from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import { SaveIcon, AddToListIcon } from '../../../components/Icons'
import { withStyles } from '@material-ui/core/styles';
import { arrayPush } from 'redux-form';
import { GET_ONE,GET_MANY,UPDATE} from 'ra-core';

const styles = theme => (
  {
    container: {
      display: 'flex',
      marginTop: 16
    },
    button: {
      margin: 5
    }
  })

class UserCreateDrawer extends Component {
  state = {
    loading: false
  }

  onSave = () => {
    this.setState({ loading: true });
    if(this.state.userId){
      return this.props.dataProvider(UPDATE, 'users',
      { id:this.state.userId, data: {name:this.state.name ,password:this.state.password} })
      .then(result =>
        {
          if (this.props.afterSave) {
            this.props.afterSave(result.data);
          }
          this.props.dispatch(showNotification('ra.notification.updated', 'info', { messageArgs:  { smart_count: 1 } }));
          return result.data
        }
      ).catch((e) => {
        this.props.dispatch(showNotification('ra.page.error', 'warning'))
      })
      .finally(() => {
        this.setState({ loading: false });
      })
    }
    else{
      return this.props.dataProvider(CREATE, 'users',
      { data: {id:this.props.data.form["record-form"].initial.id,employeeId:this.props.data.form["record-form"].initial.id, name: this.state.name, email:"aaa@aa.com", password:this.state.password} })
      .then(result =>
        {
          if (this.props.afterSave) {
            this.props.afterSave(result.data);
          }
          this.props.dispatch(showNotification('ra.notification.created'));
          return result.data
        }
      ).catch((e) => {
        if (e.toString() === 'Error: Validation error') {
          this.props.dispatch(showNotification('error.duplcated', 'warning', 
                              {messageArgs: { field: this.props.translate('account') }} ));
        } else {
          this.props.dispatch(showNotification('ra.page.error', 'warning'));
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      })
    }
 
  }
  componentWillMount(){
    this.props.dataProvider(GET_ONE,'users',{id:this.props.data.form["record-form"].initial.id}).then(
      response =>{
        if(response.data){
          this.setState({userId: response.data.id ,name:response.data.name, isNameDisabled:true})
        }
      }).catch(err=>err)
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={{ padding: 16, marginTop: 32 }}>
        <Typography
          variant="title"
          color="inherit"
          className={classes.title}
        > 机构账号</Typography>
        <div style={{ margin: '16px 0px' }}>
        {
          this.state.isNameDisabled ?
          
              <TextField 
                  value={this.state.name}
                  required
                  disabled={this.state.isNameDisabled}
                  label="帐号" /> 
                  :
                <span>
                  <TextField 
                    value={this.state.name}  
                    required
                    onChange={e => this.setState({ name: e.target.value })}
                    label="帐号"  />
                </span>
              
        }
        </div>

        <TextField
          style={{display:"block"}}
          label="密码"
          required
          type="password"
          value={this.state.password}
          onChange={e => this.setState({ password: e.target.value })}
          
        />
       <div className={classes.container}>
          <Button className={classes.button} variant="outlined"
            label="保存" onClick={this.onSave} disabled={this.state.loading}>
            <AddToListIcon />
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  // Get the record by its id from the react-admin state.
  data: state,
  version: state.admin.ui.viewVersion
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export const CreateUser = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDataProvider,
  translate,
  withStyles(styles)
)(UserCreateDrawer);
