import React, { Component, Fragment} from 'react';
import { List, Button, ReferenceField, TextField, Create, SimpleForm, TextInput, SelectInput, Edit, Filter } from 'react-admin';
import FormContainerActions from "../../components/Widgets/FormContainerActions";
import { EditIcon } from '../../components/Icons';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import {
    withStyles,
} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import formCardStyle from '../../assets/style/formCard';
import { IntentStudentIcon,  RegisterIcon} from '../../components/Icons';
import { BaseStudentList } from '../students';
import { connect } from 'react-redux';
import { BulkDeleteButton } from 'react-admin';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['分校名不能为空'];
    }
    return errors
};

const fields = [

    { source: "extra_attributes.prop1", label: "属性1" },
    { source: "extra_attributes.prop2", label: "属性2" },
    { source: "extra_attributes.prop3", label: "属性3" },
]

const StudentFilter = (props) => (
    // <div style={{ }}> 
    <Filter {...ShowFilterWhenHasOp(props)}>
        {/* <TextInput label="属性1" source="extra_attributes.prop1" />
        <TextInput label="属性2" source="extra_attributes.prop2" /> */}

        {
            fields.map(f =>
                <TextInput label={f.label} source={f.source} />
            )
        }
    </Filter>
    // </div>
);

//
class IntentStudents extends Component {
    
    render () {
        const ApplyButton = ({...props}) => (
                            <Button label="报名" onClick={(porps) =>  this.props.dispatch({
                                    type: 'START_TRANSACTION', 
                                    payload: {ids: props.record ? [props.record.id] : props.selectedIds, 
                                              showApplyCourse: true,
                                              applyType: '报名',
                                              defaultValue: {
                                                payments: [{ received: 0 }]
                                              }
                                            } 
                                 })} > 
                                 <RegisterIcon />
                            </Button>)
        return (
            <Fragment>
            {
                 React.createElement(BaseStudentList, {
                    ...this.props,
                    bulkActionButtons: [<ApplyButton/>, <BulkDeleteButton />],
                    additionalRowButtons: [
                        <ApplyButton/>
                    ],
                    additionalColumns: [
                         <TextField label="年龄" source="age" />,
                        <ReferenceField allowEmpty={true} label="营销活动" source="campaignId" reference="campaigns">
                            <TextField source="name" />
                        </ReferenceField>,
                        <ReferenceField  allowEmpty={true} label="渠道来源" source="channelId" reference="acquisition-channels">
                            <TextField source="name" />
                       </ReferenceField>
                    ]
                })
            }
            
         </Fragment>
        )
    }
};



const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }

export const IntentStudentList = compose(connect(null, mapDispatchToProps))(IntentStudents);
