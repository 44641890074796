import React from 'react';
import { List,BooleanInput, ReferenceField, Datagrid, EditButton, TextField, Create, ReferenceInput,SimpleForm, TextInput, SelectInput, Edit, Filter, BulkDeleteButton } from 'react-admin';
import ListActions from '../../../components/Widgets/ListActions';
import FormToolbar from '../../../components/Widgets/FormToolbar';
import FormContainerActions from "../../../components/Widgets/FormContainerActions";
import formCardStyle from '../../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import { EditIcon } from '../../../components/Icons';
import MoneyFormat from '../../../util/MoneyFormat';
import ShowFilterWhenHasOp from '../../../util/ShowFilterWhenHasOp';
import CloneButton from '../../../components/CloneButton';
import FromNowField from '../../../components/Fields/FromNowField';

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['不能为空'];
    } 
    if (!values.courseTypeId) {
        errors.courseTypeId = ['不能为空'];
    } 
    if (!values.purchasePrice) {
        errors.purchasePrice = ['不能为空'];
    } 
    if (!values.sellingPrice) {
        errors.sellingPrice = ['不能为空'];
    } 
    if (!values.status) {
        errors.status = ['不能为空'];
    } 
    return errors
};

const IncidentalFilter = (props) => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        <TextInput label="员工名" source="name" />
    </Filter>
);

export const IncidentalList = props => {
    let user = localStorage.getItem("user");
    let bulkActionButtons = <div></div>
    if (user) {
        user = JSON.parse(user);
        if (user.name === "admin") {
            bulkActionButtons = <BulkDeleteButton {...props} />
        }
        
    }
    
     return  <List actions={<ListActions />} filters={<IncidentalFilter />} {...props} bulkActionButtons={bulkActionButtons}>
                <Datagrid rowClick="edit">
                    <TextField source="name" label="incidental.name"/>
                    <ReferenceField label="course.type" source="courseTypeId" reference="course_types">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField InputProps={{
                                inputComponent: MoneyFormat
                     }} source="sellingPrice" label="售价"/>
                    <TextField source="status" label="状态"/>
                    <FromNowField sortBy="createdAt" source="createdAt" label="创建时间"/>
                    { user.name  === "admin" ? <EditButton icon={<EditIcon />}/> : null} 
                    <CloneButton />
                </Datagrid>
            </List>
};


export const CreateIncidental = (withStyles)(formCardStyle) (props => (
    <Create actions={<FormContainerActions />}  title="incidental.create"  {...props}>
        <SimpleForm  toolbar={<FormToolbar />}  submitOnEnter={true} redirect="list" validate={validateUserCreation}>
            <TextInput source="name" label="incidental.name"/>
            <ReferenceInput source="courseTypeId" label="course.type" reference="course_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="sellingPrice" InputProps={{
                                inputComponent: MoneyFormat
                     }}  label="售价"/>
            <SelectInput source="status" label="状态" choices={[
                { id: '正常', name: '正常' },
                { id: '下架', name: '下架' }
            ]} />
        </SimpleForm>
    </Create>
))

export const EditIncidental = (withStyles)(formCardStyle) (props => (
    <Edit actions={<FormContainerActions />} title="incidental.edit" {...props}>
        <SimpleForm toolbar={<FormToolbar />} submitOnEnter={true} redirect="list"  validate={validateUserCreation}>
            <TextInput source="name" label="incidental.name"/>
            <ReferenceInput source="courseTypeId" label="course.type" reference="course_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput InputProps={{
                                inputComponent: MoneyFormat
                     }} source="sellingPrice"  label="售价"/>
            <SelectInput source="status" label="状态" choices={[
                { id: '正常', name: '正常' },
                { id: '下架', name: '下架' }
            ]} />
        </SimpleForm>
    </Edit>
))