import React, { Fragment, useState } from 'react';
import {
    List, GET_LIST, ReferenceField, Datagrid, EditButton, TextField, BooleanInput, ReferenceInput,
    SimpleForm, withDataProvider, SelectInput, Edit, Filter, FunctionField, Responsive
} from 'react-admin';
import ListActions from '../../components/Widgets/ListActions';
import FormToolbar from '../../components/Widgets/FormToolbar';
import formCardStyle from '../../assets/style/formCard';
import withStyles from '@material-ui/core/styles/withStyles';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';
import FromNowField from '../../components/Fields/FromNowField';
import TinymceInput from '../../components/Inputs/TinymceInput';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import NameAvatarField from '../../components/Fields/NameAvatarField';
import Button from '@material-ui/core/Button';
import { EditIcon } from '../../components/Icons';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardContent: theme.typography.body1,
    subheading: {
        minWidth: 80,
        fontWeight: 500
    },
    label: {
        opacity: .7
    },
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['不能为空'];
    }
    if (!values.courseTypeId) {
        errors.courseTypeId = ['不能为空'];
    }
    if (!values.purchasePrice) {
        errors.purchasePrice = ['不能为空'];
    }
    if (!values.sellingPrice) {
        errors.sellingPrice = ['不能为空'];
    }
    if (!values.status) {
        errors.status = ['不能为空'];
    }
    return errors
};

const MaterialFilter = props => (
    <Filter {...ShowFilterWhenHasOp(props)}>
        {/* <ReferenceInput perPage={10000} label="学生"
            source="studentId"
            filter={{ isLogged: true, teacherId: props.teacherId }}
            reference="students" >
            <SelectInput optionText="name" />
        </ReferenceInput> */}

        {/* <BooleanInput alwaysOn source="isComment" label="" /> */}
        <SelectInput source="isComment" defaultValue={false} label="批改状态" choices={[
            { id: "", name: '所有作业' },
            { id: true, name: '已批改作业' },
            { id: false, name:  '只显示未批改的作业' },
        ]} />
        <ReferenceInput perPage={10000} label="作业"
            source="classScheduleId"
            filter={props.teacherId ? { teacherId: props.teacherId, 'taskTitle[$ne]': null } :
                { 'taskTitle[$ne]': null }}
            reference="class-schedules" >
            <SelectInput native optionText={
                record => {
                    return `${record.class.name} - ${record.taskTitle}`
                }
            } />
        </ReferenceInput>

        {/* <ReferenceInput perPage={10000} label="上课时间"
            source="classScheduleId"
            filter={{ isLogged: true }}
            reference="class-schedules" >
            <SelectInput optionText={c => (new Date(c.beginAt)).toLocaleString('zh-CN',
                { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false, timeZone: 'Asia/Shanghai' })} />
        </ReferenceInput> */}

        <ReferenceInput perPage={10000} label="班级"
            source="classSchedule.classId"
            reference="classes" >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const HomeworkMobileGrid = compose(withStyles(styles))(({ ids, data, basePath, classes }) => {
    return <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <span className={classes.cardContentRow}>
                        <Typography className={classes.subheading}
                            component="span" variant="subheading">
                            作业标题 </Typography>
                        <ReferenceField linkType={false}
                            className={classes.label}
                            record={data[id]} basePath={basePath}
                            source="classScheduleId" label="作业"
                            reference="class-schedules" >
                            <TextField className={classes.label} source="taskTitle" />
                        </ReferenceField>
                    </span>
                    <Divider />
                    <span className={classes.cardContentRow}>
                        <Typography className={classes.subheading}
                            component="span" variant="subheading">
                            学员</Typography>
                        <ReferenceField linkType={false}
                            record={data[id]} basePath={basePath}
                            source="studentId" label="学生" reference="students" >
                            <NameAvatarField className={classes.label} label="student.name" />
                        </ReferenceField>
                    </span>
                    <Divider />
                    <span className={classes.cardContentRow}>
                        <Typography className={classes.subheading}
                            component="span" variant="subheading">
                            班级</Typography>
                        <ReferenceField linkType={false}
                            record={data[id]} basePath={basePath}
                            source="classSchedule.classId" label="班级"
                            reference="classes" >
                            <TextField className={classes.label} source="name" />
                        </ReferenceField>
                    </span>
                    <Divider />
                    <span className={classes.cardContentRow}>
                        <Typography className={classes.subheading}
                            component="span" variant="subheading">
                            是否评价</Typography>
                        <Chip
                            variant="outlined"
                            size="small"
                            color={data[id].comments ? "primary" : "secondary"}
                            label={
                                data[id].comments ?
                                    '已评价' :
                                    '未评价'
                            }
                        />
                    </span>
                    <Divider />
                    <span className={classes.cardContentRow}>
                        <Typography className={classes.subheading}
                            component="span" variant="subheading">
                            上课时间</Typography>
                        <FromNowField sortBy="classSchedule.beginAt"
                            record={data[id]} basePath={basePath} className={classes.label}
                            source="classSchedule.beginAt" label="上课时间" />
                    </span>
                    <Divider />
                    <span className={classes.cardContentRow}>
                        <Typography className={classes.subheading}
                            component="span" variant="subheading">
                            上传时间</Typography>
                        <FromNowField sortBy="classSchedule.beginAt"
                            record={data[id]} basePath={basePath} className={classes.label}
                            sortBy="createdAt" source="createdAt" label="上传时间" />
                    </span>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" component={Link} to={`${basePath}/${id}`}>
                        <EditIcon />
                        批作业
                    </Button>
                </CardActions>
            </Card>
        ))}
    </div>
});

export const HomeworkList = props => {
    let user = JSON.parse(localStorage.getItem('user'));
    let defaultFilter = props.location.search ?
        { classScheduleId: props.location.search.replace('?', '') } : {}
    if (user && user.isTeacher) {
        defaultFilter.teacherId = user.id
    }
    defaultFilter.isComment = false

    return <List actions={<ListActions
        addtionalFlags={{ isHideCreate: true }} />}
        bulkActionButtons={false} filterDefaultValues={defaultFilter}
        filters={<MaterialFilter />} {...props}>
        <Responsive
            small={
                (<HomeworkMobileGrid />)
            }
            medium={
                <Datagrid >
                    <ReferenceField linkType={false} source="studentId" label="学生"
                        reference="students" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField linkType={false} source="classScheduleId" label="作业"
                        reference="class-schedules" >
                        <TextField source="taskTitle" />
                    </ReferenceField>
                    <ReferenceField linkType={false}
                        source="classSchedule.classId" label="班级"
                        reference="classes" >
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField label="是否评价" render={record => record.comments ? '已评价' : '未评价'} />
                    {
                        user && !user.isTeacher &&
                        <ReferenceField linkType={false} source="classSchedule.teacherId" label="上课老师"
                            reference="employees" >
                            <TextField source="name" />
                        </ReferenceField>
                    }
                    <FromNowField sortBy="classSchedule.beginAt" source="classSchedule.beginAt" label="上课时间" />
                    <FromNowField sortBy="createdAt" source="createdAt" label="上传时间" />
                    <EditButton label="批作业" />
                </Datagrid>
            } />
    </List>
};

const VideoPreview = compose(withDataProvider)(props => {
    const [url, setUrl] = useState();

    props.dataProvider(GET_LIST, "qiniu", {
        filter: { getVideoUrl: "1", name: props.record.fileName },
        pagination: { page: 0, limit: 1000, perPage: 1 }
    }).then(res => {
        setUrl(res.data[0].id)
    })

    return <Player fluid={false}
        width={400}
        playsInline src={url} />
})

export const CommentHomework = (withStyles)(formCardStyle)(props => {
    return <Fragment>
        <Edit title={<span>评论作业</span>} {...props}>
            <SimpleForm submitOnEnter={true} redirect="list" toolbar={<FormToolbar />} >
                <VideoPreview label="作业" />
                <ReferenceField linkType={false} source="studentId" label="学生"
                        reference="students" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField linkType={false} source="classScheduleId" label="作业"
                        reference="class-schedules" >
                        <TextField source="taskTitle" />
                    </ReferenceField>
                <TinymceInput source="comments" label="评论" init={{
                    language: 'zh_CN',
                    skin: "oxide",
                    mobile: {
                        theme: 'silver'
                    },
                    width: '350px',
                    height: '500px',
                    fontsize_formats: "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 16pt 24pt 28pt 36pt 48pt",
                    menubar: '',
                    plugins: 'preview save paste image',
                    toolbar: 'formatselect | bold italic forecolor backcolor permanentpen formatpainter | link image pageembed | alignleft aligncenter alignright  | numlist bullist outdent indent',
                    paste_data_images: true,
                    file_picker_types: 'image media',
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');

                        input.onchange = function () {
                            var file = this.files[0];
                            var reader = new FileReader();
                            reader.onload = function () {
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = window.tinyMCE.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                        };
                        input.click();
                    }
                }} />
            </SimpleForm>
        </Edit>
    </Fragment>
})