
export default (
    previousState = { showApplyCourse: false, showSelectCourse: false, showSelectCourseNotification: false, showTransactionDetail: false },
    { type, payload }) => {

    if (type === 'START_TRANSACTION') {
        return {
            ...previousState,
            showApplyCourse: payload.showApplyCourse,
            studentIds: payload.ids,
            applyType: payload.applyType,
            transactionDefaultValue: payload.defaultValue,
            transactionMessage: ''

        };
    } else if (type === 'SHOW_SELECT_COURSE') {
        return {
            ...previousState,
            showSelectCourse: payload.showSelectCourse
        };
    } else if (type === 'SHOW_SELECT_COURSE_NOTIFICATION') {
        return {
            ...previousState,
            showSelectCourseNotification: payload.showSelectCourseNotification
        };
    } else if (type === 'SHOW_CLASS_TRANSFER') {
        return {
            ...previousState,
            ids: payload.ids,
            learningId: payload.learningId,
            learningClassId: payload.learningClassId,
            title: payload.title,
            showClassTransfer: payload.show,
            selectedCourse: payload.selectedCourse,
            selectedClass: payload.selectedClass,
            studentIds: payload.ids
        };
    } else if (type === 'SHOW_REFUND') {
        return {
            ...previousState,
            SHOW_REFUND: payload.show,
            studentIds: payload.ids
        };
    } else if (type === 'SET_TRANSACTION_MESSAGE') {
        return {
            ...previousState,
            transactionMessage: payload.message
        };
    } else if (type === 'PRINT_CONTRACT') {
        return {
            ...previousState,
            showPrintContract: payload.show,
            transaction: payload.transaction
        };
    } else if (type === 'SHOW_TRANSACTION_DETAIL') {
        return {
            ...previousState,
            showTransactionDetail: payload.show
        };
    }
    return previousState;
}