import React, { Component } from 'react'
import { SelectArrayInput, ReferenceArrayInput, required, Loading, DateField, ArrayField, ReferenceArrayField, SingleFieldList, ChipField, FunctionField, ArrayInput, TabbedForm, FormTab, GET_LIST, withDataProvider, List, BooleanInput, ReferenceField, Datagrid, EditButton, NumberInput, TextField, Create, ReferenceInput, SimpleForm, TextInput, SelectInput, Edit, Filter, Show } from 'react-admin';
import Calendar from '../../components/Widgets/Calendar'
import ListActions from '../../components/Widgets/ListActions';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ShowFilterWhenHasOp from '../../util/ShowFilterWhenHasOp';

const TimetableFilter = (props) => {
    var user = JSON.parse(localStorage.getItem("user"))
    var teacherFilterQueryObj = { isTeacher: true }

    if (user && user.selectedBranch) {
        teacherFilterQueryObj.branchId = user.selectedBranch
    }

    return (
        <Filter {...ShowFilterWhenHasOp(props)}>
            <TextInput label="班级名称" source="name.$substring" alwaysOn />

            <ReferenceInput label="教师"
                //source="teachers.teacherId$contains"
                source="teacherId"
                reference="employees"
                filter={teacherFilterQueryObj}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>)
}

const Calendar2 = ({ ids, data, basePath }) => {

    let classSchedules=ids.map(id =>data[id])
    return <Calendar
        events={classSchedules}
    />
}



export default ((props) => {
    return <List actions={<ListActions />} filters={<TimetableFilter />} {...props} perPage="10000">
        <Calendar2></Calendar2>
    </List>
})